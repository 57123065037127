import {
  CountryCode,
  Tier,
  TierConfigParsed,
  TierManager,
} from '@alamere/core';
import { Autocomplete, SxProps, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTierConfigLazyQuery } from '../graphql/_generated/generated-graphql-types';

export interface Props {
  onChange: (tier: Tier | null) => void;
  sx?: SxProps;
  countryCode?: CountryCode | null;
  useGeoDiff: boolean;
  value: Tier | null;
}

export function TierPicker({
  sx,
  onChange,
  countryCode,
  useGeoDiff,
  value,
}: Props) {
  const [fetchTierConfig, { data: tierConfigData }] = useTierConfigLazyQuery();
  const [options, setOptions] = useState<Tier[]>([]);

  useEffect(() => {
    if (countryCode === CountryCode.US && useGeoDiff) {
      fetchTierConfig();
    } else {
      setOptions([]);
      onChange(null);
    }
  }, [countryCode, useGeoDiff]);

  useEffect(() => {
    if (
      tierConfigData?.tierConfig.tierConfigJson &&
      countryCode === CountryCode.US
    ) {
      const newTierManager = new TierManager(
        JSON.parse(tierConfigData.tierConfig.tierConfigJson) as TierConfigParsed
      );
      const newOptions = newTierManager.getAlltiers();
      setOptions(newOptions);

      handleChange(newOptions[0]);
    } else {
      setOptions([]);
    }
  }, [tierConfigData, countryCode]);

  const handleChange = (v: Tier | null) => {
    onChange(v);
  };

  return (
    <Autocomplete
      sx={sx}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoComplete
      blurOnSelect
      value={value}
      disabled={options.length === 0}
      size="small"
      options={options}
      getOptionLabel={(option) => option.label || `Tier ${option.tierNumber}`}
      noOptionsText={t('global.noOptions')}
      onChange={(e, v) => handleChange(v)}
      isOptionEqualToValue={(option, value) =>
        option.tierNumber === value?.tierNumber
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.tierNumber}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          label={
            value
              ? t('components.TierPicker.inputLabelSelected')
              : options.length > 0
              ? t('components.TierPicker.inputLabel')
              : t('components.TierPicker.notAvailable')
          }
          {...params}
        />
      )}
    />
  );
}
