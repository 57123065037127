import { CsvUploadsModal } from './CsvUploadsModal';
import { useApolloClient } from '@apollo/client';
import { JobFamilyFragment } from '@alamere/generated-graphql-types';
import { useSnackbar } from 'notistack';
import { Button, Box } from '@mui/material';
import UploadRounded from '@mui/icons-material/UploadRounded';
import { t } from 'i18next';
import { useState } from 'react';
import {
  jobFamiliesHeaders,
  jobFamiliesExampleContent,
  validateJobFamilyRow,
  processJobFamilyData,
  preValidateJobFamilies,
  useFetchReferenceData,
} from './helpers/JobFamiliesCsv.helpers';
import { CsvValue } from './CsvUploadsModal';
import { CsvValidationResult } from './helpers/csvUpload.helpers';

interface JobFamiliesCsvUploadProps {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  onDataProcessed: (newEntities: Record<string, JobFamilyFragment>) => void;
  existingItems: JobFamilyFragment[];
}

export const JobFamiliesCsvUpload = ({
  editing,
  setEditing,
  onDataProcessed,
  existingItems,
}: JobFamiliesCsvUploadProps) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isImporting, setIsImporting] = useState(false);
  const fetchReferenceData = useFetchReferenceData();

  // Pre-validating function for the CSV data
  const preValidateData = async (
    result: CsvValidationResult
  ): Promise<{
    warnings: string[];
    isValid: boolean;
  }> => {
    if (!result.valid) {
      return { warnings: [], isValid: false };
    }

    try {
      // Run pre-validation without saving data to check for warnings
      const validationResults = await preValidateJobFamilies(
        result.groupedData,
        client,
        fetchReferenceData
      );

      return {
        warnings: validationResults.warnings,
        isValid: validationResults.criticalErrors.length === 0,
      };
    } catch (error) {
      console.error('Error during pre-validation:', error);
      return { warnings: [], isValid: true };
    }
  };

  const handleProcessData = async (
    processedData: Record<string, Array<Record<string, CsvValue>>>
  ): Promise<void> => {
    try {
      if (!editing) {
        setEditing(true);
      }

      setIsImporting(true);

      const { newUpdates, criticalErrors } = await processJobFamilyData(
        processedData,
        client,
        existingItems,
        enqueueSnackbar,
        fetchReferenceData
      );

      // If there are critical errors, show them and stop processing
      if (criticalErrors.length > 0) {
        throw new Error(
          t('csvUpload.criticalErrors', 'Critical errors found: ') +
            criticalErrors.join(', ')
        );
      }

      if (Object.keys(newUpdates).length > 0) {
        onDataProcessed(newUpdates);
        enqueueSnackbar(
          `${t('csvUpload.successMessage', 'Successfully processed')} ${
            Object.keys(newUpdates).length
          } ${t('jobFamiliesPage.items', 'items')}`,
          { variant: 'success' }
        );
      } else {
        enqueueSnackbar(
          t(
            'csvUpload.noDataProcessed',
            'No data was processed. Please check your CSV file.'
          ),
          { variant: 'warning' }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        `${t('csvUpload.errorProcessing', 'Error processing CSV')}: ${
          error instanceof Error
            ? error.message
            : t('csvUpload.unknownError', 'Unknown error')
        }`,
        { variant: 'error' }
      );
      throw error; // Rethrow so the CsvUploadsModal can handle it
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <Box sx={{ display: 'inline-block' }}>
      <CsvUploadsModal
        pageName={t('jobFamiliesPage.pageName', 'Job Families')}
        headers={jobFamiliesHeaders}
        exampleContent={jobFamiliesExampleContent}
        templateFileName="job_families_template.csv"
        validateRow={validateJobFamilyRow}
        processData={handleProcessData}
        preValidateData={preValidateData}
        showGroupCounts={false}
        renderButton={(onClick) => (
          <Button
            sx={{ width: 'fit-content' }}
            variant="outlined"
            startIcon={<UploadRounded />}
            onClick={onClick}
            disabled={!editing || isImporting}
          >
            {isImporting
              ? t('common.processing', 'Processing...')
              : t('jobFamiliesPage.bulkUpload', 'Bulk Upload')}
          </Button>
        )}
      />
    </Box>
  );
};
