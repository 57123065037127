import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';

import {
  WorkspaceIdAndNameFragment,
  useSignupFromInvitationMutation,
} from '@alamere/generated-graphql-types';
import { GoogleLogin } from '@react-oauth/google';
import { useCallback, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/auth';
import { useGoogleLogin } from '../useGoogleLogin';

export interface SignUpWithAutoSignupProps {
  email: string;
  workspace: WorkspaceIdAndNameFragment;
}
export default function SignUpWithAutoSignup({
  email: emailFromProps,
  workspace,
}: SignUpWithAutoSignupProps) {
  const [signup, { error }] = useSignupFromInvitationMutation();
  const { onLogIn, user } = useAuth();
  const [loginWithGoogle] = useGoogleLogin();
  const [email, setEmail] = useState(emailFromProps);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const onJoin = () => {
    signup({
      variables: {
        createUserRequest: {
          firstName,
          lastName,
          email,
          password,
          workspaceId: workspace.id,
        },
      },
    }).then(() =>
      navigate({
        pathname: '/login',
        search: createSearchParams({ email, password }).toString(),
      })
    );
  };

  const onGoogleLogin = useCallback(
    (googleTokenLoginRequest: { credential?: string; clientId?: string }) => {
      if (
        !googleTokenLoginRequest.credential ||
        !googleTokenLoginRequest.clientId
      ) {
        return;
      }

      loginWithGoogle(googleTokenLoginRequest).then((response) => {
        return onLogIn(response?.data?.loginWithGoogle.access_token);
      });
    },
    []
  );

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        spacing={3}
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <PersonAddIcon />

        <Typography component="h1" variant="h5" textAlign="center">
          {t('signUpPage.joinWorkspace', { workspaceName: workspace.name })}
        </Typography>
        <Box>
          <GoogleLogin
            size="large"
            onSuccess={onGoogleLogin}
            width={500}
            logo_alignment="center"
            useOneTap={false}
          />
        </Box>
        <Divider sx={{ py: 1, px: 15 }}>
          <Typography color="text.secondary">or</Typography>
        </Divider>
        <TextField
          margin="normal"
          required
          fullWidth
          id="given-name"
          label="First Name"
          name="given-name"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="family-name"
          label="Last Name"
          name="family-name"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button fullWidth variant="contained" size="large" onClick={onJoin}>
          {t('global.next')}
        </Button>
      </Stack>
    </Container>
  );
}
function acceptInvitationWithGoogle(arg0: {
  variables: {
    createGoogleUserInWorkspaceRequest: {
      credential: string;
      workspaceId: any;
      code: any;
    };
  };
}) {
  throw new Error('Function not implemented.');
}
