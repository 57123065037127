import { Scope, ScopeMetadata, ScopesByScopeGroup } from '@alamere/core';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from '@mui/material';
import flagsmith from 'flagsmith';
import { t } from 'i18next';
import React from 'react';

const TableHeader = React.memo(() => (
  <TableHead>
    <TableRow>
      <TableCell>{t('Group')}</TableCell>
      <TableCell>{t('Scope')}</TableCell>
      <TableCell align="center">{t('View')}</TableCell>
      <TableCell align="center">{t('Edit')}</TableCell>
      <TableCell align="center">{t('View Notes')}</TableCell>
    </TableRow>
  </TableHead>
));

export function ScopeTable({
  currentScopes,
  onToggleScope = () => null,
  areAllSwitchesDisabled = false,
}: {
  currentScopes: Record<Scope, boolean>;
  onToggleScope?: (id: Scope) => void;
  areAllSwitchesDisabled?: boolean;
}) {
  function renderRow(
    viewScope: ScopeMetadata,
    dependentScopes: ScopeMetadata[] = []
  ) {
    return (
      <TableRow key={viewScope.id}>
        <TableCell>{t(viewScope.featureGroup)}</TableCell>

        <TableCell>
          {t(viewScope.label)
            .replace(/\b(VIEW|EDIT)\b/gi, '')
            .trim()}
        </TableCell>

        <TableCell align="center">
          <Switch
            checked={!!currentScopes[viewScope.id]}
            onChange={() => onToggleScope(viewScope.id)}
            disabled={areAllSwitchesDisabled}
          />
        </TableCell>

        {dependentScopes.map((depScope) => (
          <TableCell align="center" key={depScope.id}>
            <Switch
              checked={!!currentScopes[depScope.id]}
              onChange={() => onToggleScope(depScope.id)}
              disabled={!currentScopes[viewScope.id] || areAllSwitchesDisabled} // Disable if the view scope is not selected
            />
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function renderBody() {
    return (
      <TableBody>
        {Object.values(ScopesByScopeGroup).map((groupScopes) => {
          const availableScopes = groupScopes.filter(
            (scope) =>
              !scope.flags ||
              scope.flags.every((requiredFlag) =>
                flagsmith.hasFeature(requiredFlag)
              )
          );

          if (availableScopes.length === 0) return null;

          const [viewScope] =
            availableScopes.length === 1
              ? availableScopes
              : availableScopes.filter(
                  ({ requires }) => requires === undefined
                );
          const dependentScopes =
            availableScopes.length === 1
              ? []
              : availableScopes.filter(({ id }) => id !== viewScope.id);

          return renderRow(viewScope, dependentScopes);
        })}
      </TableBody>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHeader />
        {renderBody()}
      </Table>
    </TableContainer>
  );
}
