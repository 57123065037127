import { Scope } from '@alamere/core';
import { Box, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useScopes } from '../../hooks/useScopes';

const MenuItems = [
  { href: 'single', labelKey: 'locationLookupPage' },
  {
    href: 'multiple',
    labelKey: 'multiZipLookupPage',
    requiredScopes: [Scope.LOCATION_CALCULATOR_MULTIPLE_VIEW],
  },
];

export default function LocationCalculatorContainerPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { checkScopes } = useScopes();
  const pathRoot = useMemo(
    () => pathname.split('/').slice(0, -1).join('/'),
    [pathname]
  );
  const currentTabPath = useMemo(() => pathname.split('/').pop(), [pathname]);
  const currentTabIndex = useMemo(
    () => MenuItems.findIndex((item) => item.href === currentTabPath),
    [currentTabPath]
  );

  return (
    <>
      <Box sx={{ width: '100%', mb: 5 }}>
        <Tabs
          value={currentTabIndex === -1 ? 0 : currentTabIndex}
          aria-label="location calculator tabs navigation"
        >
          {MenuItems.filter(({ requiredScopes }) =>
            requiredScopes ? checkScopes(...requiredScopes) : true
          ).map((item, index) => (
            <Tab
              key={index}
              component="a"
              label={t(`${item.labelKey}.htmlTitle`)}
              onClick={() => navigate([pathRoot, item.href].join('/'))}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </>
  );
}
