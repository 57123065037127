import {
  Chip,
  Stack,
  TableCell,
  Typography,
  SxProps,
  Box,
  Tooltip,
} from '@mui/material';
import { memo, useState } from 'react';
import {
  JobFragment,
  JobGroup,
  JobLevelFragment,
} from '../../../graphql/_generated/generated-graphql-types';
import CellItem from './CellItem';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';
import EditIcon from '@mui/icons-material/EditRounded';
import { IconButton } from '@mui/material';
import NewJobButton from './NewJobButton';

interface Props {
  jobs: JobFragment[];
  jobFamilyId: number;
  jobGroup: JobGroup;
  jobLevel?: JobLevelFragment;
  editing: boolean;
  onItemChange: OnItemChangeFunction;
  onToggleExpand?: () => void;
  sx?: SxProps;
}
export default memo(
  ({
    jobs,
    editing,
    jobLevel,
    jobFamilyId,
    onItemChange,
    onToggleExpand,
    sx,
  }: Props) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
      <TableCell
        width="fit-content"
        sx={{ maxWidth: editing ? 330 : 300, ...sx }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {jobLevel && (
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="flex-start"
          >
            {(jobs || editing) && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: 1,
                    width: 100,
                    mr: 1,
                  }}
                >
                  <Chip size="small" label={jobLevel.code} />
                  <Tooltip title={jobLevel.name}>
                    <Typography
                      sx={{
                        lineHeight: 1,
                        maxWidth: 100,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant="caption"
                      color="text.secondary"
                    >
                      {jobLevel.name}
                    </Typography>
                  </Tooltip>
                </Box>
                {jobs && (
                  <Stack>
                    <Stack gap={0.5}>
                      {jobs?.map((job) => (
                        <CellItem
                          job={job}
                          editing={editing}
                          key={job.id}
                          onItemChange={onItemChange}
                          onToggleExpand={onToggleExpand}
                        />
                      ))}
                    </Stack>
                  </Stack>
                )}
              </>
            )}
            {editing && (
              <IconButton
                sx={{
                  height: 'fit-content',
                  visibility: hovered ? 'visible' : 'hidden',
                }}
                size="small"
                onClick={onToggleExpand}
              >
                <EditIcon />
              </IconButton>
            )}
            {editing && (
              <NewJobButton
                visible={hovered}
                jobLevel={jobLevel}
                jobFamilyId={jobFamilyId}
                editing={editing}
                onItemChange={onItemChange}
              />
            )}
          </Stack>
        )}
      </TableCell>
    );
  }
);
