import { useApolloClient } from '@apollo/client';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { memo } from 'react';
import { JobFamilyFragment } from '../../../graphql/_generated/generated-graphql-types';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  hovered: boolean;
  onItemChange: OnItemChangeFunction;
}

export const Delete = memo(
  ({ editing, jobFamily, hovered, onItemChange }: Props) => {
    const { cache } = useApolloClient();

    const onDelete = () => {
      onItemChange({ jobFamilyIdToDelete: jobFamily.id });

      // Only try to evict from cache if it's an existing record (not a temp one)
      // Temp records have IDs that were generated client-side
      if (jobFamily.id && jobFamily.__typename === 'JobFamily') {
        try {
          cache.evict({ id: `JobFamily:${jobFamily.id}` });
        } catch (error) {
          console.debug('Could not evict from cache:', error);
        }
      }
    };

    if (!editing) {
      return null;
    }

    return (
      <TableCell align="center">
        <Tooltip title={`${t('global.delete')} ${jobFamily.name}`}>
          <IconButton
            onClick={onDelete}
            size="small"
            sx={{ visibility: hovered ? 'visible' : 'hidden' }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    );
  }
);
