import { t } from 'i18next';
import Papa from 'papaparse';
import { CsvHeader, CsvValue } from '../CsvUploadsModal';

/**
 * Enum for the different steps of the CSV upload process
 */
export enum CsvUploadStep {
  Upload = 0,
  Review = 1,
  Complete = 2,
}

/**
 * Interface for validation error tracking
 */
export interface ValidationErrorMap {
  [errorMessage: string]: {
    count: number;
    lines: number[];
  };
}

/**
 * Interface for CSV validation result
 */
export interface CsvValidationResult {
  valid: boolean;
  errorMap: ValidationErrorMap;
  groupedData: Record<string, Array<Record<string, CsvValue>>>;
}

/**
 * Papa Parse config with common settings
 */
export const getDefaultPapaConfig = () => ({
  skipEmptyLines: 'greedy' as const,
  delimitersToGuess: [',', '\t', '|', ';'],
  dynamicTyping: false, // We'll handle type conversion explicitly
  header: true,
  trimHeaders: true,
  transformHeader: (header: string) => header.trim(),
});

/**
 * Validates the presence of required headers
 */
export const validateHeaders = (
  parsedHeaders: string[],
  expectedHeaders: CsvHeader[]
): string | null => {
  // Check if all required headers are present
  const requiredHeaderLabels = expectedHeaders
    .filter((h) => h.required)
    .map((h) => h.label);

  const missingHeaders = requiredHeaderLabels.filter(
    (requiredHeader) => !parsedHeaders.includes(requiredHeader)
  );

  if (missingHeaders.length > 0) {
    return t(
      'csvUpload.missingRequiredHeaders',
      'Missing required headers: {{headers}}',
      { headers: missingHeaders.join(', ') }
    );
  }

  return null;
};

/**
 * Performs field-level type validation based on header configuration
 */
export const validateFieldValue = (
  value: string,
  header: CsvHeader
): {
  valid: boolean;
  errorMessage: string | null;
  processedValue: CsvValue;
} => {
  // For non-required fields, empty is valid - but for numeric types, use 0 instead of null
  if (value === undefined || value === null || value === '') {
    if (header.type === 'number' || header.type === 'percentage') {
      return { valid: true, errorMessage: null, processedValue: 0 };
    } else if (!header.required) {
      return { valid: true, errorMessage: null, processedValue: null };
    } else {
      return {
        valid: false,
        errorMessage: `Missing ${header.label}`,
        processedValue: null,
      };
    }
  }

  if (header.type === 'number' || header.type === 'percentage') {
    const numValue = Number(value);
    if (isNaN(numValue)) {
      return {
        valid: false,
        errorMessage: `"${value}" is not a valid number for ${header.label}`,
        processedValue: null,
      };
    }

    if (numValue < 0 && header.type === 'percentage') {
      return {
        valid: false,
        errorMessage: `${header.label} cannot be negative`,
        processedValue: null,
      };
    }

    return { valid: true, errorMessage: null, processedValue: numValue };
  }

  // Default case for string fields
  return { valid: true, errorMessage: null, processedValue: value };
};

/**
 * Validates a complete CSV row against header configuration
 */
export const validateRowAgainstHeaders = (
  row: Record<string, string>,
  headers: CsvHeader[],
  rowIndex: number,
  errorMap: ValidationErrorMap
): { valid: boolean; processedRow: Record<string, CsvValue> } => {
  const processedRow: Record<string, CsvValue> = {};
  let rowValid = true;

  // First check required fields
  for (const header of headers) {
    // Convert header label to key to access the row data
    const headerKey = header.key;
    const value = row[header.label] !== undefined ? row[header.label] : '';

    // Check required fields
    if (header.required && !value) {
      const errorKey = `Missing ${header.label}`;
      if (!errorMap[errorKey]) {
        errorMap[errorKey] = { count: 0, lines: [] };
      }
      errorMap[errorKey].count++;
      errorMap[errorKey].lines.push(rowIndex);
      rowValid = false;
    }

    // Validate type
    if (value || header.required) {
      const validation = validateFieldValue(value, header);
      if (!validation.valid && validation.errorMessage) {
        const errorKey = validation.errorMessage;
        if (!errorMap[errorKey]) {
          errorMap[errorKey] = { count: 0, lines: [] };
        }
        errorMap[errorKey].count++;
        errorMap[errorKey].lines.push(rowIndex);
        rowValid = false;
      } else {
        processedRow[headerKey] = validation.processedValue;
      }
    } else {
      processedRow[headerKey] = null;
    }
  }

  return { valid: rowValid, processedRow };
};

/**
 * Apply custom row validation if provided
 */
export const applyCustomValidation = (
  row: Record<string, string>,
  rowIndex: number,
  validateRow:
    | ((row: Record<string, string>, rowIndex: number) => string | null)
    | undefined,
  errorMap: ValidationErrorMap
): boolean => {
  if (!validateRow) return true;

  const validationError = validateRow(row, rowIndex);
  if (validationError) {
    // Extract the error message without the line number prefix
    const errorMessage = validationError.replace(/^Line \d+: /, '');

    if (!errorMap[errorMessage]) {
      errorMap[errorMessage] = { count: 0, lines: [] };
    }
    errorMap[errorMessage].count++;
    errorMap[errorMessage].lines.push(rowIndex + 2); // +2 for 1-based index and header row
    return false;
  }

  return true;
};

/**
 * Parse and validate a CSV file using Papa Parse
 */
export const parseAndValidateCsv = (
  file: File,
  headers: CsvHeader[],
  validateRow?: (
    row: Record<string, string>,
    rowIndex: number
  ) => string | null,
  groupByField: string = headers[0]?.key
): Promise<CsvValidationResult> => {
  return new Promise((resolve, reject) => {
    const errorMap: ValidationErrorMap = {};
    const groupedData: Record<string, Array<Record<string, CsvValue>>> = {};

    Papa.parse(file, {
      ...getDefaultPapaConfig(),
      error: (error) => {
        reject(new Error(`CSV parsing error: ${error.message}`));
      },
      complete: (results) => {
        if (results.data.length === 0) {
          reject(new Error(t('csvUpload.emptyFile', 'The file is empty')));
          return;
        }

        // Validate headers
        const parsedHeaders = Object.keys(
          results.data[0] as Record<string, string>
        );
        const headerError = validateHeaders(parsedHeaders, headers);

        if (headerError) {
          reject(new Error(headerError));
          return;
        }

        // Process each row
        results.data.forEach((rowData, i) => {
          const typedRow = rowData as Record<string, string>;

          // Apply custom validation if provided
          const customValid = applyCustomValidation(
            typedRow,
            i,
            validateRow,
            errorMap
          );
          if (!customValid) return;

          // Validate against header configuration
          const { valid, processedRow } = validateRowAgainstHeaders(
            typedRow,
            headers,
            i + 2, // +2 for 1-based index and header row
            errorMap
          );

          if (!valid) return;

          // Group data if groupByField is specified
          const groupKey = String(processedRow[groupByField] || 'default');
          if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
          }

          groupedData[groupKey].push(processedRow);
        });

        // Return validation result
        resolve({
          valid: Object.keys(errorMap).length === 0,
          errorMap,
          groupedData,
        });
      },
    });
  });
};
