import { CountryCode, Scope } from '@alamere/core';
import {
  CompBandFragment,
  CompBandSaveRequest,
  GlobalLevelFragment,
  useCompBandGroupDeleteMutation,
  useCompBandGroupsQuery,
  useCompBandGroupsSaveMutation,
  useCompBandSaveMutation,
  useGlobalLevelsQuery,
  useJobLevelsQuery,
} from '@alamere/generated-graphql-types';
import AddIcon from '@mui/icons-material/Add';
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { t } from 'i18next';
import { cloneDeep, orderBy } from 'lodash';
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EditButtons } from '../../components/EditButtons';
import { useScopes } from '../../hooks/useScopes';
import {
  CompBandGroup,
  CompBandGroupUpdates,
  CompBandUpdates,
  OnCompBandChangeFunction,
  OnCompBandGroupChangeFunction,
  OnCompBandGroupDeleteFunction,
  RowData,
} from './types';
import { useAuth } from '../../providers/auth';
import { useSnackbar } from 'notistack';
import {
  CsvHeader,
  CsvUploadsModal,
  CsvValue,
} from '../CsvUploads/CsvUploadsModal';

export function CompBandsPage() {
  const { checkScopes } = useScopes();
  const { workspaceMembership } = useAuth();
  const { data: globalLevelData, loading: loadingGlobalLevel } =
    useGlobalLevelsQuery();
  const {
    data: compBandGroupsData,
    refetch: refetchCompBandGroups,
    loading: loadingCompBandGroups,
  } = useCompBandGroupsQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });
  const visibleGlobalLevels = useMemo(
    () =>
      globalLevelData?.globalLevels.filter((level) =>
        jobLevelsData?.jobLevels.some(
          (jobLevel) => jobLevel?.level === level.level
        )
      ),
    [globalLevelData, jobLevelsData]
  );

  const [saveCompBands] = useCompBandSaveMutation();
  const [saveCompBandGroups] = useCompBandGroupsSaveMutation();
  const [deleteCompBandGroup] = useCompBandGroupDeleteMutation();

  const [globalLevels, setGlobalLevels] = useState<GlobalLevelFragment[]>([]);
  const [rows, setRows] = useState<RowData[]>([]);
  const [compBandGroups, setCompBandGroups] = useState<CompBandGroup[]>([]);
  const [updates, setUpdates] = useState<CompBandUpdates>({});
  const [compBandGroupUpdates, setCompBandGroupUpdates] =
    useState<CompBandGroupUpdates>({});
  const [editing, setEditing] = useState<boolean>(false);
  const [resetKey, setResetKey] = useState(0);
  const [widthUpdateErrors, setWidthUpdateErrors] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (visibleGlobalLevels && compBandGroupsData?.compBandsGroups) {
      const orderedGlobalLevels = orderBy(
        visibleGlobalLevels,
        ['level'],
        ['desc']
      );
      const sortedGroups = orderBy(
        compBandGroupsData.compBandsGroups,
        ['id'],
        ['asc']
      );

      const rows = orderedGlobalLevels.map((globalLevel) => {
        return {
          globalLevel,
          compBands: sortedGroups.map((compBandGroup) => {
            return compBandGroup.compBands.find(
              (compBand) => compBand.globalLevelLevel === globalLevel.level
            ) as CompBandFragment;
          }),
        };
      });

      setGlobalLevels(orderedGlobalLevels);
      setCompBandGroups(sortedGroups);
      setRows(rows);
      setUpdates({});
      setCompBandGroupUpdates({});
    }
  }, [visibleGlobalLevels, compBandGroupsData]);

  const handleCompBandChange = useCallback<OnCompBandChangeFunction>(
    ({ compBand }) => {
      setUpdates((prevUpdates) => ({
        ...prevUpdates,
        [compBand.globalLevelLevel]: {
          ...prevUpdates[compBand.globalLevelLevel],
          [compBand.compBandGroupId]: compBand,
        },
      }));
    },
    []
  );

  const handleDeleteCompBandGroup = useCallback<OnCompBandGroupDeleteFunction>(
    async (id) => {
      await deleteCompBandGroup({ variables: { compBandGroupDeleteId: id } });
      refetchCompBandGroups();
    },
    []
  );

  const validate = () => {
    const widthErrors: string[] = [];

    Object.values(updates).forEach((levelUpdates) => {
      Object.values(levelUpdates).forEach((update: any) => {
        if (update.percentVariable !== 0 && update.absoluteVariable !== 0) {
          widthErrors.push(
            `Percent and absolute variables cannot be both set for level ${update.globalLevelLevel}.`
          );
        }

        if (update.onTargetEarnings > 0 && update.hourlyRate > 0) {
          widthErrors.push(
            `On target earnings and hourly rate cannot be both set for level ${update.globalLevelLevel}.`
          );
        }
      });
    });

    setWidthUpdateErrors(widthErrors);
    return widthErrors.length === 0;
  };

  const handleSave = async () => {
    const isValid = validate();

    if (isValid) {
      const widthUpdates = Object.values(updates).flatMap(
        (levelUpdates) => Object.values(levelUpdates) as CompBandSaveRequest[]
      );
      const groupUpdates = Object.values(compBandGroupUpdates);

      try {
        if (widthUpdates.length > 0) {
          await saveCompBands({
            variables: {
              compBandSaveRequest: {
                items: widthUpdates,
              },
            },
          });
        }
        if (groupUpdates.length > 0) {
          await saveCompBandGroups({
            variables: {
              compBandGroupSaveManyRequest: {
                items: groupUpdates,
              },
            },
          });
        }

        await refetchCompBandGroups();
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(`Save failed: ${error.message}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Save failed due to an unknown error', {
            variant: 'error',
          });
        }

        return;
      }

      setEditing(false);
    }
  };

  const handleCreateNewGroup = async () => {
    await saveCompBandGroups({
      variables: {
        compBandGroupSaveManyRequest: {
          items: [{ name: t('compBandsPage.newGroupName') }],
        },
      },
    });
    await refetchCompBandGroups();
  };

  const handleCompBandGroupChange = useCallback<OnCompBandGroupChangeFunction>(
    (compBandGroup) => {
      setCompBandGroupUpdates((updates) => {
        const newUpdates = cloneDeep(updates);
        newUpdates[compBandGroup.id] = compBandGroup;
        return newUpdates;
      });
    },
    []
  );

  const LazyCompBandsTable = lazy(() => import('./CompBandsTable'));
  const tableComponent = useMemo(() => {
    return (
      <LazyCompBandsTable
        key={resetKey}
        globalLevel={globalLevels}
        rows={rows}
        compBandGroups={compBandGroups}
        editing={editing}
        onCompBandChange={handleCompBandChange}
        onCompBandGroupChange={handleCompBandGroupChange}
        onCompBandGroupDelete={handleDeleteCompBandGroup}
      />
    );
  }, [globalLevels, rows, compBandGroups, editing, resetKey]);

  const handleCancel = () => {
    setEditing(false);
    setUpdates({});
    setCompBandGroupUpdates({});
    setResetKey((prevKey) => prevKey + 1);
  };

  const noData = useMemo(
    () => rows.every((row) => row.compBands.length === 0),
    [rows]
  );

  // Define the headers for the CSV
  const csvHeaders: CsvHeader[] = [
    { key: 'groupName', label: 'Group Name', required: true },
    { key: 'globalLevel', label: 'Global Level', required: true },
    { key: 'onTargetEarnings', label: 'On Target Earnings', type: 'number' },
    { key: 'hourlyRate', label: 'Hourly Rate', type: 'number' },
    { key: 'percentVariable', label: '% Variable', type: 'number' },
    { key: 'absoluteVariable', label: 'Absolute Variable', type: 'number' },
  ];

  // Generate example content for the CSV download
  const generateExampleContent = () => {
    let csvContent = `${csvHeaders.map((h) => h.label).join(',')}\n`;

    for (const level of globalLevels) {
      csvContent += `Example Group,${level.name || `L${level.level}`},,,,\n`;
    }

    for (const level of globalLevels) {
      csvContent += `Another Group,${level.name || `L${level.level}`},,,,\n`;
    }

    return csvContent;
  };

  // Row validation function
  const validateCompBandRow = (
    row: Record<string, string>,
    rowIndex: number
  ) => {
    // The keys should match the exact header labels from the CSV
    const values = {
      onTargetEarnings:
        row['On Target Earnings'] && row['On Target Earnings'].trim() !== ''
          ? parseFloat(row['On Target Earnings'])
          : 0,
      hourlyRate:
        row['Hourly Rate'] && row['Hourly Rate'].trim() !== ''
          ? parseFloat(row['Hourly Rate'])
          : 0,
      percentVariable:
        row['% Variable'] && row['% Variable'].trim() !== ''
          ? parseFloat(row['% Variable'])
          : 0,
      absoluteVariable:
        row['Absolute Variable'] && row['Absolute Variable'].trim() !== ''
          ? parseFloat(row['Absolute Variable'])
          : 0,
    };

    // Check if any conversions resulted in NaN
    const fields = {
      'On Target Earnings': 'onTargetEarnings',
      'Hourly Rate': 'hourlyRate',
      '% Variable': 'percentVariable',
      'Absolute Variable': 'absoluteVariable',
    };

    for (const [header, field] of Object.entries(fields)) {
      if (
        isNaN(values[field as keyof typeof values]) &&
        row[header] &&
        row[header].trim() !== ''
      ) {
        return `Line ${rowIndex + 1}: ${header} must be a valid number`;
      }
    }

    // Check for negative values
    for (const [header, field] of Object.entries(fields)) {
      if (values[field as keyof typeof values] < 0) {
        return `Line ${rowIndex + 1}: ${header} cannot be negative`;
      }
    }

    return null;
  };

  // Process data function
  const processCompBandData = useCallback(
    async (groupedData: Record<string, Array<Record<string, CsvValue>>>) => {
      try {
        const defaultCountry = 'US' as CountryCode;

        // Process each group
        for (const [groupName, items] of Object.entries(groupedData)) {
          const result = await saveCompBandGroups({
            variables: {
              compBandGroupSaveManyRequest: {
                items: [{ name: groupName }],
              },
            },
          });

          // Get the newly created group ID
          const newGroupId = result.data?.compBandGroupsSave?.[0]?.id;
          if (!newGroupId) {
            throw new Error('Failed to create new group');
          }

          // Process the rows and create the comp band entries
          const compBandRequests: CompBandSaveRequest[] = [];

          for (const item of items) {
            // Try different possible key formats for global level
            const possibleKeys = [
              'Global Level',
              'globalLevel',
              'global level',
              'global_level',
              'GLOBAL LEVEL',
            ];

            let globalLevelValue: any = undefined;

            // Check each possible key
            for (const key of possibleKeys) {
              if (item[key] !== undefined) {
                globalLevelValue = item[key];
                break;
              }
            }

            // If still undefined, check if there's a key that contains "level" or "global"
            if (globalLevelValue === undefined) {
              const levelKey = Object.keys(item).find(
                (key) =>
                  key.toLowerCase().includes('level') ||
                  key.toLowerCase().includes('global')
              );

              if (levelKey) {
                globalLevelValue = item[levelKey];
              }
            }

            if (globalLevelValue === undefined) {
              continue;
            }

            const globalLevelStr = String(globalLevelValue).trim();
            const levelNumberMatch = globalLevelStr.match(/L?(\d+)/i);
            const levelNumber = levelNumberMatch
              ? Number(levelNumberMatch[1])
              : null;

            // Look for a direct match or a numeric level match
            const foundGlobalLevel = globalLevels.find((level) => {
              // Check exact name match
              if (level.name === globalLevelStr) {
                return true;
              }

              // Check formatted level match
              if (`L${level.level}` === globalLevelStr) {
                return true;
              }

              // Check numeric level match
              if (levelNumber !== null && level.level === levelNumber) {
                return true;
              }

              // Check special case for intern
              if (
                globalLevelStr.toLowerCase() === 'int' &&
                level.name?.toLowerCase() === 'intern'
              ) {
                return true;
              }

              return false;
            });

            if (!foundGlobalLevel) {
              continue;
            }

            if (workspaceMembership?.workspace.id) {
              // Convert values to numbers - handle different possible key formats
              const onTargetEarnings =
                parseFloat(
                  String(
                    item['On Target Earnings'] || item.onTargetEarnings || 0
                  )
                ) || 0;
              const hourlyRate =
                parseFloat(
                  String(item['Hourly Rate'] || item.hourlyRate || 0)
                ) || 0;
              const percentVariable =
                parseFloat(
                  String(item['% Variable'] || item.percentVariable || 0)
                ) || 0;
              const absoluteVariable =
                parseFloat(
                  String(
                    item['Absolute Variable'] || item.absoluteVariable || 0
                  )
                ) || 0;

              compBandRequests.push({
                compBandGroupId: newGroupId,
                globalLevelLevel: foundGlobalLevel.level,
                currencyCode: 'USD',
                onTargetEarnings: onTargetEarnings,
                hourlyRate: hourlyRate,
                percentVariable: percentVariable,
                absoluteVariable: absoluteVariable,
                country: defaultCountry,
                workspaceId: workspaceMembership?.workspace.id as number,
              });
            }
          }

          // Save the comp band data for this group
          if (compBandRequests.length > 0) {
            await saveCompBands({
              variables: {
                compBandSaveRequest: {
                  items: compBandRequests,
                },
              },
            });
          }
        }

        // Refresh data after all groups are processed
        await refetchCompBandGroups();
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(`Import failed: ${error.message}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Import failed due to an unknown error', {
            variant: 'error',
          });
        }
      }
    },
    [
      saveCompBandGroups,
      saveCompBands,
      globalLevels,
      workspaceMembership,
      refetchCompBandGroups,
      enqueueSnackbar,
    ]
  );

  if (loadingGlobalLevel || loadingCompBandGroups) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {checkScopes(Scope.COMP_BANDS_EDIT) && (
          <EditButtons
            onEdit={() => setEditing(true)}
            onSave={() => handleSave()}
            onCancel={handleCancel}
          />
        )}
        {editing && (
          <CsvUploadsModalWrapper
            pageName={t('compBandsPage.title', 'Compensation Bands')}
            headers={csvHeaders}
            exampleContent={generateExampleContent()}
            templateFileName="comp_bands_template.csv"
            validateRow={validateCompBandRow}
            processData={processCompBandData}
            groupByField="groupName"
          />
        )}
      </Box>
      <Suspense fallback={<CircularProgress />}>
        {widthUpdateErrors.length > 0 && (
          <Typography color="error">
            {widthUpdateErrors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </Typography>
        )}
        {noData && !loadingCompBandGroups && !editing ? (
          <Typography>{t('compBandsPage.noData')}</Typography>
        ) : (
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              {tableComponent}
              {editing && (
                <IconButton
                  aria-label={t('compBandsPage.createNewGroup')}
                  sx={{ height: 'fit-content' }}
                  title={t('compBandsPage.createNewGroup')}
                  onClick={handleCreateNewGroup}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Stack>

            {editing && <Box sx={{ mb: 10 }} />}
          </Stack>
        )}
      </Suspense>
    </>
  );
}

const CsvUploadsModalWrapper = (
  props: React.ComponentProps<typeof CsvUploadsModal>
) => {
  return (
    <CsvUploadsModal
      {...props}
      renderButton={(onClick) => (
        <Box sx={{ ml: 2 }}>
          <CsvUploadsModal.Button onClick={onClick} />
        </Box>
      )}
    />
  );
};
