import { Scope } from '@alamere/generated-graphql-types';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import { useScopes } from '../hooks/useScopes';

export interface EditButtonsProps {
  onCancel: () => void;
  onSave: () => void;
  onEdit: () => void;
  requiredScopes?: Scope[];
  sx?: SxProps<Theme>;
  editing?: boolean;
  setEditing?: (value: boolean) => void;
}

export function EditButtons({
  onCancel,
  onSave,
  onEdit,
  requiredScopes = [],
  sx,
  editing: controlledEditing,
  setEditing: setControlledEditing,
}: EditButtonsProps) {
  const [internalEditing, setInternalEditing] = useState<boolean>(false);
  const { checkScopes } = useScopes();

  const isEditing = controlledEditing ?? internalEditing;
  const setIsEditing = setControlledEditing ?? setInternalEditing;

  if (!checkScopes(...requiredScopes)) {
    return null;
  }

  return (
    <Box sx={sx}>
      {isEditing ? (
        <>
          <Button
            variant="text"
            startIcon={<CancelRoundedIcon />}
            onClick={() => {
              setIsEditing(false);
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            startIcon={<CheckRoundedIcon />}
            onClick={() => {
              if (!setControlledEditing) {
                setIsEditing(false);
              }
              onSave();
            }}
          >
            Save
          </Button>
        </>
      ) : (
        <Button
          variant="text"
          startIcon={<ModeEditRoundedIcon />}
          onClick={() => {
            setIsEditing(true);
            onEdit();
          }}
        >
          Edit
        </Button>
      )}
    </Box>
  );
}
