import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useJobLevelsQuery } from '@alamere/generated-graphql-types';

export interface JobLevelPickerProps {
  onChange?: (level: number) => void;
  disabled?: boolean;
  value?: SelectOption | undefined;
  SelectProps?: SelectProps;
  FormControlProps?: FormControlProps;
}

export interface SelectOption {
  label: string;
  value: number;
}

export function JobLevelPicker({
  onChange = () => {},
  disabled,
  value,
  SelectProps = {},
  FormControlProps = {},
}: JobLevelPickerProps) {
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: false,
      },
    },
  });
  const [levels, setLevels] = useState<SelectOption[] | undefined>();
  useEffect(() => {
    if (jobLevelsData) {
      const groupedByLevel = jobLevelsData.jobLevels.reduce<
        Record<number, string[]>
      >((acc, { name, level }) => {
        acc[level] = acc[level] ? [...acc[level], name] : [name];
        return acc;
      }, {});

      const levelOptions = Object.entries(groupedByLevel).map(
        ([level, names]) => ({
          label: names.join(' / '),
          value: Number(level),
        })
      );

      setLevels(levelOptions);
    }
  }, [jobLevelsData]);
  return (
    <FormControl {...FormControlProps}>
      <InputLabel id="level">Level</InputLabel>
      <Select
        fullWidth
        labelId="level"
        size="small"
        label="Level"
        value={value?.value}
        disabled={disabled}
        onChange={(e) => onChange(parseInt(e.target.value as string))}
        {...SelectProps}
      >
        {levels &&
          levels.map((level) => {
            return (
              <MenuItem value={level.value} key={level.value}>
                {level.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
