import { TableCell, TableHead, TableRow, Box } from '@mui/material';
import { t } from 'i18next';
import { tableBorder } from '../../layout/theme';

export function Head() {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={(theme) => ({ borderRight: tableBorder(theme) })}>
          {t('compRangesPage.table.track')}
        </TableCell>
        <TableCell sx={(theme) => ({ borderRight: tableBorder(theme) })}>
          {t('compRangesPage.table.job')}
        </TableCell>
        <TableCell
          sx={(theme) => ({
            borderRight: tableBorder(theme),
            textAlign: 'center',
          })}
        >
          {t('compRangesPage.table.baseVariable')}
        </TableCell>
        <TableCell
          sx={(theme) => ({
            borderRight: tableBorder(theme),
            textAlign: 'center',
          })}
        >
          <Box>{t('compRangesPage.table.cashComp')}</Box>
          <Box>Min --- OTE --- Max</Box>
        </TableCell>
        <TableCell
          sx={(theme) => ({
            borderRight: tableBorder(theme),
            textAlign: 'center',
          })}
        >
          {t('compRangesPage.table.equity')}
        </TableCell>
        <TableCell
          sx={(theme) => ({
            borderRight: tableBorder(theme),
            textAlign: 'center',
          })}
        >
          {t('compRangesPage.table.calculator')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
