import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import FunctionsIcon from '@mui/icons-material/Functions';
import {
  Chip,
  TableCell,
  TableRow,
  Typography,
  Box,
  TextField,
  Button,
  Modal,
  Slider,
  Table,
  TableHead,
  TableBody,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { tableBorder } from '../../layout/theme';
import { Tier } from '@alamere/core';
import { styled } from '@mui/material/styles';
import { black, calculatorColors, green, white } from '../../layout/colors';
import { t } from 'i18next';
import { convertHourlyToAnnual } from '../../utils';
import { RangeBounds } from './Table';

interface Props {
  job: {
    title: string;
    jobLevel: { level: number; code: string; group: string };
  };
  jobFamilyName: string;
  bonus: { percent: number };
  compBand: {
    onTargetEarnings: number;
    absoluteVariable: number | null;
    percentVariable: number;
    hourlyRate: number;
  };
  compBandGroupName: string;
  rangeWidth: {
    percentBelowMid: number;
    percentAboveMid: number;
  };
  rangeWidthGroupName: string;
  equityBand: {
    value: number;
    rangeWidth: {
      percentBelowMid: number;
      percentAboveMid: number;
    };
  };
  equityBandRangeWidthGroupName: string;
  equityBandGroupName: string;
  compBandMultiplier: number;
  tier: Tier;
  itemIndex: number;
  isFirstInGroup: boolean;
  rangeBounds: RangeBounds;
  shallShowBreakdownButtons: boolean;
}

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#d3d3d3', // Default color for the slider
  height: 8,
  padding: 0,
  margin: 0,
  '& .MuiSlider-track': {
    backgroundColor: green['400'], // Default color for the track
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#d3d3d3', // Color for the rail
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'transparent', // Hide all marks by default
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  // top labels
  '& .MuiSlider-markLabel': {
    fontSize: '12px',
    color: theme.palette.mode === 'light' ? black[1000] : white,
    top: -25,
  },
  '& .MuiSlider-track[data-index="1"]': {
    backgroundColor: green['400'], // Green color for the specific range
  },
  '& .MuiSlider-thumb': {
    display: 'none', // Hide thumbs since the slider is static
  },
  '& .MuiSlider-mark[data-index="3"]': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? calculatorColors['red']
        : calculatorColors['orange'],
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  '& .MuiSlider-markLabel[data-index="3"]': {
    color:
      theme.palette.mode === 'light'
        ? calculatorColors['red']
        : calculatorColors['orange'],
    fontWeight: 'bold',
    fontSize: '12px',
    top: -25,
  },
}));

const shouldHideMarkAndPercent = (
  markValue: number,
  customValue?: number,
  rangeMin?: number,
  rangeMax?: number
) => {
  if (customValue === markValue) {
    return false;
  }
  const range = (rangeMax ?? 0) - (rangeMin ?? 0);
  const distance = Math.abs(markValue - (customValue ?? 0));
  return distance < range * 0.08; // Hide marks that are too close to the custom value
};

const getPercentageAtValue = (
  value: number,
  referenceValue: number
): number => {
  return Math.round((value / referenceValue) * 100);
};

const RangeBar = ({
  rangeMin,
  rangeMax,
  greenRange,
  lowPercent,
  topPercent,
  newPercent,
  newMidPercent,
  isHourlyRate,
  setModalSalaryMid,
  setOpenModal,
  customValue,
  customValuePercent,
}: {
  rangeMin: number;
  rangeMax: number;
  greenRange: number[];
  lowPercent: number;
  topPercent: number;
  newPercent?: boolean;
  newMidPercent?: number;
  isHourlyRate?: boolean;
  setModalSalaryMid?: (salary: number) => void;
  setOpenModal?: (value: boolean) => void;
  customValue?: number;
  customValuePercent?: number;
}) => {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [hoverPercent, setHoverPercent] = useState<number | null>(null);

  const marks = [
    {
      value: greenRange[0],
      label: shouldHideMarkAndPercent(
        greenRange[0],
        customValue,
        rangeMin,
        rangeMax
      )
        ? null
        : `$${formatNumber(greenRange[0], isHourlyRate)}`,
    },
    {
      value: greenRange[1],
      label: shouldHideMarkAndPercent(
        greenRange[1],
        customValue,
        rangeMin,
        rangeMax
      )
        ? null
        : `$${formatNumber(greenRange[1], isHourlyRate)}`,
    },
    {
      value: greenRange[2],
      label: shouldHideMarkAndPercent(
        greenRange[2],
        customValue,
        rangeMin,
        rangeMax
      )
        ? null
        : `$${formatNumber(greenRange[2], isHourlyRate)}`,
    },
    ...(customValue !== undefined
      ? [
          {
            value: customValue,
            label: `$${formatNumber(customValue, isHourlyRate)}`,
          },
        ]
      : []),
  ].filter(
    // TODO comment: what is this filter for? Removing redundant labels?
    (obj, index, self) => index === self.findIndex((o) => o.label === obj.label)
  );

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const sliderElement = event.currentTarget;
    const { left, top, width, height } = sliderElement.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;
    if (x >= 0 && x <= width && y >= 0 && y <= height) {
      const percentage = x / width; // Calculate the percentage of the slider's width
      const value = isHourlyRate
        ? rangeMin + percentage * (rangeMax - rangeMin)
        : Math.round(rangeMin + percentage * (rangeMax - rangeMin)); // Calculate the value based on the percentage
      setHoverValue(value);
      setHoverPercent(() =>
        getPercentageAtValue(
          rangeMin + percentage * (rangeMax - rangeMin),
          greenRange[1]
        )
      );
    } else {
      handleMouseLeave();
    }
  };

  const handleMouseLeave = () => {
    setHoverValue(null);
    setHoverPercent(null);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '15px 20px',
        position: 'relative',
      }}
    >
      <CustomSlider
        value={greenRange}
        min={rangeMin}
        max={rangeMax}
        marks={marks}
        onClick={() => {
          if (setModalSalaryMid && setOpenModal && hoverValue) {
            setModalSalaryMid(parseFormattedNumber(formatNumber(hoverValue)));
            setOpenModal(true);
          }
        }}
        valueLabelDisplay="auto"
        onMouseOver={handleMouseMove}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        sx={{
          '& .MuiSlider-mark[data-index="1"]': {
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? green['800'] : white,
          },
          '& .MuiSlider-markLabel[data-index="1"]': {
            fontWeight: 'bold', // Apply bold font to the second/middle label
          },
        }}
      />
      <Box sx={{ position: 'relative', marginTop: '5px' }}>
        {!shouldHideMarkAndPercent(
          greenRange[0],
          customValue,
          rangeMin,
          rangeMax
        ) && (
          <Box
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light' ? black[1000] : white,
              position: 'absolute',
              top: 0,
              left: `calc(${
                ((greenRange[0] - rangeMin) / (rangeMax - rangeMin)) * 100
              }% - 10px)`,
              transform: 'translateX(-50%, 0)',
              fontSize: '12px',
            }}
          >
            <div>{100 - lowPercent}%</div>
          </Box>
        )}

        {newPercent &&
          !shouldHideMarkAndPercent(
            greenRange[1],
            customValue,
            rangeMin,
            rangeMax
          ) && (
            <Box
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? black[1000] : white,
                position: 'absolute',
                top: 0,
                left: `calc(${
                  ((greenRange[1] - rangeMin) / (rangeMax - rangeMin)) * 100
                }% - 10px)`,
                transform: 'translateX(-50%, 0)',
                fontSize: '12px',
              }}
            >
              <div>{newMidPercent}%</div>
            </Box>
          )}

        {!shouldHideMarkAndPercent(
          greenRange[2],
          customValue,
          rangeMin,
          rangeMax
        ) && (
          <Box
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light' ? black[1000] : white,
              position: 'absolute',
              top: 0,
              left: `calc(${
                ((greenRange[2] - rangeMin) / (rangeMax - rangeMin)) * 100
              }% - 10px)`,
              transform: 'translateX(-50%, 0)',
              fontSize: '12px',
            }}
          >
            <div>{100 + topPercent}%</div>
          </Box>
        )}

        {customValue !== undefined && customValuePercent !== 100 && (
          <Box
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light'
                  ? calculatorColors['red']
                  : calculatorColors['orange'],
              position: 'absolute',
              top: 0,
              left: `calc(${
                ((customValue - rangeMin) / (rangeMax - rangeMin)) * 100
              }% - 10px)`,
              transform: 'translateX(-50%, 0)',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            <div>{customValuePercent}%</div>
          </Box>
        )}
      </Box>
      {hoverValue !== null && hoverPercent !== null && (
        <Box
          sx={{
            position: 'absolute',
            left: `calc(${
              ((hoverValue - rangeMin) / (rangeMax - rangeMin)) * 100
            }%)`,
            top: '-35px',
            transform: 'translateX(-50%)',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.7)'
                : green[400],
            color: 'white',
            padding: '2px 5px',
            borderRadius: '3px',
            fontSize: '12px',
            textAlign: 'center',
          }}
        >
          <Box>${formatNumber(hoverValue, isHourlyRate)}</Box>
          <Box>{hoverPercent}%</Box>
        </Box>
      )}
    </Box>
  );
};

const parseFormattedNumber = (formattedNum: string): number => {
  if (formattedNum.endsWith('k')) {
    return parseFloat(formattedNum) * 1000;
  }
  return parseFloat(formattedNum.replace(/,/g, ''));
};

// TODO move to a util module
// TODO refactor `isHourlyRate` to something more general like `shallDisplayFractional`
const formatNumber = (
  num: number,
  isHourlyRate = false,
  shallShortenThousands = true
) => {
  if (shallShortenThousands && num >= 1000) {
    return (
      (num / 1000).toLocaleString('en-US', { maximumFractionDigits: 1 }) + 'k'
    );
  }

  if (isHourlyRate) {
    return num.toFixed(2);
  }

  return num.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

const formatNumberWithCommas = (number: string) => {
  // TODO use `toLocaleString` instead
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const RangeDisplay: React.FC<{
  min: number;
  mid: number;
  max: number;
  lowPercent: number;
  topPercent: number;
  rangeMin: number;
  rangeMax: number;
  newPercent?: boolean;
  isHourlyRate?: boolean;
  setModalSalaryMid?: (value: number) => void;
  setOpenModal?: (value: boolean) => void;
}> = React.memo(
  ({
    min,
    mid,
    max,
    lowPercent,
    topPercent,
    rangeMin,
    rangeMax,
    newPercent,
    isHourlyRate = false,
    setModalSalaryMid,
    setOpenModal,
  }) => (
    <Box sx={{ width: '100%' }}>
      <RangeBar
        rangeMin={rangeMin}
        rangeMax={rangeMax}
        greenRange={[min, mid, max]}
        lowPercent={lowPercent}
        topPercent={topPercent}
        newPercent={newPercent}
        isHourlyRate={isHourlyRate}
        setModalSalaryMid={setModalSalaryMid}
        setOpenModal={setOpenModal}
      />
    </Box>
  )
);

export const Row: React.FC<Props> = React.memo(
  ({
    job,
    jobFamilyName,
    bonus,
    rangeWidth,
    rangeWidthGroupName,
    compBand,
    compBandGroupName,
    compBandMultiplier,
    equityBand,
    equityBandGroupName,
    equityBandRangeWidthGroupName,
    tier,
    itemIndex,
    isFirstInGroup,
    rangeBounds,
    shallShowBreakdownButtons,
  }) => {
    const [isSalariedCalculatorModalOpen, setIsSalariedCalculatorModalOpen] =
      useState(false);
    const [modalSalaryMid, setModalSalaryMid] = useState(0);
    const [
      isHourlyRateCalculatorModalOpen,
      setIsHourlyRateCalculatorModalOpen,
    ] = useState(false);
    const [isBreakdownDialogOpen, setIsBreakdownDialogOpen] = useState(false);
    const [breakdownDialogTitle, setBreakdownDialogTitle] = useState('');
    const [breakdownDialogValues, setBreakdownDialogValues] = useState<
      BreakdownValueData[]
    >([]);

    const calculateMidValues = (
      baseValue: number,
      rangeWidthPercentBelowMid: number,
      rangeWidthPercentAboveMid: number,
      tierPercentile: number,
      compBandMultiplier = 100
    ) => {
      const mid =
        baseValue * (compBandMultiplier / 100) * (tierPercentile / 100);
      const min = mid * (1 - rangeWidthPercentBelowMid / 100);
      const max = mid * (1 + rangeWidthPercentAboveMid / 100);
      return { mid, min, max };
    };

    const calculateMidOteValues = (
      baseValue: number,
      compBandMultiplier: number,
      tierPercentile: number,
      rangeWidthPercentBelowMid: number,
      rangeWidthPercentAboveMid: number
    ) => {
      const midOte = compBand.absoluteVariable
        ? baseValue +
          compBand.absoluteVariable *
            (compBandMultiplier / 100) *
            (tierPercentile / 100)
        : baseValue +
          (compBand.percentVariable / 100) *
            baseValue *
            (compBandMultiplier / 100) *
            (tierPercentile / 100);
      const minOte = midOte * (1 - rangeWidthPercentBelowMid / 100);
      const maxOte = midOte * (1 + rangeWidthPercentAboveMid / 100);
      return { midOte, minOte, maxOte };
    };

    const {
      mid: salaryMid,
      min: salaryMin,
      max: salaryMax,
    } = calculateMidValues(
      compBand.onTargetEarnings,
      rangeWidth.percentBelowMid,
      rangeWidth.percentAboveMid,
      tier.percentile,
      compBandMultiplier
    );

    const {
      mid: hourlyRateMid,
      min: hourlyRateMin,
      max: hourlyRateMax,
    } = calculateMidValues(
      compBand.hourlyRate,
      rangeWidth.percentBelowMid,
      rangeWidth.percentAboveMid,
      tier.percentile,
      compBandMultiplier
    );

    const {
      mid: equityMid,
      min: equityMin,
      max: equityMax,
    } = calculateMidValues(
      equityBand.value,
      equityBand.rangeWidth.percentBelowMid,
      equityBand.rangeWidth.percentAboveMid,
      tier.percentile
      // equity doesn't use Comp Band Multiplier
    );

    const ratioText = compBand.absoluteVariable
      ? `$${formatNumber(compBand.absoluteVariable)} variable`
      : `${100 - compBand.percentVariable}:${compBand.percentVariable}`;

    const CalculatorModalContent = ({
      modalSalaryMid,
      isHourlyRate = false,
    }: {
      modalSalaryMid: number;
      isHourlyRate?: boolean;
    }) => {
      const [base, setBase] = useState(modalSalaryMid);

      const {
        midOte: midCustomOte,
        minOte: minCustomOte,
        maxOte: maxCustomOte,
      } = calculateMidOteValues(
        salaryMid,
        compBandMultiplier,
        tier.percentile,
        rangeWidth.percentBelowMid,
        rangeWidth.percentAboveMid
      );

      const [customOte, setCustomOte] = useState(
        compBand.absoluteVariable
          ? base + compBand.absoluteVariable
          : base + (compBand.percentVariable / 100) * base
      );

      const [tcc, setTcc] = useState(
        compBand.absoluteVariable
          ? base + compBand.absoluteVariable + (base * bonus.percent) / 100
          : base +
              (compBand.percentVariable / 100) * base +
              (base * bonus.percent) / 100
      );

      const [equity, setEquity] = useState(equityBand.value);

      const [displayBaseValue, setDisplayBaseValue] = useState(
        formatNumberWithCommas(base.toFixed(0))
      );

      const [displayEquityValue, setDisplayEquityValue] = useState(
        formatNumberWithCommas(equity.toFixed(0))
      );

      const ote = compBand.absoluteVariable
        ? base + compBand.absoluteVariable
        : base + (compBand.percentVariable / 100) * base;

      const variable = compBand.absoluteVariable
        ? compBand.absoluteVariable
        : (compBand.percentVariable / 100) * base;

      const variablePercent = compBand.absoluteVariable
        ? ((compBand.absoluteVariable / base) * 100).toFixed(0)
        : compBand.percentVariable;

      const bonusValue = bonus.percent > 0 ? (base * bonus.percent) / 100 : 0;

      const [customValuePercent, setCustomValuePercent] = useState(
        getPercentageAtValue(base, salaryMid)
      );

      const handleBaseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/,/g, '');
        if (inputValue === '') {
          setTcc(0);
          setBase(0);
          setDisplayBaseValue('0');
          setCustomValuePercent(0);
        } else if (!isNaN(Number(inputValue))) {
          const newBase = parseFloat(inputValue);
          const newTcc = compBand.absoluteVariable
            ? newBase + variable + (newBase * bonus.percent) / 100
            : newBase +
              Number(variablePercent) * newBase +
              (newBase * bonus.percent) / 100;

          setCustomOte(
            compBand.absoluteVariable
              ? newBase + variable
              : newBase + (Number(variablePercent) / 100) * newBase
          );
          setBase(newBase);
          setTcc(newTcc);
          setDisplayBaseValue(formatNumberWithCommas(newBase.toFixed(0)));
          setCustomValuePercent(
            Number(getPercentageAtValue(newBase, salaryMid).toFixed(0))
          );
        }
      };

      const handleEquityChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const inputValue = event.target.value.replace(/,/g, '');
        if (inputValue === '') {
          setEquity(0);
          setDisplayEquityValue('0');
        } else if (!isNaN(Number(inputValue))) {
          const newEquity = parseFloat(inputValue);
          setEquity(newEquity);
          setDisplayEquityValue(formatNumberWithCommas(newEquity.toFixed(0)));
        }
      };

      return (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            gap: 2,
            borderRadius: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'bold', paddingBottom: 2, textAlign: 'center' }}
          >
            {/* TODO i18n string */}
            {job.title} details {isHourlyRate ? '(Hourly Rate Annualized)' : ''}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ width: 100 }}>Base</Typography>
              <TextField
                value={displayBaseValue}
                fullWidth
                onChange={handleBaseChange}
                type="text"
                inputProps={{ inputMode: 'numeric' }}
              />
            </Box>

            {(compBand.absoluteVariable || compBand.percentVariable) > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography sx={{ width: 100 }}>Variable</Typography>
                <TextField
                  disabled
                  value={`$${formatNumber(variable)} (${variablePercent}%)`}
                  fullWidth
                  multiline
                />
              </Box>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ width: 100 }}>OTE</Typography>
              <TextField
                disabled
                value={`$${formatNumber(ote)}`}
                fullWidth
                multiline
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <RangeBar
                rangeMin={
                  customOte < minCustomOte ? customOte * 0.95 : minCustomOte
                }
                rangeMax={
                  customOte > maxCustomOte ? customOte * 1.05 : maxCustomOte
                }
                greenRange={[minCustomOte, midCustomOte, maxCustomOte]}
                lowPercent={rangeWidth.percentBelowMid}
                topPercent={rangeWidth.percentAboveMid}
                isHourlyRate={isHourlyRate}
                newPercent={true}
                newMidPercent={100}
                customValue={customOte}
                customValuePercent={customValuePercent}
              />
            </Box>

            {bonus.percent > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography sx={{ width: 100 }}>Bonus</Typography>
                <TextField
                  disabled
                  value={`$${formatNumber(bonusValue)} (${bonus.percent}%)`}
                  fullWidth
                  multiline
                />
              </Box>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ width: 100 }}>TCC</Typography>
              <TextField
                disabled
                value={`$${formatNumber(tcc)}`}
                fullWidth
                multiline
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ width: 100 }}>Equity</Typography>
              <TextField
                value={displayEquityValue}
                fullWidth
                onChange={handleEquityChange}
                type="text"
                inputProps={{ inputMode: 'numeric' }}
              />
            </Box>
          </Box>
        </Box>
      );
    };

    type BreakdownValueData = {
      name: string;
      terms: string[][];
      calculations: string[];
    };

    const handleBreakdownButtonClick = (type: 'cash comp' | 'equity') => {
      // TODO memo/pure function
      const isSalaried = compBand.onTargetEarnings > 0;
      const formattedSalaryBase = formatNumber(
        isSalaried ? compBand.onTargetEarnings : compBand.hourlyRate,
        !isSalaried,
        false
      );
      const formattedSalaryMid = formatNumber(
        isSalaried ? salaryMid : hourlyRateMid,
        !isSalaried,
        false
      );
      const formattedSalaryMin = formatNumber(
        isSalaried ? salaryMin : hourlyRateMin,
        !isSalaried,
        false
      );
      const formattedSalaryMax = formatNumber(
        isSalaried ? salaryMax : hourlyRateMax,
        !isSalaried,
        false
      );

      // TODO i18n strings
      const cashCompBreakdownValues: BreakdownValueData[] = [
        {
          name: 'Midpoint',
          terms: [
            [
              `Base value: ${formattedSalaryBase}`,
              `Comp Band: ${compBandGroupName}`,
            ],
            [
              `Comp band multiplier: ${compBandMultiplier}%`,
              `Job Family: ${jobFamilyName}`,
            ],
            [
              `Tier multiplier: ${tier.percentile}%`,
              `Tier: ${tier.label || tier.tierNumber}`,
            ],
          ],
          calculations: [
            `(midpoint value) = (base value) ⋅ (comp band mult) ⋅ (tier mult)`,
            `= ${formattedSalaryBase} ⋅ ${compBandMultiplier}% ⋅ ${tier.percentile}%`,
            `= ${formattedSalaryMid}`,
          ],
        },
        {
          name: 'Minimum',
          terms: [
            [`Midpoint value: ${formattedSalaryMid}`],
            [
              `Percent below: ${rangeWidth.percentBelowMid}%`,
              `Range Width: ${rangeWidthGroupName}`,
            ],
          ],
          calculations: [
            `(minimum value) = (midpoint value) ⋅ (100 - (percent below))`,
            `= ${formattedSalaryMid} ⋅ (100 - ${rangeWidth.percentBelowMid})%`,
            `= ${formattedSalaryMin}`,
          ],
        },
        {
          name: 'Maximum',
          terms: [
            [`Midpoint value: ${formattedSalaryMid}`],
            [
              `Percent above: ${rangeWidth.percentAboveMid}%`,
              `Range Width: ${rangeWidthGroupName}`,
            ],
          ],
          calculations: [
            `(maximum value) = (midpoint value) ⋅ (100 + (percent above))`,
            `= ${formattedSalaryMid} ⋅ (100 + ${rangeWidth.percentAboveMid})%`,
            `= ${formattedSalaryMax}`,
          ],
        },
      ];

      const formattedBaseValue = formatNumber(equityBand.value, false, false);
      const formattedEquityMid = formatNumber(equityMid, false, false);
      const formattedEquityMin = formatNumber(equityMin, false, false);
      const formattedEquityMax = formatNumber(equityMax, false, false);
      const equityBreakdownValues: BreakdownValueData[] = [
        {
          name: 'Midpoint',
          terms: [
            [
              `Base value: ${formattedBaseValue}`,
              `Equity Band: ${equityBandGroupName}`,
            ],
            [
              `Tier multiplier: ${tier.percentile}%`,
              `Tier: ${tier.label || tier.tierNumber}`,
            ],
          ],
          calculations: [
            `(midpoint value) = (base value) ⋅ (tier mult)`,
            `= ${formattedBaseValue} ⋅ ${tier.percentile}%`,
            `= ${formattedEquityMid}`,
          ],
        },
        {
          name: 'Minimum',
          terms: [
            [`Midpoint value: ${formattedEquityMid}`],
            [
              `Percent below: ${equityBand.rangeWidth.percentBelowMid}%`,
              `Range Width: ${equityBandRangeWidthGroupName}`,
            ],
          ],
          calculations: [
            `(minimum value) = (midpoint value) ⋅ (100 - (percent below))`,
            `= ${formattedEquityMid} ⋅ (100 - ${rangeWidth.percentBelowMid})%`,
            `= ${formattedEquityMin}`,
          ],
        },
        {
          name: 'Maximum',
          terms: [
            [`Midpoint value: ${formattedEquityMid}`],
            [
              `Percent above: ${equityBand.rangeWidth.percentAboveMid}%`,
              `Range Width: ${equityBandRangeWidthGroupName}`,
            ],
          ],
          calculations: [
            `(maximum value) = (midpoint value) ⋅ (100 + (percent above))`,
            `= ${formattedEquityMid} ⋅ (100 + ${rangeWidth.percentAboveMid})%`,
            `= ${formattedEquityMax}`,
          ],
        },
      ];

      setBreakdownDialogValues(
        type === 'cash comp' ? cashCompBreakdownValues : equityBreakdownValues
      );
      setBreakdownDialogTitle(
        type === 'cash comp'
          ? `Cash Comp Breakdown: ${job.title}`
          : `Equity Breakdown: ${job.title}`
      );
      setIsBreakdownDialogOpen(true);
    };

    const BreakdownModalContent = ({
      breakdownValues,
    }: {
      breakdownValues: BreakdownValueData[];
    }) => {
      function renderRows(rowData: BreakdownValueData) {
        const section = rowData.name;
        return (
          <>
            {rowData.terms.map(([valueString, sourceString], index) => (
              <TableRow key={`${section}-term-${index}`}>
                <TableCell
                  // width={'12.5%'}
                  sx={(theme) => ({
                    visibility: index === 0 ? 'visible' : 'hidden',
                    borderBottomWidth: 0,
                    borderRight: tableBorder(theme),
                    borderTop: index === 0 ? tableBorder(theme) : 0,
                  })}
                >
                  <Typography>{section}</Typography>
                </TableCell>

                <TableCell>{valueString}</TableCell>

                <TableCell>{sourceString || ''}</TableCell>
              </TableRow>
            ))}

            {rowData.calculations.map((calcString, index) => (
              <TableRow key={`${section}-calc-${index}`}>
                <TableCell
                  // width={'12.5%'}
                  sx={(theme) => ({
                    visibility: 'hidden',
                    borderBottomWidth: 0,
                    borderRight: tableBorder(theme),
                  })}
                ></TableCell>

                <TableCell
                  colSpan={2}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey[200],
                  })}
                >
                  {index === rowData.calculations.length - 1 ? (
                    // bold the last row of the calculations (the result)
                    <Box component="strong">{calcString}</Box>
                  ) : (
                    calcString
                  )}
                </TableCell>
              </TableRow>
            ))}
          </>
        );
      }

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Value</TableCell>

                <TableCell>Term</TableCell>

                <TableCell>Source</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {breakdownValues.map((breakdownValue) =>
                renderRows(breakdownValue)
              )}
            </TableBody>
          </Table>
        </Box>
      );
    };

    return (
      <>
        <TableRow
          sx={(theme) => ({
            borderTop: itemIndex === 0 ? tableBorder(theme) : undefined,
            borderTopStyle: itemIndex === 0 ? 'double' : undefined,
            borderTopWidth: 4,
          })}
        >
          {/* Track */}
          <TableCell
            width={'12.5%'}
            sx={(theme) => ({
              visibility: isFirstInGroup ? 'visible' : 'hidden',
              borderBottomWidth: 0,
              borderRight: tableBorder(theme),
            })}
          >
            <Typography>{job.jobLevel.group}</Typography>
          </TableCell>

          {/* Job */}
          <TableCell
            width={'12.5%'}
            sx={(theme) => ({
              borderRight: tableBorder(theme),
              padding: '12px',
              verticalAlign: 'top',
            })}
          >
            <Typography variant="subtitle1">{job.title}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Level {job.jobLevel.level} -
              </Typography>
              <Chip label={job.jobLevel.code} size="small" />
            </Box>
          </TableCell>

          {/* Variable */}
          <TableCell
            width={'10%'}
            sx={(theme) => ({
              borderRight: tableBorder(theme),
              padding: '12px',
              verticalAlign: 'middle',
              textAlign: 'center',
            })}
          >
            <Typography>{ratioText}</Typography>
            <Typography>
              {bonus.percent > 0 ? `${bonus.percent}% bonus` : '--'}
            </Typography>
          </TableCell>

          {/* Cash Comp. */}
          <TableCell
            width={'30%'}
            sx={(theme) => ({
              borderRight: tableBorder(theme),
              padding: '12px',
              verticalAlign: 'middle',
            })}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {/* Slider OTE */}
              {compBand.onTargetEarnings > 0 && (
                <RangeDisplay
                  min={salaryMin}
                  mid={salaryMid}
                  max={salaryMax}
                  lowPercent={rangeWidth.percentBelowMid}
                  topPercent={rangeWidth.percentAboveMid}
                  rangeMin={rangeBounds.salaryRangeMin}
                  rangeMax={rangeBounds.salaryRangeMax}
                  setModalSalaryMid={(value: number) =>
                    setModalSalaryMid(value)
                  }
                  setOpenModal={setIsSalariedCalculatorModalOpen}
                />
              )}

              {/* Slider hourlyRate */}
              {compBand.hourlyRate > 0 && (
                <RangeDisplay
                  min={hourlyRateMin}
                  mid={hourlyRateMid}
                  max={hourlyRateMax}
                  lowPercent={rangeWidth.percentBelowMid}
                  topPercent={rangeWidth.percentAboveMid}
                  rangeMin={rangeBounds.hourlyRateRangeMin}
                  rangeMax={rangeBounds.hourlyRateRangeMax}
                  setModalSalaryMid={(value: number) =>
                    setModalSalaryMid(convertHourlyToAnnual(value))
                  }
                  setOpenModal={setIsHourlyRateCalculatorModalOpen}
                  isHourlyRate={true}
                />
              )}

              {/* Breakdown button */}
              {shallShowBreakdownButtons && (
                <Tooltip title={t('compRangesPage.breakdownButtonTooltip')}>
                  <Button
                    sx={{ paddingX: '4px', minWidth: 0 }}
                    variant="outlined"
                    onClick={() => {
                      handleBreakdownButtonClick('cash comp');
                    }}
                  >
                    <FunctionsIcon />
                  </Button>
                </Tooltip>
              )}
            </Box>
          </TableCell>

          {/* Equity */}
          <TableCell
            width={'30%'}
            sx={(theme) => ({
              borderRight: tableBorder(theme),
              padding: '12px',
              verticalAlign: 'middle',
            })}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <RangeDisplay
                min={equityMin}
                mid={equityMid}
                max={equityMax}
                lowPercent={equityBand.rangeWidth.percentBelowMid}
                topPercent={equityBand.rangeWidth.percentAboveMid}
                rangeMin={rangeBounds.equityRangeMin}
                rangeMax={rangeBounds.equityRangeMax}
              />

              {/* Breakdown button */}
              {shallShowBreakdownButtons && (
                <Tooltip title={t('compRangesPage.breakdownButtonTooltip')}>
                  <Button
                    sx={{ paddingX: '4px', minWidth: 0 }}
                    variant="outlined"
                    onClick={() => {
                      handleBreakdownButtonClick('equity');
                    }}
                  >
                    <FunctionsIcon />
                  </Button>
                </Tooltip>
              )}
            </Box>
          </TableCell>

          {/* Calculator */}
          <TableCell
            width={'5%'}
            sx={(theme) => ({
              borderRight: tableBorder(theme),
              textAlign: 'center',
              verticalAlign: 'middle',
            })}
          >
            <Tooltip title={t('compRangesPage.calculatorButtonTooltip')}>
              <Button
                sx={{ paddingX: '4px', minWidth: 0 }}
                variant="outlined"
                onClick={() => {
                  setModalSalaryMid(
                    compBand.hourlyRate > 0
                      ? convertHourlyToAnnual(hourlyRateMid)
                      : salaryMid
                  );
                  compBand.hourlyRate > 0
                    ? setIsHourlyRateCalculatorModalOpen(true)
                    : setIsSalariedCalculatorModalOpen(true);
                }}
              >
                <CalculateRoundedIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </TableRow>

        {/* TODO convert to Dialog? See https://v5.mui.com/material-ui/react-modal/:
          > If you are creating a modal dialog, you probably want to use the Dialog component rather than directly using Modal.
        */}
        <Modal
          open={
            isSalariedCalculatorModalOpen || isHourlyRateCalculatorModalOpen
          }
          onClose={() => {
            setIsSalariedCalculatorModalOpen(false);
            setIsHourlyRateCalculatorModalOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <CalculatorModalContent
              modalSalaryMid={modalSalaryMid}
              isHourlyRate={compBand.hourlyRate > 0}
            />
          </Box>
        </Modal>

        <Dialog
          open={isBreakdownDialogOpen}
          onClose={() => setIsBreakdownDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll="body"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            {breakdownDialogTitle}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setIsBreakdownDialogOpen(false)}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <BreakdownModalContent breakdownValues={breakdownDialogValues} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

Row.displayName = 'Row';
