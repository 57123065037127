import {
  JobFamilyFragment,
  useCompBandGroupsQuery,
  useRangeWidthGroupsQuery,
  useBonusStructureGroupsQuery,
  useEquityBandGroupsQuery,
} from '@alamere/generated-graphql-types';
import { Autocomplete, TableCell, TextField, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { OnItemChangeFunction } from '../types';

// Define a more accurate type for the group objects
interface GroupType {
  __typename?: string;
  id: number;
  name: string;
  [key: string]: any;
}

// Define the supported group types
type GroupTypeKey =
  | 'compBandGroup'
  | 'rangeWidthGroup'
  | 'bonusStructureGroup'
  | 'equityBandGroup';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
  groupType: GroupTypeKey;
  align?: 'left' | 'center' | 'right';
}

export const GroupSelector = memo(
  ({
    editing,
    jobFamily,
    onItemChange,
    groupType,
    align = 'center',
  }: Props) => {
    // Get the current value from the job family based on groupType
    const currentValue = jobFamily[groupType] as GroupType | null | undefined;

    // Local state to track the selected value
    const [value, setValue] = useState<GroupType | null | undefined>(
      currentValue
    );
    const [open, setOpen] = useState(false);

    // Determine which query to use based on groupType
    const useQueryHook = () => {
      switch (groupType) {
        case 'compBandGroup':
          return useCompBandGroupsQuery();
        case 'rangeWidthGroup':
          return useRangeWidthGroupsQuery();
        case 'bonusStructureGroup':
          return useBonusStructureGroupsQuery();
        case 'equityBandGroup':
          return useEquityBandGroupsQuery();
        default:
          throw new Error(`Unsupported group type: ${groupType}`);
      }
    };

    // Execute the appropriate query
    const { data } = useQueryHook();

    // Get options based on groupType
    const getOptions = (): GroupType[] => {
      if (!data) return [];

      switch (groupType) {
        case 'compBandGroup':
          return 'compBandsGroups' in data
            ? ((data.compBandsGroups ?? []) as GroupType[])
            : [];
        case 'rangeWidthGroup':
          return 'rangeWidthGroups' in data
            ? ((data.rangeWidthGroups ?? []) as GroupType[])
            : [];
        case 'bonusStructureGroup':
          return 'bonusStructureGroups' in data
            ? ((data.bonusStructureGroups ?? []) as GroupType[])
            : [];
        case 'equityBandGroup':
          return 'equityBandGroups' in data
            ? ((data.equityBandGroups ?? []) as GroupType[])
            : [];
        default:
          return [];
      }
    };

    // Update local state when job family ID changes
    useEffect(() => {
      setValue(currentValue);
    }, [jobFamily.id, currentValue]);

    // Handle selection change
    const handleChange = (newValue: GroupType | null) => {
      setValue(newValue);

      // Create a new job family object with the updated group
      const newItem = {
        ...jobFamily,
        [groupType]: newValue,
      };

      // Update parent component state
      onItemChange({
        newItem,
      });
    };

    return (
      <TableCell align={align}>
        <Autocomplete
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          autoComplete
          blurOnSelect
          value={value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size="small"
          id={`${groupType}-autocomplete`}
          options={getOptions()}
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => handleChange(v)}
          sx={{ minWidth: 200, display: editing ? undefined : 'none' }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => <TextField {...params} />}
        />
        <Typography sx={{ display: editing ? 'none' : undefined }}>
          {value?.name || ''}
        </Typography>
      </TableCell>
    );
  }
);
