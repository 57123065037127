import { ZipcodeWithTierResponse } from '@alamere/generated-graphql-types';
import { Paper } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { capitalizeCity } from '../../utils';
import { tParent } from '../../vendor/i18n/i18n';
import { AlamereLocations } from '@alamere/core';
const tPage = tParent('multiZipLookupPage');

const columns: GridColDef[] = [
  { field: 'zipcode', headerName: tPage('table.zipcode'), minWidth: 100 },
  {
    field: 'city',
    headerName: tPage('table.city'),
    minWidth: 250,
    valueGetter(params) {
      return capitalizeCity(params.row.city);
    },
  },
  {
    field: 'metroArea',
    headerName: tPage('table.metroArea'),
    minWidth: 250,
    valueGetter(params) {
      return (
        (params.row.alamereLocationId &&
          AlamereLocations[
            params.row.alamereLocationId as keyof typeof AlamereLocations
          ].label) ||
        ' '
      );
    },
  },
  { field: 'state', headerName: tPage('table.state'), minWidth: 80 },
  { field: 'tierLabel', headerName: tPage('table.tierLabel'), minWidth: 100 },
  { field: 'tierNumber', headerName: tPage('table.tierNumber'), minWidth: 80 },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export type ZipcodeTableProps = {
  rows: ZipcodeWithTierResponse[];
};

export default function MultiZipcodeTable({ rows }: ZipcodeTableProps) {
  return (
    <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <DataGrid
        autoHeight={true}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
        getRowId={(row) => row.zipcode}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </Paper>
  );
}
