import {
  JobGroup,
  RadfordJobFamilyFragment,
  useJobFamiliesQuery,
  JobFamilySaveRequest,
  JobFamilyFragment,
  useRadfordJobFamiliesSearchLazyQuery,
} from '@alamere/generated-graphql-types';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { memo, useMemo, useCallback, useRef } from 'react';
import { SkeletonTableRows } from '../../components/SkeletonTableRows';
import { Header } from './Header';
import { Row } from './Row';
import { OnItemChangeFunction } from './types';

export interface TableProps {
  editing: boolean;
  onItemChange: OnItemChangeFunction;
  itemUpdates: Record<string, JobFamilySaveRequest>;
  duplicateFields?: { [key: string]: { code?: boolean; name?: boolean } };
}

export const Table = memo(
  ({ editing, onItemChange, itemUpdates, duplicateFields }: TableProps) => {
    const { data, loading } = useJobFamiliesQuery();
    const orderedFamilies = orderBy(data?.jobFamilies ?? [], ['id'], ['asc']);

    const [searchRadfordJobFamilies] = useRadfordJobFamiliesSearchLazyQuery();

    const mergedFamilies = useMemo(() => {
      const existingIds = new Set(orderedFamilies.map((fam) => fam.id));

      const newFamilies = Object.values(itemUpdates)
        .map((item) => {
          return {
            ...item,
            __typename: 'JobFamily' as const,
          };
        })
        .filter((fam) => !existingIds.has(fam.id ?? 0));

      return orderBy([...orderedFamilies, ...newFamilies], ['id'], ['asc']);
    }, [orderedFamilies, itemUpdates, editing]) as JobFamilyFragment[];

    const matchCache = useRef<Record<string, RadfordJobFamilyFragment | null>>(
      {}
    );

    const loadRadfordMatch = useCallback(
      async (code: string, jobGroup: JobGroup) => {
        if (!code) return null;

        // Create a cache key
        const cacheKey = `${code}_${jobGroup}`;

        // Check if we already have this match in cache
        if (matchCache.current[cacheKey] !== undefined) {
          return matchCache.current[cacheKey];
        }

        try {
          const baseCode = code.includes(' ') ? code.split(' ')[0] : code;

          const { data } = await searchRadfordJobFamilies({
            variables: {
              request: {
                query: baseCode,
                jobGroup,
              },
            },
          });

          const match =
            data?.radfordJobFamiliesSearch?.find(
              (item) => item.code === baseCode
            ) || null;

          // Cache the result
          matchCache.current[cacheKey] = match;

          return match;
        } catch (error) {
          console.error('Error loading Radford match:', error);
          return null;
        }
      },
      [searchRadfordJobFamilies]
    );

    return (
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 'calc(100vh - 140px)', overflow: 'auto' }}
      >
        <MuiTable
          stickyHeader
          sx={(theme) => ({
            tableLayout: 'auto',
            '& .highlighted-column': {
              backgroundColor: theme.palette.background.secondary,
            },
            'tr > :nth-of-type(3)': {
              borderRight: '1px solid',
              borderRightColor: 'grey.300',
            },
            'tr > :nth-of-type(9)': {
              borderRight: '1px solid',
              borderRightColor: 'grey.300',
            },
          })}
          size="small"
          aria-label={t('bonusStructuresPage.table.name')}
        >
          <Header editing={editing} />
          <TableBody>
            {loading && <SkeletonTableRows rows={5} cols={13} height={30} />}
            {mergedFamilies.map((fam) => (
              <Row
                key={fam.id}
                jobFamily={fam}
                editing={editing}
                onItemChange={onItemChange}
                loadRadfordMatch={loadRadfordMatch}
                loadingRadford={false}
                duplicateFields={duplicateFields}
              />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    );
  }
);
