import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ValidIcon from '@mui/icons-material/Check';
import InvalidIcon from '@mui/icons-material/Close';
import { green, red } from '@mui/material/colors';
import { t } from 'i18next';
import { PasswordRequirementValidationResult } from '../lib/passwordValidation';

export default function PasswordValidationFeedback({
  passwordValidationResults,
}: {
  passwordValidationResults: PasswordRequirementValidationResult[];
}) {
  return (
    <List>
      {passwordValidationResults.map(({ requirement, isValid }) => {
        const validColor = green[500];
        const invalidColor = red[500];
        return (
          <ListItem
            key={requirement}
            sx={{
              color: isValid ? validColor : invalidColor,
              py: 0,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, marginRight: '12px' }}>
              {isValid ? (
                <ValidIcon sx={{ color: validColor }} />
              ) : (
                <InvalidIcon sx={{ color: invalidColor }} />
              )}
            </ListItemIcon>
            <ListItemText primary={t(requirement)} />
          </ListItem>
        );
      })}
    </List>
  );
}
