import { WorkspaceIdAndNameFragment } from '@alamere/generated-graphql-types';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../providers/auth';
import EnterEmail from './EnterEmail';
import JoinWaitlist from './JoinWaitlist';
import SignUpWithAutoSignup from './SignUpWithAutoSignup';

enum Step {
  EnterEmail,
  AutoSignUp,
  JoinWaitlist,
}

export default function SignUpPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [email, setEmail] = useState(params.get('email') || '');
  const [step, setStep] = useState(Step.EnterEmail);
  const [autosignupWorkspace, setAutoSignupWorkspace] = useState<
    WorkspaceIdAndNameFragment | null | undefined
  >(null);

  useEffect(() => {
    setEmail(params.get('email') || '');
  }, [params]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const handleCheckAutoSignUp = (
    email: string,
    autoSignupWorkspace: WorkspaceIdAndNameFragment | null | undefined
  ) => {
    setEmail(email);
    setAutoSignupWorkspace(autoSignupWorkspace);
    if (!autoSignupWorkspace) {
      setStep(Step.JoinWaitlist);
    } else {
      setStep(Step.AutoSignUp);
    }
  };

  switch (step) {
    case Step.EnterEmail:
      return (
        <EnterEmail onCheckAutoSignUp={handleCheckAutoSignUp} email={email} />
      );
    case Step.JoinWaitlist:
      return <JoinWaitlist email={email} />;
    case Step.AutoSignUp:
      return (
        <SignUpWithAutoSignup email={email} workspace={autosignupWorkspace!} />
      );
    default:
      return <JoinWaitlist email={email} />;
  }
}
