import React  from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import { Head } from './Head';
import TableBody from '@mui/material/TableBody';
import { Row } from './Row';
import { JOB_GROUPS, Tier } from '@alamere/core';
import { groupBy, orderBy } from 'lodash';

interface Props {
  tier: Tier | null;
  processedData: any;
  selectedLevels: number[];
}

export function Table({ tier, processedData, selectedLevels}: Props) {
  if (!processedData) {
    return null;
  }
  const getRangePointsByGroup = (jobs: Array<{
      compBand: {onTargetEarnings: number; absoluteVariable: number | null; percentVariable: number;};
      rangeWidth: {percentBelowMid: number; percentAboveMid: number;},
      equityBand: {value: number}
      compBandMultiplier: number;
      bonus: { percent: number };
    }>) => {
    let salaryRangeMin = Infinity;
    let salaryRangeMax = -Infinity;
    let equityRangeMin = Infinity;
    let equityRangeMax = -Infinity;
    jobs.forEach((job) => {
      if (tier && job && job.bonus && job.rangeWidth && job.compBand && job.compBandMultiplier && job.equityBand) {
        const { compBand, rangeWidth, equityBand, compBandMultiplier } = job;
        const salaryMid = compBand.onTargetEarnings * (compBandMultiplier / 100) * (tier.percentile / 100);
        const currentSalaryMin = salaryMid * (1 - rangeWidth.percentBelowMid / 100);
        const currentSalaryMax = salaryMid * (1 + rangeWidth.percentAboveMid / 100);
        const equityMid = equityBand.value * (tier.percentile / 100);
        const currentEquityMin = equityMid * (1 - rangeWidth.percentBelowMid / 100);
        const currentEquityMax = equityMid * (1 + rangeWidth.percentAboveMid / 100);
        salaryRangeMin = Math.min(salaryRangeMin, currentSalaryMin);
        salaryRangeMax = Math.max(salaryRangeMax, currentSalaryMax);
        equityRangeMin = Math.min(equityRangeMin, currentEquityMin);
        equityRangeMax = Math.max(equityRangeMax, currentEquityMax);
      }
    })
    return {
      salaryRangeMin,
      salaryRangeMax,
      equityRangeMin,
      equityRangeMax
    }
  }

  const orderedJobs = orderBy(
    processedData?.jobs,
    [(j) => j.jobLevel.level],
    ['desc']
  );
  const groupedJobs = groupBy(orderedJobs, (j) => j.jobLevel.group);
  const renderRows = () => {
    return JOB_GROUPS.flatMap((group) => {
      const jobsInGroup = groupedJobs[group] || [];
      const rangePoints = getRangePointsByGroup(jobsInGroup);
      let isFirstValidRowInGroup = true;

      return jobsInGroup.map((job: any, index) => {
        if (job.missingRequiredData || !selectedLevels.includes(job.jobLevel.level)) {
          return null;
        }
        const row = (
          <Row
            key={job.id}
            job={job}
            bonus={job.bonus}
            compBand={job.compBand}
            rangePoints={rangePoints}
            rangeWidth={job.rangeWidth}
            equityBand={job.equityBand}
            compBandMultiplier={job.compBandMultiplier}
            tier={tier}
            itemIndex={index}
            isFirstInGroup={isFirstValidRowInGroup}
          />
        );

        if (isFirstValidRowInGroup) {
          isFirstValidRowInGroup = false;
        }

        return row;
      }).filter(Boolean);
    });
  };

  return (
    <TableContainer component={Paper} sx={{minWidth: '1440px'}}>
      <MuiTable size="small">
        <Head />
        <TableBody>
          {renderRows()}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
