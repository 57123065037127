import { User, Workspace } from '@alamere/generated-graphql-types';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { useEffect, useState } from 'react';
import { Flag } from '../../../../core/src/lib/flags';

export function useIdentifyFlagsmithUser(
  user: User | undefined,
  workspace: Workspace | undefined
) {
  const [userId, setUserId] = useState<number | undefined>();
  const [workspaceId, setWorkspaceId] = useState<number | undefined>();
  const flagsmith = useFlagsmith();

  useEffect(() => {
    if (user && workspace) {
      if (userId !== user.id || workspaceId !== workspace.id) {
        flagsmith.identify(`${user.id}:${workspace.id}`, {
          workspaceId: workspace.id,
        });
        setUserId(user.id);
        setWorkspaceId(workspace.id);
      }
    }
  }, [user, workspace]);
}

/**
 * Here "ungated" means "explicitly enabled".
 * TODO rename to something more intuitive
 */
export function useIsUngated(...requiredFlags: readonly Flag[]) {
  const flags = useFlags(requiredFlags);
  const [isUngated, setIsUngated] = useState(false);
  useEffect(() => {
    setIsUngated(requiredFlags.every((flag) => flags[flag]?.enabled));
  }, [flags]);

  return isUngated;
}
