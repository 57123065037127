import React, { useState } from 'react';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import { Chip, TableCell, TableRow, Typography, Box, TextField, Button, Modal, Tooltip, Slider } from '@mui/material';
import { tableBorder } from '../../layout/theme';
import { Tier } from '@alamere/core';
import { styled } from '@mui/material/styles';
import { green, white } from '../../layout/colors';


const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#d3d3d3', // Default color for the slider
  height: 8,
  '& .MuiSlider-track': {
    backgroundColor: green['400'], // Default color for the track
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#d3d3d3', // Color for the rail
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'transparent', // Hide all marks by default
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  '& .MuiSlider-markLabel': {
    fontSize: '12px',
    color: theme.palette.mode === 'light' ? '#00000099' : white,
    top: -15,
  },
  '& .MuiSlider-track[data-index="1"]': {
    backgroundColor: green['400'], // Green color for the specific range
  },
  '& .MuiSlider-thumb': {
    display: 'none', // Hide thumbs since the slider is static
  },
}));

const RangeBar = ({rangeMin, rangeMax, greenRange, lowPercent, topPercent}: {rangeMin: number; rangeMax: number; greenRange: number[]; lowPercent: number; topPercent: number}) => {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [hoverPercent, setHoverPercent] = useState<number | null>(null);

  const marks = [
    { value: greenRange[0], label: `$${formatNumber(greenRange[0])}` },
    { value: greenRange[1], label: `$${formatNumber(greenRange[1])}` },
    { value: greenRange[2], label: `$${formatNumber(greenRange[2])}` },
  ].filter((obj, index, self) =>
    index === self.findIndex((o) => o.label === obj.label)
  );

  const getPercentageAtValue = (value: number, referenceValue: number): number => {
    return Math.round((value / referenceValue) * 100);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const sliderElement = event.currentTarget;
    const { left, top, width, height } = sliderElement.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;
    if (x >= 0 && x <= width && y >= 0 && y <= height) {
      const percentage = x / width; // Calculate the percentage of the slider's width
      const value = Math.round(rangeMin + percentage * (rangeMax - rangeMin)); // Calculate the value based on the percentage
      setHoverValue(value);
      setHoverPercent(() => getPercentageAtValue(rangeMin + percentage * (rangeMax - rangeMin), greenRange[1]));
    } else {
      handleMouseLeave();
    }
};

  const handleMouseLeave = () => {
    setHoverValue(null);
    setHoverPercent(null);
  };

  return (
    <Box sx={{ width: '100%', margin: '0 auto', position: 'relative' }}>
      <CustomSlider
        value={greenRange}
        min={rangeMin}
        max={rangeMax}
        marks={marks}
        valueLabelDisplay="auto"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        sx={{
          '& .MuiSlider-mark[data-index="1"]': {
            backgroundColor: (theme) => theme.palette.mode === 'light' ? green['800'] : white,
          },
          '& .MuiSlider-markLabel[data-index="1"]': {
            fontWeight: 'bold', // Apply bold font to the second/middle label
          }
        }}
      />
      <Box
        sx={{
          color: (theme) => theme.palette.mode === 'light' ? '#00000099' : white,
          position: 'absolute',
          top: 30,
          left: `calc(${((greenRange[0] - rangeMin) / (rangeMax - rangeMin)) * 100}% - 10px)`,
          textAlign: 'center',
          fontSize: '12px',
        }}
      >
        <div>{100-lowPercent}%</div>
      </Box>
      <Box
        sx={{
          color: (theme) => theme.palette.mode === 'light' ? '#00000099' : white,
          position: 'absolute',
          top: 30,
          left: `calc(${((greenRange[2] - rangeMin) / (rangeMax - rangeMin)) * 100}% - 10px)`,
          textAlign: 'center',
          fontSize: '12px',
        }}
      >
        <div>{100+topPercent}%</div>
      </Box>
      {hoverValue !== null && hoverPercent !== null && (
        <Box
          sx={{
            position: "absolute",
            left: `calc(${((hoverValue - rangeMin) / (rangeMax - rangeMin)) * 100}%)`,
            top: "-35px",
            transform: "translateX(-50%)",
            backgroundColor:(theme) => theme.palette.mode === 'light' ? "rgba(0, 0, 0, 0.7)" : green[400],
            color: "white",
            padding: "2px 5px",
            borderRadius: "3px",
            fontSize: "12px",
            textAlign: 'center',
          }}
        >
          <Box>${formatNumber(hoverValue)}</Box>
          <Box>{hoverPercent}%</Box>
        </Box>
      )}
    </Box>
  );
};

interface Props {
  job: {
    title: string;
    jobLevel: { level: number, code: string, group: string };
  };
  bonus: { percent: number };
  compBand: {
    onTargetEarnings: number;
    absoluteVariable: number | null;
    percentVariable: number;
  };
  rangeWidth: {
    percentBelowMid: number;
    percentAboveMid: number;
  };
  equityBand: { value: number };
  compBandMultiplier: number;
  tier: Tier | null;
  itemIndex: number;
  isFirstInGroup: boolean;
  rangePoints: {
    salaryRangeMin: number,
    salaryRangeMax: number,
    equityRangeMin: number,
    equityRangeMax: number
  }
}

const formatNumber = (num: number) => {
  if (num >= 1000) {
    return (num / 1000).toLocaleString('en-US', { maximumFractionDigits: 1 }) + 'k';
  }
  return num.toLocaleString('en-US', { maximumFractionDigits: 0 });
};

const formatNumberWithCommas = (number: string) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const RangeDisplay: React.FC<{ min: number; mid: number; max: number, lowPercent: number, topPercent: number, rangeMin: number, rangeMax: number }> = React.memo(({ min, mid, max, lowPercent, topPercent, rangeMin, rangeMax }) => (
  <Box>
    <RangeBar rangeMin={rangeMin} rangeMax={rangeMax} greenRange={[min, mid, max]} lowPercent={lowPercent} topPercent={topPercent} />
  </Box>
));

export const Row: React.FC<Props> = React.memo(({ job, bonus, rangeWidth, compBand, compBandMultiplier, equityBand, tier, itemIndex, isFirstInGroup, rangePoints}) => {
  if (!job || !bonus || !rangeWidth || !compBand || !compBandMultiplier || !equityBand || !tier || !rangePoints) {
    return <></>
  }
  const [open, setOpen] = useState(false);
  const salaryMid = compBand.onTargetEarnings * (compBandMultiplier / 100) * (tier.percentile / 100);
  const salaryMin = salaryMid * (1 - rangeWidth.percentBelowMid / 100);
  const salaryMax = salaryMid * (1 + rangeWidth.percentAboveMid / 100);

  const equityMid = equityBand.value * (tier.percentile / 100);
  const equityMin = equityMid * (1 - rangeWidth.percentBelowMid / 100);
  const equityMax = equityMid * (1 + rangeWidth.percentAboveMid / 100);

  const ratioText = compBand.absoluteVariable
    ? `$${formatNumber(compBand.absoluteVariable)} variable`
    : `${100 - compBand.percentVariable}:${compBand.percentVariable}`;

  const ModalContent = () => {
    const [ote, setOte] = useState(salaryMid);
    const [base, setBase] = useState(compBand.absoluteVariable
      ? ote - compBand.absoluteVariable
      : ote - (compBand.percentVariable / 100) * ote);
    const [displayOteValue, setDisplayOteValue] = useState(formatNumberWithCommas(ote.toFixed(0)));
    const [displayBaseValue, setDisplayBaseValue] = useState(formatNumberWithCommas(base.toFixed(0)));

    const handleOteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.replace(/,/g, ''); // Remove commas for parsing

      if (inputValue === '') {
        setOte(0)
        setBase(0)
        setDisplayOteValue('0')
        setDisplayBaseValue('0')
      } else if (!isNaN(Number(inputValue))) {
        const newOte = parseFloat(inputValue);
        const newBase = compBand.absoluteVariable
          ? newOte - compBand.absoluteVariable
          : newOte - (compBand.percentVariable / 100) * newOte;
        setOte(newOte);
        setBase(newBase);
        setDisplayOteValue(formatNumberWithCommas(newOte.toFixed(0)));
        setDisplayBaseValue(formatNumberWithCommas(newBase.toFixed(0)))
      }
    };

    const handleBaseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.replace(/,/g, ''); // Remove commas for parsing
      if (inputValue === '') {
        setOte(0)
        setBase(0)
        setDisplayOteValue('0')
        setDisplayBaseValue('0')
      } else if (!isNaN(Number(inputValue)) && inputValue !== '') {
        const newBase = parseFloat(inputValue)
        const newOte = compBand.absoluteVariable
          ? newBase + compBand.absoluteVariable
          : newBase / (1 - compBand.percentVariable / 100)
        setOte(newOte);
        setBase(newBase);
        setDisplayOteValue(formatNumberWithCommas(newOte.toFixed(0)));
        setDisplayBaseValue(formatNumberWithCommas(newBase.toFixed(0)))
      }
    };

    const variable = compBand.absoluteVariable
      ? ote - base
      : (compBand.percentVariable / 100) * ote;
    const bonusPercent = compBand.absoluteVariable
      ? ((ote - base) / ote * 100).toFixed(0)
      : compBand.percentVariable;

    return (
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        gap: 2,
        borderRadius: 1
      }}>
        <Typography variant="subtitle1" sx={{fontWeight: 'bold', paddingBottom: 1}}>{job.title} details</Typography>
        <Box sx={{
          display: 'flex',
          gap: 2,
        }}>
          <Box sx={{ display: 'flex',  flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ width: 100 }}>OTE</Typography>
            <TextField
              value={displayOteValue}
              fullWidth
              onChange={handleOteChange}
              type="text"
              inputProps={{ inputMode: 'numeric' }}
            />
          </Box>
          <Box sx={{ display: 'flex',  flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ width: 100 }}>Base</Typography>
            <TextField
              value={displayBaseValue}
              fullWidth
              onChange={handleBaseChange}
              type="text"
              inputProps={{ inputMode: 'numeric' }}
            />
          </Box>
          <Box sx={{ display: 'flex',  flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ width: 100 }}>Variable</Typography>
            <TextField
              disabled
              value={`$${formatNumber(variable)} (${bonusPercent}%)`}
              fullWidth
              multiline
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <TableRow sx={(theme) => ({
      borderTop: itemIndex === 0 ? tableBorder(theme) : undefined,
      borderTopStyle: itemIndex === 0 ? 'double' : undefined,
      borderTopWidth: 4,
    })}>
      <TableCell
        width={'12.5%'}
        sx={(theme) => ({
          visibility: isFirstInGroup ? 'visible' : 'hidden',
          borderBottomWidth: 0,
          borderRight: tableBorder(theme),
        })}
      >
        <Typography>{job.jobLevel.group}</Typography>
      </TableCell>
      <TableCell
        width={'12.5%'}
        sx={(theme) => ({
          borderRight: tableBorder(theme),
          padding: '12px',
          verticalAlign: 'top'
        })}
      >
        <Typography variant="subtitle1">{job.title}</Typography>
        <Box sx={{display: 'flex', alignItems: 'center', mb: 1, gap: 1, flexWrap: 'wrap'}}>
          <Typography variant="body2" color="textSecondary">
            Level {job.jobLevel.level}  -
          </Typography>
          <Chip label={job.jobLevel.code} size="small" />
        </Box>
      </TableCell>
      <TableCell
        width={'10%'}
        sx={(theme) => ({
          borderRight: tableBorder(theme),
          padding: '12px',
          verticalAlign: 'middle',
          textAlign: 'center'
        })}
      >
        <Typography>
          {ratioText}
        </Typography>
        <Typography>
          {bonus.percent > 0 ? `${bonus.percent}% bonus` : '--'}
        </Typography>
      </TableCell>
      <TableCell
        width={'30%'}
        sx={(theme) => ({
          borderRight: tableBorder(theme),
          padding: '35px 30px 12px 30px',
          verticalAlign: 'top',
        })}
      >
        <RangeDisplay min={salaryMin} mid={salaryMid} max={salaryMax} lowPercent={rangeWidth.percentBelowMid} topPercent={rangeWidth.percentAboveMid} rangeMin={rangePoints.salaryRangeMin} rangeMax={rangePoints.salaryRangeMax} />
      </TableCell>
      <TableCell
        width={'30%'}
        sx={(theme) => ({
          borderRight: tableBorder(theme),
          padding: '35px 30px 12px 30px',
          verticalAlign: 'top',
        })}
      >
        <RangeDisplay min={equityMin} mid={equityMid} max={equityMax} lowPercent={rangeWidth.percentBelowMid} topPercent={rangeWidth.percentAboveMid} rangeMin={rangePoints.equityRangeMin} rangeMax={rangePoints.equityRangeMax} />
      </TableCell>
      <TableCell
        width={'5%'}
        sx={(theme) => ({
          borderRight: tableBorder(theme),
          padding: '30px 30px 20px',
          textAlign: 'center',
          verticalAlign: 'center'
        })}
      >
        <Button onClick={() => setOpen(true)}>
          <CalculateRoundedIcon />
        </Button>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box><ModalContent /></Box>
        </Modal>
      </TableCell>
    </TableRow>
  );
});

Row.displayName = 'Row';
