import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/auth';

export function Logo({ color = 'inherit' }: { color?: string }) {
  const navigate = useNavigate();
  const { workspace } = useAuth();

  const handleClick = () => {
    if (workspace?.id) {
      navigate(`/w/${workspace.id}/location-lookup/single`);
    } else {
      navigate('/');
    }
  };

  return (
    <Button onClick={handleClick} variant="text" color="inherit" disableRipple>
      <Typography
        component="h1"
        variant="title"
        fontWeight={900}
        pl={1}
        color={color}
      >
        Alamere
      </Typography>
    </Button>
  );
}
