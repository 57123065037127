// NOTE: enum values are i18n keys
export enum PasswordRequirement {
  LENGTH = 'signUpPage.passwordRequirement.length',
  // DISALLOWED_CHARACTERS = 'signUpPage.passwordRequirement.disallowedCharacters',
  LOWERCASE_LETTERS = 'signUpPage.passwordRequirement.lowercaseLetters',
  UPPERCASE_LETTERS = 'signUpPage.passwordRequirement.uppercaseLetters',
  NUMERIC_CHARACTERS = 'signUpPage.passwordRequirement.numericCharacters',
  SPECIAL_CHARACTERS = 'signUpPage.passwordRequirement.specialCharacters',
}

type PasswordRequirementConfig = {
  requirement: PasswordRequirement;
  regExp: RegExp;
  minCount?: number;
  isDisallowed?: boolean;
};

export const MIN_PASSWORD_LENGTH = 8;

// NOTE: configs and tests copied from `validator` package, see `node_modules/validator/lib/isStrongPassword.js`
const passwordRequirementConfigs: PasswordRequirementConfig[] = [
  // {
  //   requirement: PasswordRequirement.DISALLOWED_CHARACTERS,
  //   regExp: /^\s$/,
  //   isDisallowed: true,
  // },
  {
    requirement: PasswordRequirement.LOWERCASE_LETTERS,
    regExp: /^[a-z]$/,
    minCount: 1,
  },
  {
    requirement: PasswordRequirement.UPPERCASE_LETTERS,
    regExp: /^[A-Z]$/,
    minCount: 1,
  },
  {
    requirement: PasswordRequirement.NUMERIC_CHARACTERS,
    regExp: /^[0-9]$/,
    minCount: 1,
  },
  {
    // TODO should we configure `validator` to not accept spaces? There's a space in the special character regex
    requirement: PasswordRequirement.SPECIAL_CHARACTERS,
    regExp: /^[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./ ]$/,
    minCount: 1,
  },
];

export type PasswordRequirementValidationResult = {
  requirement: PasswordRequirement;
  isValid: boolean;
};

// NOTE: adapted from `validator` package, see `node_modules/validator/lib/isStrongPassword.js`
function countChars(str: string) {
  const result: Record<string, number> = {};
  Array.from(str).forEach(function (char) {
    const curVal = result[char];

    if (curVal) {
      result[char] += 1;
    } else {
      result[char] = 1;
    }
  });
  return result;
}

// NOTE: adapted from `validator` package, see `node_modules/validator/lib/isStrongPassword.js`
function analyzePassword(
  password: string,
  passwordRequirementConfigs: PasswordRequirementConfig[]
) {
  const charMap = countChars(password);
  const analysis = {
    length: password.length,
    uniqueChars: Object.keys(charMap).length,
    requirementCounts: {} as Record<PasswordRequirement, number>,
  };
  Object.keys(charMap).forEach((char) => {
    passwordRequirementConfigs.forEach(({ requirement, regExp }) => {
      if (regExp.test(char)) {
        analysis.requirementCounts[requirement] =
          (analysis.requirementCounts[requirement] || 0) + charMap[char];
      }
    });
  });
  return analysis;
}

export function validatePassword(
  password: string
): PasswordRequirementValidationResult[] {
  const analysis = analyzePassword(password, passwordRequirementConfigs);

  const lengthResult = {
    requirement: PasswordRequirement.LENGTH,
    isValid: password.length >= MIN_PASSWORD_LENGTH,
  };

  const configResults = passwordRequirementConfigs.map(
    ({ requirement, minCount, isDisallowed }) => {
      if (isDisallowed) {
        return {
          requirement,
          isValid: (analysis.requirementCounts[requirement] || 0) === 0,
        };
      }
      return {
        requirement,
        isValid:
          (analysis.requirementCounts[requirement] || 0) >= (minCount || 1),
      };
    }
  );

  return [lengthResult, ...configResults];
}

export const defaultValidationResults = (
  Object.values(PasswordRequirement) as PasswordRequirement[]
).map((requirement) => ({
  requirement,
  isValid: false,
}));
