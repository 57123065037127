import { Scope } from '@alamere/core';
import {
  JobFamiliesDocument,
  JobFamilyFragment,
  useJobFamiliesQuery,
  useJobFamiliesSaveMutation,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import AddRounded from '@mui/icons-material/AddRounded';
import {
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { t } from 'i18next';
import { uniq } from 'lodash';
import { useCallback, useState, ReactNode } from 'react';
import { EditButtons } from '../../components/EditButtons';
import { Table } from './Table';
import { OnItemChangeFunction } from './types';
import { createNewJobFamily, getNextCode } from './utils';
import { useSnackbar } from 'notistack';
import { JobFamiliesCsvUpload } from '../CsvUploads/JobFamiliesCsvUpload';

export default function JobFamiliesPage() {
  const client = useApolloClient();
  const [editing, setEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [warningDialogContent, setWarningDialogContent] =
    useState<ReactNode>(null);
  const [itemUpdates, setItemUpdates] = useState<
    Record<string, JobFamilyFragment>
  >({});

  const [jobFamilyIdsToDelete, setJobFamilyIdsToDelete] = useState<number[]>(
    []
  );

  const { data: jobFamiliesData } = useJobFamiliesQuery();

  const [saveItems] = useJobFamiliesSaveMutation();

  const [duplicateFields, setDuplicateFields] = useState<{
    [key: string]: { code?: boolean; name?: boolean };
  }>({});

  const validateItems = (items: JobFamilyFragment[]): boolean => {
    const newDuplicateFields: {
      [key: string]: { code?: boolean; name?: boolean };
    } = {};
    let isValid = true;

    const codeMap = new Map<string, Set<string>>();
    const nameMap = new Map<string, Set<string>>();

    // First pass: collect all codes and names using Sets to ensure unique IDs
    items.forEach((item) => {
      const code = item.code?.toLowerCase()?.trim();
      const name = item.name?.toLowerCase()?.trim();

      if (code) {
        if (!codeMap.has(code)) {
          codeMap.set(code, new Set());
        }
        const codeSet = codeMap.get(code);
        if (codeSet) {
          codeSet.add(item.id.toString());
        }
      }

      if (name) {
        if (!nameMap.has(name)) {
          nameMap.set(name, new Set());
        }
        const nameSet = nameMap.get(name);
        if (nameSet) {
          nameSet.add(item.id.toString());
        }
      }
    });

    // Second pass: mark only items that have duplicates
    codeMap.forEach((ids, code) => {
      if (ids.size > 1) {
        isValid = false;
        ids.forEach((id) => {
          newDuplicateFields[id] = {
            ...(newDuplicateFields[id] || {}),
            code: true,
          };
        });
      }
    });

    nameMap.forEach((ids, name) => {
      if (ids.size > 1) {
        isValid = false;
        ids.forEach((id) => {
          newDuplicateFields[id] = {
            ...(newDuplicateFields[id] || {}),
            name: true,
          };
        });
      }
    });

    setDuplicateFields(newDuplicateFields);
    return isValid;
  };

  const handleSave = async () => {
    if (
      Object.values(itemUpdates).length > 0 ||
      jobFamilyIdsToDelete.length > 0
    ) {
      const itemsToSave = Object.values(itemUpdates).map((item) => ({
        id: item.id,
        name: item.name?.trim(),
        code: item.code?.trim(),
        ignoreGeoDiff: item.ignoreGeoDiff,
        compBandGroupId: item.compBandGroup?.id || null,
        rangeWidthGroupId: item.rangeWidthGroup?.id || null,
        bonusStructureGroupId: item.bonusStructureGroup?.id || null,
        equityBandGroupId: item.equityBandGroup?.id || null,
        jobFunctionId: item.jobFunction?.id || null,
        radfordExecutiveMatchCode: item.radfordExecutiveMatchCode,
        radfordManagementMatchCode: item.radfordManagementMatchCode,
        radfordProfessionalMatchCode: item.radfordProfessionalMatchCode,
        radfordScientificMatchCode: item.radfordScientificMatchCode,
        radfordSupportMatchCode: item.radfordSupportMatchCode,
        compBandMultiplier: item.compBandMultiplier,
      }));

      const currentItems = [
        ...(jobFamiliesData?.jobFamilies ?? []),
        ...itemsToSave,
      ].filter((item) => !jobFamilyIdsToDelete.includes(item.id));

      if (!validateItems(currentItems)) {
        enqueueSnackbar(
          'Please fix duplicate entries before saving. Duplicate codes or names will be highlighted in red.',
          {
            variant: 'error',
            preventDuplicate: true,
          }
        );
        return;
      }

      try {
        await saveItems({
          variables: {
            request: {
              items: itemsToSave,
              jobFamilyIdsToDelete,
            },
          },
          refetchQueries: [JobFamiliesDocument],
        });
        setItemUpdates({});
        setJobFamilyIdsToDelete([]);
        setDuplicateFields({});
        setEditing(false);
      } catch (error: any) {
        if (error.message?.includes('duplicate key')) {
          enqueueSnackbar(
            'This item already exists. Please use a unique value for the code or name.',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar(
            'An error occurred while saving the job families. Please try again or contact support if the issue persists.',
            {
              variant: 'error',
              preventDuplicate: true,
            }
          );
        }
      }
    }
  };

  const handleCancel = () => {
    try {
      // Evict all temporary job family entries from cache
      Object.values(itemUpdates).forEach((item) => {
        // Only attempt to evict if it's a temporary ID (not an existing one)
        // Temporary IDs would be the ones created during this editing session
        const cacheId = `JobFamily:${item.id}`;
        client.cache.evict({ id: cacheId });
      });

      // Force garbage collection to remove dangling references
      client.cache.gc();
    } catch (error) {
      console.error('Error clearing cache:', error);
    }

    // Then reset the React state
    setItemUpdates({});
    setJobFamilyIdsToDelete([]);
    setDuplicateFields({});

    client.refetchQueries({
      include: [JobFamiliesDocument],
    });

    setEditing(false);
  };

  const handleItemChange: OnItemChangeFunction = useCallback(
    ({ newItem, jobFamilyIdToDelete }) => {
      if (newItem?.id) {
        setItemUpdates((prev) => {
          // Get the previous state for this job family, if it exists
          const prevItem = prev[newItem.id] || {};

          // Store the complete objects instead of just IDs
          return {
            ...prev,
            [newItem.id]: {
              // Start with previous values
              ...prevItem,
              // Basic fields
              id: newItem.id,
              name: newItem.name ?? prevItem.name ?? '',
              code: newItem.code ?? prevItem.code ?? '',
              ignoreGeoDiff:
                newItem.ignoreGeoDiff ?? prevItem.ignoreGeoDiff ?? false,

              // Relationship fields - preserve the entire object, not just ID
              compBandGroup:
                newItem.compBandGroup ?? prevItem.compBandGroup ?? null,
              rangeWidthGroup:
                newItem.rangeWidthGroup ?? prevItem.rangeWidthGroup ?? null,
              bonusStructureGroup:
                newItem.bonusStructureGroup ??
                prevItem.bonusStructureGroup ??
                null,
              equityBandGroup:
                newItem.equityBandGroup ?? prevItem.equityBandGroup ?? null,
              jobFunction: newItem.jobFunction ?? prevItem.jobFunction ?? null,

              // Other fields
              radfordExecutiveMatchCode:
                newItem.radfordExecutiveMatchCode ??
                prevItem.radfordExecutiveMatchCode ??
                null,
              radfordManagementMatchCode:
                newItem.radfordManagementMatchCode ??
                prevItem.radfordManagementMatchCode ??
                null,
              radfordProfessionalMatchCode:
                newItem.radfordProfessionalMatchCode ??
                prevItem.radfordProfessionalMatchCode ??
                null,
              radfordScientificMatchCode:
                newItem.radfordScientificMatchCode ??
                prevItem.radfordScientificMatchCode ??
                null,
              radfordSupportMatchCode:
                newItem.radfordSupportMatchCode ??
                prevItem.radfordSupportMatchCode ??
                null,
              compBandMultiplier:
                newItem.compBandMultiplier ??
                prevItem.compBandMultiplier ??
                null,
            },
          };
        });
      }

      if (jobFamilyIdToDelete) {
        setItemUpdates((prev) => {
          const newUpdates = { ...prev };
          delete newUpdates[jobFamilyIdToDelete];
          return newUpdates;
        });

        const isExistingRow = jobFamiliesData?.jobFamilies.some(
          (f) => f.id === jobFamilyIdToDelete
        );

        if (isExistingRow) {
          setJobFamilyIdsToDelete((prev) =>
            uniq([...prev, jobFamilyIdToDelete])
          );
        }
      }
    },
    [jobFamiliesData?.jobFamilies]
  );

  const handleCreateNewFamily = () => {
    const allJobFamilies = [
      ...(jobFamiliesData?.jobFamilies ?? []),
      ...Object.values(itemUpdates),
    ];
    const nextCode = getNextCode(allJobFamilies);
    const newJobFamily = createNewJobFamily(
      nextCode,
      `${t('jobFamiliesPage.newFamilyName')} ${nextCode}`,
      allJobFamilies
    );

    setItemUpdates((prev) => ({
      ...prev,
      [newJobFamily.id]: {
        id: newJobFamily.id,
        code: newJobFamily.code,
        name: newJobFamily.name,
        ignoreGeoDiff: newJobFamily.ignoreGeoDiff,
        compBandGroup: null,
        rangeWidthGroup: null,
        bonusStructureGroup: null,
        equityBandGroup: null,
        jobFunction: null,
        radfordExecutiveMatchCode: null,
        radfordManagementMatchCode: null,
        radfordProfessionalMatchCode: null,
        radfordScientificMatchCode: null,
        radfordSupportMatchCode: null,
        compBandMultiplier: null,
        dynamicParentId: null,
        dynamicPercent: null,
        __typename: 'JobFamily' as const,
      },
    }));
  };

  const handleCsvDataProcessed = (
    newUpdates: Record<string, JobFamilyFragment>
  ) => {
    setItemUpdates((prev) => {
      const combined = { ...prev, ...newUpdates };
      return combined;
    });
  };

  return (
    <Stack spacing={2} mb={2}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EditButtons
          requiredScopes={[Scope.RANGE_WIDTHS_EDIT]}
          onEdit={() => setEditing(true)}
          onSave={handleSave}
          onCancel={handleCancel}
          editing={editing}
          setEditing={setEditing}
        />
        {editing && (
          <JobFamiliesCsvUploadWrapper
            editing={editing}
            setEditing={setEditing}
            onDataProcessed={handleCsvDataProcessed}
            existingItems={[
              ...(jobFamiliesData?.jobFamilies ?? []),
              ...Object.values(itemUpdates),
            ]}
            setWarningDialogContent={setWarningDialogContent}
            setWarningDialogOpen={setWarningDialogOpen}
          />
        )}
      </Box>
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Table
          editing={editing}
          onItemChange={handleItemChange}
          itemUpdates={itemUpdates}
          duplicateFields={duplicateFields}
        />
      </Stack>
      {editing && (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ width: 'fit-content' }}
            variant="outlined"
            startIcon={<AddRounded />}
            onClick={handleCreateNewFamily}
          >
            {t('jobFamiliesPage.addNewFamily')}
          </Button>
        </Stack>
      )}

      <Dialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {t('jobFamiliesPage.csvWarningsTitle', 'CSV Import Warnings')}
        </DialogTitle>
        <DialogContent dividers>{warningDialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialogOpen(false)} color="primary">
            {t('common.close', 'Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

const JobFamiliesCsvUploadWrapper = ({
  editing,
  setEditing,
  onDataProcessed,
  existingItems,
}: {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  onDataProcessed: (newEntities: Record<string, JobFamilyFragment>) => void;
  existingItems: JobFamilyFragment[];
  setWarningDialogContent: (content: React.ReactNode) => void;
  setWarningDialogOpen: (open: boolean) => void;
}) => {
  return (
    <Box sx={{ ml: 2 }}>
      <JobFamiliesCsvUpload
        editing={editing}
        setEditing={setEditing}
        onDataProcessed={onDataProcessed}
        existingItems={existingItems}
      />
    </Box>
  );
};
