import { Scope } from '@alamere/core';
import {
  GlobalLevelFragment,
  RangeWidthFragment,
  RangeWidthSaveRequest,
  useGlobalLevelsQuery,
  useJobLevelsQuery,
  useRangeWidthGroupDeleteMutation,
  useRangeWidthGroupsQuery,
  useRangeWidthGroupsSaveMutation,
  useRangeWidthSaveMutation,
} from '@alamere/generated-graphql-types';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { cloneDeep, orderBy } from 'lodash';
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EditButtons } from '../../components/EditButtons';
import { useScopes } from '../../hooks/useScopes';
import {
  OnRangeWidthChangeFunction,
  OnRangeWidthGroupChangeFunction,
  OnRangeWidthGroupDeleteFunction,
  RangeWidthGroup,
  RangeWidthGroupUpdates,
  RangeWidthUpdates,
  RowData,
} from './types';
import { useAuth } from '../../providers/auth';

export function RangeWidthsPage() {
  const { checkScopes } = useScopes();
  const { workspaceMembership } = useAuth();
  const { data: globalLevelData, loading: loadingGlobalLevel } =
    useGlobalLevelsQuery();
  const {
    data: rangeWidthGroupsData,
    refetch: refetch,
    loading: loadingRangeWidthGroups,
  } = useRangeWidthGroupsQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });
  const visibleGlobalLevels = useMemo(
    () =>
      globalLevelData?.globalLevels.filter((level) =>
        jobLevelsData?.jobLevels.some(
          (jobLevel) => jobLevel?.level === level.level
        )
      ),
    [globalLevelData, jobLevelsData]
  );

  const [saveRangeWidths, { loading: savingRangeWidths }] =
    useRangeWidthSaveMutation();
  const [saveRangeWidthGroups, { loading: savingRangeWidthGroup }] =
    useRangeWidthGroupsSaveMutation();
  const [deleteRangeWidthGroup, { loading: deletingRangeWidthGroup }] =
    useRangeWidthGroupDeleteMutation();

  const [globalLevel, setGlobalLevels] = useState<GlobalLevelFragment[]>([]);
  const [rows, setRows] = useState<RowData[]>([]);
  const [rangeWidthGroups, setRangeWidthGroups] = useState<RangeWidthGroup[]>(
    []
  );

  const [updates, setUpdates] = useState<RangeWidthUpdates>({});
  const [rangeWidthGroupUpdates, setRangeWidthGroupUpdates] =
    useState<RangeWidthGroupUpdates>({});
  const [editing, setEditing] = useState<boolean>(false);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (visibleGlobalLevels && rangeWidthGroupsData?.rangeWidthGroups) {
      const orderedGlobalLevels = orderBy(
        visibleGlobalLevels,
        ['level'],
        ['desc']
      );
      const sortedGroups = orderBy(
        rangeWidthGroupsData.rangeWidthGroups,
        ['id'],
        ['asc']
      );
      const rows = (
        checkScopes(Scope.SEE_ALL_LEVELS_DATA)
          ? orderedGlobalLevels
          : orderedGlobalLevels
            .filter((level) =>
              level.level < (workspaceMembership?.globalLevelLevel as number)))
        .map((globalLevel) => {
        return {
          globalLevel,
          rangeWidths: sortedGroups.map((rangeWidthGroup) => {
            return rangeWidthGroup.rangeWidths.find(
              (rangeWidth) => rangeWidth.level === globalLevel.level
            ) as RangeWidthFragment;
          }),
        };
      });

      setRangeWidthGroups(sortedGroups);
      setGlobalLevels(orderedGlobalLevels);
      setRows(rows);
      setUpdates({});
      setRangeWidthGroupUpdates({});
    }
  }, [visibleGlobalLevels, rangeWidthGroupsData]);

  const handleRangeWidthChange = useCallback<OnRangeWidthChangeFunction>(
    ({ rangeWidth }) => {
      setUpdates((updates) => {
        const newUpdates = cloneDeep(updates);
        if (!newUpdates[rangeWidth.level]) {
          newUpdates[rangeWidth.level] = {};
        }
        newUpdates[rangeWidth.level][rangeWidth.rangeWidthGroupId] = rangeWidth;
        return newUpdates;
      });
    },
    []
  );

  const handleDeleteRangeWidthGroup =
    useCallback<OnRangeWidthGroupDeleteFunction>(async (id) => {
      await deleteRangeWidthGroup({ variables: { rangeWidthGroupDeleteId: id } });
      refetch();
    }, []);

  const handleSave = async () => {
    const widthUpdates = (
      Object.values(updates) as {
        [rangeWidthGroupId: number]: RangeWidthSaveRequest;
      }[]
    ).flatMap(
      (levelUpdates) => Object.values(levelUpdates) as RangeWidthSaveRequest[]
    );
    const groupUpdates = Object.values(rangeWidthGroupUpdates);
    console.log(groupUpdates);
    if (widthUpdates.length > 0) {
      await saveRangeWidths({
        variables: {
          rangeWidthSaveRequest: {
            items: widthUpdates,
          },
        },
      });
    }
    if (groupUpdates.length > 0) {
      await saveRangeWidthGroups({
        variables: {
          rangeWidthGroupSaveManyRequest: {
            items: groupUpdates,
          },
        },
      });
    }

    await refetch();
  };

  const handleCreateNewGroup = async () => {
    await saveRangeWidthGroups({
      variables: {
        rangeWidthGroupSaveManyRequest: {
          items: [{ name: t('rangeWidthsPage.newGroupName') }],
        },
      },
    });
    await refetch();
  };

  const handleRangeWidthGroupChange =
    useCallback<OnRangeWidthGroupChangeFunction>((rangeWidthGroup) => {
      setRangeWidthGroupUpdates((updates) => {
        const newUpdates = cloneDeep(updates);
        newUpdates[rangeWidthGroup.id] = rangeWidthGroup;
        return newUpdates;
      });
    }, []);

  const LazyRangeWidthsTable = lazy(() => import('./RangeWidthsTable'));
  const tableComponent = useMemo(() => {
    return (
      <LazyRangeWidthsTable
        key={resetKey}
        globalLevel={globalLevel}
        rows={rows}
        rangeWidthGroups={rangeWidthGroups}
        editing={editing}
        onRangeWidthChange={handleRangeWidthChange}
        onRangeWidthGroupChange={handleRangeWidthGroupChange}
        onRangeWidthGroupDelete={handleDeleteRangeWidthGroup}
      />
    );
  }, [globalLevel, rows, rangeWidthGroups, editing, resetKey]);

  const handleCancel = () => {
    setEditing(false);
    setUpdates({});
    setRangeWidthGroupUpdates({});
    setResetKey(prevKey => prevKey + 1);
  };

  const noData = useMemo(
    () => rows.every((row) => row.rangeWidths.length === 0),
    [rows]
  );

  const loading = loadingGlobalLevel || loadingRangeWidthGroups;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {checkScopes(Scope.RANGE_WIDTHS_EDIT) && (
        <EditButtons
          onEdit={() => setEditing(true)}
          onSave={() => {
            handleSave();
            setEditing(false);
          }}
          onCancel={handleCancel}
          sx={{ mb: 2 }}
        />
      )}
      <Suspense fallback={<CircularProgress />}>
        {noData && !loadingRangeWidthGroups && !editing ? (
          <Typography>{t('rangeWidthsPage.noData')}</Typography>
        ) : (
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            {tableComponent}
            {editing && (
              <IconButton
                aria-label={t('rangeWidthsPage.createNewGroup')}
                sx={{ height: 'fit-content' }}
                title={t('rangeWidthsPage.createNewGroup')}
                onClick={handleCreateNewGroup}
              >
                <AddIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </Suspense>
    </>
  );
}
