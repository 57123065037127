import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AcceptInvitationRequest = {
  code: Scalars['String'];
  email: Scalars['String'];
  workspaceId: Scalars['Int'];
};

export enum AlamereLocationId {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  AllOtherLocations = 'AllOtherLocations',
  Atlanta = 'Atlanta',
  Austin = 'Austin',
  BostonProvidence = 'BostonProvidence',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  Charlotte = 'Charlotte',
  Chicago = 'Chicago',
  Cleveland = 'Cleveland',
  DC = 'DC',
  DE = 'DE',
  DallasFortWorth = 'DallasFortWorth',
  Denver = 'Denver',
  Detroit = 'Detroit',
  FL = 'FL',
  GA = 'GA',
  GreaterLosAngeles = 'GreaterLosAngeles',
  HI = 'HI',
  Houston = 'Houston',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  LasVegas = 'LasVegas',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  MiamiFortLauderdale = 'MiamiFortLauderdale',
  MinneapolisStPaul = 'MinneapolisStPaul',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  NewYorkTriState = 'NewYorkTriState',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  Orlando = 'Orlando',
  PA = 'PA',
  Philadelphia = 'Philadelphia',
  Phoenix = 'Phoenix',
  Portland = 'Portland',
  RI = 'RI',
  Raleigh = 'Raleigh',
  SC = 'SC',
  SD = 'SD',
  Sacramento = 'Sacramento',
  SaltLakeCity = 'SaltLakeCity',
  SanDiego = 'SanDiego',
  Seattle = 'Seattle',
  SfBayArea = 'SfBayArea',
  StLouis = 'StLouis',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  WashingtonDcBaltimore = 'WashingtonDcBaltimore'
}

export enum AreaCode {
  AA = 'AA',
  AC = 'AC',
  AD = 'AD',
  AG = 'AG',
  AM = 'AM',
  AP = 'AP',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AV = 'AV',
  AY = 'AY',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BH = 'BH',
  BO = 'BO',
  BP = 'BP',
  BR = 'BR',
  BU = 'BU',
  CA = 'CA',
  CB = 'CB',
  CC = 'CC',
  CD = 'CD',
  CE = 'CE',
  CH = 'CH',
  CL = 'CL',
  CM = 'CM',
  CO = 'CO',
  CP = 'CP',
  CR = 'CR',
  CS = 'CS',
  DA = 'DA',
  DC = 'DC',
  DE = 'DE',
  DF = 'DF',
  DI = 'DI',
  DM = 'DM',
  DO = 'DO',
  DP = 'DP',
  DR = 'DR',
  DT = 'DT',
  DV = 'DV',
  EC = 'EC',
  ED = 'ED',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  EL = 'EL',
  EM = 'EM',
  EN = 'EN',
  EP = 'EP',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FA = 'FA',
  FH = 'FH',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  FS = 'FS',
  GA = 'GA',
  GD = 'GD',
  GE = 'GE',
  GL = 'GL',
  GM = 'GM',
  GO = 'GO',
  GP = 'GP',
  GR = 'GR',
  GT = 'GT',
  HD = 'HD',
  HL = 'HL',
  HS = 'HS',
  IA = 'IA',
  IC = 'IC',
  ID = 'ID',
  IF = 'IF',
  IM = 'IM',
  IN = 'IN',
  IR = 'IR',
  IS = 'IS',
  LA = 'LA',
  LD = 'LD',
  LE = 'LE',
  LG = 'LG',
  LM = 'LM',
  LP = 'LP',
  LS = 'LS',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MP = 'MP',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  NA = 'NA',
  NE = 'NE',
  NU = 'NU',
  OO = 'OO',
  OP = 'OP',
  OW = 'OW',
  PA = 'PA',
  PC = 'PC',
  PD = 'PD',
  PE = 'PE',
  PG = 'PG',
  PH = 'PH',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PO = 'PO',
  PP = 'PP',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  QA = 'QA',
  QC = 'QC',
  RA = 'RA',
  RC = 'RC',
  RE = 'RE',
  RK = 'RK',
  RL = 'RL',
  RM = 'RM',
  RN = 'RN',
  RS = 'RS',
  SA = 'SA',
  SD = 'SD',
  SG = 'SG',
  SM = 'SM',
  SO = 'SO',
  SP = 'SP',
  SS = 'SS',
  TD = 'TD',
  TE = 'TE',
  TH = 'TH',
  TM = 'TM',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TS = 'TS',
  TV = 'TV',
  TX = 'TX',
  TY = 'TY',
  UU = 'UU',
  VM = 'VM',
  WI = 'WI',
  WO = 'WO',
  ZZ = 'ZZ'
}

export type BonusStructure = {
  __typename?: 'BonusStructure';
  bonusStructureGroupId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  globalLevelLevel: Scalars['Int'];
  id: Scalars['Int'];
  jobLevelId?: Maybe<Scalars['Int']>;
  percent: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  workspaceId: Scalars['Int'];
};

export type BonusStructureCsvUploadRequest = {
  csvContent: Scalars['String'];
  groupName: Scalars['String'];
};

export type BonusStructureGroup = {
  __typename?: 'BonusStructureGroup';
  bonusStructures: Array<BonusStructure>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type BonusStructureGroupCreateRequest = {
  name: Scalars['String'];
};

export type BonusStructureGroupSaveManyRequest = {
  items: Array<BonusStructureGroupSaveRequest>;
};

export type BonusStructureGroupSaveRequest = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type BonusStructureSaveManyRequest = {
  items: Array<BonusStructureSaveRequest>;
};

export type BonusStructureSaveRequest = {
  bonusStructureGroupId: Scalars['Int'];
  globalLevelLevel: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  jobLevelId?: InputMaybe<Scalars['Int']>;
  percent: Scalars['Float'];
};

export type CanAutoSignupRequest = {
  email: Scalars['String'];
};

export type CompBand = {
  __typename?: 'CompBand';
  absoluteVariable: Scalars['Int'];
  compBandGroup: CompBandGroup;
  compBandGroupId: Scalars['Int'];
  country: CountryCode;
  createdAt: Scalars['DateTime'];
  currencyCode: Currency;
  globalLevelLevel: Scalars['Int'];
  hourlyRate: Scalars['Float'];
  id: Scalars['Int'];
  onTargetEarnings: Scalars['Int'];
  percentVariable: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  workspaceId: Scalars['Int'];
};

export type CompBandGroup = {
  __typename?: 'CompBandGroup';
  compBands: Array<CompBand>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type CompBandGroupSaveManyRequest = {
  items: Array<CompBandGroupSaveRequest>;
};

export type CompBandGroupSaveRequest = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type CompBandSaveManyRequest = {
  items: Array<CompBandSaveRequest>;
};

export type CompBandSaveRequest = {
  absoluteVariable: Scalars['Int'];
  compBandGroupId: Scalars['Int'];
  country: CountryCode;
  currencyCode: Scalars['String'];
  globalLevelLevel: Scalars['Int'];
  hourlyRate: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  onTargetEarnings: Scalars['Int'];
  percentVariable: Scalars['Float'];
  workspaceId: Scalars['Int'];
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateCustomRoleRequest = {
  name: Scalars['String'];
  scopes: Array<Scope>;
};

export type CreateGoogleUserInWorkspaceRequest = {
  code: Scalars['String'];
  credential: Scalars['String'];
  workspaceId: Scalars['Int'];
};

export type CreateUserAndWorkspaceRequest = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  workspaceName: Scalars['String'];
};

export type CreateUserInWorkspaceRequest = {
  code?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  workspaceId: Scalars['Int'];
};

export type CreateWorkspaceRequest = {
  domain?: InputMaybe<Scalars['String']>;
  enableInviteByDomain?: InputMaybe<Scalars['Boolean']>;
  inviteByDomainCustomRoleId?: InputMaybe<Scalars['Int']>;
  inviteByDomainRole?: InputMaybe<UserRole>;
  name: Scalars['String'];
};

export enum Currency {
  USD = 'USD'
}

export type CustomRole = {
  __typename?: 'CustomRole';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  memberships: Array<Membership>;
  name: Scalars['String'];
  scopes: Array<Scope>;
  updatedAt: Scalars['DateTime'];
  userCount: Scalars['Float'];
  workspace: Workspace;
};

export type DeleteCustomRoleRequest = {
  id: Scalars['Int'];
};

export type DeleteInvitationRequest = {
  invitationId: Scalars['Int'];
};

export type DeleteMembershipRequest = {
  id: Scalars['Float'];
};

export type Edge = {
  __typename?: 'Edge';
  cursor: Scalars['Int'];
  node: Workspace;
};

export type EquityBand = {
  __typename?: 'EquityBand';
  createdAt: Scalars['DateTime'];
  dynamicPercentOverride?: Maybe<Scalars['Float']>;
  equityBandGroupId: Scalars['Int'];
  globalLevelLevel: Scalars['Int'];
  id: Scalars['Int'];
  jobLevelId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
  workspaceId: Scalars['Int'];
};

export type EquityBandGroup = {
  __typename?: 'EquityBandGroup';
  createdAt: Scalars['DateTime'];
  dynamicChildrenGroups: Array<EquityBandGroup>;
  dynamicParentGroupId?: Maybe<Scalars['Int']>;
  dynamicPercent?: Maybe<Scalars['Float']>;
  equityBands: Array<EquityBand>;
  id: Scalars['Int'];
  jobFamilies: Array<JobFamily>;
  name: Scalars['String'];
  rangeWidthGroupId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type EquityBandGroupCreateRequest = {
  name: Scalars['String'];
};

export type EquityBandGroupSaveManyRequest = {
  items: Array<EquityBandGroupSaveRequest>;
};

export type EquityBandGroupSaveRequest = {
  dynamicParentGroupId?: InputMaybe<Scalars['Int']>;
  dynamicPercent?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  rangeWidthGroupId?: InputMaybe<Scalars['Int']>;
};

export type EquityBandSaveManyRequest = {
  items: Array<EquityBandSaveRequest>;
};

export type EquityBandSaveRequest = {
  dynamicPercentOverride?: InputMaybe<Scalars['Float']>;
  equityBandGroupId: Scalars['Int'];
  globalLevelLevel: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  jobLevelId?: InputMaybe<Scalars['Int']>;
  value: Scalars['Float'];
};

export type EquityGlobalMapping = {
  __typename?: 'EquityGlobalMapping';
  countryCode: CountryCode;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  percent: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  useGeoDiff: Scalars['Boolean'];
  workspace: Workspace;
};

export type EquityGlobalMappingSaveManyRequest = {
  idsToDelete: Array<Scalars['Int']>;
  items: Array<EquityGlobalMappingSaveRequest>;
};

export type EquityGlobalMappingSaveRequest = {
  countryCode: CountryCode;
  id?: InputMaybe<Scalars['Int']>;
  percent: Scalars['Float'];
  useGeoDiff?: Scalars['Boolean'];
};

export type FeatureAccessRequest = {
  feature: Scalars['String'];
};

export type FeedbackRequest = {
  currentRoute: Scalars['String'];
  message: Scalars['String'];
  rating: Scalars['Float'];
};

export enum FocusCode {
  AA = 'AA',
  AC = 'AC',
  AD = 'AD',
  AG = 'AG',
  AH = 'AH',
  AI = 'AI',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AP = 'AP',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AV = 'AV',
  AX = 'AX',
  BA = 'BA',
  BC = 'BC',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BI = 'BI',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BP = 'BP',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BU = 'BU',
  BX = 'BX',
  BY = 'BY',
  CA = 'CA',
  CB = 'CB',
  CC = 'CC',
  CD = 'CD',
  CE = 'CE',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CP = 'CP',
  CQ = 'CQ',
  CR = 'CR',
  CS = 'CS',
  CT = 'CT',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  DA = 'DA',
  DB = 'DB',
  DC = 'DC',
  DD = 'DD',
  DE = 'DE',
  DF = 'DF',
  DG = 'DG',
  DH = 'DH',
  DI = 'DI',
  DL = 'DL',
  DM = 'DM',
  DN = 'DN',
  DO = 'DO',
  DP = 'DP',
  DQ = 'DQ',
  DR = 'DR',
  DS = 'DS',
  DT = 'DT',
  DV = 'DV',
  DX = 'DX',
  DY = 'DY',
  EA = 'EA',
  EB = 'EB',
  EC = 'EC',
  ED = 'ED',
  EE = 'EE',
  EI = 'EI',
  EL = 'EL',
  EM = 'EM',
  EN = 'EN',
  EP = 'EP',
  EQ = 'EQ',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  EV = 'EV',
  EX = 'EX',
  FA = 'FA',
  FB = 'FB',
  FC = 'FC',
  FD = 'FD',
  FE = 'FE',
  FG = 'FG',
  FI = 'FI',
  FL = 'FL',
  FM = 'FM',
  FO = 'FO',
  FP = 'FP',
  FR = 'FR',
  FS = 'FS',
  FT = 'FT',
  FX = 'FX',
  GA = 'GA',
  GC = 'GC',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GI = 'GI',
  GK = 'GK',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GO = 'GO',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GV = 'GV',
  GW = 'GW',
  GX = 'GX',
  GY = 'GY',
  HB = 'HB',
  HC = 'HC',
  HD = 'HD',
  HE = 'HE',
  HG = 'HG',
  HI = 'HI',
  HL = 'HL',
  HM = 'HM',
  HN = 'HN',
  HO = 'HO',
  HP = 'HP',
  HR = 'HR',
  HS = 'HS',
  HT = 'HT',
  HW = 'HW',
  HY = 'HY',
  IA = 'IA',
  IB = 'IB',
  IC = 'IC',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IP = 'IP',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JR = 'JR',
  KA = 'KA',
  KC = 'KC',
  KE = 'KE',
  KM = 'KM',
  KS = 'KS',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LD = 'LD',
  LE = 'LE',
  LF = 'LF',
  LG = 'LG',
  LI = 'LI',
  LK = 'LK',
  LL = 'LL',
  LM = 'LM',
  LN = 'LN',
  LO = 'LO',
  LP = 'LP',
  LQ = 'LQ',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  MA = 'MA',
  MB = 'MB',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MI = 'MI',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MW = 'MW',
  MX = 'MX',
  NA = 'NA',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NG = 'NG',
  NM = 'NM',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NS = 'NS',
  NT = 'NT',
  NU = 'NU',
  NV = 'NV',
  NX = 'NX',
  NY = 'NY',
  OC = 'OC',
  OD = 'OD',
  OE = 'OE',
  OG = 'OG',
  OM = 'OM',
  ON = 'ON',
  OO = 'OO',
  OP = 'OP',
  OQ = 'OQ',
  OR = 'OR',
  OS = 'OS',
  OT = 'OT',
  OW = 'OW',
  OX = 'OX',
  PA = 'PA',
  PB = 'PB',
  PC = 'PC',
  PD = 'PD',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PI = 'PI',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PO = 'PO',
  PP = 'PP',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PV = 'PV',
  PW = 'PW',
  PX = 'PX',
  QA = 'QA',
  QP = 'QP',
  QR = 'QR',
  QT = 'QT',
  QU = 'QU',
  RA = 'RA',
  RB = 'RB',
  RC = 'RC',
  RD = 'RD',
  RE = 'RE',
  RF = 'RF',
  RG = 'RG',
  RI = 'RI',
  RL = 'RL',
  RM = 'RM',
  RN = 'RN',
  RO = 'RO',
  RP = 'RP',
  RR = 'RR',
  RS = 'RS',
  RT = 'RT',
  RV = 'RV',
  RW = 'RW',
  RX = 'RX',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SF = 'SF',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SP = 'SP',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SU = 'SU',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  TA = 'TA',
  TC = 'TC',
  TD = 'TD',
  TE = 'TE',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TI = 'TI',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TS = 'TS',
  TT = 'TT',
  TU = 'TU',
  TW = 'TW',
  TX = 'TX',
  TY = 'TY',
  UA = 'UA',
  UD = 'UD',
  UE = 'UE',
  UN = 'UN',
  UP = 'UP',
  US = 'US',
  UT = 'UT',
  VA = 'VA',
  VB = 'VB',
  VD = 'VD',
  VE = 'VE',
  VF = 'VF',
  VG = 'VG',
  VI = 'VI',
  VM = 'VM',
  VO = 'VO',
  VR = 'VR',
  VS = 'VS',
  VX = 'VX',
  WA = 'WA',
  WB = 'WB',
  WC = 'WC',
  WD = 'WD',
  WE = 'WE',
  WL = 'WL',
  WP = 'WP',
  WQ = 'WQ',
  WS = 'WS',
  WT = 'WT',
  WU = 'WU',
  WX = 'WX',
  XC = 'XC',
  XS = 'XS',
  XY = 'XY',
  YJ = 'YJ'
}

export enum FunctionCode {
  BC = 'BC',
  BO = 'BO',
  BP = 'BP',
  CA = 'CA',
  CB = 'CB',
  CR = 'CR',
  CS = 'CS',
  DO = 'DO',
  EH = 'EH',
  EN = 'EN',
  EO = 'EO',
  FI = 'FI',
  FO = 'FO',
  GA = 'GA',
  GS = 'GS',
  HR = 'HR',
  LE = 'LE',
  LG = 'LG',
  ME = 'ME',
  MF = 'MF',
  MK = 'MK',
  MO = 'MO',
  MR = 'MR',
  OM = 'OM',
  OT = 'OT',
  PD = 'PD',
  PO = 'PO',
  PS = 'PS',
  QU = 'QU',
  RD = 'RD',
  SA = 'SA',
  SC = 'SC',
  SK = 'SK',
  SO = 'SO',
  SP = 'SP',
  TE = 'TE',
  TO = 'TO'
}

export type GlobalLevel = {
  __typename?: 'GlobalLevel';
  createdAt: Scalars['DateTime'];
  level: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  percentRsu: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceId: Scalars['ID'];
};

export type GlobalLevelSaveManyRequest = {
  items: Array<GlobalLevelSaveRequest>;
};

export type GlobalLevelSaveRequest = {
  level: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  percentRsu?: InputMaybe<Scalars['Float']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  customRole?: Maybe<CustomRole>;
  email: Scalars['String'];
  id: Scalars['Int'];
  role: UserRole;
  status: InvitationStatus;
  workspace: Array<Workspace>;
};

export enum InvitationStatus {
  ACCEPTED = 'ACCEPTED',
  INVITED = 'INVITED'
}

export type InviteUserRequest = {
  customRoleId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  role: UserRole;
};

export type JitaRequest = {
  expiresAt: Scalars['DateTime'];
  reason: Scalars['String'];
  workspaceId: Scalars['Float'];
};

export type Job = {
  __typename?: 'Job';
  businessTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  jobArchitecture?: Maybe<Scalars['String']>;
  jobCode?: Maybe<Scalars['String']>;
  jobFamily: JobFamily;
  jobFamilyId: Scalars['Int'];
  jobLevel: JobLevel;
  jobLevelId: Scalars['Int'];
  jobSubLevel?: Maybe<Scalars['String']>;
  jobType: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type JobFamily = {
  __typename?: 'JobFamily';
  bonusStructureGroup?: Maybe<BonusStructureGroup>;
  bonusStructureGroupId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  compBandGroup?: Maybe<CompBandGroup>;
  compBandGroupId?: Maybe<Scalars['Int']>;
  compBandMultiplier?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  dynamicChildren: Array<JobFamily>;
  dynamicParentId?: Maybe<Scalars['Int']>;
  dynamicPercent?: Maybe<Scalars['Float']>;
  equityBandGroup?: Maybe<EquityBandGroup>;
  equityBandGroupId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  ignoreGeoDiff: Scalars['Boolean'];
  jobFunction?: Maybe<JobFunction>;
  jobFunctionId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  radfordExecutiveMatchCode?: Maybe<Scalars['String']>;
  radfordManagementMatchCode?: Maybe<Scalars['String']>;
  radfordProfessionalMatchCode?: Maybe<Scalars['String']>;
  radfordScientificMatchCode?: Maybe<Scalars['String']>;
  radfordSupportMatchCode?: Maybe<Scalars['String']>;
  rangeWidthGroup?: Maybe<RangeWidthGroup>;
  rangeWidthGroupId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  workspaceId: Scalars['Int'];
};

export type JobFamilySaveManyRequest = {
  items: Array<JobFamilySaveRequest>;
  jobFamilyIdsToDelete?: InputMaybe<Array<Scalars['Int']>>;
};

export type JobFamilySaveRequest = {
  bonusStructureGroupId?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  compBandGroupId?: InputMaybe<Scalars['Int']>;
  compBandMultiplier?: InputMaybe<Scalars['Int']>;
  dynamicParentId?: InputMaybe<Scalars['Int']>;
  dynamicPercent?: InputMaybe<Scalars['Float']>;
  equityBandGroupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ignoreGeoDiff?: InputMaybe<Scalars['Boolean']>;
  jobFunctionId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  radfordExecutiveMatchCode?: InputMaybe<Scalars['String']>;
  radfordManagementMatchCode?: InputMaybe<Scalars['String']>;
  radfordProfessionalMatchCode?: InputMaybe<Scalars['String']>;
  radfordScientificMatchCode?: InputMaybe<Scalars['String']>;
  radfordSupportMatchCode?: InputMaybe<Scalars['String']>;
  rangeWidthGroupId?: InputMaybe<Scalars['Int']>;
};

export type JobFunction = {
  __typename?: 'JobFunction';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  jobFamilies: Array<JobFamily>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type JobFunctionSaveRequest = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type JobGetRequest = {
  jobFamilyIds?: InputMaybe<Array<Scalars['Int']>>;
};

export enum JobGroup {
  Executive = 'Executive',
  Management = 'Management',
  Professional = 'Professional',
  Support = 'Support',
  TechnicalScientific = 'TechnicalScientific'
}

export type JobLevel = {
  __typename?: 'JobLevel';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: JobGroup;
  id: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  surveyCode: SurveyCode;
  surveyProvider: SurveyProvider;
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type JobLevelSaveManyRequest = {
  items: Array<JobLevelSaveRequest>;
};

export type JobLevelSaveRequest = {
  code: Scalars['String'];
  group: JobGroup;
  id?: InputMaybe<Scalars['Int']>;
  isVisible: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  surveyCode: SurveyCode;
  surveyProvider: SurveyProvider;
};

export type JobLevelsGetRequest = {
  onlyVisible?: InputMaybe<Scalars['Boolean']>;
};

export type JobSaveManyRequest = {
  idsToDelete?: InputMaybe<Array<Scalars['Int']>>;
  items: Array<JobSaveRequest>;
};

export type JobSaveRequest = {
  businessTitle?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  jobCode?: InputMaybe<Scalars['String']>;
  jobFamilyId: Scalars['Int'];
  jobLevelId: Scalars['Int'];
  jobSubLevel?: InputMaybe<Scalars['String']>;
  jobType?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type JoinWaitlistRequest = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  createdAt: Scalars['DateTime'];
  customRole?: Maybe<CustomRole>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  globalLevelLevel?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  role: UserRole;
  updatedAt: Scalars['DateTime'];
  user: User;
  workspace: Workspace;
};

export type MultiZipcodeSearchRequest = {
  zipcodes: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: Scalars['Boolean'];
  acceptInvitationWithGoogle: User;
  bonusStructureGroupCreate: BonusStructureGroup;
  bonusStructureGroupCsvUpload: Array<BonusStructureGroup>;
  bonusStructureGroupDelete: Scalars['Int'];
  bonusStructureGroupsSave: Array<BonusStructureGroup>;
  bonusStructuresSave: Array<BonusStructure>;
  compBandGroupDelete: Scalars['Boolean'];
  compBandGroupsSave: Array<CompBandGroup>;
  compBandSave: Array<CompBand>;
  createCustomRole: Scalars['Boolean'];
  createWorkspace: Workspace;
  deleteCustomRole: Scalars['Boolean'];
  deleteInvitation: Scalars['Boolean'];
  deleteMembership: Scalars['Boolean'];
  equityBandGroupCreate: EquityBandGroup;
  equityBandGroupDelete: Scalars['Int'];
  equityBandGroupsSave: Array<EquityBandGroup>;
  equityBandsSave: Scalars['Boolean'];
  equityGlobalMappingSave: Array<EquityGlobalMapping>;
  globalLevelsSave: Array<GlobalLevel>;
  inviteUser: Scalars['Boolean'];
  jobFamiliesSave: Array<JobFamily>;
  jobFamilyDelete: Scalars['Int'];
  jobFunctionSave: JobFunction;
  jobLevelSave: Array<JobLevel>;
  jobsSave: Array<Job>;
  joinWaitlist: Scalars['Boolean'];
  rangeWidthGroupDelete: Scalars['Boolean'];
  rangeWidthGroupsSave: Array<RangeWidthGroup>;
  rangeWidthSave: Array<RangeWidth>;
  requestFeatureAccess: Scalars['Boolean'];
  requestJita: Scalars['Boolean'];
  resetPassword: User;
  saveTierConfiguration: TierConfig;
  settingsSave: Scalars['Boolean'];
  signupAndCreateNewWorkspace: User;
  signupFromInvitation: User;
  submitFeedback: Scalars['Boolean'];
  updateCustomRole: Scalars['Boolean'];
  updateMembership: Scalars['Boolean'];
  updateMembershipLevel: Scalars['Boolean'];
  updateWorkspace: Scalars['Boolean'];
};


export type MutationAcceptInvitationArgs = {
  acceptInvitationRequest: AcceptInvitationRequest;
};


export type MutationAcceptInvitationWithGoogleArgs = {
  createGoogleUserInWorkspaceRequest: CreateGoogleUserInWorkspaceRequest;
};


export type MutationBonusStructureGroupCreateArgs = {
  request: BonusStructureGroupCreateRequest;
};


export type MutationBonusStructureGroupCsvUploadArgs = {
  request: BonusStructureCsvUploadRequest;
};


export type MutationBonusStructureGroupDeleteArgs = {
  id: Scalars['Float'];
};


export type MutationBonusStructureGroupsSaveArgs = {
  request: BonusStructureGroupSaveManyRequest;
};


export type MutationBonusStructuresSaveArgs = {
  request: BonusStructureSaveManyRequest;
};


export type MutationCompBandGroupDeleteArgs = {
  id: Scalars['Float'];
};


export type MutationCompBandGroupsSaveArgs = {
  request: CompBandGroupSaveManyRequest;
};


export type MutationCompBandSaveArgs = {
  request: CompBandSaveManyRequest;
};


export type MutationCreateCustomRoleArgs = {
  createCustomRoleRequest: CreateCustomRoleRequest;
};


export type MutationCreateWorkspaceArgs = {
  createWorkspaceInput: CreateWorkspaceRequest;
};


export type MutationDeleteCustomRoleArgs = {
  deleteCustomRoleRequest: DeleteCustomRoleRequest;
};


export type MutationDeleteInvitationArgs = {
  deleteInvitationRequest: DeleteInvitationRequest;
};


export type MutationDeleteMembershipArgs = {
  deleteMembershipRequest: DeleteMembershipRequest;
};


export type MutationEquityBandGroupCreateArgs = {
  request: EquityBandGroupCreateRequest;
};


export type MutationEquityBandGroupDeleteArgs = {
  id: Scalars['Float'];
};


export type MutationEquityBandGroupsSaveArgs = {
  request: EquityBandGroupSaveManyRequest;
};


export type MutationEquityBandsSaveArgs = {
  request: EquityBandSaveManyRequest;
};


export type MutationEquityGlobalMappingSaveArgs = {
  request: EquityGlobalMappingSaveManyRequest;
};


export type MutationGlobalLevelsSaveArgs = {
  request: GlobalLevelSaveManyRequest;
};


export type MutationInviteUserArgs = {
  inviteUserRequest: InviteUserRequest;
};


export type MutationJobFamiliesSaveArgs = {
  request: JobFamilySaveManyRequest;
};


export type MutationJobFamilyDeleteArgs = {
  id: Scalars['Float'];
};


export type MutationJobFunctionSaveArgs = {
  request: JobFunctionSaveRequest;
};


export type MutationJobLevelSaveArgs = {
  request: JobLevelSaveManyRequest;
};


export type MutationJobsSaveArgs = {
  jobSaveManyRequest: JobSaveManyRequest;
};


export type MutationJoinWaitlistArgs = {
  request: JoinWaitlistRequest;
};


export type MutationRangeWidthGroupDeleteArgs = {
  id: Scalars['Float'];
};


export type MutationRangeWidthGroupsSaveArgs = {
  request: RangeWidthGroupSaveManyRequest;
};


export type MutationRangeWidthSaveArgs = {
  request: RangeWidthSaveManyRequest;
};


export type MutationRequestFeatureAccessArgs = {
  request: FeatureAccessRequest;
};


export type MutationRequestJitaArgs = {
  jitaRequest: JitaRequest;
};


export type MutationResetPasswordArgs = {
  resetPasswordRequest: ResetPasswordRequest;
};


export type MutationSaveTierConfigurationArgs = {
  notes?: InputMaybe<Scalars['String']>;
  tierConfigJson: Scalars['String'];
};


export type MutationSettingsSaveArgs = {
  request: SettingsSaveManyRequest;
};


export type MutationSignupAndCreateNewWorkspaceArgs = {
  createUserRequest: CreateUserAndWorkspaceRequest;
};


export type MutationSignupFromInvitationArgs = {
  createUserRequest: CreateUserInWorkspaceRequest;
};


export type MutationSubmitFeedbackArgs = {
  feedbackRequest: FeedbackRequest;
};


export type MutationUpdateCustomRoleArgs = {
  updateCustomRoleRequest: UpdateCustomRoleRequest;
};


export type MutationUpdateMembershipArgs = {
  updateMembershipRequest: UpdateMembershipRequest;
};


export type MutationUpdateMembershipLevelArgs = {
  updateMembershipLevelRequest: UpdateMembershipLevelRequest;
};


export type MutationUpdateWorkspaceArgs = {
  updateWorkspaceRequest: UpdateWorkspaceRequest;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Int']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  bonusStructureGroups: Array<BonusStructureGroup>;
  bonusStructures: Array<BonusStructure>;
  compBandGroup: CompBandGroup;
  compBands: Array<CompBand>;
  compBandsGroups: Array<CompBandGroup>;
  currentUser: User;
  equityBandGroup: EquityBandGroup;
  equityBandGroups: Array<EquityBandGroup>;
  equityBands: Array<EquityBand>;
  equityGlobalMappings: Array<EquityGlobalMapping>;
  getAutoSignupWorkspace?: Maybe<Workspace>;
  getCustomRoles: Array<CustomRole>;
  globalLevels: Array<GlobalLevel>;
  jobFamilies: Array<JobFamily>;
  jobFunctions: Array<JobFunction>;
  jobLevels: Array<JobLevel>;
  jobs: Array<Job>;
  radfordJobFamilies: Array<RadfordJobFamily>;
  radfordJobFamiliesSearch: Array<RadfordJobFamily>;
  rangeWidthGroup: RangeWidthGroup;
  rangeWidthGroups: Array<RangeWidthGroup>;
  rangeWidths: Array<RangeWidth>;
  searchMultiZipcodes?: Maybe<Array<ZipcodeWithTierResponse>>;
  searchWorkspaces: WorkspacePageResponse;
  searchZipcode?: Maybe<ZipcodeResponse>;
  settings: Array<Setting>;
  tierConfig: TierConfig;
  user: User;
  workspace: Workspace;
};


export type QueryCompBandGroupArgs = {
  id: Scalars['Float'];
};


export type QueryEquityBandGroupArgs = {
  id: Scalars['Float'];
};


export type QueryGetAutoSignupWorkspaceArgs = {
  request: CanAutoSignupRequest;
};


export type QueryJobLevelsArgs = {
  jobLevelsGetRequest: JobLevelsGetRequest;
};


export type QueryJobsArgs = {
  jobGetRequest: JobGetRequest;
};


export type QueryRadfordJobFamiliesSearchArgs = {
  request: RadfordJobFamiliesSearchRequest;
};


export type QueryRangeWidthGroupArgs = {
  id: Scalars['Float'];
};


export type QuerySearchMultiZipcodesArgs = {
  multiZipcodeSearchRequest: MultiZipcodeSearchRequest;
};


export type QuerySearchWorkspacesArgs = {
  searchWorkspacesPagedRequest: SearchWorkspacesPagedRequest;
};


export type QuerySearchZipcodeArgs = {
  zipcodeSearchRequest: ZipcodeSearchRequest;
};


export type QuerySettingsArgs = {
  request: SettingsGetManyRequest;
};


export type QueryUserArgs = {
  email: Scalars['String'];
};

export type RadfordJobFamiliesSearchRequest = {
  jobGroup: JobGroup;
  query: Scalars['String'];
};

export type RadfordJobFamily = {
  __typename?: 'RadfordJobFamily';
  area: Scalars['String'];
  areaCode: AreaCode;
  areaDefinition: Scalars['String'];
  code: Scalars['String'];
  detailedDefinition: Scalars['String'];
  focus: Scalars['String'];
  focusCode: FocusCode;
  focusDefinition: Scalars['String'];
  function: Scalars['String'];
  functionCode: FunctionCode;
  functionDefinition: Scalars['String'];
  jobGroup: JobGroup;
  label: Scalars['String'];
  surveyProvider: SurveyProvider;
};

export type RangeWidth = {
  __typename?: 'RangeWidth';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  level: Scalars['Int'];
  percentAboveMid: Scalars['Float'];
  percentBelowMid: Scalars['Float'];
  rangeWidthGroup: RangeWidthGroup;
  rangeWidthGroupId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type RangeWidthGroup = {
  __typename?: 'RangeWidthGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  rangeWidths: Array<RangeWidth>;
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
};

export type RangeWidthGroupSaveManyRequest = {
  items: Array<RangeWidthGroupSaveRequest>;
};

export type RangeWidthGroupSaveRequest = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type RangeWidthSaveManyRequest = {
  items: Array<RangeWidthSaveRequest>;
};

export type RangeWidthSaveRequest = {
  id?: InputMaybe<Scalars['Int']>;
  level: Scalars['Int'];
  percentAboveMid: Scalars['Float'];
  percentBelowMid: Scalars['Float'];
  rangeWidthGroupId: Scalars['Int'];
};

export type ResetPasswordRequest = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum Scope {
  BONUS_STRUCTURES_EDIT = 'BONUS_STRUCTURES_EDIT',
  BONUS_STRUCTURES_VIEW = 'BONUS_STRUCTURES_VIEW',
  COMP_BANDS_EDIT = 'COMP_BANDS_EDIT',
  COMP_BANDS_VIEW = 'COMP_BANDS_VIEW',
  COMP_RANGES_VIEW = 'COMP_RANGES_VIEW',
  COMP_RANGE_CALCULATION_BREAKDOWN_VIEW = 'COMP_RANGE_CALCULATION_BREAKDOWN_VIEW',
  EDIT_LOCATION_CALCULATOR = 'EDIT_LOCATION_CALCULATOR',
  EDIT_WORKSPACE = 'EDIT_WORKSPACE',
  EQUITY_BANDS_EDIT = 'EQUITY_BANDS_EDIT',
  EQUITY_BANDS_VIEW = 'EQUITY_BANDS_VIEW',
  JOB_ARCHITECTURE_EDIT = 'JOB_ARCHITECTURE_EDIT',
  JOB_ARCHITECTURE_VIEW = 'JOB_ARCHITECTURE_VIEW',
  JOB_DEFINITIONS_EDIT = 'JOB_DEFINITIONS_EDIT',
  JOB_DEFINITIONS_VIEW = 'JOB_DEFINITIONS_VIEW',
  JOB_FAMILIES_EDIT = 'JOB_FAMILIES_EDIT',
  JOB_FAMILIES_VIEW = 'JOB_FAMILIES_VIEW',
  JOB_FUNCTIONS_EDIT = 'JOB_FUNCTIONS_EDIT',
  JOB_FUNCTIONS_VIEW = 'JOB_FUNCTIONS_VIEW',
  LOCATION_CALCULATOR_MULTIPLE_VIEW = 'LOCATION_CALCULATOR_MULTIPLE_VIEW',
  RANGE_WIDTHS_EDIT = 'RANGE_WIDTHS_EDIT',
  RANGE_WIDTHS_VIEW = 'RANGE_WIDTHS_VIEW',
  SEE_ALL_LEVELS_DATA = 'SEE_ALL_LEVELS_DATA',
  SET_USERS_JOB_LEVEL = 'SET_USERS_JOB_LEVEL',
  VIEW_LOCATION_CALCULATOR = 'VIEW_LOCATION_CALCULATOR',
  VIEW_LOCATION_CALCULATOR_NOTES = 'VIEW_LOCATION_CALCULATOR_NOTES'
}

export type SearchWorkspacesPagedRequest = {
  after?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['Int'];
  property: SettingProperty;
  stringValue?: Maybe<Scalars['String']>;
  type: SettingType;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  workspace?: Maybe<Workspace>;
  workspaceId?: Maybe<Scalars['Int']>;
};

export enum SettingProperty {
  EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT = 'EQUITY_BANDS_PAGE_SHOW_ALTERNATE_EQUITY_UNIT',
  EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND = 'EQUITY_BANDS_PAGE_SHOW_DELTA_FROM_PREVIOUS_BAND',
  EQUITY_BANDS_PAGE_SHOW_MIN_MAX = 'EQUITY_BANDS_PAGE_SHOW_MIN_MAX',
  PREFERRED_STOCK_PRICE = 'PREFERRED_STOCK_PRICE',
  STOCK_UNIT = 'STOCK_UNIT',
  STRIKE_PRICE = 'STRIKE_PRICE'
}

export enum SettingType {
  USER = 'USER',
  WORKSPACE = 'WORKSPACE',
  WORKSPACE_USER = 'WORKSPACE_USER'
}

export type SettingsGetManyRequest = {
  items: Array<SettingsGetRequest>;
};

export type SettingsGetRequest = {
  property: SettingProperty;
  type: SettingType;
};

export type SettingsSaveManyRequest = {
  items: Array<SettingsSaveRequest>;
};

export type SettingsSaveRequest = {
  property: SettingProperty;
  stringValue?: InputMaybe<Scalars['String']>;
  type: SettingType;
};

export enum SurveyCode {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
  E5 = 'E5',
  E6 = 'E6',
  Intern = 'Intern',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M5 = 'M5',
  M6 = 'M6',
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  P7 = 'P7',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S4 = 'S4',
  S5 = 'S5',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  T7 = 'T7'
}

export enum SurveyProvider {
  Radford = 'Radford'
}

export type TierConfig = {
  __typename?: 'TierConfig';
  createdAt?: Maybe<Scalars['DateTime']>;
  isDefault: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  tierConfigJson?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workspaceId: Scalars['Int'];
};

export type UpdateCustomRoleRequest = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  scopes?: InputMaybe<Array<Scope>>;
};

export type UpdateMembershipLevelRequest = {
  id: Scalars['Float'];
  level: Scalars['Float'];
};

export type UpdateMembershipRequest = {
  customRoleId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Float'];
  role: UserRole;
};

export type UpdateWorkspaceRequest = {
  domain?: InputMaybe<Scalars['String']>;
  enableInviteByDomain?: InputMaybe<Scalars['Boolean']>;
  inviteByDomainCustomRoleId?: InputMaybe<Scalars['Int']>;
  inviteByDomainRole?: InputMaybe<UserRole>;
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasVerifiedEmail?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lastName: Scalars['String'];
  memberships: Array<Membership>;
  updatedAt: Scalars['DateTime'];
};

export enum UserRole {
  ADMIN = 'ADMIN',
  CUSTOM = 'CUSTOM',
  JITA_WRITE = 'JITA_WRITE',
  MEMBER = 'MEMBER'
}

export type Workspace = {
  __typename?: 'Workspace';
  createdAt: Scalars['DateTime'];
  customRoles: Array<CustomRole>;
  domain?: Maybe<Scalars['String']>;
  enableInviteByDomain?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  invitations: Array<Invitation>;
  inviteByDomainCustomRole?: Maybe<CustomRole>;
  inviteByDomainRole?: Maybe<UserRole>;
  memberships: Array<Membership>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type WorkspacePageResponse = {
  __typename?: 'WorkspacePageResponse';
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type ZipcodeResponse = {
  __typename?: 'ZipcodeResponse';
  alamereLocationId: AlamereLocationId;
  city: Scalars['String'];
  csa?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  zipcode: Scalars['String'];
};

export type ZipcodeSearchRequest = {
  zipcode: Scalars['String'];
};

export type ZipcodeWithTierResponse = {
  __typename?: 'ZipcodeWithTierResponse';
  alamereLocationId: AlamereLocationId;
  city: Scalars['String'];
  csa?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  tierLabel: Scalars['String'];
  tierNumber: Scalars['String'];
  zipcode: Scalars['String'];
};

export type AcceptInvitationWithGoogleMutationVariables = Exact<{
  createGoogleUserInWorkspaceRequest: CreateGoogleUserInWorkspaceRequest;
}>;


export type AcceptInvitationWithGoogleMutation = { __typename?: 'Mutation', acceptInvitationWithGoogle: { __typename?: 'User', id: number } };

export type AcceptInvitationMutationVariables = Exact<{
  acceptInvitationRequest: AcceptInvitationRequest;
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitation: boolean };

export type BonusStructureFragment = { __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number };

export type BonusStructureGroupPropertiesFragment = { __typename?: 'BonusStructureGroup', id: number, name: string };

export type BonusStructureGroupFragment = { __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> };

export type BonusStructureGroupCreateMutationVariables = Exact<{
  request: BonusStructureGroupCreateRequest;
}>;


export type BonusStructureGroupCreateMutation = { __typename?: 'Mutation', bonusStructureGroupCreate: { __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> } };

export type BonusStructureGroupCsvUploadMutationVariables = Exact<{
  request: BonusStructureCsvUploadRequest;
}>;


export type BonusStructureGroupCsvUploadMutation = { __typename?: 'Mutation', bonusStructureGroupCsvUpload: Array<{ __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> }> };

export type BonusStructureGroupDeleteMutationVariables = Exact<{
  bonusStructureGroupDeleteId: Scalars['Float'];
}>;


export type BonusStructureGroupDeleteMutation = { __typename?: 'Mutation', bonusStructureGroupDelete: number };

export type BonusStructureGroupsSaveMutationVariables = Exact<{
  bonusStructureGroupSaveManyRequest: BonusStructureGroupSaveManyRequest;
}>;


export type BonusStructureGroupsSaveMutation = { __typename?: 'Mutation', bonusStructureGroupsSave: Array<{ __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> }> };

export type BonusStructureGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type BonusStructureGroupsQuery = { __typename?: 'Query', bonusStructureGroups: Array<{ __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> }> };

export type BonusStructuresQueryVariables = Exact<{ [key: string]: never; }>;


export type BonusStructuresQuery = { __typename?: 'Query', bonusStructures: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> };

export type BonusStructuresSaveMutationVariables = Exact<{
  bonusStructuresSaveRequest: BonusStructureSaveManyRequest;
}>;


export type BonusStructuresSaveMutation = { __typename?: 'Mutation', bonusStructuresSave: Array<{ __typename?: 'BonusStructure', bonusStructureGroupId: number, globalLevelLevel: number, id: number, jobLevelId?: number | null, percent: number }> };

export type CompBandFragment = { __typename?: 'CompBand', id: number, currencyCode: Currency, globalLevelLevel: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number };

export type CompBandGroupPropertiesFragment = { __typename?: 'CompBandGroup', id: number, name: string };

export type CompBandGroupFragment = { __typename?: 'CompBandGroup', id: number, name: string, compBands: Array<{ __typename?: 'CompBand', id: number, currencyCode: Currency, globalLevelLevel: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number }> };

export type CompBandGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompBandGroupsQuery = { __typename?: 'Query', compBandsGroups: Array<{ __typename?: 'CompBandGroup', id: number, name: string, compBands: Array<{ __typename?: 'CompBand', id: number, currencyCode: Currency, globalLevelLevel: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number }> }> };

export type CompBandGroupQueryVariables = Exact<{
  compBandGroupId: Scalars['Float'];
}>;


export type CompBandGroupQuery = { __typename?: 'Query', compBandGroup: { __typename?: 'CompBandGroup', id: number, name: string, compBands: Array<{ __typename?: 'CompBand', id: number, currencyCode: Currency, globalLevelLevel: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number }> } };

export type CompBandSaveMutationVariables = Exact<{
  compBandSaveRequest: CompBandSaveManyRequest;
}>;


export type CompBandSaveMutation = { __typename?: 'Mutation', compBandSave: Array<{ __typename?: 'CompBand', id: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, currencyCode: Currency, globalLevelLevel: number, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number }> };

export type CompBandGroupsSaveMutationVariables = Exact<{
  compBandGroupSaveManyRequest: CompBandGroupSaveManyRequest;
}>;


export type CompBandGroupsSaveMutation = { __typename?: 'Mutation', compBandGroupsSave: Array<{ __typename?: 'CompBandGroup', id: number, name: string }> };

export type CompBandGroupDeleteMutationVariables = Exact<{
  compBandGroupDeleteId: Scalars['Float'];
}>;


export type CompBandGroupDeleteMutation = { __typename?: 'Mutation', compBandGroupDelete: boolean };

export type CreateCustomRoleMutationVariables = Exact<{
  createCustomRoleRequest: CreateCustomRoleRequest;
}>;


export type CreateCustomRoleMutation = { __typename?: 'Mutation', createCustomRole: boolean };

export type CreateWorkspaceMutationVariables = Exact<{
  createWorkspaceInput: CreateWorkspaceRequest;
}>;


export type CreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace: { __typename?: 'Workspace', id: number } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', email: string, firstName: string, id: number, lastName: string, fullName: string, updatedAt: any, createdAt: any, avatarUrl?: string | null, hasVerifiedEmail?: boolean | null, memberships: Array<{ __typename?: 'Membership', role: UserRole, createdAt: any, updatedAt: any, expiresAt?: any | null, id: number, globalLevelLevel?: number | null, customRole?: { __typename?: 'CustomRole', id: number, name: string, scopes: Array<Scope> } | null, workspace: { __typename?: 'Workspace', id: number, name: string, domain?: string | null, createdAt: any, updatedAt: any, enableInviteByDomain?: boolean | null, inviteByDomainRole?: UserRole | null, inviteByDomainCustomRole?: { __typename?: 'CustomRole', id: number, name: string } | null } }> } };

export type DeleteCustomRoleMutationVariables = Exact<{
  deleteCustomRoleRequest: DeleteCustomRoleRequest;
}>;


export type DeleteCustomRoleMutation = { __typename?: 'Mutation', deleteCustomRole: boolean };

export type DeleteInvitationMutationVariables = Exact<{
  deleteInvitationRequest: DeleteInvitationRequest;
}>;


export type DeleteInvitationMutation = { __typename?: 'Mutation', deleteInvitation: boolean };

export type DeleteMembershipMutationVariables = Exact<{
  deleteMembershipRequest: DeleteMembershipRequest;
}>;


export type DeleteMembershipMutation = { __typename?: 'Mutation', deleteMembership: boolean };

export type EquityBandFragment = { __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null };

export type EquityBandGroupPropertiesFragment = { __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null };

export type EquityBandGroupFragment = { __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null, equityBands: Array<{ __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null }> };

export type EquityBandGroupCreateMutationVariables = Exact<{
  request: EquityBandGroupCreateRequest;
}>;


export type EquityBandGroupCreateMutation = { __typename?: 'Mutation', equityBandGroupCreate: { __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null, equityBands: Array<{ __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null }> } };

export type EquityBandGroupsSaveMutationVariables = Exact<{
  equityBandGroupSaveManyRequest: EquityBandGroupSaveManyRequest;
}>;


export type EquityBandGroupsSaveMutation = { __typename?: 'Mutation', equityBandGroupsSave: Array<{ __typename?: 'EquityBandGroup', id: number }> };

export type EquityBandSaveMutationVariables = Exact<{
  equityBandSaveManyRequest: EquityBandSaveManyRequest;
}>;


export type EquityBandSaveMutation = { __typename?: 'Mutation', equityBandsSave: boolean };

export type EquityBandGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type EquityBandGroupsQuery = { __typename?: 'Query', equityBandGroups: Array<{ __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null, equityBands: Array<{ __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null }> }> };

export type EquityBandGroupQueryVariables = Exact<{
  equityBandGroupId: Scalars['Float'];
}>;


export type EquityBandGroupQuery = { __typename?: 'Query', equityBandGroup: { __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null, equityBands: Array<{ __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null }> } };

export type EquityBandGroupDeleteMutationVariables = Exact<{
  equityBandGroupDeleteId: Scalars['Float'];
}>;


export type EquityBandGroupDeleteMutation = { __typename?: 'Mutation', equityBandGroupDelete: number };

export type EquityGlobalMappingFragment = { __typename?: 'EquityGlobalMapping', countryCode: CountryCode, percent: number, id: number, useGeoDiff: boolean };

export type EquityGlobalMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type EquityGlobalMappingsQuery = { __typename?: 'Query', equityGlobalMappings: Array<{ __typename?: 'EquityGlobalMapping', countryCode: CountryCode, percent: number, id: number, useGeoDiff: boolean }> };

export type EquityGlobalMappingSaveMutationVariables = Exact<{
  request: EquityGlobalMappingSaveManyRequest;
}>;


export type EquityGlobalMappingSaveMutation = { __typename?: 'Mutation', equityGlobalMappingSave: Array<{ __typename?: 'EquityGlobalMapping', id: number, percent: number, countryCode: CountryCode }> };

export type CustomRoleFragment = { __typename?: 'CustomRole', createdAt: any, updatedAt: any, id: number, name: string, scopes: Array<Scope>, userCount: number };

export type GetCustomRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomRolesQuery = { __typename?: 'Query', getCustomRoles: Array<{ __typename?: 'CustomRole', createdAt: any, updatedAt: any, id: number, name: string, scopes: Array<Scope>, userCount: number }> };

export type SubmitFeedbackMutationVariables = Exact<{
  feedbackRequest: FeedbackRequest;
}>;


export type SubmitFeedbackMutation = { __typename?: 'Mutation', submitFeedback: boolean };

export type RequestFeatureAccessMutationVariables = Exact<{
  request: FeatureAccessRequest;
}>;


export type RequestFeatureAccessMutation = { __typename?: 'Mutation', requestFeatureAccess: boolean };

export type JoinWaitlistMutationVariables = Exact<{
  request: JoinWaitlistRequest;
}>;


export type JoinWaitlistMutation = { __typename?: 'Mutation', joinWaitlist: boolean };

export type InviteUserMutationVariables = Exact<{
  inviteUserRequest: InviteUserRequest;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: boolean };

export type JobFamilyFragment = { __typename?: 'JobFamily', code?: string | null, id: number, name: string, ignoreGeoDiff: boolean, compBandMultiplier?: number | null, radfordExecutiveMatchCode?: string | null, radfordManagementMatchCode?: string | null, radfordProfessionalMatchCode?: string | null, radfordScientificMatchCode?: string | null, radfordSupportMatchCode?: string | null, dynamicParentId?: number | null, dynamicPercent?: number | null, equityBandGroup?: { __typename?: 'EquityBandGroup', id: number, name: string, rangeWidthGroupId?: number | null } | null, bonusStructureGroup?: { __typename?: 'BonusStructureGroup', id: number, name: string } | null, jobFunction?: { __typename?: 'JobFunction', id: number, name: string } | null, rangeWidthGroup?: { __typename?: 'RangeWidthGroup', id: number, name: string } | null, compBandGroup?: { __typename?: 'CompBandGroup', id: number, name: string } | null };

export type JobFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type JobFamiliesQuery = { __typename?: 'Query', jobFamilies: Array<{ __typename?: 'JobFamily', code?: string | null, id: number, name: string, ignoreGeoDiff: boolean, compBandMultiplier?: number | null, radfordExecutiveMatchCode?: string | null, radfordManagementMatchCode?: string | null, radfordProfessionalMatchCode?: string | null, radfordScientificMatchCode?: string | null, radfordSupportMatchCode?: string | null, dynamicParentId?: number | null, dynamicPercent?: number | null, equityBandGroup?: { __typename?: 'EquityBandGroup', id: number, name: string, rangeWidthGroupId?: number | null } | null, bonusStructureGroup?: { __typename?: 'BonusStructureGroup', id: number, name: string } | null, jobFunction?: { __typename?: 'JobFunction', id: number, name: string } | null, rangeWidthGroup?: { __typename?: 'RangeWidthGroup', id: number, name: string } | null, compBandGroup?: { __typename?: 'CompBandGroup', id: number, name: string } | null }> };

export type JobFamiliesSaveMutationVariables = Exact<{
  request: JobFamilySaveManyRequest;
}>;


export type JobFamiliesSaveMutation = { __typename?: 'Mutation', jobFamiliesSave: Array<{ __typename?: 'JobFamily', code?: string | null, id: number, name: string, ignoreGeoDiff: boolean, compBandMultiplier?: number | null, radfordExecutiveMatchCode?: string | null, radfordManagementMatchCode?: string | null, radfordProfessionalMatchCode?: string | null, radfordScientificMatchCode?: string | null, radfordSupportMatchCode?: string | null, dynamicParentId?: number | null, dynamicPercent?: number | null, equityBandGroup?: { __typename?: 'EquityBandGroup', id: number, name: string, rangeWidthGroupId?: number | null } | null, bonusStructureGroup?: { __typename?: 'BonusStructureGroup', id: number, name: string } | null, jobFunction?: { __typename?: 'JobFunction', id: number, name: string } | null, rangeWidthGroup?: { __typename?: 'RangeWidthGroup', id: number, name: string } | null, compBandGroup?: { __typename?: 'CompBandGroup', id: number, name: string } | null }> };

export type JobFamilyDeleteMutationVariables = Exact<{
  jobFamilyDeleteId: Scalars['Float'];
}>;


export type JobFamilyDeleteMutation = { __typename?: 'Mutation', jobFamilyDelete: number };

export type CompBandGroupRefFragment = { __typename?: 'CompBandGroup', id: number, name: string, compBands: Array<{ __typename?: 'CompBand', id: number, currencyCode: Currency, globalLevelLevel: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number }> };

export type RangeWidthGroupRefFragment = { __typename?: 'RangeWidthGroup', id: number, name: string, rangeWidths: Array<{ __typename?: 'RangeWidth', id: number, level: number, percentAboveMid: number, percentBelowMid: number, rangeWidthGroupId: number }> };

export type BonusStructureGroupRefFragment = { __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', id: number, bonusStructureGroupId: number, globalLevelLevel: number, jobLevelId?: number | null, percent: number }> };

export type EquityBandGroupRefFragment = { __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null, equityBands: Array<{ __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null }> };

export type JobFunctionRefFragment = { __typename?: 'JobFunction', id: number, name: string };

export type RadfordJobFamilyRefFragment = { __typename?: 'RadfordJobFamily', area: string, areaCode: AreaCode, areaDefinition: string, code: string, focus: string, focusCode: FocusCode, focusDefinition: string, function: string, functionDefinition: string, functionCode: FunctionCode, label: string, jobGroup: JobGroup, surveyProvider: SurveyProvider, detailedDefinition: string };

export type GetAllJobFamilyReferenceDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllJobFamilyReferenceDataQuery = { __typename?: 'Query', compBandsGroups: Array<{ __typename?: 'CompBandGroup', id: number, name: string, compBands: Array<{ __typename?: 'CompBand', id: number, currencyCode: Currency, globalLevelLevel: number, compBandGroupId: number, absoluteVariable: number, country: CountryCode, onTargetEarnings: number, percentVariable: number, hourlyRate: number, workspaceId: number }> }>, rangeWidthGroups: Array<{ __typename?: 'RangeWidthGroup', id: number, name: string, rangeWidths: Array<{ __typename?: 'RangeWidth', id: number, level: number, percentAboveMid: number, percentBelowMid: number, rangeWidthGroupId: number }> }>, bonusStructureGroups: Array<{ __typename?: 'BonusStructureGroup', id: number, name: string, bonusStructures: Array<{ __typename?: 'BonusStructure', id: number, bonusStructureGroupId: number, globalLevelLevel: number, jobLevelId?: number | null, percent: number }> }>, equityBandGroups: Array<{ __typename?: 'EquityBandGroup', id: number, name: string, dynamicPercent?: number | null, dynamicParentGroupId?: number | null, rangeWidthGroupId?: number | null, equityBands: Array<{ __typename?: 'EquityBand', globalLevelLevel: number, id: number, jobLevelId?: number | null, value: number, equityBandGroupId: number, dynamicPercentOverride?: number | null }> }>, jobFunctions: Array<{ __typename?: 'JobFunction', id: number, name: string }>, radfordJobFamilies: Array<{ __typename?: 'RadfordJobFamily', area: string, areaCode: AreaCode, areaDefinition: string, code: string, focus: string, focusCode: FocusCode, focusDefinition: string, function: string, functionDefinition: string, functionCode: FunctionCode, label: string, jobGroup: JobGroup, surveyProvider: SurveyProvider, detailedDefinition: string }> };

export type JobFunctionFragment = { __typename?: 'JobFunction', id: number, name: string };

export type JobFunctionsQueryVariables = Exact<{ [key: string]: never; }>;


export type JobFunctionsQuery = { __typename?: 'Query', jobFunctions: Array<{ __typename?: 'JobFunction', id: number, name: string }> };

export type JobFunctionSaveMutationVariables = Exact<{
  request: JobFunctionSaveRequest;
}>;


export type JobFunctionSaveMutation = { __typename?: 'Mutation', jobFunctionSave: { __typename?: 'JobFunction', id: number, name: string } };

export type JobLevelFragment = { __typename?: 'JobLevel', code: string, group: JobGroup, id: number, isVisible: boolean, name: string, level: number, surveyCode: SurveyCode, surveyProvider: SurveyProvider };

export type JobLevelsQueryVariables = Exact<{
  jobLevelsGetRequest: JobLevelsGetRequest;
}>;


export type JobLevelsQuery = { __typename?: 'Query', jobLevels: Array<{ __typename?: 'JobLevel', code: string, group: JobGroup, id: number, isVisible: boolean, name: string, level: number, surveyCode: SurveyCode, surveyProvider: SurveyProvider }> };

export type JobLevelSaveMutationVariables = Exact<{
  request: JobLevelSaveManyRequest;
}>;


export type JobLevelSaveMutation = { __typename?: 'Mutation', jobLevelSave: Array<{ __typename?: 'JobLevel', code: string, group: JobGroup, id: number, isVisible: boolean, name: string, level: number, surveyCode: SurveyCode, surveyProvider: SurveyProvider }> };

export type GlobalLevelFragment = { __typename?: 'GlobalLevel', level: number, name?: string | null, percentRsu: number };

export type GlobalLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalLevelsQuery = { __typename?: 'Query', globalLevels: Array<{ __typename?: 'GlobalLevel', level: number, name?: string | null, percentRsu: number }> };

export type GlobalLevelsSaveMutationVariables = Exact<{
  request: GlobalLevelSaveManyRequest;
}>;


export type GlobalLevelsSaveMutation = { __typename?: 'Mutation', globalLevelsSave: Array<{ __typename?: 'GlobalLevel', level: number, name?: string | null, percentRsu: number }> };

export type JobFragment = { __typename?: 'Job', id: number, jobFamilyId: number, jobLevelId: number, title: string, businessTitle?: string | null, jobCode?: string | null, jobSubLevel?: string | null, jobType: string, jobLevel: { __typename?: 'JobLevel', code: string, group: JobGroup, id: number, isVisible: boolean, name: string, level: number, surveyCode: SurveyCode, surveyProvider: SurveyProvider } };

export type JobsSaveMutationVariables = Exact<{
  jobSaveManyRequest: JobSaveManyRequest;
}>;


export type JobsSaveMutation = { __typename?: 'Mutation', jobsSave: Array<{ __typename?: 'Job', id: number, jobFamilyId: number, jobLevelId: number, title: string, businessTitle?: string | null, jobCode?: string | null, jobSubLevel?: string | null, jobType: string, jobLevel: { __typename?: 'JobLevel', code: string, group: JobGroup, id: number, isVisible: boolean, name: string, level: number, surveyCode: SurveyCode, surveyProvider: SurveyProvider } }> };

export type JobsQueryVariables = Exact<{
  jobGetRequest: JobGetRequest;
}>;


export type JobsQuery = { __typename?: 'Query', jobs: Array<{ __typename?: 'Job', id: number, jobFamilyId: number, jobLevelId: number, title: string, businessTitle?: string | null, jobCode?: string | null, jobSubLevel?: string | null, jobType: string, jobLevel: { __typename?: 'JobLevel', code: string, group: JobGroup, id: number, isVisible: boolean, name: string, level: number, surveyCode: SurveyCode, surveyProvider: SurveyProvider } }> };

export type MultiZipcodeSearchQueryVariables = Exact<{
  multiZipcodeSearchRequest: MultiZipcodeSearchRequest;
}>;


export type MultiZipcodeSearchQuery = { __typename?: 'Query', searchMultiZipcodes?: Array<{ __typename?: 'ZipcodeWithTierResponse', city: string, csa?: string | null, state: string, zipcode: string, alamereLocationId: AlamereLocationId, lat?: number | null, long?: number | null, tierLabel: string, tierNumber: string }> | null };

export type RadfordJobFamilyFragment = { __typename?: 'RadfordJobFamily', area: string, areaCode: AreaCode, areaDefinition: string, code: string, focus: string, focusCode: FocusCode, focusDefinition: string, function: string, functionDefinition: string, functionCode: FunctionCode, label: string, jobGroup: JobGroup, surveyProvider: SurveyProvider, detailedDefinition: string };

export type RadfordJobFamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type RadfordJobFamiliesQuery = { __typename?: 'Query', radfordJobFamilies: Array<{ __typename?: 'RadfordJobFamily', area: string, areaCode: AreaCode, areaDefinition: string, code: string, focus: string, focusCode: FocusCode, focusDefinition: string, function: string, functionDefinition: string, functionCode: FunctionCode, label: string, jobGroup: JobGroup, surveyProvider: SurveyProvider, detailedDefinition: string }> };

export type RadfordJobFamiliesSearchQueryVariables = Exact<{
  request: RadfordJobFamiliesSearchRequest;
}>;


export type RadfordJobFamiliesSearchQuery = { __typename?: 'Query', radfordJobFamiliesSearch: Array<{ __typename?: 'RadfordJobFamily', area: string, areaCode: AreaCode, areaDefinition: string, code: string, focus: string, focusCode: FocusCode, focusDefinition: string, function: string, functionDefinition: string, functionCode: FunctionCode, label: string, jobGroup: JobGroup, surveyProvider: SurveyProvider, detailedDefinition: string }> };

export type RangeWidthFragment = { __typename?: 'RangeWidth', id: number, level: number, percentAboveMid: number, percentBelowMid: number, rangeWidthGroupId: number };

export type RangeWidthGroupPropertiesFragment = { __typename?: 'RangeWidthGroup', id: number, name: string };

export type RangeWidthGroupFragment = { __typename?: 'RangeWidthGroup', id: number, name: string, rangeWidths: Array<{ __typename?: 'RangeWidth', id: number, level: number, percentAboveMid: number, percentBelowMid: number, rangeWidthGroupId: number }> };

export type RangeWidthGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type RangeWidthGroupsQuery = { __typename?: 'Query', rangeWidthGroups: Array<{ __typename?: 'RangeWidthGroup', id: number, name: string, rangeWidths: Array<{ __typename?: 'RangeWidth', id: number, level: number, percentAboveMid: number, percentBelowMid: number, rangeWidthGroupId: number }> }> };

export type RangeWidthGroupQueryVariables = Exact<{
  rangeWidthGroupId: Scalars['Float'];
}>;


export type RangeWidthGroupQuery = { __typename?: 'Query', rangeWidthGroup: { __typename?: 'RangeWidthGroup', id: number, name: string, rangeWidths: Array<{ __typename?: 'RangeWidth', id: number, level: number, percentAboveMid: number, percentBelowMid: number, rangeWidthGroupId: number }> } };

export type RangeWidthSaveMutationVariables = Exact<{
  rangeWidthSaveRequest: RangeWidthSaveManyRequest;
}>;


export type RangeWidthSaveMutation = { __typename?: 'Mutation', rangeWidthSave: Array<{ __typename?: 'RangeWidth', id: number, rangeWidthGroupId: number, level: number }> };

export type RangeWidthGroupsSaveMutationVariables = Exact<{
  rangeWidthGroupSaveManyRequest: RangeWidthGroupSaveManyRequest;
}>;


export type RangeWidthGroupsSaveMutation = { __typename?: 'Mutation', rangeWidthGroupsSave: Array<{ __typename?: 'RangeWidthGroup', id: number }> };

export type RangeWidthGroupDeleteMutationVariables = Exact<{
  rangeWidthGroupDeleteId: Scalars['Float'];
}>;


export type RangeWidthGroupDeleteMutation = { __typename?: 'Mutation', rangeWidthGroupDelete: boolean };

export type RequestJitaMutationVariables = Exact<{
  jitaRequest: JitaRequest;
}>;


export type RequestJitaMutation = { __typename?: 'Mutation', requestJita: boolean };

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordRequest: ResetPasswordRequest;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', email: string } };

export type SaveTierConfigurationMutationVariables = Exact<{
  tierConfigJson: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
}>;


export type SaveTierConfigurationMutation = { __typename?: 'Mutation', saveTierConfiguration: { __typename?: 'TierConfig', createdAt?: any | null, tierConfigJson?: string | null, updatedAt?: any | null, workspaceId: number, notes?: string | null } };

export type SearchWorkspacesQueryVariables = Exact<{
  searchWorkspacesPagedRequest: SearchWorkspacesPagedRequest;
}>;


export type SearchWorkspacesQuery = { __typename?: 'Query', searchWorkspaces: { __typename?: 'WorkspacePageResponse', edges: Array<{ __typename?: 'Edge', cursor: number, node: { __typename?: 'Workspace', domain?: string | null, id: number, name: string, createdAt: any } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: number | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: number | null, totalCount: number } } };

export type SettingFragment = { __typename?: 'Setting', property: SettingProperty, stringValue?: string | null, type: SettingType };

export type SettingsQueryVariables = Exact<{
  request: SettingsGetManyRequest;
}>;


export type SettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'Setting', property: SettingProperty, stringValue?: string | null, type: SettingType }> };

export type SettingsSaveMutationVariables = Exact<{
  settingsSaveRequest: SettingsSaveManyRequest;
}>;


export type SettingsSaveMutation = { __typename?: 'Mutation', settingsSave: boolean };

export type SignupAndCreateNewWorkspaceMutationVariables = Exact<{
  createUserRequest: CreateUserAndWorkspaceRequest;
}>;


export type SignupAndCreateNewWorkspaceMutation = { __typename?: 'Mutation', signupAndCreateNewWorkspace: { __typename?: 'User', createdAt: any, email: string, firstName: string, id: number, lastName: string } };

export type SignupFromInvitationMutationVariables = Exact<{
  createUserRequest: CreateUserInWorkspaceRequest;
}>;


export type SignupFromInvitationMutation = { __typename?: 'Mutation', signupFromInvitation: { __typename?: 'User', id: number } };

export type TierConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type TierConfigQuery = { __typename?: 'Query', tierConfig: { __typename?: 'TierConfig', createdAt?: any | null, tierConfigJson?: string | null, updatedAt?: any | null, notes?: string | null } };

export type UpdateCustomRoleMutationVariables = Exact<{
  updateCustomRoleRequest: UpdateCustomRoleRequest;
}>;


export type UpdateCustomRoleMutation = { __typename?: 'Mutation', updateCustomRole: boolean };

export type UpdateMembershipMutationVariables = Exact<{
  updateMembershipRequest: UpdateMembershipRequest;
}>;


export type UpdateMembershipMutation = { __typename?: 'Mutation', updateMembership: boolean };

export type UpdateMembershipLevelMutationVariables = Exact<{
  updateMembershipLevelRequest: UpdateMembershipLevelRequest;
}>;


export type UpdateMembershipLevelMutation = { __typename?: 'Mutation', updateMembershipLevel: boolean };

export type UpdateWorkspaceMutationVariables = Exact<{
  updateWorkspaceRequest: UpdateWorkspaceRequest;
}>;


export type UpdateWorkspaceMutation = { __typename?: 'Mutation', updateWorkspace: boolean };

export type MembershipWithCustomRoleFragment = { __typename?: 'Membership', id: number, role: UserRole, globalLevelLevel?: number | null, user: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string }, customRole?: { __typename?: 'CustomRole', id: number, name: string } | null };

export type InvitationWithCustomRoleFragment = { __typename?: 'Invitation', email: string, status: InvitationStatus, id: number, role: UserRole, customRole?: { __typename?: 'CustomRole', id: number, name: string } | null };

export type WorkspaceMembershipsAndInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceMembershipsAndInvitationsQuery = { __typename?: 'Query', workspace: { __typename?: 'Workspace', invitations: Array<{ __typename?: 'Invitation', email: string, status: InvitationStatus, id: number, role: UserRole, customRole?: { __typename?: 'CustomRole', id: number, name: string } | null }>, memberships: Array<{ __typename?: 'Membership', id: number, role: UserRole, globalLevelLevel?: number | null, user: { __typename?: 'User', id: number, email: string, firstName: string, lastName: string }, customRole?: { __typename?: 'CustomRole', id: number, name: string } | null }> } };

export type WorkspaceIdAndNameFragment = { __typename?: 'Workspace', id: number, name: string };

export type GetAutoSignupWorkspaceQueryVariables = Exact<{
  request: CanAutoSignupRequest;
}>;


export type GetAutoSignupWorkspaceQuery = { __typename?: 'Query', getAutoSignupWorkspace?: { __typename?: 'Workspace', id: number, name: string } | null };

export type ZipcodeSearchQueryVariables = Exact<{
  zipcodeSearchRequest: ZipcodeSearchRequest;
}>;


export type ZipcodeSearchQuery = { __typename?: 'Query', searchZipcode?: { __typename?: 'ZipcodeResponse', city: string, csa?: string | null, state: string, zipcode: string, alamereLocationId: AlamereLocationId, lat?: number | null, long?: number | null } | null };

export const BonusStructureGroupPropertiesFragmentDoc = gql`
    fragment BonusStructureGroupProperties on BonusStructureGroup {
  id
  name
}
    `;
export const BonusStructureFragmentDoc = gql`
    fragment BonusStructure on BonusStructure {
  bonusStructureGroupId
  globalLevelLevel
  id
  jobLevelId
  percent
}
    `;
export const BonusStructureGroupFragmentDoc = gql`
    fragment BonusStructureGroup on BonusStructureGroup {
  ...BonusStructureGroupProperties
  bonusStructures {
    ...BonusStructure
  }
}
    ${BonusStructureGroupPropertiesFragmentDoc}
${BonusStructureFragmentDoc}`;
export const CompBandGroupPropertiesFragmentDoc = gql`
    fragment CompBandGroupProperties on CompBandGroup {
  id
  name
}
    `;
export const CompBandFragmentDoc = gql`
    fragment CompBand on CompBand {
  id
  currencyCode
  globalLevelLevel
  compBandGroupId
  absoluteVariable
  country
  onTargetEarnings
  percentVariable
  hourlyRate
  workspaceId
}
    `;
export const CompBandGroupFragmentDoc = gql`
    fragment CompBandGroup on CompBandGroup {
  ...CompBandGroupProperties
  compBands {
    ...CompBand
  }
}
    ${CompBandGroupPropertiesFragmentDoc}
${CompBandFragmentDoc}`;
export const EquityBandGroupPropertiesFragmentDoc = gql`
    fragment EquityBandGroupProperties on EquityBandGroup {
  id
  name
  dynamicPercent
  dynamicParentGroupId
  rangeWidthGroupId
}
    `;
export const EquityBandFragmentDoc = gql`
    fragment EquityBand on EquityBand {
  globalLevelLevel
  id
  jobLevelId
  value
  equityBandGroupId
  dynamicPercentOverride
}
    `;
export const EquityBandGroupFragmentDoc = gql`
    fragment EquityBandGroup on EquityBandGroup {
  ...EquityBandGroupProperties
  equityBands {
    ...EquityBand
  }
}
    ${EquityBandGroupPropertiesFragmentDoc}
${EquityBandFragmentDoc}`;
export const EquityGlobalMappingFragmentDoc = gql`
    fragment EquityGlobalMapping on EquityGlobalMapping {
  countryCode
  percent
  id
  useGeoDiff
}
    `;
export const CustomRoleFragmentDoc = gql`
    fragment CustomRole on CustomRole {
  createdAt
  updatedAt
  id
  name
  scopes
  userCount
}
    `;
export const RangeWidthGroupPropertiesFragmentDoc = gql`
    fragment RangeWidthGroupProperties on RangeWidthGroup {
  id
  name
}
    `;
export const JobFamilyFragmentDoc = gql`
    fragment JobFamily on JobFamily {
  code
  id
  name
  ignoreGeoDiff
  compBandMultiplier
  equityBandGroup {
    id
    name
    rangeWidthGroupId
  }
  bonusStructureGroup {
    id
    name
  }
  jobFunction {
    id
    name
  }
  rangeWidthGroup {
    ...RangeWidthGroupProperties
  }
  compBandGroup {
    ...CompBandGroupProperties
  }
  radfordExecutiveMatchCode
  radfordManagementMatchCode
  radfordProfessionalMatchCode
  radfordScientificMatchCode
  radfordSupportMatchCode
  dynamicParentId
  dynamicPercent
}
    ${RangeWidthGroupPropertiesFragmentDoc}
${CompBandGroupPropertiesFragmentDoc}`;
export const CompBandGroupRefFragmentDoc = gql`
    fragment CompBandGroupRef on CompBandGroup {
  id
  name
  compBands {
    id
    currencyCode
    globalLevelLevel
    compBandGroupId
    absoluteVariable
    country
    onTargetEarnings
    percentVariable
    hourlyRate
    workspaceId
  }
}
    `;
export const RangeWidthGroupRefFragmentDoc = gql`
    fragment RangeWidthGroupRef on RangeWidthGroup {
  id
  name
  rangeWidths {
    id
    level
    percentAboveMid
    percentBelowMid
    rangeWidthGroupId
  }
}
    `;
export const BonusStructureGroupRefFragmentDoc = gql`
    fragment BonusStructureGroupRef on BonusStructureGroup {
  id
  name
  bonusStructures {
    id
    bonusStructureGroupId
    globalLevelLevel
    jobLevelId
    percent
  }
}
    `;
export const EquityBandGroupRefFragmentDoc = gql`
    fragment EquityBandGroupRef on EquityBandGroup {
  id
  name
  dynamicPercent
  dynamicParentGroupId
  rangeWidthGroupId
  equityBands {
    globalLevelLevel
    id
    jobLevelId
    value
    equityBandGroupId
    dynamicPercentOverride
  }
}
    `;
export const JobFunctionRefFragmentDoc = gql`
    fragment JobFunctionRef on JobFunction {
  id
  name
}
    `;
export const RadfordJobFamilyRefFragmentDoc = gql`
    fragment RadfordJobFamilyRef on RadfordJobFamily {
  area
  areaCode
  areaDefinition
  code
  focus
  focusCode
  focusDefinition
  function
  functionDefinition
  functionCode
  label
  jobGroup
  surveyProvider
  detailedDefinition
}
    `;
export const JobFunctionFragmentDoc = gql`
    fragment JobFunction on JobFunction {
  id
  name
}
    `;
export const GlobalLevelFragmentDoc = gql`
    fragment GlobalLevel on GlobalLevel {
  level
  name
  percentRsu
}
    `;
export const JobLevelFragmentDoc = gql`
    fragment JobLevel on JobLevel {
  code
  group
  id
  isVisible
  name
  level
  surveyCode
  surveyProvider
}
    `;
export const JobFragmentDoc = gql`
    fragment Job on Job {
  id
  jobFamilyId
  jobLevelId
  title
  businessTitle
  jobCode
  jobSubLevel
  jobType
  jobLevel {
    ...JobLevel
  }
}
    ${JobLevelFragmentDoc}`;
export const RadfordJobFamilyFragmentDoc = gql`
    fragment RadfordJobFamily on RadfordJobFamily {
  area
  areaCode
  areaDefinition
  code
  focus
  focusCode
  focusDefinition
  function
  functionDefinition
  functionCode
  label
  jobGroup
  surveyProvider
  detailedDefinition
}
    `;
export const RangeWidthFragmentDoc = gql`
    fragment RangeWidth on RangeWidth {
  id
  level
  percentAboveMid
  percentBelowMid
  rangeWidthGroupId
}
    `;
export const RangeWidthGroupFragmentDoc = gql`
    fragment RangeWidthGroup on RangeWidthGroup {
  ...RangeWidthGroupProperties
  rangeWidths {
    ...RangeWidth
  }
}
    ${RangeWidthGroupPropertiesFragmentDoc}
${RangeWidthFragmentDoc}`;
export const SettingFragmentDoc = gql`
    fragment Setting on Setting {
  property
  stringValue
  type
}
    `;
export const MembershipWithCustomRoleFragmentDoc = gql`
    fragment MembershipWithCustomRole on Membership {
  id
  role
  user {
    id
    email
    firstName
    lastName
  }
  customRole {
    id
    name
  }
  globalLevelLevel
}
    `;
export const InvitationWithCustomRoleFragmentDoc = gql`
    fragment InvitationWithCustomRole on Invitation {
  email
  status
  id
  role
  customRole {
    id
    name
  }
}
    `;
export const WorkspaceIdAndNameFragmentDoc = gql`
    fragment WorkspaceIdAndName on Workspace {
  id
  name
}
    `;
export const AcceptInvitationWithGoogleDocument = gql`
    mutation AcceptInvitationWithGoogle($createGoogleUserInWorkspaceRequest: CreateGoogleUserInWorkspaceRequest!) {
  acceptInvitationWithGoogle(
    createGoogleUserInWorkspaceRequest: $createGoogleUserInWorkspaceRequest
  ) {
    id
  }
}
    `;
export type AcceptInvitationWithGoogleMutationFn = Apollo.MutationFunction<AcceptInvitationWithGoogleMutation, AcceptInvitationWithGoogleMutationVariables>;

/**
 * __useAcceptInvitationWithGoogleMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationWithGoogleMutation, { data, loading, error }] = useAcceptInvitationWithGoogleMutation({
 *   variables: {
 *      createGoogleUserInWorkspaceRequest: // value for 'createGoogleUserInWorkspaceRequest'
 *   },
 * });
 */
export function useAcceptInvitationWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationWithGoogleMutation, AcceptInvitationWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationWithGoogleMutation, AcceptInvitationWithGoogleMutationVariables>(AcceptInvitationWithGoogleDocument, options);
      }
export type AcceptInvitationWithGoogleMutationHookResult = ReturnType<typeof useAcceptInvitationWithGoogleMutation>;
export type AcceptInvitationWithGoogleMutationResult = Apollo.MutationResult<AcceptInvitationWithGoogleMutation>;
export type AcceptInvitationWithGoogleMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationWithGoogleMutation, AcceptInvitationWithGoogleMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($acceptInvitationRequest: AcceptInvitationRequest!) {
  acceptInvitation(acceptInvitationRequest: $acceptInvitationRequest)
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      acceptInvitationRequest: // value for 'acceptInvitationRequest'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const BonusStructureGroupCreateDocument = gql`
    mutation BonusStructureGroupCreate($request: BonusStructureGroupCreateRequest!) {
  bonusStructureGroupCreate(request: $request) {
    ...BonusStructureGroup
  }
}
    ${BonusStructureGroupFragmentDoc}`;
export type BonusStructureGroupCreateMutationFn = Apollo.MutationFunction<BonusStructureGroupCreateMutation, BonusStructureGroupCreateMutationVariables>;

/**
 * __useBonusStructureGroupCreateMutation__
 *
 * To run a mutation, you first call `useBonusStructureGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBonusStructureGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bonusStructureGroupCreateMutation, { data, loading, error }] = useBonusStructureGroupCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBonusStructureGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<BonusStructureGroupCreateMutation, BonusStructureGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BonusStructureGroupCreateMutation, BonusStructureGroupCreateMutationVariables>(BonusStructureGroupCreateDocument, options);
      }
export type BonusStructureGroupCreateMutationHookResult = ReturnType<typeof useBonusStructureGroupCreateMutation>;
export type BonusStructureGroupCreateMutationResult = Apollo.MutationResult<BonusStructureGroupCreateMutation>;
export type BonusStructureGroupCreateMutationOptions = Apollo.BaseMutationOptions<BonusStructureGroupCreateMutation, BonusStructureGroupCreateMutationVariables>;
export const BonusStructureGroupCsvUploadDocument = gql`
    mutation BonusStructureGroupCsvUpload($request: BonusStructureCsvUploadRequest!) {
  bonusStructureGroupCsvUpload(request: $request) {
    ...BonusStructureGroup
  }
}
    ${BonusStructureGroupFragmentDoc}`;
export type BonusStructureGroupCsvUploadMutationFn = Apollo.MutationFunction<BonusStructureGroupCsvUploadMutation, BonusStructureGroupCsvUploadMutationVariables>;

/**
 * __useBonusStructureGroupCsvUploadMutation__
 *
 * To run a mutation, you first call `useBonusStructureGroupCsvUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBonusStructureGroupCsvUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bonusStructureGroupCsvUploadMutation, { data, loading, error }] = useBonusStructureGroupCsvUploadMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBonusStructureGroupCsvUploadMutation(baseOptions?: Apollo.MutationHookOptions<BonusStructureGroupCsvUploadMutation, BonusStructureGroupCsvUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BonusStructureGroupCsvUploadMutation, BonusStructureGroupCsvUploadMutationVariables>(BonusStructureGroupCsvUploadDocument, options);
      }
export type BonusStructureGroupCsvUploadMutationHookResult = ReturnType<typeof useBonusStructureGroupCsvUploadMutation>;
export type BonusStructureGroupCsvUploadMutationResult = Apollo.MutationResult<BonusStructureGroupCsvUploadMutation>;
export type BonusStructureGroupCsvUploadMutationOptions = Apollo.BaseMutationOptions<BonusStructureGroupCsvUploadMutation, BonusStructureGroupCsvUploadMutationVariables>;
export const BonusStructureGroupDeleteDocument = gql`
    mutation BonusStructureGroupDelete($bonusStructureGroupDeleteId: Float!) {
  bonusStructureGroupDelete(id: $bonusStructureGroupDeleteId)
}
    `;
export type BonusStructureGroupDeleteMutationFn = Apollo.MutationFunction<BonusStructureGroupDeleteMutation, BonusStructureGroupDeleteMutationVariables>;

/**
 * __useBonusStructureGroupDeleteMutation__
 *
 * To run a mutation, you first call `useBonusStructureGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBonusStructureGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bonusStructureGroupDeleteMutation, { data, loading, error }] = useBonusStructureGroupDeleteMutation({
 *   variables: {
 *      bonusStructureGroupDeleteId: // value for 'bonusStructureGroupDeleteId'
 *   },
 * });
 */
export function useBonusStructureGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BonusStructureGroupDeleteMutation, BonusStructureGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BonusStructureGroupDeleteMutation, BonusStructureGroupDeleteMutationVariables>(BonusStructureGroupDeleteDocument, options);
      }
export type BonusStructureGroupDeleteMutationHookResult = ReturnType<typeof useBonusStructureGroupDeleteMutation>;
export type BonusStructureGroupDeleteMutationResult = Apollo.MutationResult<BonusStructureGroupDeleteMutation>;
export type BonusStructureGroupDeleteMutationOptions = Apollo.BaseMutationOptions<BonusStructureGroupDeleteMutation, BonusStructureGroupDeleteMutationVariables>;
export const BonusStructureGroupsSaveDocument = gql`
    mutation BonusStructureGroupsSave($bonusStructureGroupSaveManyRequest: BonusStructureGroupSaveManyRequest!) {
  bonusStructureGroupsSave(request: $bonusStructureGroupSaveManyRequest) {
    ...BonusStructureGroup
  }
}
    ${BonusStructureGroupFragmentDoc}`;
export type BonusStructureGroupsSaveMutationFn = Apollo.MutationFunction<BonusStructureGroupsSaveMutation, BonusStructureGroupsSaveMutationVariables>;

/**
 * __useBonusStructureGroupsSaveMutation__
 *
 * To run a mutation, you first call `useBonusStructureGroupsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBonusStructureGroupsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bonusStructureGroupsSaveMutation, { data, loading, error }] = useBonusStructureGroupsSaveMutation({
 *   variables: {
 *      bonusStructureGroupSaveManyRequest: // value for 'bonusStructureGroupSaveManyRequest'
 *   },
 * });
 */
export function useBonusStructureGroupsSaveMutation(baseOptions?: Apollo.MutationHookOptions<BonusStructureGroupsSaveMutation, BonusStructureGroupsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BonusStructureGroupsSaveMutation, BonusStructureGroupsSaveMutationVariables>(BonusStructureGroupsSaveDocument, options);
      }
export type BonusStructureGroupsSaveMutationHookResult = ReturnType<typeof useBonusStructureGroupsSaveMutation>;
export type BonusStructureGroupsSaveMutationResult = Apollo.MutationResult<BonusStructureGroupsSaveMutation>;
export type BonusStructureGroupsSaveMutationOptions = Apollo.BaseMutationOptions<BonusStructureGroupsSaveMutation, BonusStructureGroupsSaveMutationVariables>;
export const BonusStructureGroupsDocument = gql`
    query BonusStructureGroups {
  bonusStructureGroups {
    ...BonusStructureGroup
  }
}
    ${BonusStructureGroupFragmentDoc}`;

/**
 * __useBonusStructureGroupsQuery__
 *
 * To run a query within a React component, call `useBonusStructureGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusStructureGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusStructureGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBonusStructureGroupsQuery(baseOptions?: Apollo.QueryHookOptions<BonusStructureGroupsQuery, BonusStructureGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusStructureGroupsQuery, BonusStructureGroupsQueryVariables>(BonusStructureGroupsDocument, options);
      }
export function useBonusStructureGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusStructureGroupsQuery, BonusStructureGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusStructureGroupsQuery, BonusStructureGroupsQueryVariables>(BonusStructureGroupsDocument, options);
        }
export type BonusStructureGroupsQueryHookResult = ReturnType<typeof useBonusStructureGroupsQuery>;
export type BonusStructureGroupsLazyQueryHookResult = ReturnType<typeof useBonusStructureGroupsLazyQuery>;
export type BonusStructureGroupsQueryResult = Apollo.QueryResult<BonusStructureGroupsQuery, BonusStructureGroupsQueryVariables>;
export const BonusStructuresDocument = gql`
    query BonusStructures {
  bonusStructures {
    ...BonusStructure
  }
}
    ${BonusStructureFragmentDoc}`;

/**
 * __useBonusStructuresQuery__
 *
 * To run a query within a React component, call `useBonusStructuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusStructuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusStructuresQuery({
 *   variables: {
 *   },
 * });
 */
export function useBonusStructuresQuery(baseOptions?: Apollo.QueryHookOptions<BonusStructuresQuery, BonusStructuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusStructuresQuery, BonusStructuresQueryVariables>(BonusStructuresDocument, options);
      }
export function useBonusStructuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusStructuresQuery, BonusStructuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusStructuresQuery, BonusStructuresQueryVariables>(BonusStructuresDocument, options);
        }
export type BonusStructuresQueryHookResult = ReturnType<typeof useBonusStructuresQuery>;
export type BonusStructuresLazyQueryHookResult = ReturnType<typeof useBonusStructuresLazyQuery>;
export type BonusStructuresQueryResult = Apollo.QueryResult<BonusStructuresQuery, BonusStructuresQueryVariables>;
export const BonusStructuresSaveDocument = gql`
    mutation BonusStructuresSave($bonusStructuresSaveRequest: BonusStructureSaveManyRequest!) {
  bonusStructuresSave(request: $bonusStructuresSaveRequest) {
    ...BonusStructure
  }
}
    ${BonusStructureFragmentDoc}`;
export type BonusStructuresSaveMutationFn = Apollo.MutationFunction<BonusStructuresSaveMutation, BonusStructuresSaveMutationVariables>;

/**
 * __useBonusStructuresSaveMutation__
 *
 * To run a mutation, you first call `useBonusStructuresSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBonusStructuresSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bonusStructuresSaveMutation, { data, loading, error }] = useBonusStructuresSaveMutation({
 *   variables: {
 *      bonusStructuresSaveRequest: // value for 'bonusStructuresSaveRequest'
 *   },
 * });
 */
export function useBonusStructuresSaveMutation(baseOptions?: Apollo.MutationHookOptions<BonusStructuresSaveMutation, BonusStructuresSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BonusStructuresSaveMutation, BonusStructuresSaveMutationVariables>(BonusStructuresSaveDocument, options);
      }
export type BonusStructuresSaveMutationHookResult = ReturnType<typeof useBonusStructuresSaveMutation>;
export type BonusStructuresSaveMutationResult = Apollo.MutationResult<BonusStructuresSaveMutation>;
export type BonusStructuresSaveMutationOptions = Apollo.BaseMutationOptions<BonusStructuresSaveMutation, BonusStructuresSaveMutationVariables>;
export const CompBandGroupsDocument = gql`
    query CompBandGroups {
  compBandsGroups {
    ...CompBandGroup
  }
}
    ${CompBandGroupFragmentDoc}`;

/**
 * __useCompBandGroupsQuery__
 *
 * To run a query within a React component, call `useCompBandGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompBandGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompBandGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompBandGroupsQuery(baseOptions?: Apollo.QueryHookOptions<CompBandGroupsQuery, CompBandGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompBandGroupsQuery, CompBandGroupsQueryVariables>(CompBandGroupsDocument, options);
      }
export function useCompBandGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompBandGroupsQuery, CompBandGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompBandGroupsQuery, CompBandGroupsQueryVariables>(CompBandGroupsDocument, options);
        }
export type CompBandGroupsQueryHookResult = ReturnType<typeof useCompBandGroupsQuery>;
export type CompBandGroupsLazyQueryHookResult = ReturnType<typeof useCompBandGroupsLazyQuery>;
export type CompBandGroupsQueryResult = Apollo.QueryResult<CompBandGroupsQuery, CompBandGroupsQueryVariables>;
export const CompBandGroupDocument = gql`
    query CompBandGroup($compBandGroupId: Float!) {
  compBandGroup(id: $compBandGroupId) {
    ...CompBandGroup
  }
}
    ${CompBandGroupFragmentDoc}`;

/**
 * __useCompBandGroupQuery__
 *
 * To run a query within a React component, call `useCompBandGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompBandGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompBandGroupQuery({
 *   variables: {
 *      compBandGroupId: // value for 'compBandGroupId'
 *   },
 * });
 */
export function useCompBandGroupQuery(baseOptions: Apollo.QueryHookOptions<CompBandGroupQuery, CompBandGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompBandGroupQuery, CompBandGroupQueryVariables>(CompBandGroupDocument, options);
      }
export function useCompBandGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompBandGroupQuery, CompBandGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompBandGroupQuery, CompBandGroupQueryVariables>(CompBandGroupDocument, options);
        }
export type CompBandGroupQueryHookResult = ReturnType<typeof useCompBandGroupQuery>;
export type CompBandGroupLazyQueryHookResult = ReturnType<typeof useCompBandGroupLazyQuery>;
export type CompBandGroupQueryResult = Apollo.QueryResult<CompBandGroupQuery, CompBandGroupQueryVariables>;
export const CompBandSaveDocument = gql`
    mutation CompBandSave($compBandSaveRequest: CompBandSaveManyRequest!) {
  compBandSave(request: $compBandSaveRequest) {
    id
    compBandGroupId
    absoluteVariable
    country
    currencyCode
    globalLevelLevel
    onTargetEarnings
    percentVariable
    hourlyRate
    workspaceId
  }
}
    `;
export type CompBandSaveMutationFn = Apollo.MutationFunction<CompBandSaveMutation, CompBandSaveMutationVariables>;

/**
 * __useCompBandSaveMutation__
 *
 * To run a mutation, you first call `useCompBandSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompBandSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compBandSaveMutation, { data, loading, error }] = useCompBandSaveMutation({
 *   variables: {
 *      compBandSaveRequest: // value for 'compBandSaveRequest'
 *   },
 * });
 */
export function useCompBandSaveMutation(baseOptions?: Apollo.MutationHookOptions<CompBandSaveMutation, CompBandSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompBandSaveMutation, CompBandSaveMutationVariables>(CompBandSaveDocument, options);
      }
export type CompBandSaveMutationHookResult = ReturnType<typeof useCompBandSaveMutation>;
export type CompBandSaveMutationResult = Apollo.MutationResult<CompBandSaveMutation>;
export type CompBandSaveMutationOptions = Apollo.BaseMutationOptions<CompBandSaveMutation, CompBandSaveMutationVariables>;
export const CompBandGroupsSaveDocument = gql`
    mutation CompBandGroupsSave($compBandGroupSaveManyRequest: CompBandGroupSaveManyRequest!) {
  compBandGroupsSave(request: $compBandGroupSaveManyRequest) {
    id
    name
  }
}
    `;
export type CompBandGroupsSaveMutationFn = Apollo.MutationFunction<CompBandGroupsSaveMutation, CompBandGroupsSaveMutationVariables>;

/**
 * __useCompBandGroupsSaveMutation__
 *
 * To run a mutation, you first call `useCompBandGroupsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompBandGroupsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compBandGroupsSaveMutation, { data, loading, error }] = useCompBandGroupsSaveMutation({
 *   variables: {
 *      compBandGroupSaveManyRequest: // value for 'compBandGroupSaveManyRequest'
 *   },
 * });
 */
export function useCompBandGroupsSaveMutation(baseOptions?: Apollo.MutationHookOptions<CompBandGroupsSaveMutation, CompBandGroupsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompBandGroupsSaveMutation, CompBandGroupsSaveMutationVariables>(CompBandGroupsSaveDocument, options);
      }
export type CompBandGroupsSaveMutationHookResult = ReturnType<typeof useCompBandGroupsSaveMutation>;
export type CompBandGroupsSaveMutationResult = Apollo.MutationResult<CompBandGroupsSaveMutation>;
export type CompBandGroupsSaveMutationOptions = Apollo.BaseMutationOptions<CompBandGroupsSaveMutation, CompBandGroupsSaveMutationVariables>;
export const CompBandGroupDeleteDocument = gql`
    mutation CompBandGroupDelete($compBandGroupDeleteId: Float!) {
  compBandGroupDelete(id: $compBandGroupDeleteId)
}
    `;
export type CompBandGroupDeleteMutationFn = Apollo.MutationFunction<CompBandGroupDeleteMutation, CompBandGroupDeleteMutationVariables>;

/**
 * __useCompBandGroupDeleteMutation__
 *
 * To run a mutation, you first call `useCompBandGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompBandGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compBandGroupDeleteMutation, { data, loading, error }] = useCompBandGroupDeleteMutation({
 *   variables: {
 *      compBandGroupDeleteId: // value for 'compBandGroupDeleteId'
 *   },
 * });
 */
export function useCompBandGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CompBandGroupDeleteMutation, CompBandGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompBandGroupDeleteMutation, CompBandGroupDeleteMutationVariables>(CompBandGroupDeleteDocument, options);
      }
export type CompBandGroupDeleteMutationHookResult = ReturnType<typeof useCompBandGroupDeleteMutation>;
export type CompBandGroupDeleteMutationResult = Apollo.MutationResult<CompBandGroupDeleteMutation>;
export type CompBandGroupDeleteMutationOptions = Apollo.BaseMutationOptions<CompBandGroupDeleteMutation, CompBandGroupDeleteMutationVariables>;
export const CreateCustomRoleDocument = gql`
    mutation CreateCustomRole($createCustomRoleRequest: CreateCustomRoleRequest!) {
  createCustomRole(createCustomRoleRequest: $createCustomRoleRequest)
}
    `;
export type CreateCustomRoleMutationFn = Apollo.MutationFunction<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>;

/**
 * __useCreateCustomRoleMutation__
 *
 * To run a mutation, you first call `useCreateCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomRoleMutation, { data, loading, error }] = useCreateCustomRoleMutation({
 *   variables: {
 *      createCustomRoleRequest: // value for 'createCustomRoleRequest'
 *   },
 * });
 */
export function useCreateCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>(CreateCustomRoleDocument, options);
      }
export type CreateCustomRoleMutationHookResult = ReturnType<typeof useCreateCustomRoleMutation>;
export type CreateCustomRoleMutationResult = Apollo.MutationResult<CreateCustomRoleMutation>;
export type CreateCustomRoleMutationOptions = Apollo.BaseMutationOptions<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($createWorkspaceInput: CreateWorkspaceRequest!) {
  createWorkspace(createWorkspaceInput: $createWorkspaceInput) {
    id
  }
}
    `;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      createWorkspaceInput: // value for 'createWorkspaceInput'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    email
    memberships {
      role
      customRole {
        id
        name
        scopes
      }
      createdAt
      updatedAt
      expiresAt
      workspace {
        id
        name
        domain
        createdAt
        updatedAt
        enableInviteByDomain
        inviteByDomainRole
        inviteByDomainCustomRole {
          id
          name
        }
      }
      id
      globalLevelLevel
    }
    firstName
    id
    lastName
    fullName
    updatedAt
    createdAt
    avatarUrl
    hasVerifiedEmail
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const DeleteCustomRoleDocument = gql`
    mutation DeleteCustomRole($deleteCustomRoleRequest: DeleteCustomRoleRequest!) {
  deleteCustomRole(deleteCustomRoleRequest: $deleteCustomRoleRequest)
}
    `;
export type DeleteCustomRoleMutationFn = Apollo.MutationFunction<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>;

/**
 * __useDeleteCustomRoleMutation__
 *
 * To run a mutation, you first call `useDeleteCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomRoleMutation, { data, loading, error }] = useDeleteCustomRoleMutation({
 *   variables: {
 *      deleteCustomRoleRequest: // value for 'deleteCustomRoleRequest'
 *   },
 * });
 */
export function useDeleteCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>(DeleteCustomRoleDocument, options);
      }
export type DeleteCustomRoleMutationHookResult = ReturnType<typeof useDeleteCustomRoleMutation>;
export type DeleteCustomRoleMutationResult = Apollo.MutationResult<DeleteCustomRoleMutation>;
export type DeleteCustomRoleMutationOptions = Apollo.BaseMutationOptions<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>;
export const DeleteInvitationDocument = gql`
    mutation DeleteInvitation($deleteInvitationRequest: DeleteInvitationRequest!) {
  deleteInvitation(deleteInvitationRequest: $deleteInvitationRequest)
}
    `;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<DeleteInvitationMutation, DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      deleteInvitationRequest: // value for 'deleteInvitationRequest'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(DeleteInvitationDocument, options);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>;
export const DeleteMembershipDocument = gql`
    mutation DeleteMembership($deleteMembershipRequest: DeleteMembershipRequest!) {
  deleteMembership(deleteMembershipRequest: $deleteMembershipRequest)
}
    `;
export type DeleteMembershipMutationFn = Apollo.MutationFunction<DeleteMembershipMutation, DeleteMembershipMutationVariables>;

/**
 * __useDeleteMembershipMutation__
 *
 * To run a mutation, you first call `useDeleteMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembershipMutation, { data, loading, error }] = useDeleteMembershipMutation({
 *   variables: {
 *      deleteMembershipRequest: // value for 'deleteMembershipRequest'
 *   },
 * });
 */
export function useDeleteMembershipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipMutation, DeleteMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembershipMutation, DeleteMembershipMutationVariables>(DeleteMembershipDocument, options);
      }
export type DeleteMembershipMutationHookResult = ReturnType<typeof useDeleteMembershipMutation>;
export type DeleteMembershipMutationResult = Apollo.MutationResult<DeleteMembershipMutation>;
export type DeleteMembershipMutationOptions = Apollo.BaseMutationOptions<DeleteMembershipMutation, DeleteMembershipMutationVariables>;
export const EquityBandGroupCreateDocument = gql`
    mutation EquityBandGroupCreate($request: EquityBandGroupCreateRequest!) {
  equityBandGroupCreate(request: $request) {
    ...EquityBandGroup
  }
}
    ${EquityBandGroupFragmentDoc}`;
export type EquityBandGroupCreateMutationFn = Apollo.MutationFunction<EquityBandGroupCreateMutation, EquityBandGroupCreateMutationVariables>;

/**
 * __useEquityBandGroupCreateMutation__
 *
 * To run a mutation, you first call `useEquityBandGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEquityBandGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [equityBandGroupCreateMutation, { data, loading, error }] = useEquityBandGroupCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useEquityBandGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<EquityBandGroupCreateMutation, EquityBandGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EquityBandGroupCreateMutation, EquityBandGroupCreateMutationVariables>(EquityBandGroupCreateDocument, options);
      }
export type EquityBandGroupCreateMutationHookResult = ReturnType<typeof useEquityBandGroupCreateMutation>;
export type EquityBandGroupCreateMutationResult = Apollo.MutationResult<EquityBandGroupCreateMutation>;
export type EquityBandGroupCreateMutationOptions = Apollo.BaseMutationOptions<EquityBandGroupCreateMutation, EquityBandGroupCreateMutationVariables>;
export const EquityBandGroupsSaveDocument = gql`
    mutation EquityBandGroupsSave($equityBandGroupSaveManyRequest: EquityBandGroupSaveManyRequest!) {
  equityBandGroupsSave(request: $equityBandGroupSaveManyRequest) {
    id
  }
}
    `;
export type EquityBandGroupsSaveMutationFn = Apollo.MutationFunction<EquityBandGroupsSaveMutation, EquityBandGroupsSaveMutationVariables>;

/**
 * __useEquityBandGroupsSaveMutation__
 *
 * To run a mutation, you first call `useEquityBandGroupsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEquityBandGroupsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [equityBandGroupsSaveMutation, { data, loading, error }] = useEquityBandGroupsSaveMutation({
 *   variables: {
 *      equityBandGroupSaveManyRequest: // value for 'equityBandGroupSaveManyRequest'
 *   },
 * });
 */
export function useEquityBandGroupsSaveMutation(baseOptions?: Apollo.MutationHookOptions<EquityBandGroupsSaveMutation, EquityBandGroupsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EquityBandGroupsSaveMutation, EquityBandGroupsSaveMutationVariables>(EquityBandGroupsSaveDocument, options);
      }
export type EquityBandGroupsSaveMutationHookResult = ReturnType<typeof useEquityBandGroupsSaveMutation>;
export type EquityBandGroupsSaveMutationResult = Apollo.MutationResult<EquityBandGroupsSaveMutation>;
export type EquityBandGroupsSaveMutationOptions = Apollo.BaseMutationOptions<EquityBandGroupsSaveMutation, EquityBandGroupsSaveMutationVariables>;
export const EquityBandSaveDocument = gql`
    mutation EquityBandSave($equityBandSaveManyRequest: EquityBandSaveManyRequest!) {
  equityBandsSave(request: $equityBandSaveManyRequest)
}
    `;
export type EquityBandSaveMutationFn = Apollo.MutationFunction<EquityBandSaveMutation, EquityBandSaveMutationVariables>;

/**
 * __useEquityBandSaveMutation__
 *
 * To run a mutation, you first call `useEquityBandSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEquityBandSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [equityBandSaveMutation, { data, loading, error }] = useEquityBandSaveMutation({
 *   variables: {
 *      equityBandSaveManyRequest: // value for 'equityBandSaveManyRequest'
 *   },
 * });
 */
export function useEquityBandSaveMutation(baseOptions?: Apollo.MutationHookOptions<EquityBandSaveMutation, EquityBandSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EquityBandSaveMutation, EquityBandSaveMutationVariables>(EquityBandSaveDocument, options);
      }
export type EquityBandSaveMutationHookResult = ReturnType<typeof useEquityBandSaveMutation>;
export type EquityBandSaveMutationResult = Apollo.MutationResult<EquityBandSaveMutation>;
export type EquityBandSaveMutationOptions = Apollo.BaseMutationOptions<EquityBandSaveMutation, EquityBandSaveMutationVariables>;
export const EquityBandGroupsDocument = gql`
    query EquityBandGroups {
  equityBandGroups {
    ...EquityBandGroup
  }
}
    ${EquityBandGroupFragmentDoc}`;

/**
 * __useEquityBandGroupsQuery__
 *
 * To run a query within a React component, call `useEquityBandGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquityBandGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquityBandGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEquityBandGroupsQuery(baseOptions?: Apollo.QueryHookOptions<EquityBandGroupsQuery, EquityBandGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquityBandGroupsQuery, EquityBandGroupsQueryVariables>(EquityBandGroupsDocument, options);
      }
export function useEquityBandGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquityBandGroupsQuery, EquityBandGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquityBandGroupsQuery, EquityBandGroupsQueryVariables>(EquityBandGroupsDocument, options);
        }
export type EquityBandGroupsQueryHookResult = ReturnType<typeof useEquityBandGroupsQuery>;
export type EquityBandGroupsLazyQueryHookResult = ReturnType<typeof useEquityBandGroupsLazyQuery>;
export type EquityBandGroupsQueryResult = Apollo.QueryResult<EquityBandGroupsQuery, EquityBandGroupsQueryVariables>;
export const EquityBandGroupDocument = gql`
    query EquityBandGroup($equityBandGroupId: Float!) {
  equityBandGroup(id: $equityBandGroupId) {
    ...EquityBandGroup
  }
}
    ${EquityBandGroupFragmentDoc}`;

/**
 * __useEquityBandGroupQuery__
 *
 * To run a query within a React component, call `useEquityBandGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquityBandGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquityBandGroupQuery({
 *   variables: {
 *      equityBandGroupId: // value for 'equityBandGroupId'
 *   },
 * });
 */
export function useEquityBandGroupQuery(baseOptions: Apollo.QueryHookOptions<EquityBandGroupQuery, EquityBandGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquityBandGroupQuery, EquityBandGroupQueryVariables>(EquityBandGroupDocument, options);
      }
export function useEquityBandGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquityBandGroupQuery, EquityBandGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquityBandGroupQuery, EquityBandGroupQueryVariables>(EquityBandGroupDocument, options);
        }
export type EquityBandGroupQueryHookResult = ReturnType<typeof useEquityBandGroupQuery>;
export type EquityBandGroupLazyQueryHookResult = ReturnType<typeof useEquityBandGroupLazyQuery>;
export type EquityBandGroupQueryResult = Apollo.QueryResult<EquityBandGroupQuery, EquityBandGroupQueryVariables>;
export const EquityBandGroupDeleteDocument = gql`
    mutation EquityBandGroupDelete($equityBandGroupDeleteId: Float!) {
  equityBandGroupDelete(id: $equityBandGroupDeleteId)
}
    `;
export type EquityBandGroupDeleteMutationFn = Apollo.MutationFunction<EquityBandGroupDeleteMutation, EquityBandGroupDeleteMutationVariables>;

/**
 * __useEquityBandGroupDeleteMutation__
 *
 * To run a mutation, you first call `useEquityBandGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEquityBandGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [equityBandGroupDeleteMutation, { data, loading, error }] = useEquityBandGroupDeleteMutation({
 *   variables: {
 *      equityBandGroupDeleteId: // value for 'equityBandGroupDeleteId'
 *   },
 * });
 */
export function useEquityBandGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<EquityBandGroupDeleteMutation, EquityBandGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EquityBandGroupDeleteMutation, EquityBandGroupDeleteMutationVariables>(EquityBandGroupDeleteDocument, options);
      }
export type EquityBandGroupDeleteMutationHookResult = ReturnType<typeof useEquityBandGroupDeleteMutation>;
export type EquityBandGroupDeleteMutationResult = Apollo.MutationResult<EquityBandGroupDeleteMutation>;
export type EquityBandGroupDeleteMutationOptions = Apollo.BaseMutationOptions<EquityBandGroupDeleteMutation, EquityBandGroupDeleteMutationVariables>;
export const EquityGlobalMappingsDocument = gql`
    query EquityGlobalMappings {
  equityGlobalMappings {
    ...EquityGlobalMapping
  }
}
    ${EquityGlobalMappingFragmentDoc}`;

/**
 * __useEquityGlobalMappingsQuery__
 *
 * To run a query within a React component, call `useEquityGlobalMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquityGlobalMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquityGlobalMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEquityGlobalMappingsQuery(baseOptions?: Apollo.QueryHookOptions<EquityGlobalMappingsQuery, EquityGlobalMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquityGlobalMappingsQuery, EquityGlobalMappingsQueryVariables>(EquityGlobalMappingsDocument, options);
      }
export function useEquityGlobalMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquityGlobalMappingsQuery, EquityGlobalMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquityGlobalMappingsQuery, EquityGlobalMappingsQueryVariables>(EquityGlobalMappingsDocument, options);
        }
export type EquityGlobalMappingsQueryHookResult = ReturnType<typeof useEquityGlobalMappingsQuery>;
export type EquityGlobalMappingsLazyQueryHookResult = ReturnType<typeof useEquityGlobalMappingsLazyQuery>;
export type EquityGlobalMappingsQueryResult = Apollo.QueryResult<EquityGlobalMappingsQuery, EquityGlobalMappingsQueryVariables>;
export const EquityGlobalMappingSaveDocument = gql`
    mutation EquityGlobalMappingSave($request: EquityGlobalMappingSaveManyRequest!) {
  equityGlobalMappingSave(request: $request) {
    id
    percent
    countryCode
  }
}
    `;
export type EquityGlobalMappingSaveMutationFn = Apollo.MutationFunction<EquityGlobalMappingSaveMutation, EquityGlobalMappingSaveMutationVariables>;

/**
 * __useEquityGlobalMappingSaveMutation__
 *
 * To run a mutation, you first call `useEquityGlobalMappingSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEquityGlobalMappingSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [equityGlobalMappingSaveMutation, { data, loading, error }] = useEquityGlobalMappingSaveMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useEquityGlobalMappingSaveMutation(baseOptions?: Apollo.MutationHookOptions<EquityGlobalMappingSaveMutation, EquityGlobalMappingSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EquityGlobalMappingSaveMutation, EquityGlobalMappingSaveMutationVariables>(EquityGlobalMappingSaveDocument, options);
      }
export type EquityGlobalMappingSaveMutationHookResult = ReturnType<typeof useEquityGlobalMappingSaveMutation>;
export type EquityGlobalMappingSaveMutationResult = Apollo.MutationResult<EquityGlobalMappingSaveMutation>;
export type EquityGlobalMappingSaveMutationOptions = Apollo.BaseMutationOptions<EquityGlobalMappingSaveMutation, EquityGlobalMappingSaveMutationVariables>;
export const GetCustomRolesDocument = gql`
    query GetCustomRoles {
  getCustomRoles {
    ...CustomRole
  }
}
    ${CustomRoleFragmentDoc}`;

/**
 * __useGetCustomRolesQuery__
 *
 * To run a query within a React component, call `useGetCustomRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomRolesQuery, GetCustomRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomRolesQuery, GetCustomRolesQueryVariables>(GetCustomRolesDocument, options);
      }
export function useGetCustomRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomRolesQuery, GetCustomRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomRolesQuery, GetCustomRolesQueryVariables>(GetCustomRolesDocument, options);
        }
export type GetCustomRolesQueryHookResult = ReturnType<typeof useGetCustomRolesQuery>;
export type GetCustomRolesLazyQueryHookResult = ReturnType<typeof useGetCustomRolesLazyQuery>;
export type GetCustomRolesQueryResult = Apollo.QueryResult<GetCustomRolesQuery, GetCustomRolesQueryVariables>;
export const SubmitFeedbackDocument = gql`
    mutation SubmitFeedback($feedbackRequest: FeedbackRequest!) {
  submitFeedback(feedbackRequest: $feedbackRequest)
}
    `;
export type SubmitFeedbackMutationFn = Apollo.MutationFunction<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      feedbackRequest: // value for 'feedbackRequest'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(SubmitFeedbackDocument, options);
      }
export type SubmitFeedbackMutationHookResult = ReturnType<typeof useSubmitFeedbackMutation>;
export type SubmitFeedbackMutationResult = Apollo.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions = Apollo.BaseMutationOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;
export const RequestFeatureAccessDocument = gql`
    mutation RequestFeatureAccess($request: FeatureAccessRequest!) {
  requestFeatureAccess(request: $request)
}
    `;
export type RequestFeatureAccessMutationFn = Apollo.MutationFunction<RequestFeatureAccessMutation, RequestFeatureAccessMutationVariables>;

/**
 * __useRequestFeatureAccessMutation__
 *
 * To run a mutation, you first call `useRequestFeatureAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFeatureAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFeatureAccessMutation, { data, loading, error }] = useRequestFeatureAccessMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRequestFeatureAccessMutation(baseOptions?: Apollo.MutationHookOptions<RequestFeatureAccessMutation, RequestFeatureAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestFeatureAccessMutation, RequestFeatureAccessMutationVariables>(RequestFeatureAccessDocument, options);
      }
export type RequestFeatureAccessMutationHookResult = ReturnType<typeof useRequestFeatureAccessMutation>;
export type RequestFeatureAccessMutationResult = Apollo.MutationResult<RequestFeatureAccessMutation>;
export type RequestFeatureAccessMutationOptions = Apollo.BaseMutationOptions<RequestFeatureAccessMutation, RequestFeatureAccessMutationVariables>;
export const JoinWaitlistDocument = gql`
    mutation JoinWaitlist($request: JoinWaitlistRequest!) {
  joinWaitlist(request: $request)
}
    `;
export type JoinWaitlistMutationFn = Apollo.MutationFunction<JoinWaitlistMutation, JoinWaitlistMutationVariables>;

/**
 * __useJoinWaitlistMutation__
 *
 * To run a mutation, you first call `useJoinWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWaitlistMutation, { data, loading, error }] = useJoinWaitlistMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useJoinWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<JoinWaitlistMutation, JoinWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinWaitlistMutation, JoinWaitlistMutationVariables>(JoinWaitlistDocument, options);
      }
export type JoinWaitlistMutationHookResult = ReturnType<typeof useJoinWaitlistMutation>;
export type JoinWaitlistMutationResult = Apollo.MutationResult<JoinWaitlistMutation>;
export type JoinWaitlistMutationOptions = Apollo.BaseMutationOptions<JoinWaitlistMutation, JoinWaitlistMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($inviteUserRequest: InviteUserRequest!) {
  inviteUser(inviteUserRequest: $inviteUserRequest)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      inviteUserRequest: // value for 'inviteUserRequest'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const JobFamiliesDocument = gql`
    query JobFamilies {
  jobFamilies {
    ...JobFamily
  }
}
    ${JobFamilyFragmentDoc}`;

/**
 * __useJobFamiliesQuery__
 *
 * To run a query within a React component, call `useJobFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobFamiliesQuery(baseOptions?: Apollo.QueryHookOptions<JobFamiliesQuery, JobFamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobFamiliesQuery, JobFamiliesQueryVariables>(JobFamiliesDocument, options);
      }
export function useJobFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobFamiliesQuery, JobFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobFamiliesQuery, JobFamiliesQueryVariables>(JobFamiliesDocument, options);
        }
export type JobFamiliesQueryHookResult = ReturnType<typeof useJobFamiliesQuery>;
export type JobFamiliesLazyQueryHookResult = ReturnType<typeof useJobFamiliesLazyQuery>;
export type JobFamiliesQueryResult = Apollo.QueryResult<JobFamiliesQuery, JobFamiliesQueryVariables>;
export const JobFamiliesSaveDocument = gql`
    mutation JobFamiliesSave($request: JobFamilySaveManyRequest!) {
  jobFamiliesSave(request: $request) {
    ...JobFamily
  }
}
    ${JobFamilyFragmentDoc}`;
export type JobFamiliesSaveMutationFn = Apollo.MutationFunction<JobFamiliesSaveMutation, JobFamiliesSaveMutationVariables>;

/**
 * __useJobFamiliesSaveMutation__
 *
 * To run a mutation, you first call `useJobFamiliesSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobFamiliesSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobFamiliesSaveMutation, { data, loading, error }] = useJobFamiliesSaveMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useJobFamiliesSaveMutation(baseOptions?: Apollo.MutationHookOptions<JobFamiliesSaveMutation, JobFamiliesSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobFamiliesSaveMutation, JobFamiliesSaveMutationVariables>(JobFamiliesSaveDocument, options);
      }
export type JobFamiliesSaveMutationHookResult = ReturnType<typeof useJobFamiliesSaveMutation>;
export type JobFamiliesSaveMutationResult = Apollo.MutationResult<JobFamiliesSaveMutation>;
export type JobFamiliesSaveMutationOptions = Apollo.BaseMutationOptions<JobFamiliesSaveMutation, JobFamiliesSaveMutationVariables>;
export const JobFamilyDeleteDocument = gql`
    mutation JobFamilyDelete($jobFamilyDeleteId: Float!) {
  jobFamilyDelete(id: $jobFamilyDeleteId)
}
    `;
export type JobFamilyDeleteMutationFn = Apollo.MutationFunction<JobFamilyDeleteMutation, JobFamilyDeleteMutationVariables>;

/**
 * __useJobFamilyDeleteMutation__
 *
 * To run a mutation, you first call `useJobFamilyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobFamilyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobFamilyDeleteMutation, { data, loading, error }] = useJobFamilyDeleteMutation({
 *   variables: {
 *      jobFamilyDeleteId: // value for 'jobFamilyDeleteId'
 *   },
 * });
 */
export function useJobFamilyDeleteMutation(baseOptions?: Apollo.MutationHookOptions<JobFamilyDeleteMutation, JobFamilyDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobFamilyDeleteMutation, JobFamilyDeleteMutationVariables>(JobFamilyDeleteDocument, options);
      }
export type JobFamilyDeleteMutationHookResult = ReturnType<typeof useJobFamilyDeleteMutation>;
export type JobFamilyDeleteMutationResult = Apollo.MutationResult<JobFamilyDeleteMutation>;
export type JobFamilyDeleteMutationOptions = Apollo.BaseMutationOptions<JobFamilyDeleteMutation, JobFamilyDeleteMutationVariables>;
export const GetAllJobFamilyReferenceDataDocument = gql`
    query GetAllJobFamilyReferenceData {
  compBandsGroups {
    ...CompBandGroupRef
  }
  rangeWidthGroups {
    ...RangeWidthGroupRef
  }
  bonusStructureGroups {
    ...BonusStructureGroupRef
  }
  equityBandGroups {
    ...EquityBandGroupRef
  }
  jobFunctions {
    ...JobFunctionRef
  }
  radfordJobFamilies {
    ...RadfordJobFamilyRef
  }
}
    ${CompBandGroupRefFragmentDoc}
${RangeWidthGroupRefFragmentDoc}
${BonusStructureGroupRefFragmentDoc}
${EquityBandGroupRefFragmentDoc}
${JobFunctionRefFragmentDoc}
${RadfordJobFamilyRefFragmentDoc}`;

/**
 * __useGetAllJobFamilyReferenceDataQuery__
 *
 * To run a query within a React component, call `useGetAllJobFamilyReferenceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllJobFamilyReferenceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllJobFamilyReferenceDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllJobFamilyReferenceDataQuery(baseOptions?: Apollo.QueryHookOptions<GetAllJobFamilyReferenceDataQuery, GetAllJobFamilyReferenceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllJobFamilyReferenceDataQuery, GetAllJobFamilyReferenceDataQueryVariables>(GetAllJobFamilyReferenceDataDocument, options);
      }
export function useGetAllJobFamilyReferenceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllJobFamilyReferenceDataQuery, GetAllJobFamilyReferenceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllJobFamilyReferenceDataQuery, GetAllJobFamilyReferenceDataQueryVariables>(GetAllJobFamilyReferenceDataDocument, options);
        }
export type GetAllJobFamilyReferenceDataQueryHookResult = ReturnType<typeof useGetAllJobFamilyReferenceDataQuery>;
export type GetAllJobFamilyReferenceDataLazyQueryHookResult = ReturnType<typeof useGetAllJobFamilyReferenceDataLazyQuery>;
export type GetAllJobFamilyReferenceDataQueryResult = Apollo.QueryResult<GetAllJobFamilyReferenceDataQuery, GetAllJobFamilyReferenceDataQueryVariables>;
export const JobFunctionsDocument = gql`
    query JobFunctions {
  jobFunctions {
    ...JobFunction
  }
}
    ${JobFunctionFragmentDoc}`;

/**
 * __useJobFunctionsQuery__
 *
 * To run a query within a React component, call `useJobFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobFunctionsQuery(baseOptions?: Apollo.QueryHookOptions<JobFunctionsQuery, JobFunctionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobFunctionsQuery, JobFunctionsQueryVariables>(JobFunctionsDocument, options);
      }
export function useJobFunctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobFunctionsQuery, JobFunctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobFunctionsQuery, JobFunctionsQueryVariables>(JobFunctionsDocument, options);
        }
export type JobFunctionsQueryHookResult = ReturnType<typeof useJobFunctionsQuery>;
export type JobFunctionsLazyQueryHookResult = ReturnType<typeof useJobFunctionsLazyQuery>;
export type JobFunctionsQueryResult = Apollo.QueryResult<JobFunctionsQuery, JobFunctionsQueryVariables>;
export const JobFunctionSaveDocument = gql`
    mutation JobFunctionSave($request: JobFunctionSaveRequest!) {
  jobFunctionSave(request: $request) {
    ...JobFunction
  }
}
    ${JobFunctionFragmentDoc}`;
export type JobFunctionSaveMutationFn = Apollo.MutationFunction<JobFunctionSaveMutation, JobFunctionSaveMutationVariables>;

/**
 * __useJobFunctionSaveMutation__
 *
 * To run a mutation, you first call `useJobFunctionSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobFunctionSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobFunctionSaveMutation, { data, loading, error }] = useJobFunctionSaveMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useJobFunctionSaveMutation(baseOptions?: Apollo.MutationHookOptions<JobFunctionSaveMutation, JobFunctionSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobFunctionSaveMutation, JobFunctionSaveMutationVariables>(JobFunctionSaveDocument, options);
      }
export type JobFunctionSaveMutationHookResult = ReturnType<typeof useJobFunctionSaveMutation>;
export type JobFunctionSaveMutationResult = Apollo.MutationResult<JobFunctionSaveMutation>;
export type JobFunctionSaveMutationOptions = Apollo.BaseMutationOptions<JobFunctionSaveMutation, JobFunctionSaveMutationVariables>;
export const JobLevelsDocument = gql`
    query JobLevels($jobLevelsGetRequest: JobLevelsGetRequest!) {
  jobLevels(jobLevelsGetRequest: $jobLevelsGetRequest) {
    ...JobLevel
  }
}
    ${JobLevelFragmentDoc}`;

/**
 * __useJobLevelsQuery__
 *
 * To run a query within a React component, call `useJobLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobLevelsQuery({
 *   variables: {
 *      jobLevelsGetRequest: // value for 'jobLevelsGetRequest'
 *   },
 * });
 */
export function useJobLevelsQuery(baseOptions: Apollo.QueryHookOptions<JobLevelsQuery, JobLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobLevelsQuery, JobLevelsQueryVariables>(JobLevelsDocument, options);
      }
export function useJobLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobLevelsQuery, JobLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobLevelsQuery, JobLevelsQueryVariables>(JobLevelsDocument, options);
        }
export type JobLevelsQueryHookResult = ReturnType<typeof useJobLevelsQuery>;
export type JobLevelsLazyQueryHookResult = ReturnType<typeof useJobLevelsLazyQuery>;
export type JobLevelsQueryResult = Apollo.QueryResult<JobLevelsQuery, JobLevelsQueryVariables>;
export const JobLevelSaveDocument = gql`
    mutation JobLevelSave($request: JobLevelSaveManyRequest!) {
  jobLevelSave(request: $request) {
    ...JobLevel
  }
}
    ${JobLevelFragmentDoc}`;
export type JobLevelSaveMutationFn = Apollo.MutationFunction<JobLevelSaveMutation, JobLevelSaveMutationVariables>;

/**
 * __useJobLevelSaveMutation__
 *
 * To run a mutation, you first call `useJobLevelSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobLevelSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobLevelSaveMutation, { data, loading, error }] = useJobLevelSaveMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useJobLevelSaveMutation(baseOptions?: Apollo.MutationHookOptions<JobLevelSaveMutation, JobLevelSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobLevelSaveMutation, JobLevelSaveMutationVariables>(JobLevelSaveDocument, options);
      }
export type JobLevelSaveMutationHookResult = ReturnType<typeof useJobLevelSaveMutation>;
export type JobLevelSaveMutationResult = Apollo.MutationResult<JobLevelSaveMutation>;
export type JobLevelSaveMutationOptions = Apollo.BaseMutationOptions<JobLevelSaveMutation, JobLevelSaveMutationVariables>;
export const GlobalLevelsDocument = gql`
    query GlobalLevels {
  globalLevels {
    ...GlobalLevel
  }
}
    ${GlobalLevelFragmentDoc}`;

/**
 * __useGlobalLevelsQuery__
 *
 * To run a query within a React component, call `useGlobalLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalLevelsQuery(baseOptions?: Apollo.QueryHookOptions<GlobalLevelsQuery, GlobalLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalLevelsQuery, GlobalLevelsQueryVariables>(GlobalLevelsDocument, options);
      }
export function useGlobalLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalLevelsQuery, GlobalLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalLevelsQuery, GlobalLevelsQueryVariables>(GlobalLevelsDocument, options);
        }
export type GlobalLevelsQueryHookResult = ReturnType<typeof useGlobalLevelsQuery>;
export type GlobalLevelsLazyQueryHookResult = ReturnType<typeof useGlobalLevelsLazyQuery>;
export type GlobalLevelsQueryResult = Apollo.QueryResult<GlobalLevelsQuery, GlobalLevelsQueryVariables>;
export const GlobalLevelsSaveDocument = gql`
    mutation GlobalLevelsSave($request: GlobalLevelSaveManyRequest!) {
  globalLevelsSave(request: $request) {
    ...GlobalLevel
  }
}
    ${GlobalLevelFragmentDoc}`;
export type GlobalLevelsSaveMutationFn = Apollo.MutationFunction<GlobalLevelsSaveMutation, GlobalLevelsSaveMutationVariables>;

/**
 * __useGlobalLevelsSaveMutation__
 *
 * To run a mutation, you first call `useGlobalLevelsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGlobalLevelsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [globalLevelsSaveMutation, { data, loading, error }] = useGlobalLevelsSaveMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGlobalLevelsSaveMutation(baseOptions?: Apollo.MutationHookOptions<GlobalLevelsSaveMutation, GlobalLevelsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GlobalLevelsSaveMutation, GlobalLevelsSaveMutationVariables>(GlobalLevelsSaveDocument, options);
      }
export type GlobalLevelsSaveMutationHookResult = ReturnType<typeof useGlobalLevelsSaveMutation>;
export type GlobalLevelsSaveMutationResult = Apollo.MutationResult<GlobalLevelsSaveMutation>;
export type GlobalLevelsSaveMutationOptions = Apollo.BaseMutationOptions<GlobalLevelsSaveMutation, GlobalLevelsSaveMutationVariables>;
export const JobsSaveDocument = gql`
    mutation JobsSave($jobSaveManyRequest: JobSaveManyRequest!) {
  jobsSave(jobSaveManyRequest: $jobSaveManyRequest) {
    ...Job
  }
}
    ${JobFragmentDoc}`;
export type JobsSaveMutationFn = Apollo.MutationFunction<JobsSaveMutation, JobsSaveMutationVariables>;

/**
 * __useJobsSaveMutation__
 *
 * To run a mutation, you first call `useJobsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobsSaveMutation, { data, loading, error }] = useJobsSaveMutation({
 *   variables: {
 *      jobSaveManyRequest: // value for 'jobSaveManyRequest'
 *   },
 * });
 */
export function useJobsSaveMutation(baseOptions?: Apollo.MutationHookOptions<JobsSaveMutation, JobsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobsSaveMutation, JobsSaveMutationVariables>(JobsSaveDocument, options);
      }
export type JobsSaveMutationHookResult = ReturnType<typeof useJobsSaveMutation>;
export type JobsSaveMutationResult = Apollo.MutationResult<JobsSaveMutation>;
export type JobsSaveMutationOptions = Apollo.BaseMutationOptions<JobsSaveMutation, JobsSaveMutationVariables>;
export const JobsDocument = gql`
    query Jobs($jobGetRequest: JobGetRequest!) {
  jobs(jobGetRequest: $jobGetRequest) {
    ...Job
  }
}
    ${JobFragmentDoc}`;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      jobGetRequest: // value for 'jobGetRequest'
 *   },
 * });
 */
export function useJobsQuery(baseOptions: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const MultiZipcodeSearchDocument = gql`
    query MultiZipcodeSearch($multiZipcodeSearchRequest: MultiZipcodeSearchRequest!) {
  searchMultiZipcodes(multiZipcodeSearchRequest: $multiZipcodeSearchRequest) {
    city
    csa
    state
    zipcode
    alamereLocationId
    lat
    long
    tierLabel
    tierNumber
  }
}
    `;

/**
 * __useMultiZipcodeSearchQuery__
 *
 * To run a query within a React component, call `useMultiZipcodeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiZipcodeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiZipcodeSearchQuery({
 *   variables: {
 *      multiZipcodeSearchRequest: // value for 'multiZipcodeSearchRequest'
 *   },
 * });
 */
export function useMultiZipcodeSearchQuery(baseOptions: Apollo.QueryHookOptions<MultiZipcodeSearchQuery, MultiZipcodeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MultiZipcodeSearchQuery, MultiZipcodeSearchQueryVariables>(MultiZipcodeSearchDocument, options);
      }
export function useMultiZipcodeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MultiZipcodeSearchQuery, MultiZipcodeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MultiZipcodeSearchQuery, MultiZipcodeSearchQueryVariables>(MultiZipcodeSearchDocument, options);
        }
export type MultiZipcodeSearchQueryHookResult = ReturnType<typeof useMultiZipcodeSearchQuery>;
export type MultiZipcodeSearchLazyQueryHookResult = ReturnType<typeof useMultiZipcodeSearchLazyQuery>;
export type MultiZipcodeSearchQueryResult = Apollo.QueryResult<MultiZipcodeSearchQuery, MultiZipcodeSearchQueryVariables>;
export const RadfordJobFamiliesDocument = gql`
    query RadfordJobFamilies {
  radfordJobFamilies {
    ...RadfordJobFamily
  }
}
    ${RadfordJobFamilyFragmentDoc}`;

/**
 * __useRadfordJobFamiliesQuery__
 *
 * To run a query within a React component, call `useRadfordJobFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRadfordJobFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRadfordJobFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRadfordJobFamiliesQuery(baseOptions?: Apollo.QueryHookOptions<RadfordJobFamiliesQuery, RadfordJobFamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RadfordJobFamiliesQuery, RadfordJobFamiliesQueryVariables>(RadfordJobFamiliesDocument, options);
      }
export function useRadfordJobFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RadfordJobFamiliesQuery, RadfordJobFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RadfordJobFamiliesQuery, RadfordJobFamiliesQueryVariables>(RadfordJobFamiliesDocument, options);
        }
export type RadfordJobFamiliesQueryHookResult = ReturnType<typeof useRadfordJobFamiliesQuery>;
export type RadfordJobFamiliesLazyQueryHookResult = ReturnType<typeof useRadfordJobFamiliesLazyQuery>;
export type RadfordJobFamiliesQueryResult = Apollo.QueryResult<RadfordJobFamiliesQuery, RadfordJobFamiliesQueryVariables>;
export const RadfordJobFamiliesSearchDocument = gql`
    query RadfordJobFamiliesSearch($request: RadfordJobFamiliesSearchRequest!) {
  radfordJobFamiliesSearch(request: $request) {
    ...RadfordJobFamily
  }
}
    ${RadfordJobFamilyFragmentDoc}`;

/**
 * __useRadfordJobFamiliesSearchQuery__
 *
 * To run a query within a React component, call `useRadfordJobFamiliesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRadfordJobFamiliesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRadfordJobFamiliesSearchQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRadfordJobFamiliesSearchQuery(baseOptions: Apollo.QueryHookOptions<RadfordJobFamiliesSearchQuery, RadfordJobFamiliesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RadfordJobFamiliesSearchQuery, RadfordJobFamiliesSearchQueryVariables>(RadfordJobFamiliesSearchDocument, options);
      }
export function useRadfordJobFamiliesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RadfordJobFamiliesSearchQuery, RadfordJobFamiliesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RadfordJobFamiliesSearchQuery, RadfordJobFamiliesSearchQueryVariables>(RadfordJobFamiliesSearchDocument, options);
        }
export type RadfordJobFamiliesSearchQueryHookResult = ReturnType<typeof useRadfordJobFamiliesSearchQuery>;
export type RadfordJobFamiliesSearchLazyQueryHookResult = ReturnType<typeof useRadfordJobFamiliesSearchLazyQuery>;
export type RadfordJobFamiliesSearchQueryResult = Apollo.QueryResult<RadfordJobFamiliesSearchQuery, RadfordJobFamiliesSearchQueryVariables>;
export const RangeWidthGroupsDocument = gql`
    query RangeWidthGroups {
  rangeWidthGroups {
    ...RangeWidthGroup
  }
}
    ${RangeWidthGroupFragmentDoc}`;

/**
 * __useRangeWidthGroupsQuery__
 *
 * To run a query within a React component, call `useRangeWidthGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangeWidthGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangeWidthGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRangeWidthGroupsQuery(baseOptions?: Apollo.QueryHookOptions<RangeWidthGroupsQuery, RangeWidthGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RangeWidthGroupsQuery, RangeWidthGroupsQueryVariables>(RangeWidthGroupsDocument, options);
      }
export function useRangeWidthGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RangeWidthGroupsQuery, RangeWidthGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RangeWidthGroupsQuery, RangeWidthGroupsQueryVariables>(RangeWidthGroupsDocument, options);
        }
export type RangeWidthGroupsQueryHookResult = ReturnType<typeof useRangeWidthGroupsQuery>;
export type RangeWidthGroupsLazyQueryHookResult = ReturnType<typeof useRangeWidthGroupsLazyQuery>;
export type RangeWidthGroupsQueryResult = Apollo.QueryResult<RangeWidthGroupsQuery, RangeWidthGroupsQueryVariables>;
export const RangeWidthGroupDocument = gql`
    query RangeWidthGroup($rangeWidthGroupId: Float!) {
  rangeWidthGroup(id: $rangeWidthGroupId) {
    ...RangeWidthGroup
  }
}
    ${RangeWidthGroupFragmentDoc}`;

/**
 * __useRangeWidthGroupQuery__
 *
 * To run a query within a React component, call `useRangeWidthGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangeWidthGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangeWidthGroupQuery({
 *   variables: {
 *      rangeWidthGroupId: // value for 'rangeWidthGroupId'
 *   },
 * });
 */
export function useRangeWidthGroupQuery(baseOptions: Apollo.QueryHookOptions<RangeWidthGroupQuery, RangeWidthGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RangeWidthGroupQuery, RangeWidthGroupQueryVariables>(RangeWidthGroupDocument, options);
      }
export function useRangeWidthGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RangeWidthGroupQuery, RangeWidthGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RangeWidthGroupQuery, RangeWidthGroupQueryVariables>(RangeWidthGroupDocument, options);
        }
export type RangeWidthGroupQueryHookResult = ReturnType<typeof useRangeWidthGroupQuery>;
export type RangeWidthGroupLazyQueryHookResult = ReturnType<typeof useRangeWidthGroupLazyQuery>;
export type RangeWidthGroupQueryResult = Apollo.QueryResult<RangeWidthGroupQuery, RangeWidthGroupQueryVariables>;
export const RangeWidthSaveDocument = gql`
    mutation RangeWidthSave($rangeWidthSaveRequest: RangeWidthSaveManyRequest!) {
  rangeWidthSave(request: $rangeWidthSaveRequest) {
    id
    rangeWidthGroupId
    level
  }
}
    `;
export type RangeWidthSaveMutationFn = Apollo.MutationFunction<RangeWidthSaveMutation, RangeWidthSaveMutationVariables>;

/**
 * __useRangeWidthSaveMutation__
 *
 * To run a mutation, you first call `useRangeWidthSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRangeWidthSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rangeWidthSaveMutation, { data, loading, error }] = useRangeWidthSaveMutation({
 *   variables: {
 *      rangeWidthSaveRequest: // value for 'rangeWidthSaveRequest'
 *   },
 * });
 */
export function useRangeWidthSaveMutation(baseOptions?: Apollo.MutationHookOptions<RangeWidthSaveMutation, RangeWidthSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RangeWidthSaveMutation, RangeWidthSaveMutationVariables>(RangeWidthSaveDocument, options);
      }
export type RangeWidthSaveMutationHookResult = ReturnType<typeof useRangeWidthSaveMutation>;
export type RangeWidthSaveMutationResult = Apollo.MutationResult<RangeWidthSaveMutation>;
export type RangeWidthSaveMutationOptions = Apollo.BaseMutationOptions<RangeWidthSaveMutation, RangeWidthSaveMutationVariables>;
export const RangeWidthGroupsSaveDocument = gql`
    mutation RangeWidthGroupsSave($rangeWidthGroupSaveManyRequest: RangeWidthGroupSaveManyRequest!) {
  rangeWidthGroupsSave(request: $rangeWidthGroupSaveManyRequest) {
    id
  }
}
    `;
export type RangeWidthGroupsSaveMutationFn = Apollo.MutationFunction<RangeWidthGroupsSaveMutation, RangeWidthGroupsSaveMutationVariables>;

/**
 * __useRangeWidthGroupsSaveMutation__
 *
 * To run a mutation, you first call `useRangeWidthGroupsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRangeWidthGroupsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rangeWidthGroupsSaveMutation, { data, loading, error }] = useRangeWidthGroupsSaveMutation({
 *   variables: {
 *      rangeWidthGroupSaveManyRequest: // value for 'rangeWidthGroupSaveManyRequest'
 *   },
 * });
 */
export function useRangeWidthGroupsSaveMutation(baseOptions?: Apollo.MutationHookOptions<RangeWidthGroupsSaveMutation, RangeWidthGroupsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RangeWidthGroupsSaveMutation, RangeWidthGroupsSaveMutationVariables>(RangeWidthGroupsSaveDocument, options);
      }
export type RangeWidthGroupsSaveMutationHookResult = ReturnType<typeof useRangeWidthGroupsSaveMutation>;
export type RangeWidthGroupsSaveMutationResult = Apollo.MutationResult<RangeWidthGroupsSaveMutation>;
export type RangeWidthGroupsSaveMutationOptions = Apollo.BaseMutationOptions<RangeWidthGroupsSaveMutation, RangeWidthGroupsSaveMutationVariables>;
export const RangeWidthGroupDeleteDocument = gql`
    mutation RangeWidthGroupDelete($rangeWidthGroupDeleteId: Float!) {
  rangeWidthGroupDelete(id: $rangeWidthGroupDeleteId)
}
    `;
export type RangeWidthGroupDeleteMutationFn = Apollo.MutationFunction<RangeWidthGroupDeleteMutation, RangeWidthGroupDeleteMutationVariables>;

/**
 * __useRangeWidthGroupDeleteMutation__
 *
 * To run a mutation, you first call `useRangeWidthGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRangeWidthGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rangeWidthGroupDeleteMutation, { data, loading, error }] = useRangeWidthGroupDeleteMutation({
 *   variables: {
 *      rangeWidthGroupDeleteId: // value for 'rangeWidthGroupDeleteId'
 *   },
 * });
 */
export function useRangeWidthGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<RangeWidthGroupDeleteMutation, RangeWidthGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RangeWidthGroupDeleteMutation, RangeWidthGroupDeleteMutationVariables>(RangeWidthGroupDeleteDocument, options);
      }
export type RangeWidthGroupDeleteMutationHookResult = ReturnType<typeof useRangeWidthGroupDeleteMutation>;
export type RangeWidthGroupDeleteMutationResult = Apollo.MutationResult<RangeWidthGroupDeleteMutation>;
export type RangeWidthGroupDeleteMutationOptions = Apollo.BaseMutationOptions<RangeWidthGroupDeleteMutation, RangeWidthGroupDeleteMutationVariables>;
export const RequestJitaDocument = gql`
    mutation RequestJita($jitaRequest: JitaRequest!) {
  requestJita(jitaRequest: $jitaRequest)
}
    `;
export type RequestJitaMutationFn = Apollo.MutationFunction<RequestJitaMutation, RequestJitaMutationVariables>;

/**
 * __useRequestJitaMutation__
 *
 * To run a mutation, you first call `useRequestJitaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestJitaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestJitaMutation, { data, loading, error }] = useRequestJitaMutation({
 *   variables: {
 *      jitaRequest: // value for 'jitaRequest'
 *   },
 * });
 */
export function useRequestJitaMutation(baseOptions?: Apollo.MutationHookOptions<RequestJitaMutation, RequestJitaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestJitaMutation, RequestJitaMutationVariables>(RequestJitaDocument, options);
      }
export type RequestJitaMutationHookResult = ReturnType<typeof useRequestJitaMutation>;
export type RequestJitaMutationResult = Apollo.MutationResult<RequestJitaMutation>;
export type RequestJitaMutationOptions = Apollo.BaseMutationOptions<RequestJitaMutation, RequestJitaMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPasswordRequest: ResetPasswordRequest!) {
  resetPassword(resetPasswordRequest: $resetPasswordRequest) {
    email
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordRequest: // value for 'resetPasswordRequest'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SaveTierConfigurationDocument = gql`
    mutation SaveTierConfiguration($tierConfigJson: String!, $notes: String) {
  saveTierConfiguration(tierConfigJson: $tierConfigJson, notes: $notes) {
    createdAt
    tierConfigJson
    updatedAt
    workspaceId
    notes
  }
}
    `;
export type SaveTierConfigurationMutationFn = Apollo.MutationFunction<SaveTierConfigurationMutation, SaveTierConfigurationMutationVariables>;

/**
 * __useSaveTierConfigurationMutation__
 *
 * To run a mutation, you first call `useSaveTierConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTierConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTierConfigurationMutation, { data, loading, error }] = useSaveTierConfigurationMutation({
 *   variables: {
 *      tierConfigJson: // value for 'tierConfigJson'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useSaveTierConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<SaveTierConfigurationMutation, SaveTierConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTierConfigurationMutation, SaveTierConfigurationMutationVariables>(SaveTierConfigurationDocument, options);
      }
export type SaveTierConfigurationMutationHookResult = ReturnType<typeof useSaveTierConfigurationMutation>;
export type SaveTierConfigurationMutationResult = Apollo.MutationResult<SaveTierConfigurationMutation>;
export type SaveTierConfigurationMutationOptions = Apollo.BaseMutationOptions<SaveTierConfigurationMutation, SaveTierConfigurationMutationVariables>;
export const SearchWorkspacesDocument = gql`
    query SearchWorkspaces($searchWorkspacesPagedRequest: SearchWorkspacesPagedRequest!) {
  searchWorkspaces(searchWorkspacesPagedRequest: $searchWorkspacesPagedRequest) {
    edges {
      cursor
      node {
        domain
        id
        name
        createdAt
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      totalCount
    }
  }
}
    `;

/**
 * __useSearchWorkspacesQuery__
 *
 * To run a query within a React component, call `useSearchWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkspacesQuery({
 *   variables: {
 *      searchWorkspacesPagedRequest: // value for 'searchWorkspacesPagedRequest'
 *   },
 * });
 */
export function useSearchWorkspacesQuery(baseOptions: Apollo.QueryHookOptions<SearchWorkspacesQuery, SearchWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchWorkspacesQuery, SearchWorkspacesQueryVariables>(SearchWorkspacesDocument, options);
      }
export function useSearchWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchWorkspacesQuery, SearchWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchWorkspacesQuery, SearchWorkspacesQueryVariables>(SearchWorkspacesDocument, options);
        }
export type SearchWorkspacesQueryHookResult = ReturnType<typeof useSearchWorkspacesQuery>;
export type SearchWorkspacesLazyQueryHookResult = ReturnType<typeof useSearchWorkspacesLazyQuery>;
export type SearchWorkspacesQueryResult = Apollo.QueryResult<SearchWorkspacesQuery, SearchWorkspacesQueryVariables>;
export const SettingsDocument = gql`
    query Settings($request: SettingsGetManyRequest!) {
  settings(request: $request) {
    ...Setting
  }
}
    ${SettingFragmentDoc}`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSettingsQuery(baseOptions: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const SettingsSaveDocument = gql`
    mutation SettingsSave($settingsSaveRequest: SettingsSaveManyRequest!) {
  settingsSave(request: $settingsSaveRequest)
}
    `;
export type SettingsSaveMutationFn = Apollo.MutationFunction<SettingsSaveMutation, SettingsSaveMutationVariables>;

/**
 * __useSettingsSaveMutation__
 *
 * To run a mutation, you first call `useSettingsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsSaveMutation, { data, loading, error }] = useSettingsSaveMutation({
 *   variables: {
 *      settingsSaveRequest: // value for 'settingsSaveRequest'
 *   },
 * });
 */
export function useSettingsSaveMutation(baseOptions?: Apollo.MutationHookOptions<SettingsSaveMutation, SettingsSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingsSaveMutation, SettingsSaveMutationVariables>(SettingsSaveDocument, options);
      }
export type SettingsSaveMutationHookResult = ReturnType<typeof useSettingsSaveMutation>;
export type SettingsSaveMutationResult = Apollo.MutationResult<SettingsSaveMutation>;
export type SettingsSaveMutationOptions = Apollo.BaseMutationOptions<SettingsSaveMutation, SettingsSaveMutationVariables>;
export const SignupAndCreateNewWorkspaceDocument = gql`
    mutation SignupAndCreateNewWorkspace($createUserRequest: CreateUserAndWorkspaceRequest!) {
  signupAndCreateNewWorkspace(createUserRequest: $createUserRequest) {
    createdAt
    email
    firstName
    id
    lastName
  }
}
    `;
export type SignupAndCreateNewWorkspaceMutationFn = Apollo.MutationFunction<SignupAndCreateNewWorkspaceMutation, SignupAndCreateNewWorkspaceMutationVariables>;

/**
 * __useSignupAndCreateNewWorkspaceMutation__
 *
 * To run a mutation, you first call `useSignupAndCreateNewWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupAndCreateNewWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupAndCreateNewWorkspaceMutation, { data, loading, error }] = useSignupAndCreateNewWorkspaceMutation({
 *   variables: {
 *      createUserRequest: // value for 'createUserRequest'
 *   },
 * });
 */
export function useSignupAndCreateNewWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<SignupAndCreateNewWorkspaceMutation, SignupAndCreateNewWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupAndCreateNewWorkspaceMutation, SignupAndCreateNewWorkspaceMutationVariables>(SignupAndCreateNewWorkspaceDocument, options);
      }
export type SignupAndCreateNewWorkspaceMutationHookResult = ReturnType<typeof useSignupAndCreateNewWorkspaceMutation>;
export type SignupAndCreateNewWorkspaceMutationResult = Apollo.MutationResult<SignupAndCreateNewWorkspaceMutation>;
export type SignupAndCreateNewWorkspaceMutationOptions = Apollo.BaseMutationOptions<SignupAndCreateNewWorkspaceMutation, SignupAndCreateNewWorkspaceMutationVariables>;
export const SignupFromInvitationDocument = gql`
    mutation SignupFromInvitation($createUserRequest: CreateUserInWorkspaceRequest!) {
  signupFromInvitation(createUserRequest: $createUserRequest) {
    id
  }
}
    `;
export type SignupFromInvitationMutationFn = Apollo.MutationFunction<SignupFromInvitationMutation, SignupFromInvitationMutationVariables>;

/**
 * __useSignupFromInvitationMutation__
 *
 * To run a mutation, you first call `useSignupFromInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupFromInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupFromInvitationMutation, { data, loading, error }] = useSignupFromInvitationMutation({
 *   variables: {
 *      createUserRequest: // value for 'createUserRequest'
 *   },
 * });
 */
export function useSignupFromInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SignupFromInvitationMutation, SignupFromInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupFromInvitationMutation, SignupFromInvitationMutationVariables>(SignupFromInvitationDocument, options);
      }
export type SignupFromInvitationMutationHookResult = ReturnType<typeof useSignupFromInvitationMutation>;
export type SignupFromInvitationMutationResult = Apollo.MutationResult<SignupFromInvitationMutation>;
export type SignupFromInvitationMutationOptions = Apollo.BaseMutationOptions<SignupFromInvitationMutation, SignupFromInvitationMutationVariables>;
export const TierConfigDocument = gql`
    query TierConfig {
  tierConfig {
    createdAt
    tierConfigJson
    updatedAt
    notes
  }
}
    `;

/**
 * __useTierConfigQuery__
 *
 * To run a query within a React component, call `useTierConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useTierConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTierConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useTierConfigQuery(baseOptions?: Apollo.QueryHookOptions<TierConfigQuery, TierConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TierConfigQuery, TierConfigQueryVariables>(TierConfigDocument, options);
      }
export function useTierConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TierConfigQuery, TierConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TierConfigQuery, TierConfigQueryVariables>(TierConfigDocument, options);
        }
export type TierConfigQueryHookResult = ReturnType<typeof useTierConfigQuery>;
export type TierConfigLazyQueryHookResult = ReturnType<typeof useTierConfigLazyQuery>;
export type TierConfigQueryResult = Apollo.QueryResult<TierConfigQuery, TierConfigQueryVariables>;
export const UpdateCustomRoleDocument = gql`
    mutation UpdateCustomRole($updateCustomRoleRequest: UpdateCustomRoleRequest!) {
  updateCustomRole(updateCustomRoleRequest: $updateCustomRoleRequest)
}
    `;
export type UpdateCustomRoleMutationFn = Apollo.MutationFunction<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>;

/**
 * __useUpdateCustomRoleMutation__
 *
 * To run a mutation, you first call `useUpdateCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomRoleMutation, { data, loading, error }] = useUpdateCustomRoleMutation({
 *   variables: {
 *      updateCustomRoleRequest: // value for 'updateCustomRoleRequest'
 *   },
 * });
 */
export function useUpdateCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>(UpdateCustomRoleDocument, options);
      }
export type UpdateCustomRoleMutationHookResult = ReturnType<typeof useUpdateCustomRoleMutation>;
export type UpdateCustomRoleMutationResult = Apollo.MutationResult<UpdateCustomRoleMutation>;
export type UpdateCustomRoleMutationOptions = Apollo.BaseMutationOptions<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>;
export const UpdateMembershipDocument = gql`
    mutation UpdateMembership($updateMembershipRequest: UpdateMembershipRequest!) {
  updateMembership(updateMembershipRequest: $updateMembershipRequest)
}
    `;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      updateMembershipRequest: // value for 'updateMembershipRequest'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const UpdateMembershipLevelDocument = gql`
    mutation UpdateMembershipLevel($updateMembershipLevelRequest: UpdateMembershipLevelRequest!) {
  updateMembershipLevel(
    updateMembershipLevelRequest: $updateMembershipLevelRequest
  )
}
    `;
export type UpdateMembershipLevelMutationFn = Apollo.MutationFunction<UpdateMembershipLevelMutation, UpdateMembershipLevelMutationVariables>;

/**
 * __useUpdateMembershipLevelMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipLevelMutation, { data, loading, error }] = useUpdateMembershipLevelMutation({
 *   variables: {
 *      updateMembershipLevelRequest: // value for 'updateMembershipLevelRequest'
 *   },
 * });
 */
export function useUpdateMembershipLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipLevelMutation, UpdateMembershipLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipLevelMutation, UpdateMembershipLevelMutationVariables>(UpdateMembershipLevelDocument, options);
      }
export type UpdateMembershipLevelMutationHookResult = ReturnType<typeof useUpdateMembershipLevelMutation>;
export type UpdateMembershipLevelMutationResult = Apollo.MutationResult<UpdateMembershipLevelMutation>;
export type UpdateMembershipLevelMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipLevelMutation, UpdateMembershipLevelMutationVariables>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($updateWorkspaceRequest: UpdateWorkspaceRequest!) {
  updateWorkspace(updateWorkspaceRequest: $updateWorkspaceRequest)
}
    `;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      updateWorkspaceRequest: // value for 'updateWorkspaceRequest'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options);
      }
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const WorkspaceMembershipsAndInvitationsDocument = gql`
    query WorkspaceMembershipsAndInvitations {
  workspace {
    invitations {
      ...InvitationWithCustomRole
    }
    memberships {
      ...MembershipWithCustomRole
    }
  }
}
    ${InvitationWithCustomRoleFragmentDoc}
${MembershipWithCustomRoleFragmentDoc}`;

/**
 * __useWorkspaceMembershipsAndInvitationsQuery__
 *
 * To run a query within a React component, call `useWorkspaceMembershipsAndInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMembershipsAndInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMembershipsAndInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceMembershipsAndInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceMembershipsAndInvitationsQuery, WorkspaceMembershipsAndInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceMembershipsAndInvitationsQuery, WorkspaceMembershipsAndInvitationsQueryVariables>(WorkspaceMembershipsAndInvitationsDocument, options);
      }
export function useWorkspaceMembershipsAndInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceMembershipsAndInvitationsQuery, WorkspaceMembershipsAndInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceMembershipsAndInvitationsQuery, WorkspaceMembershipsAndInvitationsQueryVariables>(WorkspaceMembershipsAndInvitationsDocument, options);
        }
export type WorkspaceMembershipsAndInvitationsQueryHookResult = ReturnType<typeof useWorkspaceMembershipsAndInvitationsQuery>;
export type WorkspaceMembershipsAndInvitationsLazyQueryHookResult = ReturnType<typeof useWorkspaceMembershipsAndInvitationsLazyQuery>;
export type WorkspaceMembershipsAndInvitationsQueryResult = Apollo.QueryResult<WorkspaceMembershipsAndInvitationsQuery, WorkspaceMembershipsAndInvitationsQueryVariables>;
export const GetAutoSignupWorkspaceDocument = gql`
    query GetAutoSignupWorkspace($request: CanAutoSignupRequest!) {
  getAutoSignupWorkspace(request: $request) {
    ...WorkspaceIdAndName
  }
}
    ${WorkspaceIdAndNameFragmentDoc}`;

/**
 * __useGetAutoSignupWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetAutoSignupWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoSignupWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoSignupWorkspaceQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAutoSignupWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<GetAutoSignupWorkspaceQuery, GetAutoSignupWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoSignupWorkspaceQuery, GetAutoSignupWorkspaceQueryVariables>(GetAutoSignupWorkspaceDocument, options);
      }
export function useGetAutoSignupWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoSignupWorkspaceQuery, GetAutoSignupWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoSignupWorkspaceQuery, GetAutoSignupWorkspaceQueryVariables>(GetAutoSignupWorkspaceDocument, options);
        }
export type GetAutoSignupWorkspaceQueryHookResult = ReturnType<typeof useGetAutoSignupWorkspaceQuery>;
export type GetAutoSignupWorkspaceLazyQueryHookResult = ReturnType<typeof useGetAutoSignupWorkspaceLazyQuery>;
export type GetAutoSignupWorkspaceQueryResult = Apollo.QueryResult<GetAutoSignupWorkspaceQuery, GetAutoSignupWorkspaceQueryVariables>;
export const ZipcodeSearchDocument = gql`
    query ZipcodeSearch($zipcodeSearchRequest: ZipcodeSearchRequest!) {
  searchZipcode(zipcodeSearchRequest: $zipcodeSearchRequest) {
    city
    csa
    state
    zipcode
    alamereLocationId
    lat
    long
  }
}
    `;

/**
 * __useZipcodeSearchQuery__
 *
 * To run a query within a React component, call `useZipcodeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useZipcodeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZipcodeSearchQuery({
 *   variables: {
 *      zipcodeSearchRequest: // value for 'zipcodeSearchRequest'
 *   },
 * });
 */
export function useZipcodeSearchQuery(baseOptions: Apollo.QueryHookOptions<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>(ZipcodeSearchDocument, options);
      }
export function useZipcodeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>(ZipcodeSearchDocument, options);
        }
export type ZipcodeSearchQueryHookResult = ReturnType<typeof useZipcodeSearchQuery>;
export type ZipcodeSearchLazyQueryHookResult = ReturnType<typeof useZipcodeSearchLazyQuery>;
export type ZipcodeSearchQueryResult = Apollo.QueryResult<ZipcodeSearchQuery, ZipcodeSearchQueryVariables>;