import { JobGroup } from '@alamere/core';
import {
  JobFamilyFragment,
  RadfordJobFamilyFragment,
} from '@alamere/generated-graphql-types';
import { TableRow } from '@mui/material';
import { memo, useState } from 'react';
import { Code } from './cells/Code';
import { Delete } from './cells/Delete';
import { DynamicParent } from './cells/DynamicParent';
import { IgnoreGeoDiff } from './cells/IgnoreGeoDiff';
import { JobFunction } from './cells/JobFunction';
import { Name } from './cells/Name';
import { RadfordMatch } from './cells/RadfordMatch';
import { OnItemChangeFunction } from './types';
import { GroupSelector } from './cells/GroupSelector';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
  loadRadfordMatch: (
    code: string,
    jobGroup: JobGroup
  ) => Promise<RadfordJobFamilyFragment | null>;
  loadingRadford: boolean;
  duplicateFields?: { [key: string]: { code?: boolean; name?: boolean } };
}

const GROUP_TYPES = [
  'compBandGroup',
  'rangeWidthGroup',
  'bonusStructureGroup',
  'equityBandGroup',
] as const;

const JOB_GROUPS = [
  JobGroup.Executive,
  JobGroup.Management,
  JobGroup.Professional,
  JobGroup.TechnicalScientific,
  JobGroup.Support,
] as const;

export const Row = memo(
  ({
    editing,
    jobFamily,
    loadRadfordMatch,
    loadingRadford,
    onItemChange,
    duplicateFields,
  }: Props) => {
    const [hovered, setHovered] = useState(false);

    // Get the specific error flags for this job family
    const duplicateInfo = duplicateFields?.[jobFamily.id];
    const hasCodeError = duplicateInfo?.code === true;
    const hasNameError = duplicateInfo?.name === true;

    return (
      <TableRow
        hover
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Code
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          error={hasCodeError}
        />
        <JobFunction
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        <Name
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
          error={hasNameError}
        />
        <IgnoreGeoDiff
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />
        {GROUP_TYPES.map((groupType) => (
          <GroupSelector
            key={groupType}
            editing={editing}
            jobFamily={jobFamily}
            onItemChange={onItemChange}
            groupType={groupType}
          />
        ))}
        <DynamicParent
          editing={editing}
          jobFamily={jobFamily}
          onItemChange={onItemChange}
        />

        {JOB_GROUPS.map((jobGroup) => (
          <RadfordMatch
            key={jobGroup}
            loading={loadingRadford}
            editing={editing}
            jobFamily={jobFamily}
            onItemChange={onItemChange}
            jobGroup={jobGroup}
            loadRadfordMatch={loadRadfordMatch}
          />
        ))}

        <Delete
          editing={editing}
          jobFamily={jobFamily}
          hovered={hovered}
          onItemChange={onItemChange}
        />
      </TableRow>
    );
  }
);
