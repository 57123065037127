import { PermissionSets, USER_ROLES_WITH_METADATA, Scope } from '@alamere/core';
import { Alert, Box, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ScopeTable } from '../../components/ScopeTable';

export function DefaultRoleDetailPage() {
  const { defaultRoleId } = useParams<{ defaultRoleId: string }>();
  const navigate = useNavigate();

  const role =
    USER_ROLES_WITH_METADATA[
      defaultRoleId as keyof typeof USER_ROLES_WITH_METADATA
    ];

  if (!role) {
    return <Alert severity="error">{t('Role not found')}</Alert>;
  }

  const roleScopes = PermissionSets[role.id];
  const mappedRoleScopes = Array.from(roleScopes).reduce(
    (prevMap, scope) => ({ ...prevMap, [scope]: true }),
    {} as Record<Scope, boolean>
  );

  return (
    <Box sx={{ p: 4 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="title">{role.name}</Typography>
        <IconButton
          onClick={() => navigate('../permissions')}
          aria-label={t('Back to permissions')}
        >
          <ArrowBackIcon />
        </IconButton>
      </Stack>
      <Alert severity="info" sx={{ mb: 2 }}>
        {t('This is a default role and is not editable')}
      </Alert>
      <ScopeTable
        currentScopes={mappedRoleScopes}
        areAllSwitchesDisabled={true}
      />
    </Box>
  );
}
