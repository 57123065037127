import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  Box,
  Alert,
  Paper,
  styled,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Divider,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState, useRef, useCallback } from 'react';
import { t } from 'i18next';
import {
  parseAndValidateCsv,
  ValidationErrorMap,
  CsvValidationResult,
  CsvUploadStep,
} from './helpers/csvUpload.helpers';

const UploadBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  transition: 'border-color 0.2s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
  marginBottom: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '150px',
}));

export interface CsvHeader {
  key: string;
  label: string;
  required?: boolean;
  type?: 'string' | 'number' | 'percentage';
}

export type CsvValue = string | number | null;

export interface CsvUploadsModalProps {
  // Page info
  pageName: string;

  // CSV configuration
  headers: CsvHeader[];
  exampleContent: string;
  templateFileName: string;

  // Processing functions
  validateRow?: (
    row: Record<string, string>,
    rowIndex: number
  ) => string | null;
  processData: (
    processedData: Record<string, Array<Record<string, CsvValue>>>
  ) => Promise<void>;

  // Pre-validation function for more complex validations (like Radford matches)
  preValidateData?: (result: CsvValidationResult) => Promise<{
    warnings: string[];
    isValid: boolean;
  }>;

  // Optional configuration
  groupByField?: string;
  showGroupCounts?: boolean;

  // Custom button rendering
  renderButton?: (onClick: () => void) => React.ReactNode;
}

export const CsvUploadsModal = ({
  pageName,
  headers,
  exampleContent,
  templateFileName,
  validateRow,
  processData,
  preValidateData,
  groupByField = headers[0]?.key,
  showGroupCounts = true,
  renderButton,
}: CsvUploadsModalProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<CsvUploadStep>(
    CsvUploadStep.Upload
  );
  const [fileName, setFileName] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrorMap>(
    {}
  );
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [csvValidationResult, setCsvValidationResult] =
    useState<CsvValidationResult | null>(null);
  const [totalRows, setTotalRows] = useState(0);

  const handleOpen = () => {
    setOpen(true);
    setError('');
    setLoading(false);
    setValidationErrors({});
    setValidationWarnings([]);
    setActiveStep(CsvUploadStep.Upload);
    setFileName('');
    setCsvValidationResult(null);
    setTotalRows(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadSample = () => {
    const blob = new Blob([exampleContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', templateFileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleBulkUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
        setError(t('csvUpload.invalidFileType', 'Please upload a CSV file'));
        return;
      }
      processFile(file);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      setError(t('csvUpload.invalidFileType', 'Please upload a CSV file'));
      return;
    }

    processFile(file);
  };

  const processFile = async (file: File) => {
    setError('');
    setLoading(true);
    setValidationErrors({});
    setValidationWarnings([]);

    try {
      // Parse and validate the CSV using the helper function
      const result = await parseAndValidateCsv(
        file,
        headers,
        validateRow,
        groupByField
      );

      setCsvValidationResult(result);

      // Calculate total rows
      let rowCount = 0;
      Object.values(result.groupedData).forEach((rows) => {
        rowCount += rows.length;
      });
      setTotalRows(rowCount);

      if (!result.valid) {
        setValidationErrors(result.errorMap);
      }

      // If we have a pre-validation function, run it to check for warnings
      if (preValidateData && result.valid) {
        const preValidationResult = await preValidateData(result);
        setValidationWarnings(preValidationResult.warnings);

        // Update valid state based on pre-validation
        if (!preValidationResult.isValid) {
          result.valid = false;
        }
      }

      // Move to next step regardless of validation result
      // Errors will be displayed in the review step
      setActiveStep(CsvUploadStep.Review);
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : t('csvUpload.unknownError', 'An unknown error occurred')
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBack = useCallback(() => {
    setActiveStep((prevStep) => prevStep - 1);
  }, [setActiveStep]);

  const handleSubmit = async () => {
    if (!csvValidationResult) return;

    setLoading(true);
    try {
      await processData(csvValidationResult.groupedData);
      setActiveStep(CsvUploadStep.Complete);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : t('csvUpload.unknownError', 'An unknown error occurred')
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {renderButton ? (
        renderButton(handleOpen)
      ) : (
        <Button
          variant="outlined"
          startIcon={<FileUploadIcon />}
          onClick={handleOpen}
        >
          {t('csvUpload.importFromCsv', 'Import from CSV')}
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {t(
            'csvUpload.importTitle',
            'Upload {{pageName}} Structures from CSV',
            {
              pageName,
            }
          )}
        </DialogTitle>

        <DialogContent sx={{ pb: 1 }}>
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            <Step>
              <StepLabel>{t('csvUpload.step1', 'Upload CSV')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('csvUpload.step2', 'Review & Confirm')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('csvUpload.step3', 'Complete')}</StepLabel>
            </Step>
          </Stepper>

          {activeStep === CsvUploadStep.Upload && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                {t(
                  'csvUpload.uploadInstructions',
                  'Upload a CSV file with {{pageName}} structure data',
                  { pageName }
                )}
              </Typography>

              <Box sx={{ mb: 2 }}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleDownloadSample}
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                >
                  <DownloadIcon fontSize="small" />
                  {t('csvUpload.downloadSample', 'Download sample CSV file')}
                </Link>
              </Box>

              <Box
                component="div"
                sx={{
                  display: 'block',
                  width: '100%',
                  cursor: 'pointer',
                  mt: 2,
                }}
              >
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <UploadBox
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBulkUpload();
                  }}
                >
                  <CloudUploadIcon
                    color="primary"
                    sx={{ fontSize: 48, mb: 2 }}
                  />
                  <Typography variant="h6" gutterBottom>
                    {t('csvUpload.dragAndDrop', 'Drag & Drop CSV File Here')}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t('csvUpload.orClickToSelect', 'or click to browse files')}
                  </Typography>
                </UploadBox>
              </Box>

              {fileName && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    {t('csvUpload.fileSelected', 'File selected')}:{' '}
                    <strong>{fileName}</strong>
                  </Typography>
                </Alert>
              )}

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  <Typography variant="body2">{error}</Typography>
                </Alert>
              )}

              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              )}
            </>
          )}

          {activeStep === CsvUploadStep.Review && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                {t('csvUpload.reviewAndConfirm', 'Review and confirm import')}
              </Typography>

              <Divider sx={{ my: 2 }} />

              {csvValidationResult?.valid && validationWarnings.length === 0 ? (
                <>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    <Typography variant="body2">
                      {t(
                        'csvUpload.validData',
                        'Data validation successful. Please review before proceeding.'
                      )}
                    </Typography>
                  </Alert>

                  <Box sx={{ mt: 2, mb: 3 }}>
                    <Typography variant="body1">
                      <strong>{t('csvUpload.summary', 'Summary:')}</strong>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {t(
                        'csvUpload.totalRows',
                        'Total rows to import: {{count}}',
                        { count: totalRows }
                      )}
                    </Typography>
                    {showGroupCounts && (
                      <Typography variant="body2">
                        {t('csvUpload.totalGroups', 'Total groups: {{count}}', {
                          count: Object.keys(csvValidationResult.groupedData)
                            .length,
                        })}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  {/* Display validation errors if any */}
                  {Object.keys(validationErrors).length > 0 && (
                    <Alert
                      severity="error"
                      sx={{
                        mt: 2,
                        mb: 3,
                        maxHeight: '200px',
                        '& .MuiAlert-message': {
                          width: '100%',
                          overflow: 'visible',
                        },
                        '& .MuiAlert-icon': {
                          marginTop: '4px',
                          alignSelf: 'flex-start',
                        },
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                      >
                        <Typography variant="subtitle2">
                          {t(
                            'csvUpload.validationErrorsFound',
                            'Validation errors found:'
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          maxHeight: '150px',
                          overflow: 'auto',
                          overflowX: 'hidden',
                          fontSize: '0.875rem',
                          width: '100%',
                          pr: 2,
                          pl: 0,
                          m: 0,
                        }}
                      >
                        {Object.entries(validationErrors).map(
                          ([error, details], index) => (
                            <Typography
                              key={index}
                              variant="body2"
                              sx={{
                                mb: 0.5,
                                display: 'block',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                              }}
                            >
                              {error} ({details.count}{' '}
                              {details.count > 1 ? 'times' : 'time'} in lines{' '}
                              {details.lines.length > 5
                                ? `${details.lines.slice(0, 5).join(', ')}...`
                                : details.lines.join(', ')}
                              )
                            </Typography>
                          )
                        )}
                      </Box>
                    </Alert>
                  )}

                  {/* Display validation warnings if any */}
                  {validationWarnings.length > 0 && (
                    <Alert
                      severity="warning"
                      sx={{
                        mt: 2,
                        mb: 3,
                        maxHeight: '200px',
                        '& .MuiAlert-message': {
                          width: '100%',
                          overflow: 'visible',
                        },
                        '& .MuiAlert-icon': {
                          marginTop: '4px',
                          alignSelf: 'flex-start',
                        },
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                      >
                        <Typography variant="subtitle2">
                          {t(
                            'csvUpload.validationWarningsFound',
                            'Validation warnings found:'
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          maxHeight: '150px',
                          overflow: 'auto',
                          overflowX: 'hidden',
                          fontSize: '0.875rem',
                          width: '100%',
                          pr: 2,
                          pl: 0,
                          m: 0,
                        }}
                      >
                        {validationWarnings.map((warning, index) => (
                          <Typography
                            key={index}
                            variant="body2"
                            sx={{
                              mb: 0.5,
                              display: 'block',
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            }}
                          >
                            {warning}
                          </Typography>
                        ))}
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                          {t(
                            'csvUpload.warningsNote',
                            'These warnings will not prevent the import, but may result in incorrect data. You can proceed or go back to fix the issues.'
                          )}
                        </Typography>
                      </Box>
                    </Alert>
                  )}

                  {/* Display a summary even with warnings */}
                  {(csvValidationResult?.valid ||
                    validationWarnings.length > 0) && (
                    <Box sx={{ mt: 2, mb: 3 }}>
                      <Typography variant="body1">
                        <strong>{t('csvUpload.summary', 'Summary:')}</strong>
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {t(
                          'csvUpload.totalRows',
                          'Total rows to import: {{count}}',
                          { count: totalRows }
                        )}
                      </Typography>
                      {showGroupCounts && (
                        <Typography variant="body2">
                          {t(
                            'csvUpload.totalGroups',
                            'Total groups: {{count}}',
                            {
                              count: csvValidationResult
                                ? Object.keys(csvValidationResult.groupedData)
                                    .length
                                : 0,
                            }
                          )}
                        </Typography>
                      )}
                    </Box>
                  )}
                </>
              )}

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  <Typography variant="body2">{error}</Typography>
                </Alert>
              )}

              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}
              >
                <Button onClick={handleBack} variant="outlined">
                  {t('csvUpload.back', 'Back')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={loading || Object.keys(validationErrors).length > 0}
                  startIcon={
                    loading ? <CircularProgress size={20} /> : undefined
                  }
                >
                  {loading
                    ? t('csvUpload.processing', 'Processing...')
                    : t('csvUpload.save', 'Save')}
                </Button>
              </Box>
            </>
          )}

          {activeStep === CsvUploadStep.Complete && (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <CheckCircleIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                {t('csvUpload.importSuccess', 'Import Successful')}
              </Typography>
              <Typography variant="body1">
                {t(
                  'csvUpload.structuresImported',
                  '{{count}} {{pageName}} structures have been successfully imported.',
                  { count: totalRows, pageName: pageName.toLowerCase() }
                )}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

CsvUploadsModal.Button = function CsvUploadsModalButton({
  onClick,
}: {
  onClick?: () => void;
}) {
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClick}
      startIcon={<FileUploadIcon />}
    >
      {t('csvUpload.importFromCsv', 'Import from CSV')}
    </Button>
  );
};
