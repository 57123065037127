import { IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default ({
  isToggledOn,
  onToggle,
}: {
  isToggledOn: boolean;
  onToggle: (isToggledOn: boolean) => void;
}) => ({
  endAdornment: (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        edge="end"
        onClick={() => onToggle(!isToggledOn)}
      >
        {isToggledOn ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  ),
});
