import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RatingsSaveManyRequest, useRatingsQuery, useRatingsSaveMutation } from '@alamere/generated-graphql-types';
import { Rating } from '../MeritMatricesPage/MeritMatricesPage.page';
import { t } from 'i18next';

export const defaultRatings = [
  { description: 'Strongly exceeds', score: 5 },
  { description: 'Exceeds', score: 4 },
  { description: 'Meets', score: 3 },
  { description: 'Somewhat meets', score: 2 },
  { description: 'Needs Improvement', score: 1 },
];

export function MeritRatingsPage () {
  const { data: ratings } = useRatingsQuery();
  const [ratingSave] = useRatingsSaveMutation();
  const [localRatings, setLocalRatings] = useState<Rating[]>([]);

  useEffect(() => {
    if (ratings && ratings.ratings) {
      if (ratings.ratings.length > 0) {
        setLocalRatings(
          ratings.ratings.map((r) => ({
            id: r.id,
            description: r.description,
            score: parseInt(r.score),
          }))
        );
      } else {
        setLocalRatings(defaultRatings);
      }
    }
  }, [ratings]);

  const handleRatingChange = (
    index: number,
    field: 'description' | 'score',
    value: string
  ) => {
    const newRatings = localRatings.map((rating, i) => {
      if (i === index) {
        return {
          ...rating,
          [field]: field === 'score' ? parseInt(value) : value,
        };
      }
      return rating;
    });
    setLocalRatings(newRatings.sort((a, b) => b.score - a.score));
  };

  const addRating = () => {
    setLocalRatings([...localRatings, { description: '', score: 0 }]);
  };

  const removeRating = (index: number) => {
    setLocalRatings(localRatings.filter((_, i) => i !== index));
  };

  const saveRatings = async () => {
    try {
      const ratingsSaveManyRequest: RatingsSaveManyRequest = {
        items: localRatings.map((rating) => ({
          description: rating.description,
          score: rating.score.toString(),
        })),
      };

      await ratingSave({
        variables: {
          ratingsSaveManyRequest,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={3} sx={{ pl: 3, pb: 8 }}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('meritRatings.title')}
        </Typography>
        {localRatings.map((rating, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 3 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                value={rating.description}
                onChange={(e) =>
                  handleRatingChange(index, 'description', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                label="Score"
                value={rating.score}
                onChange={(e) =>
                  handleRatingChange(index, 'score', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={() => removeRating(index)}>{t('meritRatings.remove')}</Button>
            </Grid>
          </Grid>
        ))}
        <Button onClick={addRating}>{t('meritRatings.add')}</Button>
        <Button onClick={saveRatings}>{t('meritRatings.save')}</Button>
      </Grid>
    </Grid>
  )
}
