import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Typography, Input, Box
} from '@mui/material';
import {
  useMatricesQuery,
  useRatingsQuery,
  useMatricesSaveMutation,
  useMatrixDeleteMutation,
  MatrixSaveManyRequest,
  RatingsSaveManyRequest,
  MatrixRowSaveManyRequest,
  useRatingsSaveMutation,
  useMatrixRowSaveMutation,
} from '@alamere/generated-graphql-types';
import { defaultRatings } from '../MeritRatingsPage/MeritRatingsPage.page';
import { t } from 'i18next';
import e from 'express';

interface MatrixInputs {
  minBucket: string;
  maxBucket: string;
  bucketSize: string;
  meetsValue: string;
  increasePerScore: string;
  differenceBetweenBuckets: string;
  matrixName: string;
  isDefault: boolean;
}

interface GeneratedMatrix {
  buckets: number[];
  ratings: Rating[];
  rows: {
    compaRatioLower: number;
    compaRatioUpper: number;
    meritIncrease: number;
    ratingId?: number;
  }[];
}
export interface Rating {
  id?: number;
  description: string;
  score: number;
}

export function MeritMatricesPage() {
  const { data: matrices, refetch } = useMatricesQuery();
  const { data: ratings } = useRatingsQuery();
  const [matrixSave] = useMatricesSaveMutation();
  const [matrixDelete] = useMatrixDeleteMutation();
  const [matrixRowSave] = useMatrixRowSaveMutation();
  const [matrixInputs, setMatrixInputs] = useState<MatrixInputs>({
    minBucket: '80',
    maxBucket: '120',
    bucketSize: '5',
    meetsValue: '3',
    increasePerScore: '20',
    differenceBetweenBuckets: '20',
    matrixName: '',
    isDefault: false,
  });
  const [localRatings, setLocalRatings] = useState<Rating[]>([]);
  const [generatedMatrix, setGeneratedMatrix] =
    useState<GeneratedMatrix | null>(null);

  useEffect(() => {
    if (ratings && ratings.ratings) {
      if (ratings.ratings.length > 0) {
        setLocalRatings(
          ratings.ratings.map((r) => ({
            id: r.id,
            description: r.description,
            score: parseInt(r.score),
          }))
        );
      } else {
        setLocalRatings(defaultRatings);
      }
    }
  }, [ratings]);

  useEffect(() => {
    if (localRatings.length) {
      generateMatrix();
    }
  }, [localRatings]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMatrixInputs((prev) => ({ ...prev, [name]: value }));
  };

  const generateMatrix = () => {
    const minBucket = parseFloat(matrixInputs.minBucket);
    const maxBucket = parseFloat(matrixInputs.maxBucket);
    const bucketSize = parseFloat(matrixInputs.bucketSize);
    const meetsValue = parseFloat(matrixInputs.meetsValue);
    const increasePerScore = parseFloat(matrixInputs.increasePerScore) / 100;
    const differenceBetweenBuckets =
      parseFloat(matrixInputs.differenceBetweenBuckets) / 100;

    const buckets: any[] = [];
    for (let bucket = minBucket; bucket <= maxBucket; bucket += bucketSize) {
      buckets.push(parseFloat(bucket.toFixed(2)));
    }

    const middleRatingIndex = Math.floor(localRatings.length / 2);
    const middleBucket = 100;

    const matrixRows = localRatings.map((rating, ratingIndex) => {
      return buckets.map((bucket) => {
        const bucketDiff = (bucket - middleBucket) / bucketSize;
        const ratingDiff = rating.score - localRatings[middleRatingIndex].score;

        const bucketAdjustment =
          bucketDiff * differenceBetweenBuckets * meetsValue;
        const ratingAdjustment = ratingDiff * increasePerScore * meetsValue;

        let meritIncrease = meetsValue + bucketAdjustment + ratingAdjustment;
        meritIncrease = Math.max(0, parseFloat(meritIncrease.toFixed(2)));

        return {
          compaRatioLower: bucket,
          compaRatioUpper: bucket + bucketSize,
          meritIncrease,
          ratingId: rating.id,
        };
      });
    });

    setGeneratedMatrix({
      buckets,
      ratings: localRatings,
      rows: matrixRows.flat(),
    });
  };

  const saveMatrix = async () => {
    if (!generatedMatrix) return;

    try {
      const matrixSaveManyRequest: MatrixSaveManyRequest = {
        items: [
          {
            name: matrixInputs.matrixName,
            isDefault: matrixInputs.isDefault,
          },
        ],
      };

      const savedMatrix = await matrixSave({
        variables: {
          matrixSaveManyRequest,
        },
      });

      if (savedMatrix.data?.matrixSave[0]) {
        const matrixId = savedMatrix.data.matrixSave[0].id;
        const matrixRowSaveManyRequest: MatrixRowSaveManyRequest = {
          items: generatedMatrix.rows.map((row: GeneratedMatrix['rows'][0]) => {
            if (row.ratingId === undefined) {
              throw new Error('ratingId is required for MatrixRowSaveRequest');
            }
            return {
              matrixId,
              compaRatioLower: row.compaRatioLower,
              compaRatioUpper: row.compaRatioUpper,
              meritIncrease: parseFloat(row.meritIncrease.toFixed(2)),
              ratingId: row.ratingId,
            };
          }),
        };

        await matrixRowSave({
          variables: {
            matrixRowSaveManyRequest,
          },
        });
      }

      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMatrix = async (id: number) => {
    try {
      await matrixDelete({
        variables: {
          matrixDeleteId: id,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const setDefaultMatrix = async (matrixId: number) => {
    try {
      if (matrices && matrices.matrices) {
        const updatedMatrices = matrices.matrices.map(
          ({ __typename, rows, ...matrix }) => ({
            ...matrix,
            isDefault: matrix.id === matrixId,
          })
        );

        const matrixSaveManyRequest: MatrixSaveManyRequest = {
          items: updatedMatrices,
        };

        await matrixSave({
          variables: {
            matrixSaveManyRequest,
          },
        });

        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMatrixNameChange = async (matrixId: number, name: string) => {
    try {
      if (matrices && matrices.matrices) {
        const updatedMatrices = matrices.matrices.map(
          ({ __typename, rows, ...matrix }) => ({
            ...matrix,
            name: matrix.id === matrixId ? name : matrix.name,
          })
        );

        const matrixSaveManyRequest: MatrixSaveManyRequest = {
          items: updatedMatrices,
        };

        await matrixSave({
          variables: {
            matrixSaveManyRequest,
          },
        });

        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onlyDefaultRatings = (ratings: Rating[]) => {
    return (
      ratings.length === defaultRatings.length &&
      ratings.every((r) => defaultRatings.some((d) => d.score === r.score)) &&
      ratings.some((r) => !r.id)
    );
  };

  const hideContent = onlyDefaultRatings(localRatings);

  return (
    <Grid container spacing={3} sx={{ pl: 3, pb: 8 }}>
      {hideContent && (
        <Typography>{t('meritMatrices.noRatings')}</Typography>
      )}
      {!hideContent && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">{t('meritMatrices.title')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Lowest compa ratio"
              name="minBucket"
              value={matrixInputs.minBucket}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Highest compa ratio"
              name="maxBucket"
              value={matrixInputs.maxBucket}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Increment width"
              name="bucketSize"
              value={matrixInputs.bucketSize}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Value for Meets/100%"
              name="meetsValue"
              value={matrixInputs.meetsValue}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="% increase with perf score +/-1"
              name="increasePerScore"
              value={matrixInputs.increasePerScore}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="% Difference between compa ratio increments"
              name="differenceBetweenBuckets"
              value={matrixInputs.differenceBetweenBuckets}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Button variant="contained" onClick={generateMatrix}>
              Generate Matrix
            </Button>
          </Grid>

          {generatedMatrix && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Rating</TableCell>
                    {generatedMatrix.buckets.slice(0, -1).map((bucket, index) => (
                      <TableCell key={index}>{`${bucket} - ${
                        bucket + parseFloat(matrixInputs.bucketSize)
                      }`}</TableCell>
                    ))}
                    {generatedMatrix.buckets.slice(-1).map((bucket, index) => (
                      <TableCell key={index}>{`> ${bucket} `}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generatedMatrix.ratings.map((rating, ratingIndex) => (
                    <TableRow key={ratingIndex}>
                      <TableCell>{rating.description}</TableCell>
                      {generatedMatrix.buckets.map((_, bucketIndex) => {
                        const rowIndex =
                          ratingIndex * generatedMatrix.buckets.length +
                          bucketIndex;
                        return (
                          <TableCell key={bucketIndex}>
                            <TextField
                              value={generatedMatrix.rows[
                                rowIndex
                              ].meritIncrease.toFixed(2)}
                              onChange={(e) => {
                                if (!isNaN(parseFloat(e.target.value))) {
                                  const newMatrix = { ...generatedMatrix };
                                  newMatrix.rows[rowIndex].meritIncrease =
                                    parseFloat(e.target.value);
                                  setGeneratedMatrix(newMatrix);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Button
            variant="contained"
            onClick={saveMatrix}
            sx={{ ml: 3, my: 5 }}
          >
            Save Matrix
          </Button>
          {matrices && matrices.matrices && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">Saved Matrices</Typography>
              </Grid>
              {matrices.matrices.map((matrix) => (
                <SavedMatrix
                  key={matrix.id}
                  matrix={matrix}
                  ratings={ratings?.ratings}
                  onDelete={deleteMatrix}
                  onSetDefault={setDefaultMatrix}
                  onChangeName={handleMatrixNameChange}
                />
              ))}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

interface SavedMatrixProps {
  matrix: {
    id: number;
    name: string;
    isDefault: boolean;
    rows: {
      compaRatioLower: number;
      compaRatioUpper: number;
      meritIncrease: number;
      ratingId: number;
    }[];
  };
  onDelete: (id: number) => void;
  onSetDefault: (id: number) => void;
  onChangeName: (id: number, name: string) => void;
  ratings: { score: string; description: string; id: number }[] | undefined;
}

function SavedMatrix({
  matrix,
  onDelete,
  onSetDefault,
  onChangeName,
  ratings,
}: SavedMatrixProps) {
  const [editName, setEditName] = useState<boolean>(false);
  const [matrixName, setMatrixName] = useState<string>(matrix.name);
  const buckets = Array.from(
    new Set(matrix.rows.map((row) => row.compaRatioLower))
  ).sort((a, b) => a - b);

  return (
    <Grid item xs={12}>
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", gap: 5, py: 2 }}>
      {editName ? (
        <>
          <TextField
            label="Matrix Name"
            name="name"
            value={matrixName}
            onChange={(e) => setMatrixName(e.target.value)}
          />
          <Button variant="contained" onClick={() => {
            onChangeName(matrix.id, matrixName);
            setEditName(false);
          }}>
            Save
          </Button>
        </>
      ) :
        (
          <>
            <Typography>{matrix.name}</Typography>
            <Button variant="contained" onClick={() => setEditName(true)}>Edit Matrix Name</Button>
          </>
        )}

    </Box>
      <Button onClick={() => onDelete(matrix.id)}>Delete</Button>
      <Button
        onClick={() => onSetDefault(matrix.id)}
        disabled={matrix.isDefault}
      >
        {matrix.isDefault ? 'Default' : 'Set as Default'}
      </Button>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Rating</TableCell>
              {buckets.slice(0, -1).map((bucket, index) => (
                <TableCell key={index}>{`${bucket} - ${
                  matrix.rows.find((row) => row.compaRatioLower === bucket)
                    ?.compaRatioUpper
                }`}</TableCell>
              ))}
              {buckets.slice(-1).map((bucket, index) => (
                <TableCell key={index}>{`> ${bucket}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ratings?.map((rating) => (
              <TableRow key={rating.id}>
                <TableCell>{rating.description}</TableCell>
                {buckets.map((bucket) => {
                  const cell = matrix.rows.find(
                    (row) =>
                      row.compaRatioLower === bucket &&
                      row.ratingId === rating.id
                  );
                  return (
                    <TableCell key={`${rating.id}-${bucket}`}>
                      {cell ? cell.meritIncrease.toFixed(2) : '-'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
