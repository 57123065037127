import {
  EquityBandGroupFragment,
  useEquityBandGroupDeleteMutation,
  useEquityBandGroupsQuery,
  useRangeWidthGroupsQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import DeleteIconRounded from '@mui/icons-material/DeleteRounded';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { t } from 'i18next';
import { memo, useEffect, useState } from 'react';
import { OnGroupChangeFunction } from './types';

export interface EquityBandsHeaderCellProps {
  group: EquityBandGroupFragment;
  editing: boolean;
  isHighlighted: boolean;
  colSpan: number;
  onGroupChange: OnGroupChangeFunction;
}

export default memo(function EquityBandsHeaderCell({
  group: groupFromProps,
  editing,
  isHighlighted,
  colSpan,
  onGroupChange,
}: EquityBandsHeaderCellProps) {
  const [group, setGroup] = useState(groupFromProps);
  const { data: equityBandGroupsData } = useEquityBandGroupsQuery();
  const { data: rangeWidthGroupsData } = useRangeWidthGroupsQuery();
  const [deleteGroup] = useEquityBandGroupDeleteMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          equityBandGroups(existingGroups = [], { readField }) {
            return existingGroups.filter(
              (groupRef: any) =>
                data?.equityBandGroupDelete !== readField('id', groupRef)
            );
          },
        },
      });
    },
  });

  useEffect(() => {
    if (groupFromProps) {
      setGroup(groupFromProps);
    }
  }, [groupFromProps]);

  const handleGroupNameChange = (value: string) => {
    const newValue = {
      ...group,
      name: value,
    };

    setGroup(newValue);
    onGroupChange({
      ...newValue,
      name: value.trim(),
    });
  };

  const handleRangeWidthChange = (e: SelectChangeEvent<number | undefined>) => {
    const newValue = {
      ...group,
      rangeWidthGroupId:
        e.target.value === '' ? null : (e.target.value as number),
    };

    setGroup(newValue);
    onGroupChange(newValue);
  };

  const handleDynamicPercentChange = (value: string) => {
    const newValue = {
      ...group,
      dynamicPercent: parseFloat(value) || null,
    };

    setGroup(newValue);
    if (newValue.dynamicPercent && newValue.dynamicParentGroupId) {
      onGroupChange(newValue);
    }
  };

  const handleDynamicParentChange = (
    e: SelectChangeEvent<number | undefined>
  ) => {
    const newValue = {
      ...group,
      dynamicPercent:
        e.target.value === '' ? null : group.dynamicPercent || 100,
      dynamicParentGroupId:
        e.target.value === '' ? null : (e.target.value as number),
    };

    setGroup(newValue);
    onGroupChange(newValue);
  };

  const handleDeleteGroup = async () => {
    await deleteGroup({
      variables: { equityBandGroupDeleteId: group.id },
    });
  };

  const hasDynamicChildren = equityBandGroupsData?.equityBandGroups.some(
    (grp) => grp.dynamicParentGroupId === group.id
  );

  return (
    <TableCell
      key={groupFromProps.id}
      align={editing ? 'left' : 'center'}
      sx={{
        verticalAlign: editing ? 'top' : 'middle',
        minWidth: editing ? 280 : 'auto',
        width: editing ? 280 : 'auto',
      }}
      colSpan={colSpan}
      className={isHighlighted ? 'highlighted-column' : ''}
    >
      <Stack
        sx={{ display: editing ? 'none' : 'block' }}
        direction="row"
        spacing={1}
      >
        <Typography variant="subtitle1">{groupFromProps.name}</Typography>
      </Stack>
      <Stack sx={{ display: editing ? 'block' : 'none' }} spacing={2}>
        <OutlinedInput
          fullWidth
          size="small"
          value={group.name}
          sx={{
            width: '100%',
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={t('equityPage.bands.deleteGroup')}
                size="small"
                title={t('equityPage.bands.deleteGroup')}
                disabled={hasDynamicChildren}
                onClick={handleDeleteGroup}
              >
                <DeleteIconRounded
                  titleAccess={
                    hasDynamicChildren
                      ? t('equityPage.bands.cantDeleteGroup')
                      : t('equityPage.bands.deleteGroup')
                  }
                />
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => handleGroupNameChange(e.target.value)}
        />
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="range-width-group-select-label">
            {t('equityPage.bands.rangeWidthSelect')}
          </InputLabel>
          <Select
            labelId="range-width-group-select-label"
            id="range-width-group-select"
            value={rangeWidthGroupsData ? group.rangeWidthGroupId || '' : ''}
            label={t('equityPage.bands.rangeWidthSelect')}
            onChange={handleRangeWidthChange}
            size="small"
            sx={{
              width: '100%',
              '& .MuiSelect-select': {
                color: 'white',
              },
            }}
          >
            <MenuItem value="">
              <em>{t('equityPage.bands.noSelection')}</em>
            </MenuItem>
            {rangeWidthGroupsData?.rangeWidthGroups.map((rangeWidthGroup) => {
              return (
                <MenuItem key={rangeWidthGroup.id} value={rangeWidthGroup.id}>
                  {rangeWidthGroup.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="center" spacing={1}>
          <OutlinedInput
            sx={{ width: 130 }}
            inputProps={{ style: { textAlign: 'center' } }}
            size="small"
            value={group.dynamicPercent ?? ''}
            disabled={!group.dynamicParentGroupId}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            onChange={(e) => handleDynamicPercentChange(e.target.value)}
          />
          <Typography>of</Typography>
          <FormControl size="small" sx={{ width: 150 }}>
            <InputLabel id="dynamic-parent-select-label">
              {t('equityPage.bands.dynamicParent')}
            </InputLabel>
            <Select
              labelId="dynamic-parent-select-label"
              id="range-width-group-select"
              value={group.dynamicParentGroupId || ''}
              label={t('equityPage.bands.dynamicParent')}
              onChange={handleDynamicParentChange}
              size="small"
              sx={{
                width: '100%',
                '& .MuiSelect-select': {
                  color: 'white',
                },
              }}
            >
              <MenuItem value="">
                <em>{t('equityPage.bands.noSelection')}</em>
              </MenuItem>
              {equityBandGroupsData?.equityBandGroups
                .filter((grp) => grp.id !== group.id)
                .map((rangeWidthGroup) => {
                  return (
                    <MenuItem
                      disabled={
                        rangeWidthGroup.dynamicParentGroupId === group.id
                      }
                      key={rangeWidthGroup.id}
                      value={rangeWidthGroup.id}
                    >
                      {rangeWidthGroup.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </TableCell>
  );
});
