import {
  WorkspaceIdAndNameFragment,
  useGetAutoSignupWorkspaceLazyQuery,
} from '@alamere/generated-graphql-types';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { Divider, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface EnterEmailProps {
  onCheckAutoSignUp: (
    email: string,
    workspace: WorkspaceIdAndNameFragment | null | undefined
  ) => void;
  email: string;
}

export default function EnterEmail({
  onCheckAutoSignUp,
  email: emailFromParams,
}: EnterEmailProps) {
  const [checkCanAutoSignup, { loading }] =
    useGetAutoSignupWorkspaceLazyQuery();
  const navigate = useNavigate();

  const [email, setEmail] = useState(emailFromParams);

  const onNext = async () => {
    const res = await checkCanAutoSignup({
      variables: {
        request: {
          email,
        },
      },
    });

    if (res.data) {
      onCheckAutoSignUp(email, res.data.getAutoSignupWorkspace);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack
        spacing={3}
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <AutoAwesomeRoundedIcon />

        <Typography component="h1" variant="h5">
          {t('signUpPage.title')}
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          disabled={loading}
        />
        <Button fullWidth variant="contained" size="large" onClick={onNext}>
          {t('global.next')}
        </Button>
        <Divider />

        <Button
          onClick={() => navigate('/login')}
          variant="text"
          disabled={loading}
        >
          {'Already have an account? Sign In'}
        </Button>
      </Stack>
    </Container>
  );
}
