import { Box, Container, Tab, Tabs } from '@mui/material';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const MenuItems = [
  { href: 'general', label: 'Workspace' },
  { href: 'users', label: 'Users' },
  { href: 'permissions', label: 'Roles' },
];

export default function WorkspaceSettingsPage() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const pathRoot = useMemo(() => {
    const segments = pathname.split('/');
    // Return the base path: everything up to 'settings'
    return segments.slice(0, segments.indexOf('settings') + 1).join('/');
  }, [pathname]);

  const currentTabPath = useMemo(() => {
    const segments = pathname.split('/');
    const lastSegment = segments.pop(); // Get the last segment

    // Check if the last segment is one of the static paths (general, users, permissions)
    if (lastSegment && MenuItems.some((item) => item.href === lastSegment)) {
      return lastSegment;
    }

    // If it's not a static path, it's likely a dynamic roleId, so fallback to 'permissions'
    return 'permissions';
  }, [pathname]);

  const currentTabIndex = useMemo(
    () => MenuItems.findIndex((item) => item.href === currentTabPath),
    [currentTabPath]
  );

  return (
    <Container sx={{ ml: 0 }}>
      <Box sx={{ width: '100%', mb: 5 }}>
        <Tabs value={currentTabIndex} aria-label="workspace tabs navigation">
          {MenuItems.map((item, index) => (
            <Tab
              key={index}
              component="a"
              label={item.label}
              onClick={() => navigate([pathRoot, item.href].join('/'))}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Container>
  );
}
