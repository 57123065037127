import { useState } from 'react';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { replace } from 'lodash';
import { t } from 'i18next';

interface Props extends BaseTextFieldProps {
  onChange: (value: string, isValid: boolean) => void;
}

const ZIP_CODE_LENGTH = 5;

export default function ZipcodeTextField({ onChange, ...rest }: Props) {
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState(true);

  const handleChange = (newZipcode: string) => {
    // Only allow between 0 and 5 numeric characters.
    //   This approach is more flexible than setting `type="number"` and `maxLength="5"`
    //   on the input element itself - better supports copy+pasting for example.
    const normalizedZipcode = replace(newZipcode, /\D/gi, '').slice(
      0,
      ZIP_CODE_LENGTH
    );
    setValue(normalizedZipcode);

    const isValid =
      normalizedZipcode.length === 0 ||
      normalizedZipcode.length === ZIP_CODE_LENGTH;
    setIsValid(isValid);
    onChange(normalizedZipcode, isValid);
  };

  return (
    <TextField
      name="zipcode"
      label={t('zipcodeTextField.label')}
      variant="outlined"
      fullWidth
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      error={!isValid}
      helperText={!isValid ? t('zipcodeTextField.invalidMessage') : ''}
      {...rest}
    />
  );
}
