import { JobGroup } from '@alamere/core';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import { memo } from 'react';
import { SxProps } from '@mui/material';

interface Props {
  editing?: boolean;
  cols: JobGroup[];
  sx?: SxProps;
}

export const Head = memo(({ cols, editing, sx }: Props) => {
  if (cols.length === 0) {
    return null;
  }
  return (
    <TableHead sx={sx}>
      <TableRow>
        <TableCell sx={{ width: 80 }}>{t('global.globalLevel')}</TableCell>
        {cols.map((group) => (
          <TableCell sx={{ maxWidth: editing ? 220 : 200 }} key={group}>
            {t(`radford.groups.${group}`)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
