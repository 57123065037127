import { GlobalLevelFragment } from '@alamere/generated-graphql-types';
import { Stack, TableCell, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { OnGlobalLevelChangeFunction } from './JobArchitecturePage.page';

export interface GlobalLevelCellProps {
  level: number;
  globalLevel?: GlobalLevelFragment;
  editing: boolean;
  onChange?: OnGlobalLevelChangeFunction;
}

export default function EditableGlobalLevelCell({
  level,
  globalLevel,
  editing,
  onChange,
}: GlobalLevelCellProps) {
  const globalLevelOrDefault = globalLevel || {
    level,
    name: level.toString(),
  };

  if (!editing) {
    return (
      <TableCell
        sx={{
          border: 'none',
        }}
      >
        {globalLevelOrDefault.name}
      </TableCell>
    );
  }

  return (
    <TableCell>
      <Stack textAlign="center">
        {editing ? (
          <TextField
            label={t('jobArchitecturePage.fields.globalLevelName')}
            defaultValue={globalLevelOrDefault.name}
            InputProps={{
              sx: { minWidth: 85 },
            }}
            inputProps={{ style: { textAlign: 'center' } }}
            onChange={(e) => {
              onChange?.({
                globalLevel: globalLevelOrDefault,
                updates: { name: e.target.value },
              });
            }}
          />
        ) : (
          <Typography>{level}</Typography>
        )}
      </Stack>
    </TableCell>
  );
}
