import { JOB_GROUPS } from '@alamere/core';
import {
  CircularProgress,
  Table as MuiTable,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  Typography,
  Collapse,
  IconButton,
  TableRow,
  TableCell,
} from '@mui/material';
import { t } from 'i18next';
import { groupBy, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import {
  JobFamilyFragment,
  useGlobalLevelsQuery,
  useJobLevelsQuery,
  useJobsQuery,
} from '../../../graphql/_generated/generated-graphql-types';
import { Head } from './Head';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';
import Row from './Row';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import JobEditForm from './JobEditForm';

export interface Props {
  editing: boolean;
  onItemChange: OnItemChangeFunction;
  jobFamily: JobFamilyFragment;
  loading?: boolean;
}

export default function Table({
  editing,
  onItemChange,
  jobFamily,
  loading,
}: Props) {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const toggleRowExpanded = (rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const { data: jobsData, loading: loadingJobs } = useJobsQuery({
    variables: {
      jobGetRequest: {
        jobFamilyIds: [jobFamily.id],
      },
    },
  });

  const { data: jobLevelsData, loading: loadingJobLevels } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });

  const jobLevelsByLevel = useMemo(
    () => groupBy(jobLevelsData?.jobLevels, (jobLevel) => jobLevel?.level),
    [jobLevelsData?.jobLevels]
  );

  const { data: globalLevelsData, loading: loadingGlobalLevels } =
    useGlobalLevelsQuery();

  const isLoading =
    loading || loadingJobs || loadingJobLevels || loadingGlobalLevels;

  const jobsByLevel = useMemo(
    () => groupBy(jobsData?.jobs, (job) => job?.jobLevel.level),
    [jobsData?.jobs]
  );

  const globalLevelsByLevel = useMemo(
    () => groupBy(globalLevelsData?.globalLevels, (level) => level?.level),
    [globalLevelsData?.globalLevels]
  );

  const orderedJobGroups = useMemo(() => {
    const availableGroups = uniqBy(
      jobLevelsData?.jobLevels,
      (jobLevel) => jobLevel?.group
    );
    return JOB_GROUPS.filter((group) =>
      availableGroups.some((jobLevel) =>
        editing
          ? jobLevel.group === group && jobLevel.isVisible
          : jobsData?.jobs.some((job) => job?.jobLevel.group === group)
      )
    );
  }, [jobsData?.jobs, jobLevelsData?.jobLevels, editing]);

  if (isLoading) {
    return (
      <Stack sx={{ p: 4 }}>
        <CircularProgress />
      </Stack>
    );
  }

  if (orderedJobGroups.length === 0) {
    return <Typography>{t('jobDefinitionsPage.noJobsForFamily')}</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      elevation={1}
      sx={{ width: 'fit-content' }}
    >
      <MuiTable aria-label="Job Levels" size="small">
        {/* TODO GB: hide if no data in view mode */}
        <Head editing={editing} cols={orderedJobGroups} />
        <TableBody>
          {Object.entries(jobLevelsByLevel)
            .reverse()
            .map(([level, jobLevels]) => {
              if (!globalLevelsByLevel[level]?.[0]) {
                return null;
              }

              const rowId = `row-${level}`;
              const isExpanded = expandedRows[rowId] || false;

              return (
                <>
                  <Row
                    key={level}
                    globalLevel={globalLevelsByLevel[level]?.[0]}
                    orderedJobGroups={orderedJobGroups}
                    jobLevels={jobLevels}
                    jobs={jobsByLevel[level]}
                    jobFamilyId={jobFamily.id}
                    editing={editing}
                    onItemChange={onItemChange}
                    isExpanded={editing && isExpanded}
                    onToggleExpand={() => editing && toggleRowExpanded(rowId)}
                    hideBorder={editing && isExpanded}
                  />
                  {editing && (
                    <TableRow>
                      <TableCell
                        colSpan={orderedJobGroups.length + 1}
                        sx={{ padding: 0 }}
                      >
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <div
                            style={{
                              padding: 16,
                              backgroundColor: '#f8f9fa',
                              borderTop: '1px solid rgba(224, 224, 224, 1)',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Edit Jobs for Level {level}
                            </Typography>

                            <Stack spacing={1}>
                              {jobsByLevel[level]?.map((job) => (
                                <JobEditForm
                                  key={job.id}
                                  job={job}
                                  onItemChange={onItemChange}
                                />
                              ))}

                              {(!jobsByLevel[level] ||
                                jobsByLevel[level].length === 0) && (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  No jobs available for this level.
                                </Typography>
                              )}
                            </Stack>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
