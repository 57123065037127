import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SxProps,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';

export interface Props {
  label: string;
  options: SelectOption[];
  onChange: (options: SelectOption[]) => void;
  sx?: SxProps;
  disabled?: boolean;
}

export interface SelectOption {
  title: string;
  value: number;
  disabled: boolean;
  checked: boolean;
}

export function MultiSelect({ label, options, onChange, sx, disabled }: Props) {
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const values = event.target.value as number[];
    const newOptions = options.map((option) => ({
      ...option,
      checked: values.includes(option.value),
    }));
    onChange(newOptions);
  };

  return (
    <FormControl sx={{ ...sx, width: 400 }} size="small">
      <InputLabel id="multi-select-label">{label}</InputLabel>
      <Select
        labelId="multi-select-label"
        multiple
        disabled={disabled}
        value={options
          .filter((option) => option.checked)
          .map((option) => option.value)}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          (selected as number[])
            .map(
              (value) => options.find((option) => option.value === value)?.title
            )
            .join(', ')
        }
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            <Checkbox checked={option.checked} />
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
