import { JobsQuery } from '../graphql/_generated/generated-graphql-types';
import capitalize from 'lodash/capitalize';

export interface FilterableItem {
  globalLevelLevel: number;
}

export interface Group {
  id: number;
}

export const parseInput = (input: string, addTrailingLine = true): string[] => {
  const enteredZips = new Set();
  if (input.length < 5) {
    return [restrictToFiveDigits(input)];
  }
  const lines = input.split(/[\r?\n,\s]/);

  for (let i = 0; i < lines.length; i++) {
    lines[i] = restrictToFiveDigits(lines[i]);
    if (lines[i] === '' || enteredZips.has(lines[i])) {
      lines.splice(i, 1);
    }
    enteredZips.add(lines[i]);
  }
  if (addTrailingLine && lines[lines.length - 1].length === 5) {
    lines.push('');
  }
  return lines;
};

const restrictToFiveDigits = (input: string) => {
  input = input
    .trim()
    .replace(/[^0-9]/g, '')
    .slice(0, 5);
  return input;
};

export const capitalizeCity = (city: string): string => {
  return city.split(' ').map(capitalize).join(' ');
};

export const formatNumber = (number: number | undefined) => {
  return number !== undefined ? number.toLocaleString('en') : '';
};

export function convertHourlyToAnnual(hourlyRate: number): number {
  const weeksInYear = 52;
  const hoursInWeek = 40;
  return hourlyRate * hoursInWeek * weeksInYear;
}

export function processJobData(
  jobFamily: number | null,
  levels: number[],
  combinedJobFamilyData: any,
  jobsData: JobsQuery
) {
  if (!jobsData || !jobsData.jobs || !jobFamily) {
    return { data: null };
  }

  const jobFamilyData = combinedJobFamilyData.find(
    (jf: any) => jf.id === jobFamily
  );
  if (!jobFamilyData) {
    return { data: null };
  }

  const filteredJobs = jobsData.jobs.filter(
    (job) =>
      job.jobFamilyId === jobFamily && levels.includes(job.jobLevel.level)
  );

  const processedJobs = filteredJobs.map((job) => {
    const bonus = jobFamilyData.bonusStructureGroup.bonusStructures.find(
      (bonus: any) => bonus.globalLevelLevel === job.jobLevel.level
    );
    const compBand = jobFamilyData.compBandGroup.compBands.find(
      (compBand: any) => compBand.globalLevelLevel === job.jobLevel.level
    );
    const rangeWidth = jobFamilyData.rangeWidthGroup.rangeWidths.find(
      (rangeWidth: any) => rangeWidth.level === job.jobLevel.level
    );
    const equityBand = jobFamilyData.equityBandGroup.equityBands.find(
      (equityBand: any) => equityBand.globalLevelLevel === job.jobLevel.level
    );
    const equityBandRangeWidth =
      jobFamilyData.equityBandGroup.rangeWidthGroup.rangeWidths.find(
        (rangeWidth: any) => rangeWidth.level === job.jobLevel.level
      );

    return {
      ...job,
      bonus,
      compBand,
      rangeWidth,
      equityBand: {
        ...equityBand,
        rangeWidth: equityBandRangeWidth,
      },
      compBandMultiplier: jobFamilyData.compBandMultiplier,
      missingRequiredData: Boolean(
        !bonus ||
          !compBand ||
          !rangeWidth ||
          !equityBand ||
          !equityBandRangeWidth
      ),
    };
  });

  return {
    data: {
      ...jobFamilyData,
      jobs: processedJobs,
    },
  };
}

export function getFilteredGroup<T extends Group>(
  groups: T[] | undefined,
  groupId: number | undefined
): T | undefined {
  return groups?.find((group) => group.id === groupId);
}

export function filterByLevels<T extends FilterableItem>(
  items: T[] | undefined,
  levelOptions: { value: number }[]
): T[] {
  return (
    items?.filter((item) =>
      levelOptions.some((level) => level.value === item.globalLevelLevel)
    ) || []
  );
}

export function parseBoolean(str: string | null): boolean | null {
  const isValid = str === 'true' || str === 'false';
  if (!isValid) {
    return null;
  }
  return str === 'true';
}
