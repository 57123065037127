import {
  useGlobalLevelsQuery,
  useJobLevelsQuery,
} from '@alamere/generated-graphql-types';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { memo, useMemo } from 'react';
import { Header } from './Header';
import { Row } from './Row';
import { OnGroupChangeFunction, OnItemChangeFunction } from './types';
import { useScopes } from '../../hooks/useScopes';
import { useAuth } from '../../providers/auth';
import { Scope } from '@alamere/core';

export interface TableProps {
  editing: boolean;
  onGroupChange: OnGroupChangeFunction;
  onItemChange: OnItemChangeFunction;
}

export const Table = memo(
  ({ editing, onGroupChange, onItemChange }: TableProps) => {
    const { checkScopes } = useScopes();
    const { workspaceMembership } = useAuth();
    const { data: globalLevelData, loading: loadingGlobalLevelData } =
      useGlobalLevelsQuery();
    const { data: jobLevelsData } = useJobLevelsQuery({
      variables: {
        jobLevelsGetRequest: {
          onlyVisible: true,
        },
      },
    });
    const visibleGlobalLevels = useMemo(
      () =>
        globalLevelData?.globalLevels.filter((level) =>
          jobLevelsData?.jobLevels.some(
            (jobLevel) => jobLevel?.level === level.level
          )
        ),
      [globalLevelData, jobLevelsData]
    );
    const orderedGlobalLevels = orderBy(
      visibleGlobalLevels ?? [],
      ['level'],
      ['desc']
    );

    return (
      <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
        <MuiTable
          sx={(theme) => ({
            tableLayout: 'auto',
            '& .highlighted-column': {
              backgroundColor: theme.palette.background.secondary,
            },
          })}
          size="small"
          aria-label={t('bonusStructuresPage.table.name')}
        >
          <Header editing={editing} onGroupChange={onGroupChange} />
          <TableBody>
            {(checkScopes(Scope.SEE_ALL_LEVELS_DATA)
              ? orderedGlobalLevels
              : orderedGlobalLevels.filter(
                  (level) =>
                    level.level <
                    (workspaceMembership?.globalLevelLevel as number)
                )
            ).map((globalLevel, index) => (
              <Row
                key={globalLevel.level}
                globalLevel={globalLevel}
                editing={editing}
                onItemChange={onItemChange}
              />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    );
  }
);
