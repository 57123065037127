import { JobGroup } from '@alamere/core';
import { IconButton, Stack, TableCell, TableRow } from '@mui/material';
import { groupBy } from 'lodash';
import { memo, useMemo } from 'react';
import {
  GlobalLevelFragment,
  JobFragment,
  JobLevelFragment,
} from '../../../graphql/_generated/generated-graphql-types';
import EditableGlobalLevelCell from '../EditableGlobalLevelCell';
import Cell from './Cell';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Props {
  globalLevel: GlobalLevelFragment;
  jobLevels: JobLevelFragment[];
  jobs: JobFragment[];
  editing: boolean;
  orderedJobGroups: JobGroup[];
  jobFamilyId: number;
  onItemChange: OnItemChangeFunction;
  isExpanded?: boolean;
  onToggleExpand?: () => void;
  hideBorder?: boolean;
}
export default memo(
  ({
    globalLevel,
    jobLevels,
    jobs,
    orderedJobGroups,
    editing,
    jobFamilyId,
    onItemChange,
    isExpanded = false,
    onToggleExpand = () => {},
    hideBorder,
  }: Props) => {
    const jobsByGroup = useMemo(
      () => groupBy(jobs, (job) => job?.jobLevel.group),
      [jobs]
    );

    if (
      !editing &&
      !Object.values(jobsByGroup).some((job) => job !== undefined)
    ) {
      return null;
    }

    return (
      <TableRow
        hover
        sx={{
          borderBottom: hideBorder ? 'none' : undefined,
        }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center">
            {editing && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={onToggleExpand}
                sx={{ mr: 1 }}
              >
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            <EditableGlobalLevelCell
              level={globalLevel.level}
              globalLevel={globalLevel}
              editing={false}
            />
          </Stack>
        </TableCell>
        {orderedJobGroups.map((group) => {
          const jobLevel = jobLevels.find(
            (jobLevel) => jobLevel?.group === group
          );
          return (
            <Cell
              jobs={jobsByGroup[group]}
              jobLevel={jobLevel}
              jobFamilyId={jobFamilyId}
              jobGroup={group}
              editing={editing}
              key={group}
              onItemChange={onItemChange}
              onToggleExpand={onToggleExpand}
            />
          );
        })}
      </TableRow>
    );
  }
);
