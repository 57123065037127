import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EditButtons } from '../../../components/EditButtons';
import JobFamilyPicker from '../../../components/JobFamilyPicker';
import {
  JobFamilyFragment,
  JobFragment,
  JobSaveRequest,
  Scope,
  useJobsSaveMutation,
} from '../../../graphql/_generated/generated-graphql-types';
import Table from './Table';
import { useApolloClient } from '@apollo/client';

export type OnItemChangeFunction = ({
  newItem,
  jobIdsToDeleteIfCancel,
  jobIdsToDelete,
}: {
  newItem?: JobFragment;
  jobIdsToDeleteIfCancel?: number[];
  jobIdsToDelete?: number[];
}) => void;

export default function JobDefinitionsPage() {
  const [params] = useSearchParams();
  const { cache } = useApolloClient();

  const [editing, setEditing] = useState(false);
  const [jobFamily, setJobFamily] = useState<JobFamilyFragment | null>(null);
  const [loading, setLoading] = useState(false);
  const [saveJobs, { loading: savingJobs }] = useJobsSaveMutation();

  const [itemUpdates, setItemUpdates] = useState<
    Record<string, JobSaveRequest>
  >({});
  const [jobIdsToDeleteIfCancel, setJobIdsToDeleteIfCancel] = useState<
    Set<number>
  >(new Set());
  const [jobIdsToDelete, setJobIdsToDelete] = useState<Set<number>>(new Set());

  const handleSave = async () => {
    setEditing(false);
    await saveJobs({
      variables: {
        jobSaveManyRequest: {
          items: Object.values(itemUpdates),
          idsToDelete: Array.from(jobIdsToDelete),
        },
      },
    });
    setItemUpdates({});
    setJobIdsToDelete(new Set());
    setJobIdsToDeleteIfCancel(new Set());
  };

  const handleCancel = async () => {
    setLoading(true);
    await cache.reset();
    setEditing(false);
    if (jobIdsToDeleteIfCancel.size !== 0) {
      saveJobs({
        variables: {
          jobSaveManyRequest: {
            items: [],
            idsToDelete: Array.from(jobIdsToDeleteIfCancel),
          },
        },
        refetchQueries: ['Jobs'],
      });
    }
    setJobIdsToDeleteIfCancel(new Set());
    setJobIdsToDelete(new Set());
    setItemUpdates({});
    setLoading(false);
  };

  const handleItemChange: OnItemChangeFunction = useCallback(
    ({ newItem, jobIdsToDeleteIfCancel, jobIdsToDelete }) => {
      setItemUpdates((prev) => {
        if (!newItem?.id) {
          return prev;
        }
        return {
          ...prev,
          [newItem.id]: {
            id: newItem.id,
            title: newItem.title,
            jobFamilyId: newItem.jobFamilyId,
            jobLevelId: newItem.jobLevelId,
            businessTitle: newItem.businessTitle,
            jobCode: newItem.jobCode,
            jobSubLevel: newItem.jobSubLevel,
            jobType: newItem.jobType,
          },
        };
      });

      setJobIdsToDeleteIfCancel((prev) => {
        if (!jobIdsToDeleteIfCancel || jobIdsToDeleteIfCancel.length === 0) {
          return prev;
        }

        const newSet = new Set(prev);
        jobIdsToDeleteIfCancel.forEach((id) => newSet.add(id));
        return newSet;
      });

      setJobIdsToDelete((prev) => {
        if (!jobIdsToDelete || jobIdsToDelete.length === 0) {
          return prev;
        }
        const newSet = new Set(prev);
        jobIdsToDelete.forEach((id) => newSet.add(id));
        return newSet;
      });
    },
    []
  );

  const handleSelectJobFamily = (jobFamily: JobFamilyFragment | null) => {
    setJobFamily(jobFamily);
  };

  return (
    <Stack spacing={2} mb={2}>
      {!loading && (
        <>
          <EditButtons
            requiredScopes={[Scope.RANGE_WIDTHS_EDIT]}
            onEdit={() => setEditing(true)}
            onSave={handleSave}
            onCancel={handleCancel}
          />
          <JobFamilyPicker
            onChange={handleSelectJobFamily}
            sx={{ width: 300 }}
          />
          {jobFamily && (
            <Table
              editing={editing}
              onItemChange={handleItemChange}
              jobFamily={jobFamily}
              loading={loading}
            />
          )}
        </>
      )}
    </Stack>
  );
}
