export const USER = 'USER';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const THEME_MODE = 'THEME_MODE';
export const WORKSPACE_ID = 'WORKSPACE_ID';
export const GOOGLE_OAUTH_CLIENT_ID =
  '151282641869-01bs67r3ir0kaisj22cfmcansskf2vgi.apps.googleusercontent.com';
export const DEBOUNCE_MS = 50;
export const CHACHED_PICKER_JOB_FAMILY_ID = 'CHACHED_PICKER_JOB_FAMILY_ID';
export const CHACHED_PICKER_COUNTRY_CODE = 'CHACHED_PICKER_COUNTRY_CODE';
export const COMP_RANGES_IS_ABSOLUTE_RANGE_MODE_KEY =
  'COMP_RANGES_IS_ABSOLUTE_RANGE_MODE';
