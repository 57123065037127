/**
 * See https://docs.google.com/document/d/1AGdWSeyQoVrxu4uqOWRy48Ns-XM4rbEnZkpxPpINq2Y/edit?usp=sharing
 */

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useEffect, useState } from 'react';
import { useAuth } from '../providers/auth';
import { env } from '../../environments/env';

const amplitudeApiKey = env.amplitude.apiKey;

function init() {
  if (!amplitudeApiKey) {
    return;
  }

  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);

  amplitude.init(amplitudeApiKey, undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
}

init();

export enum AmplitudeEventType {
  SearchZipcode = 'Search Zipcode',
  EditGeoTierConfiguration = 'Edit Geo Tier Configuration',
}

export function useIdentifyAmplitudeUser() {
  const [userInitialized, setUserInitialized] = useState(false);
  const [workspaceInitialized, setWorkspaceInitialized] = useState(false);

  const { user, workspace } = useAuth();

  useEffect(() => {
    if (!userInitialized && user) {
      amplitude.setUserId(user.email);
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('userId', user.id);
      amplitude.identify(identifyEvent);

      setUserInitialized(true);
    }

    if (!workspaceInitialized && workspace) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('workspaceId', workspace.id);
      amplitude.identify(identifyEvent);
      setWorkspaceInitialized(true);
    }
  }, [user, userInitialized, workspaceInitialized, workspace]);
}

export default amplitude;
