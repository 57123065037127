import { useApolloClient } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  JobFragment,
  JobFragmentDoc,
} from '../../../graphql/_generated/generated-graphql-types';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';

interface Props {
  job: JobFragment;
  onItemChange: OnItemChangeFunction;
}

export default function JobEditForm({ job, onItemChange }: Props) {
  const { cache } = useApolloClient();
  const [jobTitle, setJobTitle] = useState(job.title || '');
  const [businessTitle, setBusinessTitle] = useState(job.businessTitle || '');
  const [jobCode, setJobCode] = useState(job.jobCode || '');
  const [jobSubLevel, setJobSubLevel] = useState(job.jobSubLevel || '');
  const [jobType, setJobType] = useState(job.jobType || 'Salaried');

  const handleChange = (field: string, val: string) => {
    switch (field) {
      case 'title':
        setJobTitle(val);
        break;
      case 'businessTitle':
        setBusinessTitle(val);
        break;
      case 'jobCode':
        setJobCode(val);
        break;
      case 'jobSubLevel':
        setJobSubLevel(val);
        break;
      case 'jobType':
        setJobType(val);
        break;
    }
  };

  const handleBlur = () => {
    const newItem = {
      ...job,
      title: jobTitle,
      businessTitle,
      jobCode,
      jobSubLevel,
      jobType,
    };

    cache.writeFragment({
      id: `Job:${job.id}`,
      data: newItem,
      fragment: JobFragmentDoc,
      fragmentName: 'Job',
    });
    onItemChange({ newItem });
  };

  const handleDelete = () => {
    cache.evict({ id: `Job:${job.id}` });
    onItemChange({ jobIdsToDelete: [job.id] });
  };

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="subtitle1" fontWeight="bold" color="primary">
          {jobTitle}
        </Typography>

        <IconButton size="small" onClick={handleDelete} color="error">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>

      <Stack spacing={2}>
        <TextField
          value={jobTitle}
          size="small"
          label="Backend Title"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange('title', e.target.value)}
          onBlur={handleBlur}
        />

        <TextField
          value={businessTitle}
          size="small"
          label="Business Title"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange('businessTitle', e.target.value)}
          onBlur={handleBlur}
        />

        <TextField
          value={jobCode}
          size="small"
          label="Job Code"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange('jobCode', e.target.value)}
          onBlur={handleBlur}
        />

        <TextField
          value={jobSubLevel}
          size="small"
          label="Job Sub Level"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChange('jobSubLevel', e.target.value)}
          onBlur={handleBlur}
        />

        <FormControl fullWidth size="small">
          <InputLabel id={`job-type-label-${job.id}`}>Job Type</InputLabel>
          <Select
            labelId={`job-type-label-${job.id}`}
            id={`job-type-select-${job.id}`}
            value={jobType}
            label="Job Type"
            onChange={(e) => handleChange('jobType', e.target.value)}
            onBlur={handleBlur}
          >
            <MenuItem value="Salaried">Salaried</MenuItem>
            <MenuItem value="Hourly">Hourly</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Paper>
  );
}
