import {
  CountryCode,
  Scope,
  Tier,
  TierConfigParsed,
  TierManager,
} from '@alamere/core';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TableContainer,
  CircularProgress,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // TODO regular import when we upgrade to MUI v6
import { useMemo, useState, useEffect, useRef } from 'react';
import { t } from 'i18next';
import {
  EquityGlobalMappingFragment,
  useBonusStructureGroupsQuery,
  useCompBandGroupsQuery,
  useEquityBandGroupsQuery,
  useJobFamiliesQuery,
  useJobsQuery,
  useRangeWidthGroupsQuery,
  JobFamilyFragment,
  useTierConfigLazyQuery,
  ZipcodeResponse,
  useZipcodeSearchLazyQuery,
} from '../../graphql/_generated/generated-graphql-types';
import amplitude, { AmplitudeEventType } from '../../vendor/amplitude';
import CompRangesFamilyPicker from './CompRangesFamilyPicker';
import { MultiSelect, SelectOption } from '../../components/MultiSelect';
import { TierPicker } from '../../components/TierPicker';
import { Table } from './Table';
import {
  processJobData,
  getFilteredGroup,
  filterByLevels,
  parseBoolean,
} from '../../utils';
import ZipcodeTextField from '../../components/ZipcodeTextField';
import { useScopes } from '../../hooks/useScopes';
import { COMP_RANGES_IS_ABSOLUTE_RANGE_MODE_KEY } from '../../lib/constants';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';

interface ProcessedJob {
  jobLevel: {
    level: number;
  };
  missingRequiredData: boolean;
}

interface ProcessedData {
  jobs: ProcessedJob[];
}

export default function CompRangesPage() {
  const client = useApolloClient();
  const { checkScopes } = useScopes();
  const location = useLocation();

  const [jobFamily, setJobFamily] = useState<JobFamilyFragment | null>(null);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [tier, setTier] = useState<Tier | null>(null);
  const [tierManager, setTierManager] = useState<TierManager | undefined>();
  const [isAbsoluteRangeMode, setIsAbsoluteRangeMode] = useState(
    parseBoolean(
      localStorage.getItem(COMP_RANGES_IS_ABSOLUTE_RANGE_MODE_KEY)
    ) ?? false
  );
  const canShowBreakdownButtons = checkScopes(
    Scope.COMP_RANGE_CALCULATION_BREAKDOWN_VIEW
  );
  const [shallShowBreakdownButtons, setShallShowBreakdownButtons] = useState(
    canShowBreakdownButtons
  );

  const [isDataReady, setIsDataReady] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const levelOptionsSetRef = useRef(false);

  const [equityGlobalMapping] = useState<EquityGlobalMappingFragment | null>({
    countryCode: CountryCode.US,
    id: 1,
    percent: 2,
    useGeoDiff: true,
  });

  const [search] = useZipcodeSearchLazyQuery();
  const [
    fetchTierConfig,
    { data: tierConfigData, loading: tierConfigLoading },
  ] = useTierConfigLazyQuery();

  const { data: jobFamiliesData } = useJobFamiliesQuery();
  const { data: jobsData, loading: loadingJobs } = useJobsQuery({
    variables: {
      jobGetRequest: {
        jobFamilyIds: jobFamily ? [jobFamily.id] : [],
      },
    },
  });
  const { data: rangeWidthGroupsData } = useRangeWidthGroupsQuery();
  const { data: compBandGroupsData } = useCompBandGroupsQuery();
  const { data: groupsData } = useBonusStructureGroupsQuery();
  const { data: equityBandGroupsData } = useEquityBandGroupsQuery();

  // Derived data calculations
  const levelOptions = useMemo(() => {
    if (!jobsData?.jobs) return [];

    const levelMap = new Map();

    jobsData.jobs.forEach((job) => {
      const level = job.jobLevel.level;
      const title = job.title;

      if (levelMap.has(level)) {
        levelMap.get(level).titles.push(title);
      } else {
        levelMap.set(level, {
          level,
          titles: [title],
          name: job.jobLevel.name,
        });
      }
    });

    return Array.from(levelMap.values())
      .map(({ level, titles }) => ({
        title: `${titles.join(' / ')}`,
        value: level,
      }))
      .sort((a, b) => b.value - a.value);
  }, [jobsData]);

  const combinedJobFamilyData = useMemo(() => {
    if (!jobFamiliesData || !jobFamily) return [];

    return jobFamiliesData.jobFamilies
      .filter((family) => family.id === jobFamily.id)
      .map((jobFamily) => {
        const rangeWidthGroup = getFilteredGroup(
          rangeWidthGroupsData?.rangeWidthGroups,
          jobFamily.rangeWidthGroup?.id
        );
        const compBandGroup = getFilteredGroup(
          compBandGroupsData?.compBandsGroups,
          jobFamily.compBandGroup?.id
        );
        const bonusStructureGroup = getFilteredGroup(
          groupsData?.bonusStructureGroups,
          jobFamily.bonusStructureGroup?.id
        );
        const equityBandGroup = getFilteredGroup(
          equityBandGroupsData?.equityBandGroups,
          jobFamily.equityBandGroup?.id
        );
        const equityBandRangeWidthGroup = getFilteredGroup(
          rangeWidthGroupsData?.rangeWidthGroups,
          jobFamily.equityBandGroup?.rangeWidthGroupId || undefined
        );

        const filteredData = {
          rangeWidths: filterByLevels(
            rangeWidthGroup?.rangeWidths?.map((rw) => ({
              ...rw,
              globalLevelLevel: rw.level,
            })),
            levelOptions
          ),
          compBands: filterByLevels(compBandGroup?.compBands, levelOptions),
          bonusStructures: filterByLevels(
            bonusStructureGroup?.bonusStructures,
            levelOptions
          ),
          equityBands: filterByLevels(
            equityBandGroup?.equityBands,
            levelOptions
          ),
          equityBandRangeWidths: filterByLevels(
            equityBandRangeWidthGroup?.rangeWidths?.map((rw) => ({
              ...rw,
              globalLevelLevel: rw.level,
            })),
            levelOptions
          ),
        };

        if (Object.values(filteredData).some((data) => data.length === 0))
          return null;

        return {
          ...jobFamily,
          rangeWidthGroup: {
            ...rangeWidthGroup,
            rangeWidths: filteredData.rangeWidths,
          },
          compBandGroup: {
            ...compBandGroup,
            compBands: filteredData.compBands,
          },
          bonusStructureGroup: {
            ...bonusStructureGroup,
            bonusStructures: filteredData.bonusStructures,
          },
          equityBandGroup: {
            ...equityBandGroup,
            equityBands: filteredData.equityBands,
            rangeWidthGroup: {
              ...equityBandRangeWidthGroup,
              rangeWidths: filteredData.equityBandRangeWidths,
            },
          },
        };
      })
      .filter(Boolean);
  }, [
    jobFamiliesData,
    rangeWidthGroupsData,
    compBandGroupsData,
    groupsData,
    equityBandGroupsData,
    jobFamily,
    levelOptions,
  ]);

  const { data: processedData } = useMemo(() => {
    if (loadingJobs || !jobsData || !jobFamily) return { data: null };
    return processJobData(
      jobFamily.id,
      levelOptions.flatMap((level) => level.value),
      combinedJobFamilyData,
      jobsData
    );
  }, [jobFamily, levelOptions, combinedJobFamilyData, jobsData, loadingJobs]);

  useEffect(() => {
    if (!jobFamily || !levelOptions.length) return;

    if (levelOptionsSetRef.current && options.length > 0) return;

    if (processedData?.jobs) {
      const disabledLevels = new Set<number>(
        (processedData as ProcessedData).jobs
          .filter((job: ProcessedJob) => job.missingRequiredData)
          .map((job: ProcessedJob) => job.jobLevel.level)
      );

      setOptions(
        levelOptions.map((option) => ({
          ...option,
          disabled: disabledLevels.has(option.value),
          checked: !disabledLevels.has(option.value),
        }))
      );
    } else {
      setOptions(
        levelOptions.map((option) => ({
          ...option,
          checked: true,
          disabled: false,
        }))
      );
    }

    levelOptionsSetRef.current = true;

    return () => {
      if (jobFamily) {
        levelOptionsSetRef.current = false;
      }
    };
  }, [jobFamily, levelOptions, processedData, options.length]);

  // Handle job family changes
  const handleJobFamilyChange = (newJobFamily: JobFamilyFragment | null) => {
    // If it's the same family, just ignore the change to prevent unnecessary rerenders
    if (newJobFamily?.id === jobFamily?.id) {
      return;
    }

    setJobFamily(newJobFamily);
  };

  const handleLevelChange = (options: SelectOption[]) => {
    setOptions(options);
  };

  useEffect(() => {
    fetchTierConfig();
    if (!tierConfigLoading && tierConfigData?.tierConfig.tierConfigJson) {
      setTierManager(
        new TierManager(
          JSON.parse(
            tierConfigData.tierConfig.tierConfigJson
          ) as TierConfigParsed
        )
      );
    }
  }, [fetchTierConfig, tierConfigLoading, tierConfigData]);

  const handleZipcodeResponse = (response: ZipcodeResponse | undefined) => {
    setTier(
      response?.alamereLocationId && tierManager
        ? tierManager.getTier(
            tierManager.getMostSpecificLocationId(
              response.alamereLocationId,
              response.state
            )
          )
        : tier
    );
  };

  const handleIsAbsoluteRangeModeChange = (_isAbsoluteRangeMode: boolean) => {
    setIsAbsoluteRangeMode(_isAbsoluteRangeMode);
    localStorage.setItem(
      COMP_RANGES_IS_ABSOLUTE_RANGE_MODE_KEY,
      JSON.stringify(_isAbsoluteRangeMode)
    );
  };

  const onChange = (zipcode: string, isValid: boolean) => {
    if (!zipcode || !isValid) {
      return;
    }
    search({
      variables: { zipcodeSearchRequest: { zipcode } },
    }).then((res) => {
      handleZipcodeResponse(res.data?.searchZipcode as ZipcodeResponse);
      amplitude.track(AmplitudeEventType.SearchZipcode);
    });
  };

  // Mark when component is ready to display data
  useEffect(() => {
    if (isDataReady) return;

    const timer = setTimeout(() => {
      setIsDataReady(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [isDataReady]);

  // Track when initial data load is complete
  useEffect(() => {
    if (jobFamily && processedData) {
      setInitialLoadComplete(true);
    }
  }, [jobFamily, processedData]);

  // Fallback to restore job family from localStorage if needed
  useEffect(() => {
    const cachedId = localStorage.getItem('CHACHED_PICKER_JOB_FAMILY_ID');

    if (cachedId && !jobFamily) {
      let isMounted = true;

      const timeoutId = setTimeout(() => {
        if (isMounted && !jobFamily && jobFamiliesData?.jobFamilies) {
          const foundJobFamily = jobFamiliesData.jobFamilies.find(
            (jf) => jf.id === parseInt(cachedId)
          );

          if (foundJobFamily) {
            setJobFamily(foundJobFamily);
          }
        }
      }, 500);

      return () => {
        isMounted = false;
        clearTimeout(timeoutId);
      };
    }
  }, [jobFamily, jobFamiliesData]);

  // Handle navigation from Job Architecture page
  useEffect(() => {
    const previousPath = location.state?.from || '';
    if (previousPath.includes('job-architecture')) {
      setIsRefreshing(true);

      client
        .refetchQueries({
          include: ['JobFamilies', 'Jobs'],
        })
        .then(() => {
          setIsRefreshing(false);
        })
        .catch(() => {
          setIsRefreshing(false);
        });
    }
  }, [client, location.state]);

  // Determine loading state
  const isLoading = useMemo(() => {
    return isRefreshing || !isDataReady || loadingJobs;
  }, [isRefreshing, isDataReady, loadingJobs]);

  // Extract rendering components for better readability
  const renderLoadingState = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
      <CircularProgress />
      <Typography variant="body2" sx={{ ml: 2 }}>
        {isRefreshing ? 'Refreshing data...' : 'Loading data...'}
      </Typography>
    </Box>
  );

  const renderLoadingJobFamilyData = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
      {initialLoadComplete ? (
        <CircularProgress size="small" />
      ) : (
        <>
          <CircularProgress size="small" />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Loading job family data...
          </Typography>
        </>
      )}
    </Box>
  );

  const renderTable = () => (
    <Table
      processedData={processedData}
      selectedLevels={options
        .filter((option) => option.checked)
        .map((option) => option.value)}
      tier={tier}
      isAbsoluteRangeMode={isAbsoluteRangeMode}
      shallShowBreakdownButtons={shallShowBreakdownButtons}
    />
  );

  const renderNoJobFamilySelected = () => (
    <Typography>
      Please select a job family to view compensation ranges.
    </Typography>
  );

  return (
    <Stack spacing={2} mb={2}>
      <Grid container spacing={2}>
        <Grid>
          <CompRangesFamilyPicker
            onChange={handleJobFamilyChange}
            sx={{ minWidth: 285 }}
          />
        </Grid>
        <Grid>
          <MultiSelect
            disabled={!jobFamily}
            label={t('compRangesPage.select.level')}
            options={options}
            sx={{ minWidth: 285 }}
            onChange={handleLevelChange}
          />
        </Grid>
        <Grid>
          <TierPicker
            countryCode={CountryCode.US}
            useGeoDiff={equityGlobalMapping?.useGeoDiff ?? false}
            value={tier}
            onChange={setTier}
            sx={{ width: 150 }}
          />
        </Grid>
        <Grid>
          <ZipcodeTextField onChange={onChange} size="small" />
        </Grid>
        <Grid>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAbsoluteRangeMode}
                  onChange={(e) =>
                    handleIsAbsoluteRangeModeChange(e.target.checked)
                  }
                />
              }
              label={t('compRangesPage.rangeModeToggleLabel')}
            />
          </Box>
        </Grid>
        {canShowBreakdownButtons && (
          <Grid>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shallShowBreakdownButtons}
                    onChange={(e) =>
                      setShallShowBreakdownButtons(e.target.checked)
                    }
                  />
                }
                label={t('compRangesPage.breakdownToggleLabel')}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Render different content based on the current state of data loading and availability */}
      <TableContainer>
        {/* Show loading indicator when data is being fetched or refreshed */}
        {isLoading && renderLoadingState()}

        {/* Show loading indicator for job family data if job family is selected but data isn't processed yet */}
        {jobFamily && !processedData && renderLoadingJobFamilyData()}

        {/* Render the table if processed data is available */}
        {processedData && renderTable()}

        {/* Show a prompt to select a job family if no job family is selected */}
        {!jobFamily && renderNoJobFamilySelected()}
      </TableContainer>
    </Stack>
  );
}
