import { Flag } from './flags';
import { UserRole } from './user-roles';

export interface ScopeMetadata {
  id: Scope;
  label: string;
  featureGroup: FeatureGroup;
  group?: ScopeGroup;
  requires?: Scope[];
  isDependencyTo?: Scope[];
  flags?: Flag[];
}

export enum Scope {
  // DO NOT REMOVE ITEMS - USED IN DB
  EDIT_WORKSPACE = 'EDIT_WORKSPACE',
  VIEW_LOCATION_CALCULATOR = 'VIEW_LOCATION_CALCULATOR', // single location lookup
  VIEW_LOCATION_CALCULATOR_NOTES = 'VIEW_LOCATION_CALCULATOR_NOTES', // single location lookup
  EDIT_LOCATION_CALCULATOR = 'EDIT_LOCATION_CALCULATOR', // single location lookup
  LOCATION_CALCULATOR_MULTIPLE_VIEW = 'LOCATION_CALCULATOR_MULTIPLE_VIEW',
  EQUITY_BANDS_VIEW = 'EQUITY_BANDS_VIEW',
  EQUITY_BANDS_EDIT = 'EQUITY_BANDS_EDIT',
  JOB_ARCHITECTURE_VIEW = 'JOB_ARCHITECTURE_VIEW',
  JOB_ARCHITECTURE_EDIT = 'JOB_ARCHITECTURE_EDIT',
  JOB_DEFINITIONS_VIEW = 'JOB_DEFINITIONS_VIEW',
  JOB_DEFINITIONS_EDIT = 'JOB_DEFINITIONS_EDIT',
  RANGE_WIDTHS_VIEW = 'RANGE_WIDTHS_VIEW',
  RANGE_WIDTHS_EDIT = 'RANGE_WIDTHS_EDIT',
  JOB_FAMILIES_VIEW = 'JOB_FAMILIES_VIEW',
  JOB_FAMILIES_EDIT = 'JOB_FAMILIES_EDIT',
  JOB_FUNCTIONS_VIEW = 'JOB_FUNCTIONS_VIEW',
  JOB_FUNCTIONS_EDIT = 'JOB_FUNCTIONS_EDIT',
  BONUS_STRUCTURES_VIEW = 'BONUS_STRUCTURES_VIEW',
  BONUS_STRUCTURES_EDIT = 'BONUS_STRUCTURES_EDIT',
  COMP_BANDS_VIEW = 'COMP_BANDS_VIEW',
  COMP_BANDS_EDIT = 'COMP_BANDS_EDIT',
  SET_USERS_JOB_LEVEL = 'SET_USERS_JOB_LEVEL',
  SEE_ALL_LEVELS_DATA = 'SEE_ALL_LEVELS_DATA',
  COMP_RANGES_VIEW = 'COMP_RANGES_VIEW',
  COMP_RANGE_CALCULATION_BREAKDOWN_VIEW = 'COMP_RANGE_CALCULATION_BREAKDOWN_VIEW',
}

// note that the enum value is a i18n key
export enum FeatureGroup {
  WORKSPACE_MANAGEMENT = 'scopes.workspaceManagement.title',
  USERS_JOB_LEVEL = 'scopes.usersJobLevel.title',
  LEVEL_DATA = 'scopes.allLevelData.title',
  GEO_TIERS = 'scopes.geoTiers.title',
  COMP_RANGES = 'scopes.compRanges.title',
  JOB_CATALOG = 'scopes.jobCatalog.title',
}

// TODO could use a i18n key for the value
export enum ScopeGroup {
  GEO_TIERS_SINGLE_LOOKUP = 'GEO_TIERS_SINGLE_LOOKUP',
  GEO_TIERS_MULTIPLE_LOOKUP = 'GEO_TIERS_MULTIPLE_LOOKUP',
  JOB_CATALOG_JOB_ARCHITECTURE = 'JOB_CATALOG_JOB_ARCHITECTURE',
  JOB_CATALOG_JOB_DEFINITIONS = 'JOB_CATALOG_JOB_DEFINITIONS',
  JOB_CATALOG_EQUITY_BANDS = 'JOB_CATALOG_EQUITY_BANDS',
  JOB_CATALOG_RANGE_WIDTHS = 'JOB_CATALOG_RANGE_WIDTHS',
  JOB_CATALOG_JOB_FAMILIES = 'JOB_CATALOG_JOB_FAMILIES',
  JOB_CATALOG_JOB_FUNCTIONS = 'JOB_CATALOG_JOB_FUNCTIONS',
  JOB_CATALOG_BONUS_STRUCTURES = 'JOB_CATALOG_BONUS_STRUCTURES',
  JOB_CATALOG_COMP_BANDS = 'JOB_CATALOG_COMP_BANDS',
}

const ALL_SCOPES = new Set(Object.values(Scope));

/**
 * - `group` is used to group multiple scopes that are related or have some dependencies
 *    on each other, e.g. "edit job families" requires "view job families".
 * - Scopes that lack an explicit `group` will be put into their own group.
 * - Dependencies need to be defined in both directions via `requires` and `isDependencyTo`,
 *    e.g. { id: Scope.JOB_FAMILIES_VIEW, isDependencyTo: [Scope.JOB_FAMILIES_EDIT] },
 *         { id: Scope.JOB_FAMILIES_EDIT, requires: [Scope.JOB_FAMILIES_VIEW] }
 * - `featureGroup` is just for organization.
 */
export const Scopes: Record<Scope, ScopeMetadata> = {
  EDIT_WORKSPACE: {
    id: Scope.EDIT_WORKSPACE,
    label: 'scopes.workspaceManagement.editWorkspace.title',
    featureGroup: FeatureGroup.WORKSPACE_MANAGEMENT,
  },
  SET_USERS_JOB_LEVEL: {
    id: Scope.SET_USERS_JOB_LEVEL,
    label: 'scopes.usersJobLevel.title',
    featureGroup: FeatureGroup.USERS_JOB_LEVEL,
  },
  SEE_ALL_LEVELS_DATA: {
    id: Scope.SEE_ALL_LEVELS_DATA,
    label: 'scopes.allLevelData.title',
    featureGroup: FeatureGroup.LEVEL_DATA,
  },
  VIEW_LOCATION_CALCULATOR: {
    id: Scope.VIEW_LOCATION_CALCULATOR,
    label: 'scopes.geoTiers.viewGeoTiers.title',
    featureGroup: FeatureGroup.GEO_TIERS,
    group: ScopeGroup.GEO_TIERS_SINGLE_LOOKUP,
    isDependencyTo: [
      Scope.EDIT_LOCATION_CALCULATOR,
      Scope.VIEW_LOCATION_CALCULATOR_NOTES,
    ],
  },
  VIEW_LOCATION_CALCULATOR_NOTES: {
    id: Scope.VIEW_LOCATION_CALCULATOR_NOTES,
    label: 'scopes.geoTiers.viewGeoTiersNotes.title',
    featureGroup: FeatureGroup.GEO_TIERS,
    group: ScopeGroup.GEO_TIERS_SINGLE_LOOKUP,
    requires: [Scope.VIEW_LOCATION_CALCULATOR],
  },
  EDIT_LOCATION_CALCULATOR: {
    id: Scope.EDIT_LOCATION_CALCULATOR,
    label: 'scopes.geoTiers.editGeoTiers.title',
    featureGroup: FeatureGroup.GEO_TIERS,
    group: ScopeGroup.GEO_TIERS_SINGLE_LOOKUP,
    requires: [Scope.VIEW_LOCATION_CALCULATOR],
  },
  LOCATION_CALCULATOR_MULTIPLE_VIEW: {
    id: Scope.LOCATION_CALCULATOR_MULTIPLE_VIEW,
    label: 'scopes.geoTiers.viewMultipleGeoTiers.title',
    featureGroup: FeatureGroup.GEO_TIERS,
    group: ScopeGroup.GEO_TIERS_MULTIPLE_LOOKUP,
  },
  COMP_RANGES_VIEW: {
    id: Scope.COMP_RANGES_VIEW,
    label: 'scopes.compRanges.compRangesView.title',
    featureGroup: FeatureGroup.COMP_RANGES,
    flags: [Flag.JOB_CATALOG],
  },
  COMP_RANGE_CALCULATION_BREAKDOWN_VIEW: {
    id: Scope.COMP_RANGE_CALCULATION_BREAKDOWN_VIEW,
    label: 'scopes.compRanges.compRangeCalculationBreakdownView.title',
    featureGroup: FeatureGroup.COMP_RANGES,
    flags: [Flag.JOB_CATALOG],
  },
  JOB_ARCHITECTURE_VIEW: {
    id: Scope.JOB_ARCHITECTURE_VIEW,
    label: 'scopes.jobCatalog.jobArchitectureView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_ARCHITECTURE,
    isDependencyTo: [Scope.JOB_ARCHITECTURE_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_ARCHITECTURE_EDIT: {
    id: Scope.JOB_ARCHITECTURE_EDIT,
    label: 'scopes.jobCatalog.jobArchitectureEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_ARCHITECTURE,
    requires: [Scope.JOB_ARCHITECTURE_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_DEFINITIONS_VIEW: {
    id: Scope.JOB_DEFINITIONS_VIEW,
    label: 'scopes.jobCatalog.jobDefinitionsView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_DEFINITIONS,
    isDependencyTo: [Scope.JOB_DEFINITIONS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_DEFINITIONS_EDIT: {
    id: Scope.JOB_DEFINITIONS_EDIT,
    label: 'scopes.jobCatalog.jobDefinitionsEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_DEFINITIONS,
    requires: [Scope.JOB_DEFINITIONS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  EQUITY_BANDS_VIEW: {
    id: Scope.EQUITY_BANDS_VIEW,
    label: 'scopes.jobCatalog.equityBandsView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_EQUITY_BANDS,
    isDependencyTo: [Scope.EQUITY_BANDS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  EQUITY_BANDS_EDIT: {
    id: Scope.EQUITY_BANDS_EDIT,
    label: 'scopes.jobCatalog.equityBandsEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_EQUITY_BANDS,
    requires: [Scope.EQUITY_BANDS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  RANGE_WIDTHS_VIEW: {
    id: Scope.RANGE_WIDTHS_VIEW,
    label: 'scopes.jobCatalog.rangeWidthsView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_RANGE_WIDTHS,
    isDependencyTo: [Scope.RANGE_WIDTHS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  RANGE_WIDTHS_EDIT: {
    id: Scope.RANGE_WIDTHS_EDIT,
    label: 'scopes.jobCatalog.rangeWidthsEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_RANGE_WIDTHS,
    requires: [Scope.RANGE_WIDTHS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FAMILIES_VIEW: {
    id: Scope.JOB_FAMILIES_VIEW,
    label: 'scopes.jobCatalog.jobFamiliesView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_FAMILIES,
    isDependencyTo: [Scope.JOB_FAMILIES_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FAMILIES_EDIT: {
    id: Scope.JOB_FAMILIES_EDIT,
    label: 'scopes.jobCatalog.jobFamiliesEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_FAMILIES,
    requires: [Scope.JOB_FAMILIES_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FUNCTIONS_VIEW: {
    id: Scope.JOB_FUNCTIONS_VIEW,
    label: 'scopes.jobCatalog.jobFunctionsView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_FUNCTIONS,
    isDependencyTo: [Scope.JOB_FUNCTIONS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  JOB_FUNCTIONS_EDIT: {
    id: Scope.JOB_FUNCTIONS_EDIT,
    label: 'scopes.jobCatalog.jobFunctionsEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_JOB_FUNCTIONS,
    requires: [Scope.JOB_FUNCTIONS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  BONUS_STRUCTURES_VIEW: {
    id: Scope.BONUS_STRUCTURES_VIEW,
    label: 'scopes.jobCatalog.bonusStructuresView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_BONUS_STRUCTURES,
    isDependencyTo: [Scope.BONUS_STRUCTURES_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  BONUS_STRUCTURES_EDIT: {
    id: Scope.BONUS_STRUCTURES_EDIT,
    label: 'scopes.jobCatalog.bonusSctructuresEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_BONUS_STRUCTURES,
    requires: [Scope.BONUS_STRUCTURES_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
  COMP_BANDS_VIEW: {
    id: Scope.COMP_BANDS_VIEW,
    label: 'scopes.jobCatalog.compBandsView.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_COMP_BANDS,
    isDependencyTo: [Scope.COMP_BANDS_EDIT],
    flags: [Flag.JOB_CATALOG],
  },
  COMP_BANDS_EDIT: {
    id: Scope.COMP_BANDS_EDIT,
    label: 'scopes.jobCatalog.compBandsEdit.title',
    featureGroup: FeatureGroup.JOB_CATALOG,
    group: ScopeGroup.JOB_CATALOG_COMP_BANDS,
    requires: [Scope.COMP_BANDS_VIEW],
    flags: [Flag.JOB_CATALOG],
  },
};

export const ScopesByFeatureGroup: Record<FeatureGroup, ScopeMetadata[]> =
  Object.keys(Scopes).reduce((acc, scopeId) => {
    const scope = Scopes[scopeId as keyof typeof Scopes];
    if (!acc[scope.featureGroup]) {
      acc[scope.featureGroup] = [];
    }
    acc[scope.featureGroup].push(scope);
    return acc;
  }, {} as Record<FeatureGroup, ScopeMetadata[]>);

// NOTE scopes that lack an explicit `group` will be mapped by their `id` instead
export const ScopesByScopeGroup: Record<string, ScopeMetadata[]> = Object.keys(
  Scopes
).reduce((acc, scopeId) => {
  const scope = Scopes[scopeId as keyof typeof Scopes];
  const key = scope.group || scope.id;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(scope);
  return acc;
}, {} as Record<string, ScopeMetadata[]>);

export const PermissionSets: Record<UserRole, Set<Scope>> = {
  [UserRole.ADMIN]: ALL_SCOPES,
  [UserRole.JITA_WRITE]: ALL_SCOPES,
  [UserRole.MEMBER]: new Set([Scope.VIEW_LOCATION_CALCULATOR]),
  [UserRole.CUSTOM]: new Set(),
};

export function canAccessAllScopes(
  scopes: Scope[],
  membership: { role: UserRole; customRole?: { scopes: Scope[] } | null }
) {
  if (!scopes || scopes.length === 0) {
    return true;
  }

  for (const scope of scopes) {
    if (membership.role === UserRole.CUSTOM) {
      if (
        !membership.customRole ||
        !membership.customRole.scopes.includes(scope)
      ) {
        return false;
      }
    } else {
      if (!PermissionSets[membership.role].has(scope)) {
        return false;
      }
    }
  }

  return true;
}
