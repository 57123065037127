import { Scope } from '@alamere/core';
import {
  GlobalLevelFragment,
  RangeWidthFragment,
  RangeWidthSaveRequest,
  useGlobalLevelsQuery,
  useJobLevelsQuery,
  useRangeWidthGroupDeleteMutation,
  useRangeWidthGroupsQuery,
  useRangeWidthGroupsSaveMutation,
  useRangeWidthSaveMutation,
} from '@alamere/generated-graphql-types';
import AddIcon from '@mui/icons-material/Add';
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { t } from 'i18next';
import { cloneDeep, orderBy } from 'lodash';
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EditButtons } from '../../components/EditButtons';
import { useScopes } from '../../hooks/useScopes';
import {
  OnRangeWidthChangeFunction,
  OnRangeWidthGroupChangeFunction,
  OnRangeWidthGroupDeleteFunction,
  RangeWidthGroup,
  RangeWidthGroupUpdates,
  RangeWidthUpdates,
  RowData,
} from './types';
import { useAuth } from '../../providers/auth';
import { useSnackbar } from 'notistack';
import { CsvHeader, CsvUploadsModal } from '../CsvUploads/CsvUploadsModal';

export function RangeWidthsPage() {
  const { checkScopes } = useScopes();
  const { workspaceMembership } = useAuth();
  const { data: globalLevelData, loading: loadingGlobalLevel } =
    useGlobalLevelsQuery();
  const {
    data: rangeWidthGroupsData,
    refetch: refetch,
    loading: loadingRangeWidthGroups,
  } = useRangeWidthGroupsQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });
  const visibleGlobalLevels = useMemo(
    () =>
      globalLevelData?.globalLevels.filter((level) =>
        jobLevelsData?.jobLevels.some(
          (jobLevel) => jobLevel?.level === level.level
        )
      ),
    [globalLevelData, jobLevelsData]
  );

  const [saveRangeWidths] = useRangeWidthSaveMutation();
  const [saveRangeWidthGroups] = useRangeWidthGroupsSaveMutation();
  const [deleteRangeWidthGroup] = useRangeWidthGroupDeleteMutation();

  const [globalLevels, setGlobalLevels] = useState<GlobalLevelFragment[]>([]);
  const [rows, setRows] = useState<RowData[]>([]);
  const [rangeWidthGroups, setRangeWidthGroups] = useState<RangeWidthGroup[]>(
    []
  );

  const [updates, setUpdates] = useState<RangeWidthUpdates>({});
  const [rangeWidthGroupUpdates, setRangeWidthGroupUpdates] =
    useState<RangeWidthGroupUpdates>({});
  const [editing, setEditing] = useState<boolean>(false);
  const [resetKey, setResetKey] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const orderedGlobalLevels = useMemo(() => {
    if (!visibleGlobalLevels) return [];
    return orderBy(visibleGlobalLevels, ['level'], ['desc']);
  }, [visibleGlobalLevels]);

  useEffect(() => {
    if (visibleGlobalLevels && rangeWidthGroupsData?.rangeWidthGroups) {
      const sortedGroups = orderBy(
        rangeWidthGroupsData.rangeWidthGroups,
        ['id'],
        ['asc']
      );

      const rows = orderedGlobalLevels.map((globalLevel) => {
        return {
          globalLevel,
          rangeWidths: sortedGroups.map((rangeWidthGroup) => {
            return rangeWidthGroup.rangeWidths.find(
              (rangeWidth) => rangeWidth.level === globalLevel.level
            );
          }),
        };
      });

      setRangeWidthGroups(sortedGroups);
      setGlobalLevels(orderedGlobalLevels);
      setRows(rows);
      setUpdates({});
      setRangeWidthGroupUpdates({});
    }
  }, [visibleGlobalLevels, rangeWidthGroupsData, orderedGlobalLevels]);

  const handleRangeWidthChange = useCallback<OnRangeWidthChangeFunction>(
    ({ rangeWidth }) => {
      setUpdates((updates) => {
        const newUpdates = cloneDeep(updates);
        if (!newUpdates[rangeWidth.level]) {
          newUpdates[rangeWidth.level] = {};
        }
        newUpdates[rangeWidth.level][rangeWidth.rangeWidthGroupId] = rangeWidth;
        return newUpdates;
      });
    },
    []
  );

  const handleDeleteRangeWidthGroup =
    useCallback<OnRangeWidthGroupDeleteFunction>(async (id) => {
      await deleteRangeWidthGroup({
        variables: { rangeWidthGroupDeleteId: id },
      });
      refetch();
    }, []);

  const handleSave = async () => {
    const widthUpdates = (
      Object.values(updates) as {
        [rangeWidthGroupId: number]: RangeWidthSaveRequest;
      }[]
    ).flatMap(
      (levelUpdates) => Object.values(levelUpdates) as RangeWidthSaveRequest[]
    );
    const groupUpdates = Object.values(rangeWidthGroupUpdates);

    if (widthUpdates.length > 0) {
      await saveRangeWidths({
        variables: {
          rangeWidthSaveRequest: {
            items: widthUpdates,
          },
        },
      });
    }
    if (groupUpdates.length > 0) {
      await saveRangeWidthGroups({
        variables: {
          rangeWidthGroupSaveManyRequest: {
            items: groupUpdates,
          },
        },
      });
    }

    await refetch();
    setEditing(false);
  };

  const handleCreateNewGroup = async () => {
    await saveRangeWidthGroups({
      variables: {
        rangeWidthGroupSaveManyRequest: {
          items: [{ name: t('rangeWidthsPage.newGroupName') }],
        },
      },
    });
    await refetch();
  };

  const handleRangeWidthGroupChange =
    useCallback<OnRangeWidthGroupChangeFunction>((rangeWidthGroup) => {
      setRangeWidthGroupUpdates((updates) => {
        const newUpdates = cloneDeep(updates);
        newUpdates[rangeWidthGroup.id] = rangeWidthGroup;
        return newUpdates;
      });
    }, []);

  const handleCancel = () => {
    setEditing(false);
    setUpdates({});
    setRangeWidthGroupUpdates({});
    setResetKey((prevKey) => prevKey + 1);
  };

  const noData = useMemo(
    () => rows.every((row) => row.rangeWidths.length === 0),
    [rows]
  );

  // Define the headers for the CSV
  const csvHeaders: CsvHeader[] = [
    { key: 'groupName', label: 'Group Name', required: true },
    { key: 'globalLevel', label: 'Global Level', required: true },
    {
      key: 'percentBelowMid',
      label: '% Below Mid',
      type: 'percentage',
      required: true,
    },
    {
      key: 'percentAboveMid',
      label: '% Above Mid',
      type: 'percentage',
      required: true,
    },
  ];

  // Generate example content for the CSV download
  const generateExampleContent = () => {
    let csvContent = `${csvHeaders.map((h) => h.label).join(',')}\n`;

    for (const level of orderedGlobalLevels) {
      csvContent += `Example Group,${level.name || `L${level.level}`},25,25\n`;
    }

    for (const level of orderedGlobalLevels) {
      csvContent += `Another Example Group,${
        level.name || `L${level.level}`
      },30,30\n`;
    }

    return csvContent;
  };

  // Row validation function
  const validateRangeWidthRow = (
    row: Record<string, string>,
    rowIndex: number
  ) => {
    // Check if the required percentage fields exist and are valid positive numbers
    if (
      row['% Below Mid'] === undefined ||
      row['% Below Mid'] === null ||
      row['% Below Mid'] === ''
    ) {
      return `Line ${rowIndex + 1}: % Below Mid is required`;
    }

    if (
      row['% Above Mid'] === undefined ||
      row['% Above Mid'] === null ||
      row['% Above Mid'] === ''
    ) {
      return `Line ${rowIndex + 1}: % Above Mid is required`;
    }

    // Check for invalid values
    if (isNaN(Number(row['% Below Mid'])) || Number(row['% Below Mid']) < 0) {
      return `Line ${rowIndex + 1}: % Below Mid must be a positive number`;
    }

    if (isNaN(Number(row['% Above Mid'])) || Number(row['% Above Mid']) < 0) {
      return `Line ${rowIndex + 1}: % Above Mid must be a positive number`;
    }

    return null;
  };

  // Process data function
  const processRangeWidthData = async (
    groupedData: Record<string, Array<Record<string, any>>>
  ) => {
    try {
      // Process each group
      for (const [groupName, items] of Object.entries(groupedData)) {
        // Create a new group with the specified name
        const result = await saveRangeWidthGroups({
          variables: {
            rangeWidthGroupSaveManyRequest: {
              items: [{ name: groupName }],
            },
          },
        });

        // Get the newly created group ID
        const newGroupId = result.data?.rangeWidthGroupsSave?.[0]?.id;
        if (!newGroupId) {
          throw new Error('Failed to create new group');
        }

        // Process the rows and create the range width entries
        const rangeWidthRequests: RangeWidthSaveRequest[] = [];

        for (const item of items) {
          // Find the global level by name
          const foundGlobalLevel = orderedGlobalLevels.find(
            (level) =>
              level.name === item.globalLevel ||
              `L${level.level}` === item.globalLevel
          );

          if (foundGlobalLevel) {
            const percentBelowMid = Number(item.percentBelowMid);
            const percentAboveMid = Number(item.percentAboveMid);

            if (isNaN(percentBelowMid) || percentBelowMid < 0) {
              throw new Error(
                `Invalid % Below Mid value for level ${foundGlobalLevel.level}`
              );
            }

            if (isNaN(percentAboveMid) || percentAboveMid < 0) {
              throw new Error(
                `Invalid % Above Mid value for level ${foundGlobalLevel.level}`
              );
            }

            rangeWidthRequests.push({
              rangeWidthGroupId: newGroupId,
              level: foundGlobalLevel.level,
              percentBelowMid: percentBelowMid,
              percentAboveMid: percentAboveMid,
            });
          }
        }

        // Save the range width data for this group
        if (rangeWidthRequests.length > 0) {
          await saveRangeWidths({
            variables: {
              rangeWidthSaveRequest: {
                items: rangeWidthRequests,
              },
            },
          });
        }
      }

      // Refresh data after all groups are processed
      await refetch();
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(`Import failed: ${error.message}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Import failed due to an unknown error', {
          variant: 'error',
        });
      }

      throw error;
    }
  };

  const LazyRangeWidthsTable = lazy(() => import('./RangeWidthsTable'));
  const tableComponent = useMemo(() => {
    return (
      <LazyRangeWidthsTable
        key={resetKey}
        globalLevel={globalLevels}
        rows={rows}
        rangeWidthGroups={rangeWidthGroups}
        editing={editing}
        onRangeWidthChange={handleRangeWidthChange}
        onRangeWidthGroupChange={handleRangeWidthGroupChange}
        onRangeWidthGroupDelete={handleDeleteRangeWidthGroup}
      />
    );
  }, [globalLevels, rows, rangeWidthGroups, editing, resetKey]);

  const loading = loadingGlobalLevel || loadingRangeWidthGroups;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {checkScopes(Scope.RANGE_WIDTHS_EDIT) && (
          <EditButtons
            onEdit={() => setEditing(true)}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}
        {editing && (
          <CsvUploadsModalWrapper
            pageName={t('rangeWidthsPage.title', 'Range Widths')}
            headers={csvHeaders}
            exampleContent={generateExampleContent()}
            templateFileName="range_widths_template.csv"
            validateRow={validateRangeWidthRow}
            processData={processRangeWidthData}
            groupByField="groupName"
          />
        )}
      </Box>
      <Suspense fallback={<CircularProgress />}>
        {noData && !loadingRangeWidthGroups && !editing ? (
          <Typography>{t('rangeWidthsPage.noData')}</Typography>
        ) : (
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              {tableComponent}
              {editing && (
                <IconButton
                  aria-label={t('rangeWidthsPage.createNewGroup')}
                  sx={{ height: 'fit-content' }}
                  title={t('rangeWidthsPage.createNewGroup')}
                  onClick={handleCreateNewGroup}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Stack>

            {editing && <Box sx={{ mb: 10 }} />}
          </Stack>
        )}
      </Suspense>
    </>
  );
}

const CsvUploadsModalWrapper = (
  props: React.ComponentProps<typeof CsvUploadsModal>
) => {
  return (
    <CsvUploadsModal
      {...props}
      renderButton={(onClick) => (
        <Box sx={{ ml: 2 }}>
          <CsvUploadsModal.Button onClick={onClick} />
        </Box>
      )}
    />
  );
};
