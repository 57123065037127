import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  JobFamilySaveRequest,
  useJobFamiliesQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Chip,
  InputAdornment,
  OutlinedInput,
  Stack,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { memo } from 'react';
import { DEBOUNCE_MS } from '../../../lib/constants';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
}
export const DynamicParent = memo(
  ({ editing, jobFamily, onItemChange }: Props) => {
    const debouncedOnItemChange = debounce(onItemChange, DEBOUNCE_MS);
    const { cache } = useApolloClient();

    const handlePercentChange = (value: string) => {
      const newItem = {
        ...jobFamily,
        compBandMultiplier: parseInt(value) || null,
      };

      cache.writeFragment<JobFamilySaveRequest>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data: newItem,
      });

      if (newItem.compBandMultiplier) {
        debouncedOnItemChange({ newItem });
      }
    };

    return (
      <TableCell>
        <OutlinedInput
          sx={{ minWidth: 85, display: editing ? undefined : 'none' }}
          inputProps={{ style: { textAlign: 'center' } }}
          size="small"
          value={jobFamily.compBandMultiplier ?? ''}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          onChange={(e) => handlePercentChange(e.target.value)}
        />
        <Typography
          sx={{ display: !editing ? undefined : 'none', textAlign: 'center' }}
        >
          {jobFamily?.compBandMultiplier}%
        </Typography>
      </TableCell>
    );
  }
);
