import { ApolloClient } from '@apollo/client';
import {
  JobFamilyFragment,
  RadfordJobFamilyFragment,
  CompBandGroupsQuery,
  RangeWidthGroupsQuery,
  BonusStructureGroupsQuery,
  EquityBandGroupsQuery,
  JobFunctionsQuery,
  RadfordJobFamiliesQuery,
  GetAllJobFamilyReferenceDataQuery,
  GetAllJobFamilyReferenceDataDocument,
} from '@alamere/generated-graphql-types';

import { JobGroup } from '@alamere/core';
import { createNewJobFamily } from '../../JobFamiliesPage/utils';
import { CsvHeader, CsvValue } from '../CsvUploadsModal';
import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

// Typed ApolloClient
type TypedApolloClient = ApolloClient<object>;

// Define type for the return value of fetchReferenceData
type ReferenceData = {
  compBandData: CompBandGroupsQuery | undefined;
  rangeWidthData: RangeWidthGroupsQuery | undefined;
  bonusStructureData: BonusStructureGroupsQuery | undefined;
  equityBandData: EquityBandGroupsQuery | undefined;
  jobFunctionsData: JobFunctionsQuery | undefined;
  radfordData: RadfordJobFamiliesQuery | undefined;
};

// Define interface for reference entities
interface ReferenceEntity {
  id: number;
  name: string;
}

// Define interface for snackbar options
interface SnackbarOptions {
  variant: 'success' | 'error' | 'warning' | 'info';
  preventDuplicate?: boolean;
}

// Define headers for job families CSV
export const jobFamiliesHeaders: CsvHeader[] = [
  { key: 'code', label: 'Code', required: true },
  { key: 'jobFunction', label: 'Job Function' },
  { key: 'name', label: 'Job Family Name', required: true },
  { key: 'ignoreGeoDiff', label: 'Ignore Geo Diff', type: 'string' },
  { key: 'compBandGroup', label: 'Comp Band Group' },
  { key: 'rangeWidthGroup', label: 'Range Width Group' },
  { key: 'bonusStructureGroup', label: 'Bonus Structure Group' },
  { key: 'equityBandGroup', label: 'Equity Band Group' },
  {
    key: 'compBandMultiplier',
    label: 'Comp Band Multiplier (%)',
    type: 'percentage',
  },
  { key: 'radfordExecutiveMatch', label: 'Radford Executive Match' },
  { key: 'radfordManagementMatch', label: 'Radford Management Match' },
  { key: 'radfordProfessionalMatch', label: 'Radford Professional Match' },
  { key: 'radfordTechnicalMatch', label: 'Radford Technical Match' },
  { key: 'radfordSupportMatch', label: 'Radford Support Match' },
];

// Example content template
export const jobFamiliesExampleContent =
  'Code,Job Function,Job Family Name,Ignore Geo Diff,Comp Band Group,Range Width Group,Bonus Structure Group,Equity Band Group,Comp Band Multiplier (%),Radford Executive Match,Radford Management Match,Radford Professional Match,Radford Technical Match,Radford Support Match\n';

// Helper function to convert various string representations to boolean
export const parseBooleanValue = (value: string): boolean => {
  if (!value) return false;

  const normalizedValue = value.toLowerCase().trim();
  return ['true', 'yes', 'y', '1', '+', 'on'].includes(normalizedValue);
};

// Define validation function - adapted to work with PapaParse validation flow
export const validateJobFamilyRow = (
  row: Record<string, string>
): string | null => {
  // Check if we have the data in either format (key or label)
  const code = row.code || row.Code || '';
  const name = row.name || row['Job Family Name'] || '';

  // Check required fields first
  if (!code || !name) {
    return `Missing required code or name`;
  }

  // Validate code format
  if (code && !/^[A-Za-z0-9]+$/.test(code)) {
    return `Code must contain only alphanumeric characters`;
  }

  // Check for minimum name length
  if (name && name.length < 2) {
    return `Name must be at least 2 characters long`;
  }

  // Validate compBandMultiplier is a positive number or empty
  const multiplierValue =
    row.compBandMultiplier || row['Comp Band Multiplier (%)'] || '';
  if (multiplierValue) {
    const multiplier = Number(multiplierValue);
    if (isNaN(multiplier) || multiplier < 0) {
      return `Comp Band Multiplier must be a positive percentage value`;
    }
  }

  return null; // No validation errors
};

// A helper function to build reference entity maps
function createReferenceMap<T extends ReferenceEntity>(
  entities: T[] | undefined
): Map<string, T> {
  const map = new Map<string, T>();
  if (entities) {
    entities.forEach((entity) => {
      map.set(entity.name.toLowerCase(), entity);
    });
  }
  return map;
}

// Define specific type for each entity type's __typename
type EntityTypeName =
  | 'JobFunction'
  | 'CompBandGroup'
  | 'RangeWidthGroup'
  | 'BonusStructureGroup'
  | 'EquityBandGroup';

// Helper function to find and map related entities with proper typing
function findRelatedEntity<T extends EntityTypeName>(
  fieldValue: string | null | undefined,
  entityMap: Map<string, ReferenceEntity>,
  entityType: T,
  jobFamilyName: string,
  warnings: string[]
): { id: number; name: string; __typename: T } | null {
  if (!fieldValue) return null;

  const lowercaseValue = fieldValue.toString().toLowerCase();
  const matchingEntity = entityMap.get(lowercaseValue);

  if (matchingEntity) {
    return {
      id: matchingEntity.id,
      name: matchingEntity.name,
      __typename: entityType,
    };
  } else {
    warnings.push(
      `${entityType} "${fieldValue}" not found for job family "${jobFamilyName}"`
    );
    return null;
  }
}

// Helper function to validate and process Radford match values
function validateRadfordMatch(
  value: string | null | undefined,
  jobFamilyName: string,
  radfordType: string,
  jobGroup: JobGroup,
  radfordJobFamilies: RadfordJobFamilyFragment[],
  warnings: string[]
): string | null {
  if (!value || value.trim() === '') return null;

  // Find matching Radford code for the specific job group
  const matchingRadford = radfordJobFamilies.find(
    (r) => r.code === value && r.jobGroup === jobGroup
  );

  if (matchingRadford) {
    return value;
  } else {
    warnings.push(
      `Radford ${radfordType} match "${value}" could not be matched for job family "${jobFamilyName}"`
    );
    return null;
  }
}

// Add a helper function to safely extract typed values
function getString(value: CsvValue | undefined): string {
  if (typeof value === 'string') {
    return value;
  }
  return value?.toString() || '';
}

function getNumber(value: CsvValue | undefined): number | null {
  if (value === null || value === undefined || value === '') {
    return null;
  }
  const num = typeof value === 'number' ? value : Number(value);
  return isNaN(num) ? null : num;
}

function getBoolean(value: CsvValue | undefined): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return parseBooleanValue(value);
  }
  return Boolean(value);
}

// Process CSV data with improved efficiency
export const processJobFamilyData = async (
  processedData: Record<string, Array<Record<string, CsvValue>>>,
  client: TypedApolloClient,
  existingItems: JobFamilyFragment[],
  enqueueSnackbar: (message: string, options: SnackbarOptions) => void,
  fetchReferenceData: (client: TypedApolloClient) => Promise<ReferenceData>
): Promise<{
  newUpdates: Record<string, JobFamilyFragment>;
  warnings: string[];
  criticalErrors: string[];
}> => {
  try {
    // Flatten the data structure from the CsvUploadsModal
    const allRows = Object.values(processedData).flat();

    if (allRows.length === 0) {
      return { newUpdates: {}, warnings: [], criticalErrors: [] };
    }

    // Load all necessary reference data using the reusable function
    const {
      compBandData,
      rangeWidthData,
      bonusStructureData,
      equityBandData,
      jobFunctionsData,
      radfordData,
    } = await fetchReferenceData(client);

    // Create lookup maps for faster reference data access using our helper
    const jobFunctionMap = createReferenceMap(jobFunctionsData?.jobFunctions);
    const compBandGroupMap = createReferenceMap(compBandData?.compBandsGroups);
    const rangeWidthGroupMap = createReferenceMap(
      rangeWidthData?.rangeWidthGroups
    );
    const bonusStructureGroupMap = createReferenceMap(
      bonusStructureData?.bonusStructureGroups
    );
    const equityBandGroupMap = createReferenceMap(
      equityBandData?.equityBandGroups
    );

    // Get Radford job families for validation
    const radfordJobFamilies = radfordData?.radfordJobFamilies || [];

    // Initialize variables
    const newUpdates: Record<string, JobFamilyFragment> = {};
    const warnings: string[] = []; // Non-critical warnings (reference data not found)
    const criticalErrors: string[] = []; // Critical errors (validation failures)
    const allExistingItems = [...existingItems];

    // Process all rows at once
    for (const row of allRows) {
      // Replace casts with type-safe accessors
      const code = getString(row.code);
      const name = getString(row.name);

      if (!code || !name) {
        criticalErrors.push('Missing required code or name');
        continue;
      }

      const newJobFamily = createNewJobFamily(code, name, allExistingItems);

      // Find related entities using our helper function
      const jobFunction = findRelatedEntity(
        getString(row.jobFunction),
        jobFunctionMap,
        'JobFunction',
        name,
        warnings
      );

      const compBandGroup = findRelatedEntity(
        getString(row.compBandGroup),
        compBandGroupMap,
        'CompBandGroup',
        name,
        warnings
      );

      const rangeWidthGroup = findRelatedEntity(
        getString(row.rangeWidthGroup),
        rangeWidthGroupMap,
        'RangeWidthGroup',
        name,
        warnings
      );

      const bonusStructureGroup = findRelatedEntity(
        getString(row.bonusStructureGroup),
        bonusStructureGroupMap,
        'BonusStructureGroup',
        name,
        warnings
      );

      const equityBandGroup = findRelatedEntity(
        getString(row.equityBandGroup),
        equityBandGroupMap,
        'EquityBandGroup',
        name,
        warnings
      );

      // Convert ignoreGeoDiff from string to boolean
      const ignoreGeoDiff = getBoolean(row.ignoreGeoDiff);

      // For numeric values
      const compBandMultiplier = getNumber(row.compBandMultiplier);

      // Create the job family object with all properties
      newUpdates[String(newJobFamily.id)] = {
        id: newJobFamily.id,
        code: newJobFamily.code,
        name: name,
        ignoreGeoDiff: ignoreGeoDiff,
        compBandMultiplier: compBandMultiplier,
        compBandGroup: compBandGroup,
        rangeWidthGroup: rangeWidthGroup,
        bonusStructureGroup: bonusStructureGroup,
        equityBandGroup: equityBandGroup,
        jobFunction: jobFunction,
        radfordExecutiveMatchCode: validateRadfordMatch(
          getString(row.radfordExecutiveMatch),
          name,
          'Executive',
          JobGroup.Executive,
          radfordJobFamilies,
          warnings
        ),
        radfordManagementMatchCode: validateRadfordMatch(
          getString(row.radfordManagementMatch),
          name,
          'Management',
          JobGroup.Management,
          radfordJobFamilies,
          warnings
        ),
        radfordProfessionalMatchCode: validateRadfordMatch(
          getString(row.radfordProfessionalMatch),
          name,
          'Professional',
          JobGroup.Professional,
          radfordJobFamilies,
          warnings
        ),
        radfordScientificMatchCode: validateRadfordMatch(
          getString(row.radfordTechnicalMatch),
          name,
          'Technical',
          JobGroup.TechnicalScientific,
          radfordJobFamilies,
          warnings
        ),
        radfordSupportMatchCode: validateRadfordMatch(
          getString(row.radfordSupportMatch),
          name,
          'Support',
          JobGroup.Support,
          radfordJobFamilies,
          warnings
        ),
        __typename: 'JobFamily' as const,
      };

      allExistingItems.push(newUpdates[String(newJobFamily.id)]);
    }

    return { newUpdates, warnings, criticalErrors };
  } catch (error) {
    enqueueSnackbar(
      `Error processing CSV: ${
        error instanceof Error ? error.message : 'Unknown error'
      }`,
      { variant: 'error' }
    );
    return { newUpdates: {}, warnings: [], criticalErrors: [] };
  }
};

// Create warning dialog content
export const createWarningDialogContent = (
  warnings: string[]
): React.ReactNode => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {t('jobFamiliesPage.import.warningTitle', 'CSV Import Warnings')}
      </Typography>
      <div style={{ maxHeight: '300px', overflow: 'auto' }}>
        <ul>
          {warnings.map((warning, index) => (
            <li key={index}>
              <Typography variant="body2">{warning}</Typography>
            </li>
          ))}
        </ul>
      </div>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        {t(
          'jobFamiliesPage.import.warningHelp',
          'You can fix these warnings and re-upload, or continue with the import.'
        )}
      </Typography>
    </>
  );
};

// New function for pre-validation before submitting the data
export const preValidateJobFamilies = async (
  processedData: Record<string, Array<Record<string, CsvValue>>>,
  client: TypedApolloClient,
  fetchReferenceData: (client: TypedApolloClient) => Promise<ReferenceData>
): Promise<{
  warnings: string[];
  criticalErrors: string[];
  isValid: boolean;
}> => {
  try {
    // Flatten the data structure from the CsvUploadsModal
    const allRows = Object.values(processedData).flat();

    if (allRows.length === 0) {
      return { warnings: [], criticalErrors: [], isValid: true };
    }

    // Load all necessary reference data using the reusable function
    const {
      compBandData,
      rangeWidthData,
      bonusStructureData,
      equityBandData,
      jobFunctionsData,
      radfordData,
    } = await fetchReferenceData(client);

    // Create lookup maps for faster reference data access
    const jobFunctionMap = createReferenceMap(jobFunctionsData?.jobFunctions);
    const compBandGroupMap = createReferenceMap(compBandData?.compBandsGroups);
    const rangeWidthGroupMap = createReferenceMap(
      rangeWidthData?.rangeWidthGroups
    );
    const bonusStructureGroupMap = createReferenceMap(
      bonusStructureData?.bonusStructureGroups
    );
    const equityBandGroupMap = createReferenceMap(
      equityBandData?.equityBandGroups
    );

    // Get Radford job families for validation
    const radfordJobFamilies = radfordData?.radfordJobFamilies || [];

    // Initialize variables
    const warnings: string[] = []; // Non-critical warnings (reference data not found)
    const criticalErrors: string[] = []; // Critical errors (validation failures)

    // Process all rows to check for warnings
    for (const row of allRows) {
      // Get basic fields
      const code = getString(row.code);
      const name = getString(row.name);

      if (!code || !name) {
        criticalErrors.push('Missing required code or name');
        continue;
      }

      // Check for entity references and collect warnings
      checkEntityReference(
        getString(row.jobFunction),
        jobFunctionMap,
        'Job Function',
        name,
        warnings
      );

      checkEntityReference(
        getString(row.compBandGroup),
        compBandGroupMap,
        'Comp Band Group',
        name,
        warnings
      );

      checkEntityReference(
        getString(row.rangeWidthGroup),
        rangeWidthGroupMap,
        'Range Width Group',
        name,
        warnings
      );

      checkEntityReference(
        getString(row.bonusStructureGroup),
        bonusStructureGroupMap,
        'Bonus Structure Group',
        name,
        warnings
      );

      checkEntityReference(
        getString(row.equityBandGroup),
        equityBandGroupMap,
        'Equity Band Group',
        name,
        warnings
      );

      // Check Radford matches
      checkRadfordMatch(
        getString(row.radfordExecutiveMatch),
        name,
        'Executive',
        JobGroup.Executive,
        radfordJobFamilies,
        warnings
      );

      checkRadfordMatch(
        getString(row.radfordManagementMatch),
        name,
        'Management',
        JobGroup.Management,
        radfordJobFamilies,
        warnings
      );

      checkRadfordMatch(
        getString(row.radfordProfessionalMatch),
        name,
        'Professional',
        JobGroup.Professional,
        radfordJobFamilies,
        warnings
      );

      checkRadfordMatch(
        getString(row.radfordTechnicalMatch),
        name,
        'Technical',
        JobGroup.TechnicalScientific,
        radfordJobFamilies,
        warnings
      );

      checkRadfordMatch(
        getString(row.radfordSupportMatch),
        name,
        'Support',
        JobGroup.Support,
        radfordJobFamilies,
        warnings
      );
    }

    return { warnings, criticalErrors, isValid: criticalErrors.length === 0 };
  } catch (error) {
    console.error('Error during pre-validation:', error);
    return {
      warnings: [],
      criticalErrors: [
        `Error during validation: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      ],
      isValid: false,
    };
  }
};

// Helper function to check entity references and add warnings
function checkEntityReference(
  value: string,
  entityMap: Map<string, ReferenceEntity>,
  entityType: string,
  jobFamilyName: string,
  warnings: string[]
): void {
  if (!value) return;

  const lowercaseValue = value.toLowerCase();
  const matchingEntity = entityMap.get(lowercaseValue);

  if (!matchingEntity) {
    warnings.push(
      `${entityType} "${value}" not found for job family "${jobFamilyName}"`
    );
  }
}

// Helper function to check Radford matches and add warnings
function checkRadfordMatch(
  value: string,
  jobFamilyName: string,
  radfordType: string,
  jobGroup: JobGroup,
  radfordJobFamilies: RadfordJobFamilyFragment[],
  warnings: string[]
): void {
  if (!value || value.trim() === '') return;

  // Find matching Radford code
  const matchingRadford = radfordJobFamilies.find(
    (r) => r.code === value && r.jobGroup === jobGroup
  );

  if (!matchingRadford) {
    warnings.push(
      `Radford ${radfordType} match "${value}" could not be matched for job family "${jobFamilyName}"`
    );
  }
}

// Function to fetch all reference data in one place
export const useFetchReferenceData = () => {
  return useCallback(
    async (client: TypedApolloClient): Promise<ReferenceData> => {
      try {
        const response = await client.query<GetAllJobFamilyReferenceDataQuery>({
          query: GetAllJobFamilyReferenceDataDocument,
        });

        // Map the consolidated response to the expected format
        return {
          compBandData: { compBandsGroups: response.data.compBandsGroups },
          rangeWidthData: { rangeWidthGroups: response.data.rangeWidthGroups },
          bonusStructureData: {
            bonusStructureGroups: response.data.bonusStructureGroups,
          },
          equityBandData: { equityBandGroups: response.data.equityBandGroups },
          jobFunctionsData: { jobFunctions: response.data.jobFunctions },
          radfordData: { radfordJobFamilies: response.data.radfordJobFamilies },
        };
      } catch (error) {
        enqueueSnackbar(
          `Error fetching reference data: ${
            error instanceof Error ? error.message : String(error)
          }`,
          { variant: 'error' }
        );
        throw new Error(
          `Failed to fetch reference data: ${
            error instanceof Error ? error.message : String(error)
          }`
        );
      }
    },
    []
  );
};
