const blessedUsers = new Set([
  'george@alamere.io',
  'bernardo@alamere.io',
  'dan@alamere.io',
  'damasceno@alamere.io',
]);

export function isBlessedUser(email?: string): boolean {
  return !!(email && blessedUsers.has(email));
}
