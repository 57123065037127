import {
  JobFragmentDoc,
  JobLevelFragment,
  useJobsSaveMutation,
} from '@alamere/generated-graphql-types';
import AddIcon from '@mui/icons-material/AddRounded';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { memo } from 'react';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';

interface Props {
  editing: boolean;
  visible: boolean;
  jobFamilyId: number;
  jobLevel: JobLevelFragment;
  onItemChange: OnItemChangeFunction;
}

export default memo(
  ({
    editing,
    jobFamilyId,
    visible: visibile,
    jobLevel,
    onItemChange,
  }: Props) => {
    const [createNew] = useJobsSaveMutation({
      update: (cache, { data }) => {
        const jobs = data?.jobsSave;
        if (!jobs) return;
        cache.modify({
          fields: {
            jobs(existingJobs = []) {
              const newRefs = jobs.map((job) =>
                cache.writeFragment({
                  id: `Job:${job.id}`,
                  data: job,
                  fragment: JobFragmentDoc,
                  fragmentName: 'Job',
                })
              );
              return [...existingJobs, ...newRefs];
            },
          },
        });
        onItemChange({ jobIdsToDeleteIfCancel: jobs.map((job) => job.id) });
      },
    });

    const handleCreateNewJob = async () => {
      const jobTitle = jobLevel.name;

      const res = await createNew({
        variables: {
          jobSaveManyRequest: {
            items: [
              {
                title: jobTitle,
                jobFamilyId,
                jobLevelId: jobLevel.id,
                businessTitle: '',
                jobCode: '',
                jobSubLevel: '',
                jobType: 'Salaried',
              },
            ],
          },
        },
      });
    };

    if (!editing) {
      return null;
    }

    return (
      <IconButton
        aria-label={t('jobdefinitionsPage.createNewJob')}
        sx={{
          height: 'fit-content',
          visibility: visibile ? 'visible' : 'hidden',
        }}
        size="small"
        title={t('jobdefinitionsPage.createNewJob')}
        onClick={handleCreateNewJob}
      >
        <AddIcon />
      </IconButton>
    );
  }
);
