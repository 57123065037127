import { Scope } from '@alamere/core';
import {
  BonusStructureGroupSaveRequest,
  BonusStructureGroupsDocument,
  BonusStructureSaveRequest,
  useBonusStructureGroupsSaveMutation,
  useBonusStructuresSaveMutation,
  useGlobalLevelsQuery,
  useJobLevelsQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { Stack, Box } from '@mui/material';
import { useState, useMemo } from 'react';
import { t } from 'i18next';
import { orderBy } from 'lodash';
import { EditButtons } from '../../components/EditButtons';
import { NewGroup } from './NewGroup';
import { Table } from './Table';
import { OnGroupChangeFunction, OnItemChangeFunction } from './types';
import {
  CsvHeader,
  CsvUploadsModal,
  CsvValue,
} from '../CsvUploads/CsvUploadsModal';
import { useSnackbar } from 'notistack';

export default function BonusStructuresPage() {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [groupUpdates, setGroupUpdates] = useState<
    Record<string, BonusStructureGroupSaveRequest>
  >({});
  const [itemUpdates, setItemUpdates] = useState<
    Record<string, BonusStructureSaveRequest>
  >({});

  const [saveGroups] = useBonusStructureGroupsSaveMutation();
  const [saveItems] = useBonusStructuresSaveMutation();

  const { data: globalLevelData } = useGlobalLevelsQuery();
  const { data: jobLevelsData } = useJobLevelsQuery({
    variables: {
      jobLevelsGetRequest: {
        onlyVisible: true,
      },
    },
  });

  const visibleGlobalLevels = useMemo(
    () =>
      globalLevelData?.globalLevels.filter((level) =>
        jobLevelsData?.jobLevels.some(
          (jobLevel) => jobLevel?.level === level.level
        )
      ),
    [globalLevelData, jobLevelsData]
  );

  const orderedGlobalLevels = useMemo(() => {
    if (!visibleGlobalLevels) return [];

    return orderBy(visibleGlobalLevels, ['level'], ['desc']);
  }, [visibleGlobalLevels]);

  const handleSave = () => {
    Promise.all([
      Object.values(groupUpdates).length > 0 &&
        saveGroups({
          variables: {
            bonusStructureGroupSaveManyRequest: {
              items: Object.values(groupUpdates),
            },
          },
        }),
      Object.values(itemUpdates).length > 0 &&
        saveItems({
          variables: {
            bonusStructuresSaveRequest: {
              items: Object.values(itemUpdates),
            },
          },
        }),
    ]);
    setGroupUpdates({});
    setItemUpdates({});
    setEditing(false);
  };

  const handleCancel = () => {
    client.refetchQueries({
      include: [BonusStructureGroupsDocument],
    });
    setGroupUpdates({});
    setItemUpdates({});
    setEditing(false);
  };

  const handleGroupChange: OnGroupChangeFunction = (newGroup) => {
    setGroupUpdates((prev) => {
      if (!newGroup.id) {
        return prev;
      }
      return {
        ...prev,
        [newGroup.id]: {
          name: newGroup.name,
          id: newGroup.id,
        },
      };
    });
  };

  const handleItemChange: OnItemChangeFunction = (newItem) => {
    setItemUpdates((prev) => {
      if (!newItem.id) {
        return prev;
      }
      return {
        ...prev,
        [newItem.id]: {
          id: newItem.id,
          percent: newItem.percent,
          globalLevelLevel: newItem.globalLevelLevel,
          bonusStructureGroupId: newItem.bonusStructureGroupId,
          jobLevelId: newItem.jobLevelId,
        },
      };
    });
  };

  // Define the headers for the CSV
  const csvHeaders: CsvHeader[] = [
    { key: 'groupName', label: 'Group Name', required: true },
    { key: 'globalLevel', label: 'Global Level', required: true },
    { key: 'percent', label: 'Bonus %', type: 'percentage', required: true },
  ];

  // Generate example content for the CSV download
  const generateExampleContent = () => {
    let csvContent = `${csvHeaders.map((h) => h.label).join(',')}\n`;

    for (const level of orderedGlobalLevels) {
      csvContent += `Example Bonus Group,${level.name || `L${level.level}`},\n`;
    }

    for (const level of orderedGlobalLevels) {
      csvContent += `Another Example Group,${
        level.name || `L${level.level}`
      },\n`;
    }

    return csvContent;
  };

  // Row validation function
  const validateBonusRow = (row: Record<string, string>, rowIndex: number) => {
    if (
      row['Bonus %'] === undefined ||
      row['Bonus %'] === null ||
      row['Bonus %'] === ''
    ) {
      return `Line ${rowIndex + 1}: Bonus % is missing`;
    }

    // Check for invalid values with correct key
    if (isNaN(Number(row['Bonus %'])) || Number(row['Bonus %']) < 0) {
      return `Line ${rowIndex + 1}: Invalid percentage value`;
    }

    return null;
  };

  // Process data function
  const processBonusData = async (
    groupedData: Record<string, Array<Record<string, CsvValue>>>
  ) => {
    try {
      // Process each group
      for (const [groupName, items] of Object.entries(groupedData)) {
        const result = await saveGroups({
          variables: {
            bonusStructureGroupSaveManyRequest: {
              items: [{ name: groupName }],
            },
          },
        });

        const newGroupId = result.data?.bonusStructureGroupsSave?.[0]?.id;
        if (!newGroupId) {
          throw new Error('Failed to create new group');
        }

        const bonusStructureRequests: BonusStructureSaveRequest[] = [];

        for (const item of items) {
          const foundGlobalLevel = visibleGlobalLevels?.find(
            (level) =>
              level.name === item.globalLevel ||
              `L${level.level}` === item.globalLevel
          );

          if (foundGlobalLevel) {
            bonusStructureRequests.push({
              bonusStructureGroupId: newGroupId,
              globalLevelLevel: foundGlobalLevel.level,
              percent: Number(item.percent),
            });
          }
        }

        if (bonusStructureRequests.length > 0) {
          await saveItems({
            variables: {
              bonusStructuresSaveRequest: {
                items: bonusStructureRequests,
              },
            },
          });
        }
      }

      client.refetchQueries({
        include: [BonusStructureGroupsDocument],
      });
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(`Import failed: ${error.message}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Import failed due to an unknown error', {
          variant: 'error',
        });
      }

      throw error;
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <EditButtons
          requiredScopes={[Scope.RANGE_WIDTHS_EDIT]}
          onEdit={() => setEditing(true)}
          onSave={() => {
            handleSave();
          }}
          onCancel={handleCancel}
        />
        {editing && (
          <CsvUploadsModalWrapper
            pageName={t('bonusStructuresPage.title', 'Bonus Structures')}
            headers={csvHeaders}
            exampleContent={generateExampleContent()}
            templateFileName="bonus_structures_template.csv"
            validateRow={validateBonusRow}
            processData={processBonusData}
            groupByField="groupName"
          />
        )}
      </Box>
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Table
          editing={editing}
          onGroupChange={handleGroupChange}
          onItemChange={handleItemChange}
        />
        <Stack direction="column" spacing={1}>
          <NewGroup editing={editing} />
        </Stack>
      </Stack>

      {editing && <Box sx={{ mb: 10 }} />}
    </>
  );
}

const CsvUploadsModalWrapper = (
  props: React.ComponentProps<typeof CsvUploadsModal>
) => {
  return (
    <CsvUploadsModal
      {...props}
      renderButton={(onClick) => (
        <Box sx={{ ml: 2 }}>
          <CsvUploadsModal.Button onClick={onClick} />
        </Box>
      )}
    />
  );
};
