import { JobFamilyFragment } from '@alamere/generated-graphql-types';

export const createNewJobFamily = (
  code: string,
  name: string,
  existingFamilies: JobFamilyFragment[]
): JobFamilyFragment => ({
  __typename: 'JobFamily' as const,
  id: getNextTempId(existingFamilies),
  code: code.trim(),
  name: name.trim(),
  ignoreGeoDiff: false,
  compBandMultiplier: 1,
  radfordExecutiveMatchCode: null,
  radfordManagementMatchCode: null,
  radfordProfessionalMatchCode: null,
  radfordScientificMatchCode: null,
  radfordSupportMatchCode: null,
  equityBandGroup: null,
  bonusStructureGroup: null,
  jobFunction: null,
  rangeWidthGroup: null,
  compBandGroup: null,
  dynamicParentId: null,
  dynamicPercent: null,
});

const getNextTempId = (jobFamilies: JobFamilyFragment[]): number => {
  const maxId = jobFamilies.reduce(
    (max, family) => Math.max(max, family.id || 0),
    0
  );

  return maxId + 1;
};

export const getNextCode = (
  jobFamilies: JobFamilyFragment[],
  defaultPrefix?: string,
  defaultPadding?: number
): string => {
  const DEFAULT_PREFIX = defaultPrefix ?? 'JF';
  const DEFAULT_PADDING = defaultPadding ?? 4;

  const maxNumber = jobFamilies.reduce((max, family) => {
    const match = family.code?.match(/(^[A-Z\.\-]+)(\d+$)/);
    if (!match) return max;

    const number = parseInt(match[2]);
    return Math.max(max, number);
  }, 0);

  const nextNumber = maxNumber + 1;
  return `${DEFAULT_PREFIX}${String(nextNumber).padStart(
    DEFAULT_PADDING,
    '0'
  )}`;
};

export const findDuplicates = (
  jobFamilies: JobFamilyFragment[],
  currentId: number,
  field: 'code' | 'name',
  value: string
): boolean => {
  return jobFamilies.some(
    (family) =>
      family.id !== currentId &&
      family[field]?.toLowerCase() === value?.toLowerCase()
  );
};
