import { useResetPasswordMutation } from '@alamere/generated-graphql-types';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  defaultValidationResults,
  PasswordRequirementValidationResult,
  validatePassword,
} from '../../lib/passwordValidation';
import PasswordValidationFeedback from '../../components/PasswordValidationFeedback';
import PasswordInputVisibilityToggleButtonProps from '../../components/PasswordInputVisibilityToggleButtonProps';

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [email] = useState(params.get('email') || '');
  const [password, setPassword] = useState('');
  const [code] = useState(params.get('code') || '0');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordValidationResults, setPasswordValidationResults] = useState<
    PasswordRequirementValidationResult[]
  >(defaultValidationResults);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error] = useState(false);
  const [resetPassword, { loading }] = useResetPasswordMutation();

  const handlePasswordChange = useCallback(
    (password: string) => {
      setPassword(password);
      const validationResults = validatePassword(password);
      setPasswordValidationResults(validationResults);
      const _isPasswordValid = validationResults
        .map(({ isValid }) => isValid)
        .every((v) => v === true);
      setIsPasswordValid(_isPasswordValid);
    },
    [setPassword, setPasswordValidationResults, setIsPasswordValid]
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isPasswordValid) {
      return;
    }
    return resetPassword({
      variables: {
        resetPasswordRequest: {
          email: email,
          password: password || '',
          code: code,
        },
      },
    }).then(() => {
      navigate({
        pathname: '/login',
        search: createSearchParams({ email, password }).toString(),
      });
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LockResetRoundedIcon />

        <Typography component="h1" variant="h5">
          Reset your password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            disabled={true}
          />
          <TextField
            disabled={loading}
            margin="normal"
            required
            fullWidth
            id="password"
            label="New password"
            name="password"
            autoComplete="new-password"
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            error={!isPasswordValid}
            InputProps={PasswordInputVisibilityToggleButtonProps({
              isToggledOn: isPasswordVisible,
              onToggle: () => setIsPasswordVisible((_isVisible) => !_isVisible),
            })}
          />

          <PasswordValidationFeedback
            passwordValidationResults={passwordValidationResults}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || !isPasswordValid}
          >
            Reset Password
          </Button>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button onClick={() => navigate('/login')} variant="text">
                {'Remember your password? Log In'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
