import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { JobFragment } from '../../../graphql/_generated/generated-graphql-types';
import { OnItemChangeFunction } from './JobDefinitionsPage.page';

interface Props {
  job: JobFragment;
  editing: boolean;
  onItemChange: OnItemChangeFunction;
  onToggleExpand?: () => void;
}

export default memo(({ job, editing, onToggleExpand }: Props) => {
  return (
    <Box
      sx={{ cursor: editing ? 'pointer' : 'default' }}
      onClick={editing ? onToggleExpand : undefined}
    >
      <Typography fontWeight="bold">{job.title}</Typography>
      {job.businessTitle && (
        <Typography variant="body2" color="primary" fontWeight="bold">
          {job.businessTitle}
        </Typography>
      )}
      {(job.jobCode || job.jobSubLevel) && (
        <Typography variant="caption" color="text.secondary" display="block">
          {job.jobCode && `Code: ${job.jobCode}`}
          {job.jobCode && job.jobSubLevel && ' | '}
          {job.jobSubLevel && `Level: ${job.jobSubLevel}`}
        </Typography>
      )}
      {job.jobType === 'Hourly' && (
        <Typography variant="caption" color="warning.main" display="block">
          Hourly
        </Typography>
      )}
    </Box>
  );
});
