import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  JobFamilySaveRequest,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import { OutlinedInput, TableCell, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { DEBOUNCE_MS } from '../../../lib/constants';
import { OnItemChangeFunction } from '../types';

interface Props {
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
  error?: boolean;
}

export const Name = memo(
  ({ editing, jobFamily, onItemChange, error }: Props) => {
    const debouncedOnItemChange = debounce(onItemChange, DEBOUNCE_MS);
    const { cache } = useApolloClient();
    // Track if this specific cell has been edited and cleared its error
    const [errorCleared, setErrorCleared] = useState(false);

    // Calculate the effective error state - original error unless cleared by edit
    const effectiveError = error && !errorCleared;

    // Reset the error cleared flag when error prop changes or edit mode changes
    useEffect(() => {
      setErrorCleared(false);
    }, [error, editing]);

    const handleChange = (value: string) => {
      // Clear error for this cell when user edits it
      setErrorCleared(true);

      const data = { ...jobFamily, name: value };

      cache.writeFragment<JobFamilySaveRequest>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data,
      });

      debouncedOnItemChange({ newItem: data });
    };

    return (
      <TableCell>
        <OutlinedInput
          value={jobFamily.name || ''}
          sx={{
            display: editing ? undefined : 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: effectiveError ? 'error.main' : undefined,
            },
          }}
          size="small"
          fullWidth
          error={effectiveError}
          onChange={(e) => handleChange(e.target.value)}
        />
        <Typography
          sx={{
            display: !editing ? undefined : 'none',
            color: error ? 'error.main' : undefined,
          }}
        >
          {jobFamily?.name}
        </Typography>
      </TableCell>
    );
  }
);
