import React from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import { Head } from './Head';
import TableBody from '@mui/material/TableBody';
import { Row } from './Row';
import { JOB_GROUPS, Tier } from '@alamere/core';
import { groupBy, orderBy } from 'lodash';

export type RangeBounds = {
  salaryRangeMin: number;
  salaryRangeMax: number;
  hourlyRateRangeMin: number;
  hourlyRateRangeMax: number;
  equityRangeMin: number;
  equityRangeMax: number;
};

function getAbsoluteRangeBounds(
  jobs: Array<{
    compBand: {
      onTargetEarnings: number;
      absoluteVariable: number | null;
      percentVariable: number;
      hourlyRate: number;
    };
    rangeWidth: { percentBelowMid: number; percentAboveMid: number };
    equityBand: {
      value: number;
      rangeWidth: { percentBelowMid: number; percentAboveMid: number };
    };
    compBandMultiplier: number;
    bonus: { percent: number };
  }>,
  tier: Tier | null
) {
  let salaryRangeMin = Infinity;
  let salaryRangeMax = -Infinity;
  let hourlyRateRangeMin = Infinity;
  let hourlyRateRangeMax = -Infinity;
  let equityRangeMin = Infinity;
  let equityRangeMax = -Infinity;
  jobs.forEach((job) => {
    if (
      tier &&
      job &&
      job.bonus &&
      job.rangeWidth &&
      job.compBand &&
      job.compBandMultiplier &&
      job.equityBand
    ) {
      const { compBand, rangeWidth, equityBand, compBandMultiplier } = job;

      const salaryMid =
        compBand.onTargetEarnings *
        (compBandMultiplier / 100) *
        (tier.percentile / 100);

      const currentSalaryMin =
        salaryMid * (1 - rangeWidth.percentBelowMid / 100);

      const currentSalaryMax =
        salaryMid * (1 + rangeWidth.percentAboveMid / 100);

      const hourlyRateMid =
        compBand.hourlyRate *
        (compBandMultiplier / 100) *
        (tier.percentile / 100);

      const currentHourlyRateMin =
        hourlyRateMid * (1 - rangeWidth.percentBelowMid / 100);

      const currentHourlyRateMax =
        hourlyRateMid * (1 + rangeWidth.percentAboveMid / 100);

      const equityMid = equityBand.value * (tier.percentile / 100);

      const currentEquityMin =
        equityMid * (1 - equityBand.rangeWidth.percentBelowMid / 100);

      const currentEquityMax =
        equityMid * (1 + equityBand.rangeWidth.percentAboveMid / 100);

      salaryRangeMin = Math.min(salaryRangeMin, currentSalaryMin);
      salaryRangeMax = Math.max(salaryRangeMax, currentSalaryMax);
      hourlyRateRangeMin = Math.min(hourlyRateRangeMin, currentHourlyRateMin);
      hourlyRateRangeMax = Math.max(hourlyRateRangeMax, currentHourlyRateMax);
      equityRangeMin = Math.min(equityRangeMin, currentEquityMin);
      equityRangeMax = Math.max(equityRangeMax, currentEquityMax);
    }
  });
  return {
    salaryRangeMin,
    salaryRangeMax,
    hourlyRateRangeMin,
    hourlyRateRangeMax,
    equityRangeMin,
    equityRangeMax,
  };
}

function getWidestRangePercentages(
  rangeWidths: Array<{ percentBelowMid: number; percentAboveMid: number }>
) {
  // This addresses an edge case where the widest percentage above and below aren't equal - they
  //   need to be equal for the midpoint on the range display to be centered.
  let largestPercent = -Infinity;
  rangeWidths.forEach(({ percentBelowMid, percentAboveMid }) => {
    largestPercent = Math.max(largestPercent, percentBelowMid);
    largestPercent = Math.max(largestPercent, percentAboveMid);
  });
  return {
    rangePercentageMin: 100 - largestPercent,
    rangePercentageMax: 100 + largestPercent,
  };
}

function getRangeBoundsFromPercentages(
  job: {
    compBand: {
      onTargetEarnings: number;
      absoluteVariable: number | null;
      percentVariable: number;
      hourlyRate: number;
    };
    rangeWidth: { percentBelowMid: number; percentAboveMid: number };
    equityBand: { value: number };
    compBandMultiplier: number;
    bonus: { percent: number };
  },
  tier: Tier,
  cashRangePercentageMin: number,
  cashRangePercentageMax: number,
  equityRangePercentageMin: number,
  equityRangePercentageMax: number
): RangeBounds {
  const { compBand, equityBand, compBandMultiplier } = job;
  const salaryMid =
    compBand.onTargetEarnings *
    (compBandMultiplier / 100) *
    (tier.percentile / 100);

  const hourlyRateMid =
    compBand.hourlyRate * (compBandMultiplier / 100) * (tier.percentile / 100);

  const equityMid = equityBand.value * (tier.percentile / 100);

  const cashMinMultiplier = cashRangePercentageMin / 100;
  const cashMaxMultiplier = cashRangePercentageMax / 100;

  return {
    salaryRangeMin: salaryMid * cashMinMultiplier,
    salaryRangeMax: salaryMid * cashMaxMultiplier,
    hourlyRateRangeMin: hourlyRateMid * cashMinMultiplier,
    hourlyRateRangeMax: hourlyRateMid * cashMaxMultiplier,
    equityRangeMin: equityMid * (equityRangePercentageMin / 100),
    equityRangeMax: equityMid * (equityRangePercentageMax / 100),
  };
}

interface Props {
  tier: Tier | null;
  processedData: any; // TODO use more specific type
  selectedLevels: number[];
  isAbsoluteRangeMode: boolean;
  shallShowBreakdownButtons: boolean;
}

export function Table({
  tier,
  processedData,
  selectedLevels,
  isAbsoluteRangeMode,
  shallShowBreakdownButtons,
}: Props) {
  if (!processedData) {
    return null;
  }

  const orderedJobs = orderBy(
    processedData?.jobs,
    [(j) => j.jobLevel.level],
    ['desc']
  );

  const groupedJobs = groupBy(orderedJobs, (j) => j.jobLevel.group);

  const renderRows = () => {
    if (!tier) {
      return null;
    }

    return JOB_GROUPS.flatMap((group) => {
      const jobsInGroup = groupedJobs[group] || [];
      const absoluteRangeBounds = getAbsoluteRangeBounds(jobsInGroup, tier);
      const {
        rangePercentageMin: cashRangePercentageMin,
        rangePercentageMax: cashRangePercentageMax,
      } = getWidestRangePercentages(
        jobsInGroup.map(({ rangeWidth }) => rangeWidth)
      );
      const {
        rangePercentageMin: equityRangePercentageMin,
        rangePercentageMax: equityRangePercentageMax,
      } = getWidestRangePercentages(
        jobsInGroup.map(({ equityBand }) => equityBand.rangeWidth)
      );
      let isFirstValidRowInGroup = true;

      return jobsInGroup
        .map((job: any, index) => {
          if (
            job.missingRequiredData ||
            !selectedLevels.includes(job.jobLevel.level)
          ) {
            return null;
          }
          const rangeBounds = isAbsoluteRangeMode
            ? absoluteRangeBounds
            : getRangeBoundsFromPercentages(
                job,
                tier,
                cashRangePercentageMin,
                cashRangePercentageMax,
                equityRangePercentageMin,
                equityRangePercentageMax
              );

          const row = (
            <Row
              key={job.id}
              job={job}
              bonus={job.bonus}
              compBand={job.compBand}
              compBandGroupName={processedData.compBandGroup.name}
              jobFamilyName={processedData.name}
              rangeBounds={rangeBounds}
              rangeWidth={job.rangeWidth}
              rangeWidthGroupName={processedData.rangeWidthGroup.name}
              equityBand={job.equityBand}
              equityBandGroupName={processedData.equityBandGroup.name}
              equityBandRangeWidthGroupName={
                processedData.equityBandGroup.rangeWidthGroup.name
              }
              compBandMultiplier={job.compBandMultiplier}
              tier={tier}
              itemIndex={index}
              isFirstInGroup={isFirstValidRowInGroup}
              shallShowBreakdownButtons={shallShowBreakdownButtons}
            />
          );

          if (isFirstValidRowInGroup) {
            isFirstValidRowInGroup = false;
          }

          return row;
        })
        .filter(Boolean);
    });
  };

  return (
    <TableContainer component={Paper} sx={{ minWidth: '1440px' }}>
      <MuiTable size="small">
        <Head />
        <TableBody>{renderRows()}</TableBody>
      </MuiTable>
    </TableContainer>
  );
}
