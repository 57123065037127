import {
  AlamereLocationId,
  AlamereLocations,
  LocationType,
  Tier,
  TierManager,
} from '@alamere/core';
import { ZipcodeResponse } from '@alamere/generated-graphql-types';
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import {
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { geoCentroid } from 'd3-geo';
import { Fragment, ReactElement, useContext, useState } from 'react';
import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';
import { yellow } from '../../layout/colors';
import { ColorModeContext, TIER_COLORS } from '../../layout/theme';
import { Exclusions } from './Exclusions';
import ALL_STATES from './data/all-states';
import STATE_TOPOLOGIES from './data/state-topologies';
import React from 'react';

export type MapProps = {
  zipcodeResponse?: ZipcodeResponse;
  tierManager: TierManager;
};

const tierToRadius: { [key: string]: number } = {
  '1': 17,
  '2': 12,
  '3': 9,
  default: 6,
};

const MARKER_COLOR = yellow[300];
const DEFAULT_CENTER: [number, number] = [-98, 38];
const DEFAULT_ZOOM = 0.9;

function Map({ zipcodeResponse, tierManager }: MapProps) {
  const {
    palette: { divider, grey },
  } = useTheme();
  const colorMode = useContext(ColorModeContext);

  const renderTierMarkers = () => {
    const items = Object.keys(tierManager.getTierConfig())
      .reverse()
      .reduce((acc, tierNumber) => {
        const tierMarkers = [];

        for (const id of tierManager.getTierConfig()[tierNumber]
          .alamereLocationIds) {
          if (!shouldShowMetroMarker(id, tierManager, tierNumber)) {
            continue;
          }
          const { label, percentile } = tierManager.getTier(id);

          tierMarkers.push(
            <LocationTooltip
              key={id}
              label={AlamereLocations[id]?.label}
              tierLabel={label || ''}
              percent={percentile}
              ExclusionsComponent={
                <Exclusions id={id} tierManager={tierManager} />
              }
            >
              <Marker
                key={`tier-marker-${id}`}
                coordinates={[
                  AlamereLocations[id].long!,
                  AlamereLocations[id].lat!,
                ]}
              >
                <circle
                  style={{
                    filter: 'drop-shadow(2px 2px 2px rgb(0 0 0 / 0.3))',
                  }}
                  r={tierToRadius[tierNumber] || tierToRadius.default}
                  fill={
                    tierManager.isInAllOtherLocationsTier(id)
                      ? grey[600]
                      : TIER_COLORS[tierNumber][700]
                  }
                  stroke="#fff"
                  strokeWidth={1}
                />
              </Marker>
            </LocationTooltip>
          );
        }

        return acc.concat(tierMarkers);
      }, [] as JSX.Element[]);
    return items;
  };

  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);

  const recenter = () => {
    setCenter(DEFAULT_CENTER);
    setZoom(DEFAULT_ZOOM);
  };

  return (
    <Paper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: 10,
        position: 'relative',
      }}
    >
      {center[0] === DEFAULT_CENTER[0] &&
      center[1] === DEFAULT_CENTER[1] &&
      zoom === DEFAULT_ZOOM ? null : (
        <Tooltip title="Recenter map" placement="top-end">
          <Button
            aria-label="Recenter map"
            sx={{ position: 'absolute', bottom: 10, right: 10 }}
            onClick={recenter}
          >
            <CenterFocusWeakRoundedIcon />
          </Button>
        </Tooltip>
      )}
      <ComposableMap
        projection="geoAlbersUsa"
        style={{ width: '100%', height: 'auto', maxHeight: 600 }}
      >
        <ZoomableGroup
          zoom={zoom}
          center={center}
          onMoveEnd={(position) => {
            setCenter(position.coordinates);
            setZoom(position.zoom);
          }}
        >
          <Geographies geography={STATE_TOPOLOGIES}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => {
                  const centroid = geoCentroid(geo);
                  const state = ALL_STATES.find((s) => s.val === geo.id); // TODO: change to map so we dont O(n) on every search
                  const colorShade =
                    colorMode.activeMode === 'light' ? 200 : 800;

                  if (!state) {
                    return null;
                  }
                  const id = state!.id as AlamereLocationId;

                  const stateColor = tierManager.isInAllOtherLocationsTier(id)
                    ? grey[colorShade]
                    : TIER_COLORS[tierManager.getTier(id).tierNumber][700];

                  return (
                    <Fragment key={geo.rsmKey}>
                      <LocationTooltip
                        label={
                          AlamereLocations[id]?.label ||
                          AlamereLocations.AllOtherLocations.label
                        }
                        tierLabel={tierManager.getTier(id)?.label || ''}
                        percent={tierManager.getTier(id)?.percentile}
                        ExclusionsComponent={
                          <Exclusions id={id} tierManager={tierManager} />
                        }
                      >
                        <Geography
                          key={geo.rsmKey}
                          stroke={divider}
                          geography={geo}
                          fill={stateColor}
                          style={{
                            default: { outline: 'none' },
                            hover: { outline: 'none' },
                            pressed: { outline: 'none' },
                          }}
                        />
                      </LocationTooltip>

                      <g>
                        {state && centroid[0] > -160 && centroid[0] < -67 && (
                          <LocationLabel
                            state={state}
                            tier={tierManager.getTier(id)}
                            centroid={centroid}
                            ExclusionsComponent={
                              <Exclusions id={id} tierManager={tierManager} />
                            }
                          />
                        )}
                      </g>
                    </Fragment>
                  );
                })}
              </>
            )}
          </Geographies>

          {renderTierMarkers()}

          {zipcodeResponse?.lat && zipcodeResponse?.long && (
            <Marker
              key="search-result"
              coordinates={[zipcodeResponse.long, zipcodeResponse.lat]}
            >
              <ResultMarker
                tier={
                  tierManager.getTier(
                    tierManager.getMostSpecificLocationId(
                      zipcodeResponse.alamereLocationId as AlamereLocationId,
                      zipcodeResponse.state
                    )
                  ).tierNumber
                }
              />
            </Marker>
          )}
        </ZoomableGroup>
      </ComposableMap>
    </Paper>
  );
}

export default React.memo(Map);

const offsetsWithConnectors: { [state: string]: number[] } = {
  VT: [50, -8],
  NH: [34, 5],
  MA: [30, -6],
  RI: [24, 0],
  CT: [37, 10],
  NJ: [22, 5],
  DE: [27, -3],
  MD: [40, 10],
  DC: [40, 23],
};
const offsetsWithoutConnectors: { [state: string]: number[] } = {
  FL: [3, 3],
  MI: [-2, 15],
  LA: [-21, 0],
  CA: [-18, 5],
};

function LocationLabel({
  state,
  tier,
  centroid,
  ExclusionsComponent,
}: {
  state: { id: string; val: string };
  tier: Tier;
  centroid: [number, number];
  ExclusionsComponent?: ReactElement;
}) {
  const {
    palette: { divider, text },
  } = useTheme();
  const shouldOffset =
    offsetsWithConnectors[state.id] !== undefined ||
    offsetsWithoutConnectors[state.id] !== undefined;

  if (shouldOffset) {
    const showConnector = offsetsWithConnectors[state.id] !== undefined;
    const x = offsetsWithConnectors[state.id]
      ? offsetsWithConnectors[state.id][0]
      : offsetsWithoutConnectors[state.id]
      ? offsetsWithoutConnectors[state.id][0]
      : undefined;
    const y = offsetsWithConnectors[state.id]
      ? offsetsWithConnectors[state.id][1]
      : offsetsWithoutConnectors[state.id]
      ? offsetsWithoutConnectors[state.id][1]
      : undefined;

    return (
      <LocationTooltip
        label={
          AlamereLocations[state.id as AlamereLocationId]?.label ||
          AlamereLocations.AllOtherLocations.label
        }
        tierLabel={tier.label || ''}
        percent={tier.percentile}
        ExclusionsComponent={ExclusionsComponent}
      >
        <Annotation
          subject={centroid}
          dx={x}
          dy={y}
          connectorProps={{
            stroke: divider,
            strokeWidth: showConnector ? 1 : 0,
            strokeLinecap: 'round',
          }}
        >
          <text
            x={4}
            fontSize={14}
            alignmentBaseline="middle"
            fill={text.primary}
            cursor="default"
          >
            {state.id}
          </text>
        </Annotation>
      </LocationTooltip>
    );
  }
  return (
    <LocationTooltip
      label={
        AlamereLocations[state.id as AlamereLocationId]?.label ||
        AlamereLocations.AllOtherLocations.label
      }
      tierLabel={tier.label || ''}
      percent={tier.percentile || 0}
      ExclusionsComponent={ExclusionsComponent}
    >
      <Marker coordinates={centroid}>
        <text
          y={5}
          fontSize={14}
          textAnchor="middle"
          fill={text.primary}
          cursor="default"
        >
          {state.id}
        </text>
      </Marker>
    </LocationTooltip>
  );
}

function ResultMarker({ tier }: { tier?: string }) {
  const SIZE = 60;

  return (
    <g
      transform={`translate(-${SIZE / 2}, -${SIZE})`}
      stroke="#fff"
      strokeWidth="1.3"
    >
      <PlaceRoundedIcon
        width={SIZE}
        height={SIZE}
        viewBox="0 0 24 24"
        sx={{
          color: MARKER_COLOR,
          filter: 'drop-shadow(1.8px 2px 1.5px rgb(0 0 0 / 0.4))',
        }}
      />

      {tier && (
        <text
          fontSize={SIZE / 2.5}
          paintOrder="stroke"
          fontWeight="bold"
          strokeWidth={6}
          strokeLinejoin="round"
          transform={`translate(${SIZE * 0.68}, ${SIZE * 0.35})`}
          fill={MARKER_COLOR}
        >
          {tier}
        </text>
      )}
    </g>
  );
}

function LocationTooltip({
  label,
  tierLabel,
  percent,
  children,
  ExclusionsComponent,
}: {
  label: string;
  tierLabel: string;
  percent: number;
  children: ReactElement;
  ExclusionsComponent?: ReactElement;
}) {
  return (
    <Tooltip
      componentsProps={{ tooltip: { sx: { maxWidth: 350 } } }}
      title={
        <Stack>
          <Typography>{label}</Typography>
          <Typography variant="body2">
            {`${tierLabel} (${percent}%)`}
          </Typography>
          {ExclusionsComponent}
        </Stack>
      }
      followCursor
    >
      {children}
    </Tooltip>
  );
}

function shouldShowMetroMarker(
  id: AlamereLocationId,
  tierManager: TierManager,
  tierNumber: string
) {
  const isMissingLatLong =
    !AlamereLocations[id].lat || !AlamereLocations[id].long;

  if (isMissingLatLong) {
    return false;
  }

  const isState = AlamereLocations[id].type === LocationType.STATE;

  if (isState) {
    return false;
  }

  const parentId = AlamereLocations[id].parentId;
  const hasParentInOtherTier =
    parentId !== null &&
    parentId !== AlamereLocationId.AllOtherLocations &&
    tierManager.getTier(parentId).tierNumber !== tierNumber;

  if (tierManager.isInAllOtherLocationsTier(id) && !hasParentInOtherTier) {
    return false;
  }

  return true;
}
