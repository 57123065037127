import { JobGroup } from '@alamere/core';
import {
  JobFamilyFragment,
  JobFamilyFragmentDoc,
  RadfordJobFamilyFragment,
  useRadfordJobFamiliesSearchLazyQuery,
} from '@alamere/generated-graphql-types';
import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Skeleton,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { OnItemChangeFunction } from '../types';

interface Props {
  loading: boolean;
  editing: boolean;
  jobFamily: JobFamilyFragment;
  onItemChange: OnItemChangeFunction;
  loadRadfordMatch: (
    code: string,
    jobGroup: JobGroup
  ) => Promise<RadfordJobFamilyFragment | null>;
  jobGroup: JobGroup;
}

export const RadfordMatch = memo(
  ({
    editing,
    loading,
    jobFamily,
    jobGroup,
    loadRadfordMatch,
    onItemChange,
  }: Props) => {
    const { cache } = useApolloClient();

    const [value, setValue] = useState<RadfordJobFamilyFragment | null>(null);
    const [loadingMatch, setLoadingMatch] = useState(false);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [
      searchUnDebounced,
      { data: familiesData, loading: loadingFamilies },
    ] = useRadfordJobFamiliesSearchLazyQuery();

    const search = useCallback(debounce(searchUnDebounced, 500), [
      searchUnDebounced,
    ]);

    useEffect(() => {
      const loadInitialMatch = async () => {
        const matchCode = getMatchCode(jobFamily, jobGroup);
        if (!matchCode) {
          setValue(null);
          return;
        }

        try {
          setLoadingMatch(true);
          const match = await loadRadfordMatch(matchCode, jobGroup);
          setValue(match);
        } catch (error) {
          console.error('Error loading Radford match:', error);
        } finally {
          setLoadingMatch(false);
        }
      };

      loadInitialMatch();
    }, [jobFamily.id, jobGroup, loadRadfordMatch]);

    useEffect(() => {
      if (inputValue.length > 1 && open) {
        search({
          variables: {
            request: { query: inputValue, jobGroup },
          },
        });
      }
    }, [inputValue, jobGroup, search, open]);

    const handleChange = (newValue: RadfordJobFamilyFragment | null) => {
      setValue(newValue);

      const data = {
        ...jobFamily,
        ...toJobFamilyProperty(newValue?.code, jobGroup),
      };

      cache.writeFragment<JobFamilyFragment>({
        id: `JobFamily:${jobFamily.id}`,
        fragmentName: 'JobFamily',
        fragment: JobFamilyFragmentDoc,
        data,
      });

      onItemChange({
        newItem: data,
      });
    };

    if (loading || loadingMatch) {
      return (
        <TableCell>
          <Skeleton />
        </TableCell>
      );
    }

    return (
      <TableCell>
        <Autocomplete
          options={
            inputValue.length > 1
              ? familiesData?.radfordJobFamiliesSearch ?? []
              : []
          }
          selectOnFocus
          handleHomeEndKeys
          autoComplete
          blurOnSelect
          value={value ?? null}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
            setInputValue('');
          }}
          noOptionsText={
            inputValue.length > 1
              ? loadingFamilies
                ? t('jobFamiliesPage.table.radfordMatch.loading')
                : t('jobFamiliesPage.table.radfordMatch.noResults')
              : t('jobFamiliesPage.table.radfordMatch.searchForResults')
          }
          size="small"
          id="equity-band-group-autocomplete"
          onChange={(e, v) => handleChange(v)}
          onInputChange={(e, v) => {
            setInputValue(v);
          }}
          sx={{ minWidth: 200, display: editing ? undefined : 'none' }}
          isOptionEqualToValue={(option, value) => option.code === value?.code}
          renderInput={(params) => (
            <TextField
              title={value ? [value.code, value.label].join(' - ') : undefined}
              {...params}
            />
          )}
          getOptionLabel={(option) => [option.code, option.label].join(' - ')}
          renderOption={(props, option, ownerState) => (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    background: 'none',
                  },
                },
              }}
              placement="right-start"
              title={<TooltipCard option={option} />}
            >
              <Box component="li" {...props}>
                {[option.code, option.label].join(' - ')}
              </Box>
            </Tooltip>
          )}
        />
        <Typography
          sx={{
            display: editing ? 'none' : undefined,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value?.label}
        >
          {value && [value?.code, value?.label].join(' - ')}
        </Typography>
      </TableCell>
    );
  }
);

function getMatchCode(
  jobFamily: JobFamilyFragment,
  jobGroup: JobGroup
): string | null | undefined {
  switch (jobGroup) {
    case JobGroup.Executive:
      return jobFamily.radfordExecutiveMatchCode;
    case JobGroup.Management:
      return jobFamily.radfordManagementMatchCode;
    case JobGroup.Professional:
      return jobFamily.radfordProfessionalMatchCode;
    case JobGroup.TechnicalScientific:
      return jobFamily.radfordScientificMatchCode;
    case JobGroup.Support:
      return jobFamily.radfordSupportMatchCode;
    default:
      return null;
  }
}

function toJobFamilyProperty(
  code: string | undefined | null,
  jobGroup: JobGroup
) {
  switch (jobGroup) {
    case JobGroup.Executive:
      return {
        radfordExecutiveMatchCode: code ?? null,
      };
    case JobGroup.Management:
      return {
        radfordManagementMatchCode: code ?? null,
      };
    case JobGroup.Professional:
      return {
        radfordProfessionalMatchCode: code ?? null,
      };
    case JobGroup.TechnicalScientific:
      return {
        radfordScientificMatchCode: code ?? null,
      };
    case JobGroup.Support:
      return {
        radfordSupportMatchCode: code ?? null,
      };
    default:
      throw new Error(`Unknown job group: ${jobGroup}`);
  }
}

function TooltipCard({ option }: { option: RadfordJobFamilyFragment }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        maxHeight: 400,
        overflow: 'scroll',
      }}
    >
      <CardContent>
        <Typography variant="h6" color="text.secondary">
          {option.code}
        </Typography>
        <Typography variant="h5">{option.area}</Typography>
        <Typography variant="h5">{option.focus}</Typography>
        <Typography sx={{ my: 1 }} color="text.secondary" variant="body2">
          {option.detailedDefinition}
        </Typography>
      </CardContent>
    </Card>
  );
}
