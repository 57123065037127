import { Scopes } from '@alamere/core';
import {
  useGetCustomRolesQuery,
  Scope,
  useCreateCustomRoleMutation,
  useDeleteCustomRoleMutation,
  useUpdateCustomRoleMutation,
} from '@alamere/generated-graphql-types';
import DeleteIconRounded from '@mui/icons-material/DeleteRounded';
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { ScopeTable } from '../../components/ScopeTable';

export function CustomRoleDetailPage() {
  const { data, refetch } = useGetCustomRolesQuery();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [create, { loading: loadingCreate }] = useCreateCustomRoleMutation();
  const [update, { loading: loadingUpdate }] = useUpdateCustomRoleMutation();
  const [deleteRole, { loading: loadingDelete }] =
    useDeleteCustomRoleMutation();
  const { roleId: customRoleId } = useParams<{ roleId: string }>();
  const [scopes, setScopes] = useState<Record<Scope, boolean>>(
    {} as Record<Scope, boolean>
  );

  React.useEffect(() => {
    if (data) {
      const customRole = data.getCustomRoles.find(
        (role) => role.id.toString() === customRoleId
      );
      if (customRole) {
        setName(customRole.name);
      }
      const scopes =
        customRole?.scopes?.reduce((acc, scope) => {
          acc[scope] = true;
          return acc;
        }, {} as Record<Scope, boolean>) ?? ({} as Record<Scope, boolean>);
      setScopes(scopes);
    }
  }, [data, customRoleId]);

  if (!data) {
    return <CircularProgress color="success" />;
  }

  const customRole = data.getCustomRoles.find(
    (role) => role.id.toString() === customRoleId
  );

  const handleToggleScope = (scope: Scope) => {
    const toggledDependencies = Scopes[scope]?.requires?.reduce(
      (acc, alsoRequiredScope) => {
        acc[alsoRequiredScope] = !scopes[scope];
        return acc;
      },
      {} as Record<Scope, boolean>
    );
    const toggledDependents = Scopes[scope]?.isDependencyTo?.reduce(
      (acc, dependentScope) => {
        acc[dependentScope] = !scopes[scope];
        return acc;
      },
      {} as Record<Scope, boolean>
    );

    setScopes((prev) => {
      const newValue = !prev[scope];
      const alsoToggle = newValue ? toggledDependencies : toggledDependents;
      return {
        ...prev,
        [scope]: newValue,
        ...alsoToggle,
      };
    });
  };

  const handleSubmit = async () => {
    if (!name) {
      return enqueueSnackbar('Please provide custome role name.', {
        variant: 'error',
      });
    }
    customRole?.id ? await handleUpdate() : await handleCreate();
  };

  const handleCreate = async () => {
    await create({
      variables: {
        createCustomRoleRequest: {
          name,
          scopes: Object.keys(scopes).filter(
            (scope) => scopes[scope as Scope]
          ) as Scope[],
        },
      },
    });
    enqueueSnackbar('Created role successfully.', {
      variant: 'success',
    });
    await refetch();
    navigate('../permissions');
  };

  const handleUpdate = async () => {
    if (!customRole) return;
    await update({
      variables: {
        updateCustomRoleRequest: {
          id: customRole.id,
          name,
          scopes: Object.keys(scopes).filter(
            (scope) => scopes[scope as Scope]
          ) as Scope[],
        },
      },
    });
    enqueueSnackbar('Updated role successfully.', {
      variant: 'success',
    });
    await refetch();
  };

  const handleDelete = async () => {
    if (!customRole) return;
    if (customRole.userCount > 0) {
      enqueueSnackbar('Cannot delete a custom role with users', {
        variant: 'error',
      });
    }
    await deleteRole({
      variables: {
        deleteCustomRoleRequest: {
          id: customRole.id,
        },
      },
    });
    enqueueSnackbar('Deleted role successfully.', {
      variant: 'success',
    });
    await refetch();
    navigate('../permissions');
  };

  const isLoading = loadingCreate || loadingUpdate || loadingDelete;

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        {customRoleId === 'new' ? 'Create Custom Role' : 'Edit Custom Role'}
      </Typography>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb: 4 }}
        disabled={isLoading}
      />
      <ScopeTable
        currentScopes={scopes}
        onToggleScope={handleToggleScope}
        areAllSwitchesDisabled={isLoading}
      />
      <Stack gap={2} mt={4} width="50%">
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading}
          loading={loadingCreate || loadingUpdate}
        >
          {customRoleId === 'new' ? t('Create') : t('Update')}
        </LoadingButton>
        {customRoleId !== 'new' && (
          <LoadingButton
            variant="outlined"
            color="error"
            onClick={handleDelete}
            disabled={isLoading}
            loading={loadingDelete}
            startIcon={<DeleteIconRounded />}
          >
            {t('Delete')}
          </LoadingButton>
        )}
      </Stack>
    </Box>
  );
}
